import {
  Box,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import HubsReportShowPdf from "../../components/HubsComponents/HubsReportShowPdf";
import { useAuth } from "../../context/authContext/Auth";
import RenewDrawer from "../../components/Common/RenewDrawer";
import { saveAs } from "file-saver";
import LoadingButton from "@mui/lab/LoadingButton";
import { BiSolidFilePdf } from "react-icons/bi";
import { ReportAccess } from "./Modals/ReportAccess";
import { DownloadingOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";

const buttonStyle = {
  height: "40px",
  padding: "8px 24px",
  fontWeight: "500",
  borderRadius: "8px",
};

const boxStyle = {
  width: "100%",
  height: "794px",
  backgroundColor: "#F4F8FA",
};

function ReportDetailsTab({
  id,
  title,
  reportPdf,
  subscribed,
  fetchReport,
  reportPpt,
}) {
  const [pdfLoading, setPdfLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(
    reportPdf[reportPdf.length - 1]
  );
  const { userInfo } = useAuth();
  const { remaining_library_credit } = useSelector((state) => state);
  const handleFileChange = (event) => {
    const selectedFile = reportPdf.find(
      (file) => file.file_name === event.target.value
    );
    setSelectedFile(selectedFile);
  };
  const { setNotify } = useAuth();

  async function forceDownload({ report_url }, pdf_name) {
    const urlToDownload = report_url;

    setPdfLoading(true);
    setNotify({
      isOpen: true,
      message: "Download in Progress",
      type: "success",
      position: "right",
    });
    return new Promise(function (resolve, reject) {
      var x = new XMLHttpRequest();
      x.open("GET", urlToDownload, true);
      x.responseType = "blob";
      x.onload = function (e) {
        resolve(x.response);
      };
      x.onerror = function () {
        reject(new Error("Error occurred while downloading PDF."));
      };
      x.send();
    })
      .then(async function (pdf_blob) {
        saveAs(pdf_blob, pdf_name, "application/pdf");
        setNotify({
          isOpen: true,
          message: "Download Completed",
          type: "success",
          position: "right",
        });
        setPdfLoading(false);
      })
      .catch(function (error) {
        setNotify({
          isOpen: true,
          message: "Download Failed",
          type: "error",
          position: "right",
        });

        setPdfLoading(false);
        console.log(error);
      });
  }
  const [showReportAccessModal, setShowReportAccessModal] = useState(false);
  const handleCancel = () => {
    setShowReportAccessModal(false);
    // fetchReport();
  };
  return (
    <Container maxWidth={"xxl"} sx={{ my: "3rem", position: "relative" }}>
      <Box>
        {showReportAccessModal && (
          <ReportAccess
            closeModal={handleCancel}
            title={title}
            report_id={id}
            reloadFunction={fetchReport}
          />
        )}
        {reportPdf && reportPdf.length > 0 && selectedFile.url && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "0.2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {userInfo?.account_type === "view only"
                ? false
                : true && (
                    <Tooltip title="Download PDF">
                      <IconButton
                        aria-label="download pdf"
                        onClick={() =>
                          forceDownload(
                            { report_url: selectedFile.url },
                            selectedFile.file_name
                          )
                        }
                        sx={{
                          color: "#189cde",
                          borderColor: "#002F75",
                          border: "0.8px solid",
                          borderRadius: "8px",

                          padding: "15px 12px",
                          "&:hover": {
                            backgroundColor: "#446ba6",
                            color: "#fff",
                          },
                        }}
                      >
                        <DownloadingOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
              {!subscribed && (
                <Box
                  sx={{
                    marginLeft: "1rem",
                  }}
                >
                  <Typography variant="subtitle1">
                    Get Access to view full report
                    <Button
                      sx={{
                        textTransform: "capitalize",
                      }}
                      color="error"
                      onClick={() => {
                        if (remaining_library_credit > 0) {
                          setShowReportAccessModal(true);
                        } else {
                          setDrawerOpen(true);
                        }
                      }}
                    >
                      Click Here
                    </Button>
                  </Typography>
                </Box>
              )}
            </Box>
            {reportPdf.length > 1 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "1rem",
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: "0.8rem",
                  }}
                >
                  Select File
                </InputLabel>
                <FormControl
                  variant="outlined"
                  className="file-dropdown"
                  sx={{
                    minWidth: 120,
                    color: "#189cde",
                    borderColor: "#002F75",
                    border: "0.8px solid",
                    borderRadius: "8px",
                    marginBottom: "1rem",
                  }}
                >
                  <Select
                    value={selectedFile.file_name}
                    onChange={handleFileChange}
                    sx={{
                      borderRadius: "8px", // Ensure the Select component also has curved edges
                      maxWidth: 400,
                    }}
                  >
                    {reportPdf?.map((file) => (
                      <MenuItem key={file.file_name} value={file.file_name}>
                        {file.fileName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
        )}
        <Box
          style={boxStyle}
          component="div"
          role="tabpanel"
          sx={{
            marginBottom: "2rem",
            overflow: "hidden",
          }}
        >
          {selectedFile.url ? (
            <HubsReportShowPdf
              showDownload={
                userInfo?.account_type === "view only" ? false : true
              }
              report_id={selectedFile.url}
            />
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              height={"100%"}
              alignItems={"center"}
            >
              {!subscribed ? (
                <Box
                  sx={{
                    marginLeft: "1rem",
                  }}
                >
                  <Typography variant="subtitle1">
                    Get Access to view full report
                    <Button
                      sx={{
                        textTransform: "capitalize",
                      }}
                      color="error"
                      onClick={() => {
                        if (remaining_library_credit > 0) {
                          setShowReportAccessModal(true);
                        } else {
                          setDrawerOpen(true);
                        }
                      }}
                    >
                      Click Here
                    </Button>
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => downloadFile(reportPpt?.[0].location, title)}
                  >
                    To view the full report, download the PPT.
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <RenewDrawer
        report_id={id}
        title={title}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={"buy"}
      />
    </Container>
  );
}

export default ReportDetailsTab;

const downloadFile = (url, name) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";

  xhr.onload = () => {
    if (xhr.status === 200) {
      const blob = new Blob([xhr.response], {
        type: "application/vnd.ms-powerpoint.macroEnabledPresentation",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${name}.pptm`; // Set the file name
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  xhr.send();
};
