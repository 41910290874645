import { Box, Fab, Tooltip } from "@mui/material";
import React, { useState,useEffect } from "react";
import Intercom from '@intercom/messenger-js-sdk';
import { Outlet } from "react-router-dom";
// import Footer from "../Footer";
import Footer from "./Footer";
import Navbar from "./Navbar";
import NavigationIcon from "@mui/icons-material/Navigation";
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import Notification from "../Common/Notification";
import { useAuth } from "../../context/authContext/Auth";
import HubsHomeChatDialog from "./ContactUs";
import useMediaQuery from "@mui/material/useMediaQuery";
import RenewDrawer from "../Common/RenewDrawer";
import ScrollToTopButton from "../Common/ScrollToTopButton";
import LiveChat from "../Common/LiveChat/LiveChat";
import Cookies from "universal-cookie";

function Layout() {
  const { notify, setNotify, userInfo } = useAuth();
  const [open, setOpen] = useState(false);
  const isMobileOrTablet = useMediaQuery("(max-width: 960px)");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const cookies = new Cookies();
  const token = cookies.get("TOKEN");


  const handleClickOpen = () => {
    setDrawerOpen(true);
  };

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu from appearing
  };

  // const  user = userInfo?.account_type;
  // useEffect(() => {
  //   const style = document.createElement('style');
  //   style.type = 'text/css';
  //   style.innerHTML = `* { user-select: ${user == 'view only' ? 'none' : 'auto'}; }`;
  //   document.head.appendChild(style);

  //   return () => {
  //     document.head.removeChild(style);
  //   };
  // }, [user]);
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      {/* <Box sx={{ position: "sticky", top: "0", zIndex: "10000" }}> */}
      <Navbar />
      <Notification notify={notify} setNotify={setNotify} />
      {/* <LiveChat/> */}
      {/* <Tooltip title="Contact us" placement="top">
      <Fab
        sx={{
          position: "fixed",
          bottom: ["70px", "80px", (theme) => theme.spacing(3)],
          right: (theme) => theme.spacing(2),
          // opacity: 1,
          transition: "opacity 0.3s",
          "&:hover": {
            backgroundColor: '#002f75',
            opacity: 0.8,

          },
          p: '16px',
          width: '56px',
          height: '56px',
          backgroundColor: '#002f75',
          borderRadius: '50%',
        }}
        variant="extended"
        color="primary"
        aria-label="add"
        onClick={handleClickOpen}
      >
        <HeadphonesOutlinedIcon />
        {!isMobileOrTablet}
      </Fab>
      </Tooltip> */}
      {/* </Box> */}
      <Box
      // onContextMenu={handleContextMenu}
      className={"no-select"}
        sx={{
          flex: "1",
          width: "100%",
          // maxWidth: "1520px",
          mx: "auto",
          // minHeight: "85vh",
        }}
      >
        <Outlet />
      </Box>
      <RenewDrawer  
      title={"Contact Us"}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
      typeModal={'contact'}
      />
       {/* <FreshchatLoader token={token} /> */}
       <InterCom/>
      {/* <HubsHomeChatDialog
        open={open}
        setOpen={setOpen}
        notify={notify}
        setNotify={setNotify}
      /> */}
      <Footer />
      <ScrollToTopButton/>
    </Box>
  );
}

export default Layout;



const FreshchatLoader = ({ token }) => {
  useEffect(() => {
    if (token) {
      // Create the first script element
      const freshchatScript = document.createElement('script');
      freshchatScript.type = 'text/javascript';
      freshchatScript.defer = true;
      freshchatScript.text = `
        function loadFreshchatWidget() {
          var freshchatScript = document.createElement('script');
          freshchatScript.src = 'https://wchat.freshchat.com/js/widget.js';
          freshchatScript.defer = true;
          document.head.appendChild(freshchatScript);
        }
        loadFreshchatWidget();
      `;
      document.head.appendChild(freshchatScript);
      // Create the second script element
      const initScript = document.createElement('script');
      initScript.type = 'text/javascript';
      initScript.text = `
        setTimeout(() => {
          if (window.fcWidget) {
            window.fcWidget.init({
              config: {
                headerProperty: { hideChatButton: false },
                cssNames: {
                  widget: "custom_fc_frame"
                }
              },
              token: "fe453811-5307-4627-b11a-5c264a21b3bd",
              host: "https://wchat.freshchat.com"
            });
          }
        }, 5000);
      `;
      document.head.appendChild(initScript);
      // Cleanup function to remove the scripts
      return () => {
        document.head.removeChild(freshchatScript);
        document.head.removeChild(initScript);
      };
    }
  }, [token]);
  return null; // This component does not render anything
};

// import React from 'react';
// 

export  function InterCom() {
  const {  userInfo } = useAuth();
  Intercom({
    app_id: 'xuv60uki',
    // user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: userInfo?.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: userInfo?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  // return <div>Example App</div>;
}