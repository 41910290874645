import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import exportFromJSON from "export-from-json";
import { Switch } from "antd";
import _ from "lodash";
import { Box , Button, Tooltip, Popover} from "@mui/material";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import { useAuth } from "../../context/authContext/Auth";
import Graphs2 from "../../Graphs/Graphs2";
import Table from "../../components/TemplateComponents/Table";
import downloadIcon from "../../assets/download_icon.svg";
import { checkAndUpdateDownloadCredit, displayNoDownloadCredit } from "../../utils/common";
import { setDownloadCredits } from "../../components/Redux/action";
import DownloadAccessChecker from "../../components/Common/DownloadOptionCheck/DownloadAccessChecker";

function View({
  data,
  index,
  alternateHeading,
  reportId,
  style = null,
  blurred = false,
}) {
  const [currentView, setCurrentView] = useState("graph");
  const [currentGraph, setCurrentGraph] = useState("volume");
  const [displayData, setDisplayData] = useState({});
  const [dataType, setDataType] = useState("VOLUME");
  const [subId, setSubId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const hasAccess = DownloadAccessChecker("report", reportId);

  const { total_credit, remaining_credit } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { config, userInfo } = useAuth();

  const switchStyles = {
    marginLeft: "12px",
    color: "red",
    backgroundColor: "#446BA6",
    padding: "1px",
    height: "22px",
  };

  const downloadButtonStyles = {
    border: "1px solid #446BA6",
    borderRadius: "14px",
    marginLeft: "12px",
    height: "22px",
    width: "60px",
  };

  const colors = [
    "#E0EFFA",
    "#E0EFFA",
    "#D7F6EB",
    "#D7F6EB",
    "#FFEDC7",
    "#FFEDC7",
    "#E6E3FC",
    "#E6E3FC",
    "#FCECEC",
    "#FCECEC",
    "#F7E6FF",
    "#F7E6FF",
    "#A5CBE6",
    "#A5CBE6",
    "#A5E6C6",
    "#A5E6C6",
    "#FFE6A5",
    "#FFE6A5",
    "#C6B1FF",
    "#C6B1FF",
    "#FFC6C6",
    "#FFC6C6",
    "#FFB1FF",
    "#FFB1FF",
  ];

  useEffect(() => {
    getCurrentData();
  }, [currentGraph]);

  let formatExportData = (data, labels, fn, fileName) => {
    let formattedColumns = [];
    let formattedDataSource = [];
    switch (data.graphType) {
      case "doughnut":
      case "pie": {
        if (labels.length === 0) {
          for (let [index, value] of Object.keys(data.data[0]).entries()) {
            let newTitle = "";

            if (value === "label" && Number(data.data[0][value]) === NaN) {
              newTitle = "YEAR";
              formattedColumns.push({
                title: newTitle,
              });
            } else if (
              value === "label" &&
              Number(data.data[0][value]) !== NaN
            ) {
              newTitle = "COMPANIES";
              formattedColumns.push({
                title: newTitle,
              });
            } else if (
              value === "value" &&
              Number(data.data[0][value]) !== NaN
            ) {
              newTitle = data?.units
                ? "REVENUE " + `(${data.units})`
                : "REVENUE";
              formattedColumns.push({
                title: newTitle,
              });
            } else {
              newTitle = value.toUpperCase();
            }
          }
        } else {
          labels.map((label) => {
            let newTitle = "";
            if (label.toLowerCase() != "company") {
              newTitle = label + `(${data.units})`;
            } else {
              newTitle = label;
            }

            newTitle = newTitle.toUpperCase();
            formattedColumns.push({
              title: newTitle,
            });
          });
        }
        for (let [index, value] of data.data.entries()) {
          let obj = {};
          for (let elem of formattedColumns) {
            if (elem.title.includes("COMPAN")) {
              let key = elem.title;
              obj[key] = value["label"];
            } else {
              let key = elem.title;
              obj[key] =
                typeof value["value"] === "number" && !isNaN(value["value"])
                  ? value["value"] % 1 === 0
                    ? fn(value["value"], 0)
                    : fn(value["value"], 2)
                  : value["value"];
            }
          }

          formattedDataSource.push(obj);
        }

        break;
      }

      case "boxes": {
        for (let [index, value] of Object.keys(data.data[0]).entries()) {
          let newTitle = "";
          if (value === "label" && Number(data.data[0][value]) === NaN) {
            newTitle = "YEAR";
          } else if (value === "label" && Number(data.data[0][value]) !== NaN) {
            newTitle = "COMPANIES";
          } else if (value === "value" && Number(data.data[0][value]) !== NaN) {
            newTitle = "REVENUE";
          } else {
            newTitle = value.toUpperCase();
          }
          formattedColumns.push({
            title: newTitle,
            dataIndex: value.toLowerCase(),
            key: value.toLowerCase(),
          });
        }

        for (let [index, value] of data.data.entries()) {
          let obj = {
            key: `${index}`,
          };
          for (let elem of formattedColumns) {
            let newKey = elem.key;
            obj[newKey] =
              typeof value[newKey] === "number" && !isNaN(value[newKey])
                ? value[newKey] % 1 === 0
                  ? fn(value[newKey], 0)
                  : fn(value[newKey], 2)
                : value[newKey];
          }
          formattedDataSource.push(obj);
        }

        break;
      }

      case "Hstackedbar":
      case "stackedColumn": {
        for (let [index, value] of Object.keys(
          data.data.catagory[0]
        ).entries()) {
          let newTitle = "";
          if (
            value === "label" &&
            Number(data.data.catagory[0][value]) !== NaN
          ) {
            newTitle = "YEAR";
          } else {
            newTitle = value.toUpperCase();
          }
          formattedColumns.push({
            title: newTitle,
          });
        }
        for (let [index, value] of data.data.data.entries()) {
          let serName = value.seriesname
            .replaceAll("-", "")
            .replaceAll("<br/>", "");
          formattedColumns.push({
            title: serName.toUpperCase(),
          });
        }
        for (let [index, value] of data.data.catagory.entries()) {
          let genKey = Object.keys(value)[0];
          let obj = {
            ["YEAR"]: value[genKey],
          };
          formattedDataSource.push(obj);
        }
        for (let [i, v] of formattedDataSource.entries()) {
          let obj = v;
          for (let [ind, val] of data.data.data.entries()) {
            let serName = val.seriesname
              .replaceAll("-", "")
              .replaceAll("<br/>", "");
            obj[serName?.toUpperCase()] =
              typeof val.data[i]?.value === "number" &&
              !isNaN(val.data[i]?.value)
                ? val.data[i]?.value % 1 === 0
                  ? fn(val.data[i]?.value, 0)
                  : fn(val.data[i]?.value, 2)
                : val.data[i]?.value;
          }
        }
        break;
      }

      case "barline": {
        for (let [index, value] of Object.keys(data.data[0]).entries()) {
          let newTitle = "";
          if (value === "label" && Number(data.data[0][value]) !== NaN) {
            newTitle = "YEAR";
          } else if (value === "value") {
            newTitle = "YOY (%)";
          } else if (value === "volume") {
            newTitle = `${data.legends[0]} (${data.units})`.toUpperCase();
          } else {
            newTitle = value.toUpperCase();
          }
          formattedColumns.push({
            title: newTitle,
          });
        }

        for (let [index, value] of data.data.entries()) {
          let obj = {};
          for (let [i, v] of Object.keys(data.data[0]).entries()) {
            obj[formattedColumns[i].title] =
              typeof value[v] === "number" && !isNaN(value[v])
                ? value[v] % 1 === 0
                  ? fn(value[v], 0)
                  : fn(value[v], 2)
                : value[v];
          }

          formattedDataSource.push(obj);
        }
        break;
      }

      case "":
      case "none":
      case null:
      case undefined: {
        formattedColumns = null;
        formattedDataSource = null;
        break;
      }
      default: {
        formattedColumns = null;
        formattedDataSource = null;
      }
    }

    for (let i = 0; i <= 2; i++) {
      let sourceObj = {};
      formattedColumns?.map((column, index) => {
        let key = column?.title;
        let val = "";
        if (i === 1) {
          val = index === 0 ? `${fileName}` : "";
        } else if (i === 2) {
          val = index === 0 ? "Source: Mordor Intelligence" : "";
        } else {
          val = "";
        }
        sourceObj[`${key}`] = val;
      });
      formattedDataSource.push(sourceObj);
    }

    return formattedDataSource;
  };

  let formatNumbers = (number, precision) => {
    let x = Number(number)?.toFixed(precision);
    x = String(x);
    let arr = x.split(".");
    let y = arr[0];
    let z = arr[1];
    let pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(y)) y = y.replace(pattern, "$1,$2");
    if (z) {
      return String(y + "." + z);
    } else {
      return String(y);
    }
  };

  let getCurrentData = () => {
    let newDisplayData = [];
    if (data?.length === 2) {
      newDisplayData = data?.filter((data) => {
        return data?.data?.dataType.toLowerCase().trim() === currentGraph;
      });
    } else {
      let objToPush = data?.length ? data[0] : null;
      newDisplayData = [objToPush];
      let newDataType = data?.length ? data[0]?.data?.dataType : null;
      setDataType(newDataType?.toUpperCase());
    }
    setSubId(data[0]?.data?.heading?.trim()?.toLowerCase());
    setDisplayData(newDisplayData[0]);
  };

  let handleViewChange = (checked) => {
    setCurrentView(checked ? "table" : "graph");
  };

  let handleGraphChange = (checked) => {
    setCurrentGraph(checked ? "value" : "volume");
  };

  let computeHeader = () => {
    if (displayData?.data?.graphType === "pie") {
      let splitLength = displayData?.data?.heading?.split(",").length;
      let newData = _.cloneDeep(displayData?.data?.heading?.split(","));

      newData[splitLength - 2] = " " + alternateHeading;
      return {
        tableHeader: newData
          .join(",")
          .replaceAll("%", `${displayData?.data?.units}`),
        graphHeader: newData.join(","),
      };
    }

    return {
      tableHeader: displayData?.data?.heading,
      graphHeader: displayData?.data?.heading,
    };
  };

  let handleDownloadModalOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let handleDownloadModalClose = (event) => {
    setAnchorEl(null);
  };

  let export_chart = async (format, id) => {
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }
    format = format.trim().toLowerCase();
    id = id.trim().toLowerCase();
    let selectedChart = FusionCharts(id);
    if (selectedChart) {
      selectedChart?.exportChart({
        exportFormat: format,
      });
    }

    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);

      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit)
      );
    }
  };

  let exportAsTable = async (exportData, labels, type) => {
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }
    let fileName = computeHeader()?.tableHeader;
    let exportType = exportFromJSON.types[type];
    let data = formatExportData(exportData, labels, formatNumbers, fileName);

    exportFromJSON({ data, fileName, exportType });

    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);

      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit)
      );
    }
  };

  const Open = Boolean(anchorEl);
  const id = Open ? "popover" : undefined;
  const labels = displayData.hasOwnProperty("labels")
    ? displayData?.labels
    : [];

  return (
    <Box
      sx={{
        flex: "0 1 49%",
        position: "relative",
      }}
    >
      {blurred && (
        <div
          style={{
            position: "absolute",
            top: "1px",
            zIndex: "2",
            margin: "auto",
            fontWeight: "bold",
            backgroundColor: "white",
            padding: "7px 0px 0px 0px",
            width: "100%",
            textTransform: "uppercase",
            fontSize: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <div 
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent:"flex-end",
              marginBottom: "10px" 
            }}
           >
          <Switch
            onChange={handleViewChange}
            checkedChildren="TABLE"
            unCheckedChildren="GRAPH"
            style={{ ...switchStyles, marginLeft: "0px"}}
          />

            <Tooltip title={"Export Options Not Allowed"} placement="top">
              <Button
              sx={{
                "&:hover":{
                  cursor:"not-allowed"
                }
              }}
                style={downloadButtonStyles}
              >
                <img src={downloadIcon} alt="Download" />
              </Button>
            </Tooltip>
            </div>
          <div
            style={{
              width: "100%",
              // color={colors[index]}
              color: "black",
              backgroundColor: colors[index],
              padding: "10px",
            }}
          >
            {computeHeader()?.graphHeader}
          </div>
        </div>
      )}

      <Box
        sx={{
          ...(style && { ...style }),
          ...(blurred && {
            filter: "blur(5px)",
            pointerEvents: "none",
          }),
          boxSizing: "border-box",
          boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.1)",
          borderRadius: "8px",
          marginBottom: "2rem",
          overflow: "hidden",
          "&:hover": {
            cursor: "pointer",
            boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.25)",
          },
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "10px",
          }}
        >
          {data?.length === 2 ? (
            <Switch
              disabled={data?.length !== 2}
              onChange={handleGraphChange}
              checkedChildren={data?.length !== 2 ? dataType : "VALUE"}
              unCheckedChildren={data?.length !== 2 ? dataType : "VOLUME"}
              handleBg="#ff0000"
              style={switchStyles}
            />
          ) : null}
          <Switch
            onChange={handleViewChange}
            checkedChildren="TABLE"
            unCheckedChildren="GRAPH"
            style={switchStyles}
          />
          {hasAccess && (
            <Tooltip title={"View Export Options"} placement="top">
              <Button
                style={downloadButtonStyles}
                onClick={handleDownloadModalOpen}
              >
                <img src={downloadIcon} alt="Download" />
              </Button>
            </Tooltip>
          )}

          <Popover
            id={id}
            open={Open}
            anchorEl={anchorEl}
            onClose={handleDownloadModalClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            {currentView === "graph" && (
              <>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      export_chart("jpg", subId);
                    }}
                  >
                    Export As JPG
                  </Button>
                </p>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      export_chart("png", subId);
                    }}
                  >
                    Export As PNG
                  </Button>
                </p>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      export_chart("svg", subId);
                    }}
                  >
                    Export As SVG
                  </Button>
                </p>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      export_chart("pdf", subId);
                    }}
                  >
                    Export As PDF
                  </Button>
                </p>
              </>
            )}
            {currentView === "table" && (
              <>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() =>
                      exportAsTable(displayData?.data, labels, "csv")
                    }
                  >
                    Export As CSV
                  </Button>
                </p>
                <p>
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() =>
                      exportAsTable(displayData?.data, labels, "xls")
                    }
                  >
                    Export As XLS
                  </Button>
                </p>
              </>
            )}
          </Popover>
        </Box>

        {currentView === "graph" ? (
          <Graphs2
            id={subId}
            meta={displayData?.data?.meta}
            data={displayData?.data?.data}
            height={"400"}
            header={computeHeader()?.graphHeader}
            footer={""}
            width={"100%"}
            yAxisName={""}
            xAxisName={""}
            dynamiclegends={displayData?.data?.legends}
            graphType={displayData?.data?.graphType}
            dataHidden={
              displayData?.data?.dataHidden &&
              displayData.data.dataHidden === true
                ? true
                : false
            }
            allow_cagr={
              displayData?.data?.hasOwnProperty("cagr_value") ? true : false
            }
            cagr_year={displayData?.data?.cagr_year}
            cagr_value={displayData?.data?.cagr_value}
            showValues={true}
            valueFontSize={12}
            valueFontBold={true}
            templateName={"Any"}
            slideName={"Any"}
            headingColor={colors[index]}
          />
        ) : (
          <Box
            sx={{
              height: "400px",
              paddingBottom: "0",
            }}
          >
            <Table
              tableData={displayData?.data}
              labels={labels}
              parentTitle={displayData?.data?.meta?.heading}
              heading={computeHeader()?.tableHeader}
              color={colors[index]}
              headingColor={colors[index]}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default View;
