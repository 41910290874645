import { Box, TablePagination } from '@mui/material'
import { useState, useEffect } from 'react'
import HubsReportCardForReports from '../HubsComponents/HubsReportCardForReports';
import SynapseReportCard from "../HubsComponents/SynapseReportsCard";
import Loader from '../Common/Loader';

const itemsPerPageOptions = [5, 10, 25, 50];

function BookmarksReportsContainer({ initialData, getReportData, sortedData, isLibraryReports, loading }) {
    const [data, setData] = useState(sortedData);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
      const paginatedData = sortedData?.slice(page * itemsPerPage, (page * itemsPerPage) + itemsPerPage);
      setData(paginatedData);
    }, [sortedData, page, itemsPerPage]);

    if (!loading && data.length === 0) {
        return (
            <Box sx={{ width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>There is no Report Found</Box>
        )
    }

    if(loading) {
        return <Loader />
    }

    return (
        <Box>
            <Box mt={'55px'}>
                {data.map((items, i) => {
                    return (
                      <Box
                        key={i}
                        width="100%"
                        style={{ marginBottom: "2rem" }}
                      >
                        {isLibraryReports ? (
                          <SynapseReportCard
                            {...items}
                            currentTab={"BOOKMARK"}
                            report_id={items.synapse_report_id}
                            getReportData={getReportData}
                          />
                        ) : (
                          <HubsReportCardForReports
                            {...items}
                            currentTab={"BOOKMARK"}
                            getReportData={getReportData}
                          />
                        )}
                      </Box>
                    );
                })}
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2} // Add margin top as needed
                >
                    <TablePagination
                        component="div"
                        count={initialData?.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={itemsPerPage}
                        onRowsPerPageChange={handleChangeItemsPerPage}
                        rowsPerPageOptions={itemsPerPageOptions}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default BookmarksReportsContainer