import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import * as XLSX from "xlsx";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { useAuth } from "../../context/authContext/Auth";
import API from "../../api/Api";

function ExcelUploadButton(props) {
  const [fileData, setFileData] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const { config } = useAuth();

  const { Base_URL } = API

  const handleSelectedFileChange = (event) => {
    setSelectedFile(event.target.value);
  };

  const handleMenuItemClick = (data, index) => {
    props.checkLoading(true);

    const requestBody = {
      s3_url: data.s3_url,
    };

    axios
      .post(
        `${Base_URL}/api/hubs/customDataset/filters`,
        requestBody,
        config
      )
      .then((response) => {
        props.onFileUpload(response.data.data);
        props.sendFileData(data, index);
        props.checkLoading(false);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        props.checkLoading(false);
      });
  };

  const getCustomData = (ifUpload) => {
    props.checkLoading(true);
    axios
      .get(
        `${Base_URL}/api/hubs/customDataset/version`,
        config
      )
      .then((response) => {
        const mainResponse = response.data.data;
        setMenuItems(mainResponse);
        props.sendFileData(
          mainResponse[[mainResponse.length - 1]],
          mainResponse.length - 1
        );
        const mapping = mainResponse?.map((item) => ({
          value: item.filename,
          label: item.filename,
        }));
        if (ifUpload) {
          setSelectedFile(mapping[mapping.length - 1].value);
        }
        props.checkLoading(false);
      })
      .catch((error) => {
        console.error(error);
        props.checkLoading(false);
      });
  };

  useEffect(() => {
    getCustomData();
  }, []);

  const handleFileChange = (e) => {
    props.checkLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const fileData = event.target.result;
      setFileData(fileData);

      // Use xlsx library to decode the file into a JavaScript object
      const workbook = XLSX.read(fileData, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Call the function to handle the excel data
      // props.onFileUpload(excelData);

      // Sending the file to the server
      const data = new FormData();
      data.append("type", "ME");
      data.append("file", file);
      axios
        .post(
          `${Base_URL}/api/hubs/customDataset/`,
          data,
          config
        )
        .then((response) => {
          getCustomData(data);
          props.onFileUpload(response.data.data);
        })
        .catch((error) => {
          console.error(error);
          window.alert(error.response.data.message);
          props.checkLoading(false);
        });
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div
      sx={
        {
          // display: "flex",
          // justifyContent: "flex-end",
          // alignItems: "center",
        }
      }
    >
      <FormControl sx={{ width: 150, marginRight: "5px" }}>
        <InputLabel id="file-select-label">Select Version</InputLabel>
        <Select
          labelId="file-select-label"
          id="file-select"
          value={selectedFile}
          label="Select File"
          onChange={handleSelectedFileChange}
          sx={{ textAlign: "left" }}
        >
          {menuItems?.map((item, i) => (
            <MenuItem
              key={item?.filename || i}
              value={item?.filename}
              onClick={() => handleMenuItemClick(item, i)}
            >
              {item?.filename}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" component="label">
        Upload Excel File
        <input
          type="file"
          accept=".xlsx, .xls, .csv"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Button>
    </div>
  );
}

export default ExcelUploadButton;
