import { initialState, reducer, RecentDevelopmentContext } from "./STATE";
import { useReducer, useEffect } from "react";
import Layout from "./Layout";
import ChooseCompanyBox from "./ChooseCompanyBox";
import TableSection from "./TableSection";

function RecentDevelopment({ companies }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({ type: "SET_COMPANIES", payload: companies });
  }, [companies]);
  return (
    <RecentDevelopmentContext.Provider value={{ state, dispatch }}>
      <Layout>
        {state.data.length > 0 ? <TableSection /> : <ChooseCompanyBox />}
      </Layout>
    </RecentDevelopmentContext.Provider>
  );
}

export default RecentDevelopment;
