import { Box } from "@mui/material";
import React from "react";

function NoData() {
  return (
    <Box
      height={"70vh"}
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent="center"
      color={"lightgray"}
    >
      <h1>No Data Found!</h1>
      <p>You do not have any data.</p>
    </Box>
  );
}

export default NoData;
