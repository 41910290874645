import { Box, Typography } from "@mui/material";
import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";

function Footer() {
  const Year = new Date().getFullYear();
  return (
    <Box
      sx={{
        boxShadow: "0 -10px 20px -5px rgba(115,115,115,0.25);",
        // backgroundColor: "#395580",
        height: "508px",
        backgroundImage:
          "url('https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/footer.svg')",
        backgroundSize: "cover", // You can adjust this as needed
        backgroundPosition: "center center",
      }}
    >
      {/* <div style={{ textAlign: "center" }}>
        <img src="./images/footer/footer_logo.png" alt="footer" width="10%" />
      </div>
      <Typography
        // variant="h6"
        component="div"
        textAlign="center"
        // fontSize={18}
        padding={1}
        color={"white"}
      >
        © {Year}. All Rights Reserved to Mordor Intelligence.
      </Typography> */}
      <Box
        sx={{
          width: "80%",
          margin: "auto",
          borderBottom: "0.5px solid #4A90E2",
          marginTop: "70px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ height: "144px", color: "#FFFFFF", width: "60%" }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "32px",
                lineHeight: "48px",
                color: "#fff",
              }}
            >
              Delivering Competitive Edge,
            </Typography>
            <Typography
              sx={{
                fontWeight: "800",
                fontSize: "32px",
                lineHeight: "48px",
                color: "#fff",
              }}
            >
              Turning Precise Data <br /> into Decisions
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: "32px", alignItems: "center" }}>
              <img
                src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/pngwing+1.svg"
                alt="certificate"
                width="100px"
              />
              <img
                src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/gptw.png"
                alt="Great Place to Work"
                height="120px"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: "#E0EFFA",
            marginBottom: "70px",
            marginTop: "70px",
          }}
        >
          <Box>
            <Box
              sx={{
                height: "32px",
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#fff",
                }}
              >
                Follow Us
              </Typography>
              <a href="https://www.facebook.com/MordorIntel" target="_blank">
                <img
                  src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/facebook.png"
                  alt="FbIcon"
                  height="32px"
                />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/mordor-intelligence"
              >
                <img
                  src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/LinkedIn.png"
                  alt="LinkedInIcon"
                  height="32px"
                />
              </a>
              <a
                target="_blank"
                href="https://www.pinterest.com/mordorintelligence/"
              >
                <img
                  src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/pinterest.png"
                  alt="PinterestIcon"
                  height="32px"
                />
              </a>
              <a target="_blank" href="https://www.instagram.com/mordorintel/">
                <img
                  src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/InstaLogo.png"
                  alt="InstagramIcon"
                  height="32px"
                />
              </a>
              <a target="_blank" href="https://twitter.com/mordorintel">
                <img
                  src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/X.png"
                  alt="XIcon"
                  height="32px"
                />
              </a>
            </Box>
          </Box>
          <Box sx={{ height: "58px" }}>
            <Box>
              <a
                href="https://synapse.mordorintelligence.com/terms_and_conditions.pdf"
                target="_blank"
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#fff",
                }}
              >
                Terms and Conditions
              </a>
            </Box>
            <Box>
              <a
                href="https://synapse.mordorintelligence.com/privacy_policy.pdf"
                target="_blank"
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#fff",
                }}
              >
                Privacy Policy
              </a>
            </Box>
            <Box>
              <a
                href="https://synapse.mordorintelligence.com/cookie_policy.pdf"
                target="_blank"
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#fff",
                }}
              >
                Cookie Policy
              </a>
            </Box>
            <Box sx={{ display: "flex", marginTop: "10px" }}>
              <MailOutlineIcon fontSize="16px" />
              <a
                href="mailto:info@mordorintelligence.com"
                aria-label="Email Company Address: info@mordorintelligence.com"
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21px",
                  marginLeft: "12px",
                  color: "#fff",
                  textDecoration: "underline",
                }}
              >
                info@mordorintelligence.com
              </a>
            </Box>
            <Box sx={{ display: "flex" }}>
              <CallOutlinedIcon fontSize="16px" />
              <a>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "21px",
                    marginLeft: "12px",
                    color: "#fff",
                  }}
                >
                  +1 617-7652493
                </Typography>
              </a>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <PinDropOutlinedIcon fontSize="16px" />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "21px",
                color: "#fff",
              }}
            >
              11th Floor, Rajapushpa Summit Nanakramguda Rd,
              <br />
              Financial District, Gachibowli, Hyderabad,
              <br />
              Telangana - 500032, India
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "80%",
          margin: "auto",
          padding: "20px 0px",
          marginBottom: "10px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#E0EFFA",
          }}
        >
          © {Year}. All Rights Reserved to Mordor Intelligence.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
