import styled from "styled-components";

export const CompaniesContainer = styled.div`
  width: 100%;
  max-width:1710px;
  margin: 0 auto;
  display:flex;
  justify-content: space-between;
  `;

export const Aside = styled.div`
  width:25%;
  flex: 0 1 25%;
  margin-right: 25px;
  margin-top: -25px;
  `;

export const Main = styled.div`
  width:75%;
  flex: 0 1 75%;
  display:flex;
  flex-direction:column;
  `;

export const SecondaryHeader = styled.div`
  position:relative;
  width:100%;
  height:100px;
  display:flex;
    justify-content:space-between;
    align-items:center;
    flex-direction:row;
  & div{
    display:flex;
    justify-content:space-between;
    align-items:center;
    flex:direction:row;
    & button{
      color: #002F75;
      width: 120px;
      height: 52px;
      padding: 8px, 24px, 8px, 24px;
      border-radius: 8px;
      border: 1px solid #002F75;
      background-color:#fff;
      margin: 8px;
      display:flex;
      justify-content: space-around;
      align-items:center;
      font-size: 18px;
      &:hover{
        cursor: pointer;
      }
    };
    & .view-type{
      width: 120px;
      height: 52px;
      margin: 8px;
      padding: 8px, 24px, 8px, 24px;
      border-radius: 8px;
      border: 1px solid #002F75;
      justify-content: space-around;
      & div{
        border: 1px solid #FFF;
        padding: 5px;
        border-radius: 4px;
        &:hover{
          cursor: pointer;
        }
      }
    };
  }
  & .search{
    height: 52px ;
    border: 1px solid  #D5D5D5;
    border-radius: 8px;
    padding: 8px, 24px, 8px, 24px;
    margin: 8px;
    margin-left: 0px;
    overflow: hidden;
    flex: 0 1 60%;
    display:flex;
    justify-content: flex-start;
    & input{
      outline:none;
      border: none;
      flex: 0 1 95%;
      height: 50px;
    }
    & img{
        margin-left: 10px;
        margin-right: 4px;
    }
  }
  `;

export const TagContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap:wrap;
  `;

export const Tag = styled.div`
  border: 1px solid blue;
  height: 32px;
  padding: 6px 12px 6px 16px;
  border: 0.5px solid rgba(28, 147, 123, 1);
  border-radius: 48px;
  color: linear-gradient(0deg, #1C937B, #1C937B);
  background:linear-gradient(0deg, #D7F6EB, #D7F6EB);
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 10px;
  & span{
    font-size: 13px;
    line-height: 18px;
  }
  & span:first-child{
    margin-right: 6px;
  }
  & .delete{
    &:hover{
      cursor:pointer;
    }
  }
`;

export const CompaniesList = styled.div`
  width:100%;
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  `;

export const CompanyCard= styled.div`
  height:85px;
  flex: 0 1 100%;
  box-shadow: 0px 2px 20px 0px #002F7514;
  border: 1px solid;
  border-radius:8px;
  border: 1px solid #ECECEC;
  margin : 16px auto;
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-around;
  & > div{
    flex: 0 1 60%;
    display: flex;
    justify-content: space-between;
    align-items:center;
  }
  &:hover{
    cursor:pointer;
    border:1px solid  #189CDE ; 
  }
`;

export const CompanyCardGrid= styled.div`
  flex: 0 1 49%;
  height:135px;
  box-shadow: 0px 2px 20px 0px #002F7514;
  border: 1px solid;
  border-radius:8px;
  border: 1px solid #ECECEC;
  margin : 16px auto;
  padding: 20px;
  display: flex;
  flex-direction:row;
  align-items:flex-start;
  justify-content:flex-start;
  &>div{
    display: flex;
    flex-direction:column;
    justify-content: space-around;
    align-items:flex-start;
  }
   &>div:nth-child(2){
    flex: 0 1 90%;
   }
   &:hover{
    cursor:pointer;
    border:1px solid  #189CDE ;
    
  }
`;

export const CompanyLogo= styled.div`
  width: 65px;
  height:65px;
  overflow:hidden;
  display:flex;
  justify-content:center;
  align-items:center;
  & img{
    object-fit: cover;
    width:80%;
  };
  & div{
    font-size: 20px;
    font-weight:600;
    width:100%;
    height:100%;
    display: flex;
    justify-content:center;
    align-items:center;
    color:white;
  }
`;

export const CompanyInfo= styled.div`
  flex: 0 1 90%;
  & h{
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #191919;
    font-family:'Avenir Heavy';
  };
  & p{
    display: flex;
    align-items:center;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #5A5A5A;
    & span{
      display:inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #000000;
      margin : 0 10px;
    }
  }
`;

export const ReportsInfo= styled.div`
  flex: 0 1 30% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
    & div{
      margin: 0 10px;
      & h{
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #2D2D2D;
      };
      & p{
        font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #5A5A5A;
      }
    }
`;

export const CompanyLogoGrid= styled.div`
  width: 65px;
  height:65px;
  margin-right:10px;
  overflow:hidden;
  display:flex;
  justify-content:center;
  align-items:center;
  & img{
    object-fit: cover;
    width:80%;
  };
  & div{
    font-size: 20px;
    font-weight:600;
    width:100%;
    height:100%;
    display: flex;
    justify-content:center;
    align-items:center;
    color:white;
  }
`;

export const CompanyInfoGrid= styled.div`
  padding-bottom:20px;
  border-bottom: 1px solid  #D5D5D5;
  width: 100%;
  & h{
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #191919;
    font-family:'Avenir Heavy';
  };
  & p{
    display: flex;
    align-items:center;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #5A5A5A;
    & span{
      display:inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #000000;
      margin : 0 10px;
    }
  }
`;

export const ReportsInfoGrid= styled.div`
  width: 100%;
  margin-top:10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items:center;
  &>span{
    display:inline-block;
    width: 4px;
    height:4px;
    background-color: #000;
    border-radius:50%;
    margin-right:10px;
  };
    & div{
     
     
      & p{
        margin-right:10px;
        font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #5A5A5A;
   & span{
    font-weight: 700;
   };
      }
    }
`;

export const Pagination = styled.div`
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin: 20px;
  margin-bottom: 30px;
  & p{
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #191919;
  };
  & p{
   margin : 10px;
  };
  & select{
  width: 60px;
  height: 40px;
  padding: 4px;
  font-weight: bold;
  font-size: 14px;
  border-radius: 8px;
  margin: 10px;
  background-color: #E0EFFA;
  border: 1px solid #E0EFFA;
  outline:none;
  &:hover{
    cursor:pointer;
  };
  }
  & button{
  background: none;
  border: 1px solid #002F75;
  color: #002F75;
  width: 60px;
  height: 40px;
  font-size: 18px;
  border-radius: 8px;
  margin: 10px;
  font-weight: 800;
  &:hover{
    cursor:pointer;
  };
  }
`;
  
export const SortModalContainer = styled.div`
  background-color: #fff;
  box-shadow: 0px 2px 20px 0px rgba(0, 47, 117, 0.2);
  width: 250px;
  position:absolute;
  right: 140px;
  top: 80px;
  &>div{
    display: flex;
    justify-content: space-between;
    flex-direction:column;
    align-items:flex-start;
    width: 100%;
     height:100%;
    &>div{
      width: 100%;
      height: 50px;
      display:flex;
      justify-content:space-between;
      padding: 10px 20px;
    }
    & span{
      color: rgba(45, 45, 45, 1);
      font-size: 14px;
      &:hover{
        cursor:pointer;
      }
    }
    & ul{
     width: 100%;
     list-style:none;
    }
    & li{
      height: 50px;
      border-top: 1px solid rgba(228, 228, 228, 1);
      padding: 15px 20px;
      color: rgba(45, 45, 45, 1);
      font-size: 14px;
       &:hover{
        background: #F0F7FC;
        color: #189CDE;
        cursor: pointer;
       }
    }
  }
`;

export const FilterModalContainer = styled.div`
  background-color: #fff;
  box-shadow: 0px 2px 20px 0px rgba(0, 47, 117, 0.2);
  width: 450px;
  position:absolute;
  right: 275px;
  top: 80px;
  &>div{
    display: flex;
    justify-content: space-between;
    flex-direction:column;
    align-items:flex-start;
    width: 100%;
    height:100%;
    &>header{
      width: 100%;
      height: 50px;
      display:flex;
      justify-content:space-between;
      padding: 15px 15px 10px 10px;
    };
    & span{
      color: rgba(45, 45, 45, 1);
      font-size: 14px;
      &:hover{
        cursor:pointer;
      }
    };
    & main{
      width: 100%;
      display:flex;
      justify-content:space-between;
      flex-direction:column;
     
    };
    & footer{
      width: 100%;
      height: 80px;
      display:flex;
      justify-content:space-between;
      padding: 10px 20px;
      &>button{
        border: none;
        outline:none;
        padding:7px 14px;
        height: 38px;
        flex: 0 1 45%;
        box-shadow: rgba(50, 50, 93, 0.12) 0px 2px 5px -1px, rgba(0, 0, 0, 0.18) 0px 1px 3px -1px;
      }
      & .clear-btn{
        color: rgba(24, 156, 222, 1);
        background: #fff;
        
      }
      & .apply-btn{
        background: rgba(24, 156, 222, 1);
        color: #fff;
      }
    }
  }
`;

export const RegionCountryContainer = styled.div`
  width: 100%;
  displau:flex;
  flex-direction:column;
  &>div{
    width: 100%;
    display: flex;
    justify-content:space-between;
    align-items:center;
    padding: 10px;
    border-top: 1px solid rgba(228, 228, 228, 1);
    border-bottom: 1px solid rgba(228, 228, 228, 1);
  }
  & > .select-dim{
    width: 100%;
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:flex-start;
    &> div{
      width:50%;
      display: flex;
      flex-direction:column;
      justify-content:space-between;
      align-items:flex-start;
      padding-top: 0px;
      padding-bottom:0px;
    };
    &> .select-country{
      border-left: 1px solid rgba(228, 228, 228, 1);
      padding-left: 10px;
    
    }
    &> .select-region{
      padding-right: 5px;
    }
    & .opt-box{
      width:100%;
      display: flex;
      flex-direction:column;
      justify-content:flex-start;
      align-items:flex-start;
      overflow-y: scroll;
      max-height: 324px;
    }
  }
`;

export const AnnualRevenueContainer = styled.div`
  width: 100%;
  display:flex;
  flex-direction:column;
  &>div{
    width: 100%;
    display: flex;
    justify-content:space-between;
    align-items:center;
    padding: 10px;
    border-top: 1px solid rgba(228, 228, 228, 1);
    border-bottom: 1px solid rgba(228, 228, 228, 1);
  }
  & > .select-revenue{
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:flex-start;
  }
`;

export const EmployeesContainer = styled.div`
  width: 100%;
  display:flex;
  flex-direction:column;
  &>div{
    width: 100%;
    display: flex;
    justify-content:space-between;
    align-items:center;
    padding: 10px;
    border-top: 1px solid rgba(228, 228, 228, 1);
    border-bottom: 1px solid rgba(228, 228, 228, 1);
  }
  & > .select-revenue{
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:flex-start;
  }
`;

export const CompanyReportsContainer = styled.div`
  width: 100%;
  display:flex;
  justify-content:space-between;
  align-iyems:center;
  flex-direction: column;
`;

export const ReportsHeader = styled.div`
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
  flex-direction:column;
  padding: 15px 0px;
  margin-bottom:25px;
  & button{
    &: hover{
      cursor:pointer;
    }
  };
  & .search-report{
    height: 52px ;
    width: 100%;
    border: 1px solid  #D5D5D5;
    border-radius: 8px;
    padding: 8px, 24px, 8px, 24px;
    display:flex;
    justify-content: flex-start;
    & input{
      outline:none;
      border: none;
      flex: 0 1 95%;
      height: 50px;
    }
    & img{
        margin-left: 10px;
        margin-right: 4px;
    }
  }
`;

export const ReportsList = styled.div`
  width:100%;
  display:flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
`;
