export const RegionCountryConfig={
  'Global': [
    'Turkey',
    'United States',
    'Sweden',
    'Colombia',
    'France',
    'Indonesia',
    'Philippines',
    'Egypt',
    'United Arab Emirates',
    'Vietnam',
    'Ethiopia',
    'Malaysia',
    'United Kingdom',
    'Hong Kong',
    'Australia',
    'Estonia',
    'Italy',
    'Brazil',
    'Oman',
    'Austria',
    'Papua New Guinea',
    'Netherlands',
    'Thailand',
    'Pakistan',
    'Mexico',
    'Spain',
    'Ireland',
    'Denmark',
    'China',
    'Kazakhstan',
    'Taiwan',
    'Japan',
    'Germany',
    'South Africa',
    'Libya',
    'Ecuador',
    'Chile',
    'India',
    'Qatar',
    'Equatorial Guinea',
    'Israel',
    'Argentina',
    'Romania',
    'Canada',
    'Uzbekistan',
    'Turkmenistan',
    'Tanzania',
    'South Korea',
    'Singapore',
    'Azerbaijan',
    'New Zealand',
    'Venezuela',
    'Czech Republic',
    'Angola',
    'Saudi Arabia',
    'Finland',
    'Kuwait',
    'Algeria',
    'Sudan',
    'Russia',
    'Uganda',
    'Iraq',
    'Bangladesh',
    'Poland',
    'Peru',
    'Iran',
    'Myanmar',
    'Norway',
    'Bahrain',
    'Switzerland',
    'Portugal',
    'Cameroon',
    'Belgium',
    'Morocco',
    'Uruguay',
    'Slovakia',
    'Sri Lanka',
    'Nigeria',
    'Greece',
    'Hungary',
    'Brunei',
    'Ghana',
    'Cuba',
    'Chad',
    'Gabon',
    'Bulgaria',
    'Trinidad and Tobago',
    'Zimbabwe',
    'Tunisia',
    'Belarus',
    'Nepal',
    'Slovenia',
    'Congo, Republic of the',
    'Paraguay',
    'Cambodia',
    'Laos',
    'Kenya',
    'Jordan',
    'Croatia',
    'Maldives',
    'Central African Republic',
    'Iceland',
    'Ukraine',
    'Armenia',
    'Moldova',
    'Albania',
    'Malta',
    'Cyprus',
    'Luxembourg',
    'Latvia',
    'Panama',
    'Lithuania',
    'Macedonia',
    'Serbia',
    'Costa Rica',
    'Serbia and Montenegro',
    'Botswana',
    'Bolivia',
    'USA',
    'US',
    'UK',
    'UAE',
    'Dominican Republic',
    'Honduras'
  ],
  'North America': [
    'United States',
    'US',
    'Canada',  
    'Mexico', 
    'Cuba', 
    'Panama', 
    'Costa Rica',
    'USA',
    'Dominican Republic', 
    'Honduras'
  ],
  'South America': [
    'Brazil', 
    'Argentina', 
    'Colombia', 
    'Chile', 
    'Ecuador', 
    'Venezuela', 
    'Peru', 
    'Uruguay', 
    'Trinidad and Tobago', 
    'Paraguay',
    'Bolivia'
  ],
  'Europe': [
    'Russia',
    'Germany',
    'France',
    'United Kingdom',
    'UK',
    'Poland',
    'Italy',
    'Spain',
    'Netherlands',
    'Ukraine',
    'Ireland',
    'Denmark',
    'Bulgaria',
    'Norway',
    'Denmark',
    'Estonia',
    'Sweden',
    'Austria',
    'Romania',
    'Czech Republic',
    'Finland',
    'Switzerland',
    'Portugal',
    'Belgium',
    'Slovakia',
    'Greece',
    'Hungary',
    'Belarus',
    'Slovenia',
    'Croatia',
    'Iceland',
    'Moldova',
    'Albania',
    'Malta',
    'Cyprus',
    'Luxembourg',
    'Latvia',
    'Lithuania',
    'Macedonia',
    'Serbia',
    'Serbia and Montenegro'
  ],
  'Asia Pacific': [
    'China',
    'India',
    'Japan',
    'Australia',
    'South Korea',
    'Vietnam',
    'Thailand',
    'Indonesia',
    'New Zealand',
    'Philippines',
    'Malaysia',
    'Singapore',
    'Pakistan',
    'Hong Kong',
    'Turkey',
    'Oman',
    'Kazakhstan',
    'Taiwan',
    'Uzbekistan',
    'Turkmenistan',
    'Azerbaijan',
    'New Zealand',
    'Bangladesh',
    'Bahrain',
    'Myanmar',
    'Sri Lanka',
    'Brunei',
    'Nepal',
    'Cambodia',
    'Laos',
    'Maldives',
    'Armenia'
  ],
  'Middle East': [
    'Turkey',
    'Saudi Arabia',
    'Iran',
    'Kuwait',
    'Iraq',
    'Israel',
    'Qatar',
    'Jordan',
    'UAE',
    'United Arab Emirates'
  ],
  'Africa': [
    'South Africa',
    'Kenya',
    'Egypt',
    'Morocco',
    'Nigeria',
    'Angola',
    'Cameroon',
    'Ethiopia',
    'Libya',
    'Equatorial Guinea',
    'Tanzania',
    'Algeria',
    'Sudan',
    'Uganda',
    'Ghana',
    'Chad',
    'Gabon',
    'Zimbabwe',
    'Tunisia',
    'Congo, Republic of the',
    'Central African Republic',
    'Botswana'
  ],
  'Middle East And Africa': [
    'South Africa',
    'Egypt',
    'Morocco',
    'Nigeria',
    'Qatar',
    'Saudi Arabia',
    'United Arab Emirates',
    'UAE'
  ],
  'Asia': [
    'China',
    'India',
    'Japan',
    'Australia',
    'South Korea',
    'Vietnam',
    'Thailand',
    'Indonesia',
    'New Zealand',
    'Philippines',
    'Malaysia',
    'Singapore',
    'Pakistan',
    'Hong Kong',
    'Turkey',
    'Oman',
    'Kazakhstan',
    'Taiwan',
    'Uzbekistan',
    'Turkmenistan',
    'Azerbaijan',
    'New Zealand',
    'Bangladesh',
    'Bahrain',
    'Myanmar',
    'Sri Lanka',
    'Brunei',
    'Nepal',
    'Cambodia',
    'Laos',
    'Maldives',
    'Armenia'
  ],
  'Australia And New Zealand': [
    'New Zealand', 
    'Australia'
  ],
  'GCC': [
    'Qatar',
    'Saudi Arabia', 
    'UAE', 
    'United Arab Emirates'
  ],
  'South East Asia': [
    'Papua New Guinea'
  ],
  'Latin America': [],
  'Americas': [],
  'North America And Europe': [],
  'Middle East And North Africa': [],
  'South Asia': [],
  'Europe, Middle East And Africa': [],
  'Rest Of World': [],
};

export const colorsArray =[
  '#000000',  
  '#FFF222',  
  '#808080', 
  '#A9A9A9',  
  '#696969', 
  '#2E8B57',  
  '#4169E1',  
  '#6B8E23',  
  '#2F4F4F',  
  '#000080', 
  '#8B4513', 
  '#808000',  
  '#556B2F',  
  '#4B0082',  
  '#8B008B',  
  '#8B0000',  
  '#800080',  
  '#A0522D',  
  '#8A2BE2',  
  '#D2691E',  
  '#483D8B',  
  '#800000',  
  '#708090',   
  '#2F4F4F', 
  '#008080', 
  '#006400'
];

export const NumberConversionConfig = {
 '0.1' : '100K',
 '1' : '1Mn',
 '50' : '50Mn',
 '100' : '100Mn',
 '500' : '500Mn',
 '1000': '1Bn',
 '50000' : '50Bn',
 '500000' : '500Bn'
}

export const NumberConversionConfig2 = {
  '100K': 0.1,
  '1Mn' : 1,
  '50Mn' : 50,
  '100Mn' : 100,
  '500Mn' : 500,
  '1Bn': 1000,
  '50Bn' : 50000,
  '500Bn' : 500000
}

export const sortOptionsArray = [
  'Default', 
  'Name (A-Z)', 
  'Name (Z-A)', 
  'Revenue', 
  'Employees', 
  'Headquarters'
];

export const employeeOptionsArray = [
  '0 - 50', 
  '51 - 100',
  '101 - 500', 
  '501 - 1000', 
  '1001 - 5000', 
  '5001 - 10000', 
  '10001 - 50000', 
  '50001 - 100000', 
  '> 100000' 
];

export const employeeOptionsConfig = {
  '0 - 50': [0, 50],
  '51 - 100': [51, 100],
  '101 - 500': [101, 500],
  '501 - 1000': [501, 1000],
  '1001 - 5000': [1001, 5000],
  '5001 - 10000': [5001, 10000],
  '10001 - 50000': [10001, 50000],
  '50001 - 100000': [50001, 100000],
  '> 100000': [100000, 1000000]
}

export const revenueOptionsArray = [
  '$ 100K - $ 1Mn',
  '$ 1Mn - $ 50Mn', 
  '$ 50Mn - $ 100Mn', 
  '$ 100Mn - $ 500Mn', 
  '$ 500Mn - $ 1Bn', 
  '$ 1Bn - $ 50Bn', 
  '> $ 50Bn'
];

export const revenueOptionsConfig = {
  '$ 100K - $ 1Mn': [0.1, 1],
  '$ 1Mn - $ 50Mn': [1, 50],
  '$ 50Mn - $ 100Mn': [50, 100],
  '$ 100Mn - $ 500Mn': [100, 500],
  '$ 500Mn - $ 1Bn': [500, 1000],
  '$ 1Bn - $ 50Bn': [1000, 50000],
  '> $ 50Bn': [50000, 500000]
}