import React, { useEffect, useMemo, useState } from "react";
import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import { Box, Button, Paper, Typography } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import _ from "lodash";
import mordor_icon from "../../utils/img/Mi_logo.png";
import SynapseLogo from "../../../src/assets/Synapse_logo.svg";
import NoDataDisplay from "../Common/NoDataDisplay";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

function CompaniesGraph({
  type,
  reset,
  setDimensions,
  selectedDimensions,
  resetHandle,
  hubTitle,
  subHeading,
  filterData,
  filteredReports,
  displayedItems,
  subTitle,
  id,
}) {
  const [showAllItems, setShowAllItems] = useState(true);
  function handleShowMoreClick() {
    setShowAllItems(!showAllItems);
  }
  const colors = [
    "#4fd025",
    "#29c3be",
    "#5d62b5",
    "#f2726f",
    "#62b58f",
    "#f7e500",
    "#2d95ec",
    "#e256ae",
    "#fff1ce",
    "#9b3192",
  ];

  const [computedGraphData, setComputedGraphData] = useState([]);

  // ⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️

  // const filteredReports = initData?.filter((report) => {
  //   let includeReport = true;
  //   Object.keys(selectedDimensions)?.forEach((dimension) => {
  //     if (
  //       selectedDimensions[dimension] !== "All" &&
  //       report.dimensions[dimension] !== selectedDimensions[dimension]
  //     ) {
  //       includeReport = false;
  //     }
  //   });
  //   return includeReport;
  // });

  // const displayedItems = filteredReports?.[0]?.analyses?.slice(
  //   0,
  //   showAllItems ? undefined : 2
  // );

  const demoData = [
    {
      label: "Tyson Foods Inc.",
      value: 919.5818471795511,
      color: "#4fd025",
    },
    {
      label: "Sysco Corporation",
      value: 479.0878610778579,
      color: "#29c3be",
    },
    {
      label: "Hormel Foods Corporation",
      value: 272.7912426906194,
      color: "#5d62b5",
    },
    {
      label: "Marfrig Global Foods S.A.",
      value: 197.24418964511997,
      color: "#f2726f",
    },
    {
      label: "WH Group Limited",
      value: 180.63848217170914,
      color: "#62b58f",
    },
    {
      label: "Cargill Inc.",
      value: 107.53333466405614,
      color: "#f7e500",
    },
    {
      label: "Thai Union Group PCL",
      value: 40.99578157802573,
      color: "#2d95ec",
    },
    {
      label: "JBS SA",
      value: 30.486615014772408,
      color: "#e256ae",
    },
    {
      label: "BRF S.A.",
      value: 29.585553494356905,
      color: "#fff1ce",
    },
    {
      label: "The Savola Group",
      value: 28.586248229234087,
      color: "#9b3192",
    },
    {
      label: "Others",
      value: 120.77930477427748,
      color: "#FFA500",
    },
  ];

  const computeYAxis = (preCal, customIncrease = 21, limit = 8) => {
    if (filterData?.length > limit) {
      let yAxis = 0;
      let totalRow = filterData?.length / 5;
      let totalRowRounded = Math.ceil(totalRow);
      let final = (totalRowRounded * customIncrease).toString();
      return preCal + "+" + final.toString();
    } else {
      return preCal;
    }
  };

  // ⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️
  //Add heading and subheading to the graph
  const chartConfigs = {
    id: id,
    width: "100%",
    height: "500",
    dataFormat: "JSON",
    dataSource: {
      chart: {
        logoURL: SynapseLogo,
        logoPosition: "BR",
        exportEnabled: 1,
        exportShowMenuItem: 0,
        exportFormats: "svg||png||jpg||pdf",
        exportFileName: `${hubTitle}`,
        caption: `${_.capitalize(hubTitle)}`,
        subcaption: `${subTitle}`,
        xaxisname: "Value",
        yaxisname: "Reserves (MMbbl)",
        theme: "fusion",
        legendFontSize: 11,
        legendFont: "Avenir Medium",
        // legendWidth: 300,
        // showPercentInTooltip: 1,
      },
      annotations: {
        autoScale: "1",
        groups: [
          {
            id: "caption",
            items: [
              {
                id: "source",
                type: "text",
                align: "left",
                fillcolor: "#7f7f7f",
                fontsize: 12,
                font: "Avenir Medium",
                text: "Source: Mordor Intelligence",
                bold: "0",
                wrap: "1",
                x: "$canvasStartX",
                y: "$legendEndY+20",
              },
            ],
          },
        ],
      },
      data: filterData,
    },
  };
  useEffect(() => {
    if (reset) {
      resetHandle();
    }
  }, [reset]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          top: "0",
          left: "-16px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              px: "20px",
              mt: "5px",
              py: "3px",
              color: "#f1f1f1",
            }}
          ></Typography>
        </Box>
      </Box>

      <Box>
        {filterData?.length > 1 ? (
          <ReactFC type={type} {...chartConfigs} />
        ) : (
          <NoDataDisplay />
        )}
      </Box>
      {filterData?.length > 0 && (
        <ul>
          {filteredReports?.[0]?.analyses
            ?.slice(0, showAllItems ? undefined : 2)
            ?.map((item, i) => {
              return (
                <li
                  style={{
                    fontFamily: "Avenir Medium",
                    color: "#002F75",
                    padding: "6px",
                    listStylePosition: "inside",
                  }}
                  key={_.uniqueId()}
                >
                  {item}
                </li>
              );
            })}
          <span>
            {filteredReports?.length > 2 && (
              <Button onClick={handleShowMoreClick}>
                {showAllItems ? "show less " : "show more"}
              </Button>
            )}
          </span>
        </ul>
      )}
    </>
  );
}

export default React.memo(CompaniesGraph);
