import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useCompanyContext } from "../../../STATE";
import { useState } from "react";
import Loader from "../../../../../components/Common/Loader";

function RegionCountries() {
  const { dispatch, state } = useCompanyContext();

  const [selectedRegion, setSelectedRegion] = useState(
    state.filters.geography.regions
  );
  const [selectedCountry, setSelectedCountry] = useState(
    state.filters.geography.countries
  );

  const handleSelect = (e, type) => {
    let filteredRegions = [...selectedRegion];
    let filteredCountries = [...selectedCountry];
    if (type === "REGION") {
      if (e.target.checked) {
        filteredRegions.push(e.target.name);
      } else {
        filteredRegions = filteredRegions.filter(
          (item) => item !== e.target.name
        );
      }
      setSelectedRegion(filteredRegions);
    } else {
      if (e.target.checked) {
        filteredCountries.push(e.target.name);
      } else {
        filteredCountries = filteredCountries.filter(
          (item) => item !== e.target.name
        );
      }
      setSelectedCountry(filteredCountries);
    }
    dispatch({
      type: "SET_REGION_COUNTRY",
      payload: { regions: filteredRegions, countries: filteredCountries },
    });
  };

  return (
    <Accordion defaultExpanded elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          "&.Mui-expanded": {
            minHeight: 38,
            maxHeight: 38,
          },
        }}
      >
        Region/Country
      </AccordionSummary>
      <AccordionDetails>
        {state.geography.loading === "PENDING" ? (
          <Loader sx={{ height: "20vh" }} />
        ) : (
          <Grid container>
            <Grid item xs={6} sx={{ borderRight: 1, borderColor: "#d4d4d4" }}>
              <Typography variant="body1" px={1} sx={{ fontSize: "14px" }}>
                Region
              </Typography>
              <Box
                sx={{
                  maxHeight: "300px",
                  overflowY: "scroll",
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                {state.geography.regions
                  .sort((a, b) => a.localeCompare(b))
                  .map((region, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                          fontSize: "14px",
                        }}
                      >
                        <Box
                          component="input"
                          type="checkbox"
                          onChange={(e) => handleSelect(e, "REGION")}
                          checked={selectedRegion.includes(region)}
                          name={region}
                        />
                        <Box component="label" htmlFor={region}>
                          {region}
                        </Box>{" "}
                      </Box>
                    );
                  })}
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ borderLeft: 1, borderColor: "#d4d4d4" }}>
              <Typography variant="body1" sx={{ fontSize: "14px" }} px={1}>
                Country
              </Typography>
              <Box
                sx={{
                  maxHeight: "300px",
                  overflowY: "scroll",
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                {state.geography.countries
                  .sort((a, b) => a.localeCompare(b))
                  .map((country, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                          fontSize: "14px",
                        }}
                      >
                        <Box
                          component="input"
                          type="checkbox"
                          onChange={(e) => handleSelect(e, "COUNTRY")}
                          name={country}
                          checked={selectedCountry.includes(country)}
                        />
                        <Box component="label" htmlFor={country}>
                          {country}
                        </Box>{" "}
                      </Box>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default RegionCountries;
