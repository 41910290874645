import FilterModal from "../FilterModal";
import filtersImg from "../../../../../assets/filters.svg";
import RegionCountries from "./RegionCountries";
import { useState } from "react";
import { useCompanyContext } from "../../../STATE";
import AnnualRevenue from "./AnnualRevenue";
import { Divider, Box } from "@mui/material";
import TotalEmployees from "./TotalEmplyees";
import styles from "../styles.module.css";

function FilterBy() {
  const { dispatch, state } = useCompanyContext();
  const [openFilter, setOpenFilter] = useState(false);


  const handleClearAll = () => {
    dispatch({ type: "MOCK_LOADING", fn: dispatch });
    setOpenFilter(false);
    dispatch({ type: "RESET_FILTERS" })
  }

  const handleApply = () => {
    dispatch({ type: "MOCK_LOADING", fn: dispatch });
    setOpenFilter(false);
    dispatch({ type: "APPLY_FILTERS" });
  }

  return (
    <>
      <button onClick={() => setOpenFilter((p) => !p)}>
        <img src={filtersImg} alt="filter" />
        Filter
      </button>
      {openFilter && (
        <FilterModal setOpenFilter={setOpenFilter} filterBy={"Filter By"}>
          <Box sx={{ minWidth: "462px" }}>
            <RegionCountries />
            <Divider />
            <AnnualRevenue />
            <Divider />
            <TotalEmployees />
            <Divider />
            <section className={styles.bottomFilterSection}>
              <button onClick={handleClearAll}>Clear All</button>
              <button className={styles.activeFilterBtn} onClick={handleApply}>Apply</button>
            </section>
          </Box>
        </FilterModal>
      )}
    </>
  );
}

export default FilterBy;
