import { FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { usePurchaseHistoryCtx } from "../STATE";
import SwapVertIcon from "@mui/icons-material/SwapVert";

const menuItemCss = {
  width: "200px",
  p: "16px 24px",
  height: "56px",
  color: "#2d2d2d",
  justifyContent: "start",
  gap: "12px",
  alignItems: "center",
  textTransform: "capitalize",
  "&.Mui-selected": {
    color: "#189CDE",
  },
  "&.MuiMenuItem-root:not(:last-child)": {
    borderBottom: "1px solid #E4E4E4",
  },
};

function Sort() {
  const {
    state: { sortType },
    dispatch,
  } = usePurchaseHistoryCtx();

  const handleSortTypeChange = (e) => {
    dispatch({ type: "SET_SORT_TYPE", payload: e.target.value });
  };

  return (
    <FormControl
      sx={{
        mr: "24px",
        alignSelf: "flex-end",
      }}
    >
      <InputLabel id="test-select-label" sx={{ color: "#002F75" }}>
        Sort By
      </InputLabel>
      <Select
        sx={{
          fontWeight: "bold",
          width: "120px",
          height: "50px",
          textTransform: "capitalize",
          color: "#002F75",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#002F75",
          },
          "&.MuiList-root": {
            paddingTop: 0,
            paddingBottom: 0,
            width: "240px",
          },
        }}
        IconComponent={() => (
          <SwapVertIcon sx={{ position: "absolute", right: "10px" }} />
        )}
        value={sortType}
        label="Sort By"
        onChange={handleSortTypeChange}
      >
        <MenuItem sx={menuItemCss} value={null}>
          <p>Default</p>
        </MenuItem>
        <MenuItem sx={menuItemCss} value={"LATEST"}>
          Latest
        </MenuItem>
        <MenuItem sx={menuItemCss} value={"OLDEST"}>
          Oldest
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default Sort;
