import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useCompanyContext } from "../../../STATE";
import { employeeOptionsArray } from "../../../../../components/CompaniesComponents/CompanyHome/CompanyHome.config";
import { useState } from "react";

function TotalEmployees() {
  const { state, dispatch } = useCompanyContext();

  const [selectedEmployee, setSelectedEmployee] = useState(state.filters.employees);

  const handleSelect = (e) => {
    let filteredEmployees = [...selectedEmployee];
    if (e.target.checked) {
      filteredEmployees.push(e.target.name);
    } else {
      filteredEmployees = filteredEmployees.filter((item) => item !== e.target.name);
    }
    setSelectedEmployee(filteredEmployees);
    dispatch({ type: "SET_EMPLOYEES", payload: filteredEmployees });
  }

  return (
    <Accordion defaultExpanded elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          "&.Mui-expanded": {
            minHeight: 38,
            maxHeight: 38,
          },
        }}
      >
        Total Employees
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            maxHeight: "300px",
            overflowY: "scroll",
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {employeeOptionsArray.map((employee, i) => {
            return (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  gap: "0.8rem",
                  alignItems: "center",
                  fontSize: "15px",
                }}
              >
                <Box
                  component="input"
                  type="checkbox"
                  onClick={(e) => handleSelect(e)}
                  name={employee}
                  checked={selectedEmployee.includes(employee)}
                />
                <Box component="label" htmlFor={employee}>
                  {employee}
                </Box>
              </Box>
            );
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default TotalEmployees;
