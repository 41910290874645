import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const env = process.env.REACT_APP_ENVIRONMENT;

export const requestInterceptor = (request) => {
 let token = undefined;
 if(cookies.get('TOKEN')) {
   token = cookies.get('TOKEN');
 }else {
   toast.error('Session expired, please login again');
   setTimeout(() => {
     window.location.assign('/');
   }, 3000);
   return null;
 }
 request.headers["authorization"] = `Bearer ${token}`;
 return request;
}
// axios interceptor response function to handle response status
export const responseInterceptor = (response)  => {
 return response;
};
// axios interceptor error function to handle error
export const errorInterceptor = (error) => {
 if (error.response?.status) {
   const status = error.response.status;
   const message = getMessageFromError(error);
   if(status >= 400 && status < 500) {
     toast.warning(message);
   }
   if(status >= 500 && env !== "PRODUCTION") {
     toast.error(message);
   }
 }
 return Promise.reject(error);
};
const getMessageFromError = (error) => {
 let message = (error.response?.data)?.message || error.message;
 if(typeof message === 'object') {
   message = message.join('\n');
 }
 return message;
}