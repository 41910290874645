import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRecentDevelopment } from "../STATE";
import mordor_icon from "../../../../utils/img/Mi_logo.png";

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    backgroundColor: theme.palette.grey[50],
    fontWeight: 600,
    padding: theme.spacing(2),
  },
  "&.MuiTableCell-body": {
    padding: theme.spacing(2),
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#f0f7fc",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.common.white,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableContainer = styled(TableContainer)({
  // Show only when printing
  "@media print": {
    padding: "20px",
    display: "block",
    marginBottom: "20px",
    borderRadius: "2px",
  },
});

const PrintOnlyBox = styled(Box)({
  // Hide in normal display
  display: "none",
  // Show only when printing
  "@media print": {
    display: "block",
    marginBottom: "20px",
  },
});

function DevelopmentTable({ data, tableRef }) {
  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const companyNameSet = new Set();
  const showCompanyName = (companyName) => {
    if (!companyNameSet.has(companyName)) {
      companyNameSet.add(companyName);
      return companyName;
    }
    return "";
  };

  return (
    <StyledTableContainer sx={{ width: "100%" }} ref={tableRef}>
      <PrintableHeading />
      <TableContainer
        id={"printableTable"}
        component={Paper}
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#e0effa" }}>
                Company Name
              </TableCell>
              <TableCell sx={{ backgroundColor: "#c8e5f9" }}>Date</TableCell>
              <TableCell sx={{ backgroundColor: "#e0effa" }}>
                Type Of Key Development
              </TableCell>
              <TableCell sx={{ backgroundColor: "#c8e5f9" }}>
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ fontSize: "0.867rem", fontWeight: 600 }}
                  >
                    {showCompanyName(row.company_name)}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ fontSize: "0.867rem", color: "#4a4a4a" }}
                  >
                    {formatDate(row.date)}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ fontSize: "0.867rem", color: "#4a4a4a" }}
                  >
                    {row.type}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{
                      maxWidth: "600px",
                      fontSize: "0.867rem",
                      color: "#4a4a4a",
                    }}
                  >
                    {row.description}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledTableContainer>
  );
}

export default DevelopmentTable;

const PrintableHeading = () => {
  const { state } = useRecentDevelopment();
  return (
    <PrintOnlyBox>
      <Box>
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <small style={{ color: "gray", fontSize: "16px" }}>
              Source: Mordor Intelligence
            </small>
            <img
              width={"34px"}
              height={"34px"}
              src={mordor_icon}
              alt="Logo Of Mordor Intelligence"
            />
          </Box>
          <h1
            style={{
              color: "#449AC6",
              textAlign: "center",
              fontSize: "36px",
              marginBottom: "20px",
            }}
          >
            {"Recent Developments"}
          </h1>
          <hr style={{ color: "gray", margin: "10px 0" }} />
          {state.selectedCompanies.length > 0 ? (
            <>
              <p>
                <span style={{ fontWeight: "bold" }}>Companies : </span>
                {state.selectedCompanies.map((company) => company.name).join(",  ")}
                .
              </p>
              <hr style={{ color: "gray", margin: "10px 0" }} />
            </>
          ) : null}

          {state.selectedDevelopmentTypes.length > 0 && (
            <>
              <p>
                <span style={{ fontWeight: "bold" }}>Development Types : </span>
                {state.selectedDevelopmentTypes.join(", ")}.
              </p>
              <hr style={{ color: "gray", margin: "10px 0" }} />
            </>
          )}
        </div>
      </Box>
    </PrintOnlyBox>
  );
};
