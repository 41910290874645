import { useState, useEffect } from 'react';
import { NoDataFoundForThis } from '../fetchData';
import styles from './styles.module.css';

const Contents = ({ data, setCurrentTab, dispatch }) => {
  const [expandedSections, setExpandedSections] = useState({});
  useEffect(() => {
    if (!data) return;
    // Expand all sections by default
    const expandAllSections = (sections, parentIndex = "") => {
      let expanded = {};
      sections.forEach((section, index) => {
        const currentIndex = `${parentIndex}${index + 1}`;
        expanded[currentIndex] = true;
        if (section.subSections && section.subSections.length > 0) {
          expanded = {
            ...expanded,
            ...expandAllSections(section.subSections, `${currentIndex}.`),
          };
        }
      });
      return expanded;
    };
 
    setExpandedSections(expandAllSections(data));
  }, [data]);

  const toggleSection = (indexPath, slideNumber) => {
    if(slideNumber) {
      dispatch({ type: 'SET_CURRENT_LIBRARY_SLIDE', payload: slideNumber-1 });
      setCurrentTab(3);
      return;
    }
    setExpandedSections(prevState => ({
      ...prevState,
      [indexPath]: !prevState[indexPath],
    }));
  };

  if(!data) return <NoDataFoundForThis />

 const renderSections = (sections, parentIndex = "") => {
    return sections?.map((section, index) => {
      const currentIndex = `${parentIndex}${index + 1}`;
      const isExpanded = expandedSections[currentIndex];
 
      return (
        <div key={currentIndex} className={styles.section}>
           <div
             className={`${styles.sectionTitle} ${parentIndex ? styles.subsectionTitle : styles.mainSectionTitle}`}
             onClick={() => toggleSection(currentIndex, section.meta.slideNumber)}
        >
            {currentIndex}. {section.title}
          </div>
          {section.subSections && section.subSections.length > 0 && isExpanded && (
            <div className={styles.subsections}>
              {renderSections(section.subSections, `${currentIndex}.`)}
            </div>
          )}
        </div>
      );
    });
  };


  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Table of Contents</h2>
      <div>{renderSections(data)}</div>
    </div>
  );
};

export default Contents;
