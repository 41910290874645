import React, { useEffect, useState } from "react";
import ExcelUploadButton from "../../components/Common/ExcelUploadButton";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import data from "./dummyData.js";
import Graphs from "../../components/CustomData/Graphs";
import Templates from "../../Templates";
import Graph from "../../Graphs";
import SideFilters from "../../components/CustomData/SideFilters";
import BackendSideFilters from "../../components/CustomData/BackendSideFilters";
import Loader from "../../components/Common/Loader";
import axios from "axios";
import { useAuth } from "../../context/authContext/Auth";
import { WindowSharp } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import API from "../../api/Api.js";

function CustomData() {
  const { config } = useAuth();
  const [loading, setLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [makeFilter, setMakeFilter] = useState([]);
  const [graph, setGraph] = useState([]);
  const [graphType, setGraphType] = useState("");
  const [metrics, setMetrics] = useState("");
  const [currentFileDetails, setCurrentFileDetails] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  const [chartType, setChartType] = useState("bar");
  const [displayText, setDisplayText] = useState(
    "Select data parameters using the filters available in navigation pane on your left"
  );
  const [isTable, setIsTable] = useState(false);
  const { Base_URL } = API

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setGraph([]);
    setSelectedValue(event.target.value);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: null,
        paragraph: null,
        backButtonData: null,
        // bannerHeight: '260px'
      },
    });
  }, []);

  const handleFileUpload = (data) => {
    setMakeFilter(data);
    setExcelData();
    setGraph([]);
  };
  // const item = data;
  const handleCurrentReportDetails = (data, index) => {
    setGraph([]);
    setCurrentFileDetails(data);
    setCurrentFileIndex(index);
  };

  const handleLoader = (value) => {
    setLoading(value);
  };

  const handleChartTypeChange = (event) => {
    setGraphType(event.target.value);
  };

  const renderTable = () => {
    if (!excelData) return null;

    // Extract the headers and rows from the data
    const headers = excelData[0];
    const rows = excelData.slice(1);
  };
  const handleApplyFilters = (value, selectedMetric) => {
    setGraphLoading(true);
    setGraph([]);
    if (selectedMetric === "") {
      setDisplayText("select the Metrics");
    } else if (Object.keys(value).length === 0) {
      setDisplayText("select atleast one filter");
    } else {
      setMetrics(selectedMetric);
      // if (Object.keys(value).length === 0) {
      //   setGraphLoading(false);
      //   window.alert("select atleast one filter")
      //   return
      // }
      try {
        axios
          .post(
            `${Base_URL}/api/hubs/customDataset/fetch`,
            {
              dimensions: value,
              // filename: currentFileDetails.filename,
              version: currentFileIndex,
              // "graphType": "bar",
              metricRequested: selectedMetric.toLowerCase(),
            },
            config
          )
          .then((response) => {
            // if (stackedbar) {
            //   setGraph(...response.data.data.data, ...response.data.data.category);
            // }
            // else {
            setGraphType(response.data.data.meta.graphType);
            if (response.data.data.meta.graphType === "bar") {
              setGraph(response.data.data.data);
            } else {
              // Assuming `graph` is an object
              // Remove the "meta" field and store the modified data in a new variable
              var jsonDataWithoutMeta = { ...response.data.data };
              delete jsonDataWithoutMeta.meta;
              // Rename the "category" key to "catagory"
              jsonDataWithoutMeta.catagory = jsonDataWithoutMeta.category;
              delete jsonDataWithoutMeta.category;

              setGraph([jsonDataWithoutMeta]);
            }
            // }
            // setFilteredData(response.data.data.data);
            setGraphLoading(false);
          })
          .catch((error) => {
            setDisplayText(error.response.data.message);
            console.error("Error:", error.response.data.message);
            setGraphLoading(false);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setGraphLoading(false);
  };

  return (
    <>
      <Box>
        {/* Uploading and converting data in readable format */}
        {/* <Switch
      onChange={() => setIsTable(!isTable)}
      checkedchildren="TABLE"
      uncheckedchildren="GRAPH"
    className="graph=table-switch"
    /> */}
        <Box sx={{ textAlign: "right" }}>
          <ExcelUploadButton
            onFileUpload={handleFileUpload}
            sendFileData={handleCurrentReportDetails}
            checkLoading={handleLoader}
          />
          {/* {renderTable()} */}
        </Box>
        {loading ? (
          <Loader />
        ) : (
          <Box display="flex">
            {/* Left Side Filters and Accordian */}
            <Box
              // sx={{maxWidth: '300px',overflow: 'scroll',height:"100vh", }}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
                maxWidth: "300px",
                mr: "10px",
                flex: "1",
                position: "sticky",
                top: "30px",
                overflow: "hidden",
                overflowY: "scroll",
                height: "90vh",
                maxHeight: "90vh",
                py: "20px",
              }}
            >
              <BackendSideFilters
                filterData={makeFilter}
                onApplyFilters={handleApplyFilters}
              />
              {/* <SideFilters data={excelData} onApplyFilters={handleApplyFilters} /> */}
              <br />
            </Box>
            {/* -----------For Graph ----------*/}
            <Box
              sx={{
                width: "70%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box minWidth={"400px"}>
                {graphLoading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    {!graph || graph.length === 0 ? (
                      <p>{displayText}</p>
                    ) : isTable ? (
                      <TableComponent data={graph} />
                    ) : (
                      <>
                        <Box>
                          {graphType === "stackedColumn" ? (
                            ""
                          ) : (
                            <Box>
                              <Select
                                value={graphType}
                                onChange={handleChartTypeChange}
                              >
                                <MenuItem value="bar">Bar</MenuItem>
                                <MenuItem value="line">Line</MenuItem>
                                <MenuItem value="pie">Pie</MenuItem>
                              </Select>
                            </Box>
                          )}
                          {graph.map((item) => (
                            <Graph
                              // data={[
                              //   { label: "x", value: 20 },
                              //   { label: "y", value: 40 },
                              //   { label: "z", value: 10 },
                              //   { label: "a", value: 80 },
                              //   { label: "b", value: 70 },
                              // ]}
                              data={item}
                              meta={{}}
                              header={`${metrics} Graph`}
                              footer={""}
                              width={"100%"}
                              height={"100%"}
                              yAxisName={""}
                              xAxisName={""}
                              dynamiclegends={false}
                              graphType={graphType}
                              dataHidden={false}
                              allow_cagr={false}
                              cagr_year={""}
                              cagr_value={""}
                              showValues={true}
                              valueFontSize={12}
                              valueFontBold={true}
                              templateName={""}
                              slideName={""}
                            />
                          ))}
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

export default CustomData;

const TableComponent = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.label}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
