import * as Yup from "yup";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { useFormik } from "formik";
import API from "../../api/Api";
import Cookies from "universal-cookie";

const AuthContext = createContext(null);

// this is for form validation
const signUpSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string().min(8).required("Please enter your password"),
});

export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const { Base_URL, login } = API;

  // ITs for the hubs report tab to redirect to
  const [currentTab, setCurrentTab] = useState(0);

  // To count hubs bookmark reprott
  const [countHubsBookmarks, setCountHubsBookmarks] = useState(0);

  // Here we are Saving the token in UniversalCookie

  const cookies = new Cookies();

  //  user token info for api header
  const userToken = cookies.get("TOKEN");

  const config = {
    headers: { authorization: `Bearer ${userToken}` },
  };

  const navigate = useNavigate();

  // this is for checking the login thing
  const [loading, setLoading] = useState(false);

  // here we are decodind the JWT token
  //const myDecodedToken = decodeToken(userToken);

  // this is for snakbar or notification
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
    position: "left",
  });

  // getting the form inputs in there
  const initialValues = {
    email: "",
    password: "",
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: async (values, action) => {
        setLoading(true);
        axios
          .post(`${Base_URL}${login}`, values)
          .then((res) => {
            // set the cookie
            cookies.set("TOKEN", res.data?.token, { path: "/" });

            navigate("/reports");
          })
          .catch((err) => {
            const data = err.response.data?.message;
            setNotify({
              isOpen: true,
              message: data,
              type: "error",
              position: "left",
            });
          })
          .finally(() => {
            setLoading(false);
          });
        action.resetForm();
      },
    });

  return (
    <AuthContext.Provider
      value={{
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        touched,
        notify,
        setNotify,
        loading,
        config,
        setUserInfo,
        userInfo,
        currentTab,
        setCurrentTab,
        setCountHubsBookmarks,
        countHubsBookmarks,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
