import React, { useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, TextField, Select} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

function BackendSideFilters({ filterData, onApplyFilters }) {
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedMetric, setSelectedMetric] = useState('');
  const [data, setData] = useState([]);
  const [metricsData, setMetricsData] = useState([]);
  const [unitsData, setUnitsData] = useState();

  const categories = Object.keys(data);

  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  const handleItemAutocompleteChange = (category, event, value) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [category]: value,
    }));
  };

  const applyFilters = () => {
      // let updatedSelectedItems = { ...selectedItems };
      // let keysToAdd = Object.keys(filterData).filter(key => key !== "Metric" && key !== "Units" && key !== "metric" && key !== "units" && key !== "metrics" && key !== "Metrics");

      // // Add missing keys to updatedSelectedItems with empty arrays
      // keysToAdd.forEach(key => {
      //     if (!(key in updatedSelectedItems)) {
      //         updatedSelectedItems[key] = [""];
      //     }else if (updatedSelectedItems[key].length === 0) {
      //       updatedSelectedItems[key] = [""];
      //   }
      // });
    
      // Create a new object with non-empty values
        let filteredItems = {};
        for (const key in selectedItems) {
            if (selectedItems[key].length !== 0) {
                filteredItems[key] = selectedItems[key];
            }
        }
      onApplyFilters(filteredItems,selectedMetric);
    
    
  }

  const getCategoryItems = (category) => data[category] || [];

  useEffect(() => {
    if (Object.keys(filterData).length > 0) {
    setSelectedItems({})
    setSelectedMetric("")
    setData(filterData);
    setMetricsData(filterData['metrics' || 'Metrics'] || []);
    setUnitsData(filterData['units' || 'Units'] || []);
    }
  }, [filterData]);

  return (
  <>
    {data.length===0 ? <p>No Filters Present</p> :
      <div>
          <h3 style={{ color: "#1976d2", marginBottom: "5px" }}>Metrics</h3>
          <FormControl sx={{ width: '95%' }}>
  <InputLabel id="metric-select-label">Metrics</InputLabel>
  <Select
    labelId="metric-select-label"
    id="metric-select"
    value={selectedMetric}
    label="Metrics"
    onChange={(event, value) => {
      handleMetricChange(event);
      // handleItemAutocompleteChange('Metrics', event, [event.target.value]);
    }}
    sx={{ textAlign: 'left' }}
  >
    {metricsData?.map((item, i) => (
      <MenuItem key={i} value={item}>
        {item}
      </MenuItem>
    ))}
  </Select>
</FormControl>


        <h3 style={{ color: "#1976d2", marginBottom: "5px" }}>Filters</h3>
        {categories.map((category, index) => {
    const lowercaseCategory = category.toLowerCase();

    if (lowercaseCategory === "units" || lowercaseCategory === "metrics") {
        return null; // Skip rendering
    }

    return (
        <FormControl key={index} sx={{ width: '95%', marginTop: '5px' }}>
            <Autocomplete
                multiple
                value={selectedItems[category] || []}
                onChange={(event, value) => handleItemAutocompleteChange(category, event, value)}
                options={getCategoryItems(category).sort()}
                renderInput={(params) => <TextField {...params} label={`${category} (${getCategoryItems(category).length})`} />}
            />
        </FormControl>
    );
})}

          <div style={{ display: 'flex', marginTop: '10px' }}>
      <Button variant="contained" component="label" marginTop="10px" onClick={() => applyFilters()}>
        Apply
      </Button>
            <Button variant="secondary" component="label" marginTop="10px" onClick={() => {
              setSelectedItems({})
              setSelectedMetric("")
            }}>
        Reset
      </Button>
      </div>
        </div>
        
}</>
  );
}

export default BackendSideFilters;
