import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
        padding: "16px",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "5rem",
          fontWeight: "bold",
          color: "#1976d2",
          marginBottom: "16px",
        }}
      >
        404
      </Typography>
      <Typography
        variant="h2"
        sx={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: "#424242",
          textAlign: "center",
          marginBottom: "16px",
        }}
      >
        Oops! The page you are looking for could not be found.
      </Typography>
      <Button
        component={Link}
        to="/hubs"
        variant="contained"
        sx={{
          fontSize: "1.2rem",
          padding: "12px 24px",
          borderRadius: "20px",
          boxShadow: "none",
          backgroundColor: "#1976d2",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1565c0",
          },
        }}
      >
        Go back to home page
      </Button>
    </Box>
  );
};

export default NotFoundPage;
