import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import RenewDrawer from './RenewDrawer';

function NoDataDisplay() {
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <>
            <Box>
                <Box sx={{
                    display: "flex",
                    height: "65vh",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: 'column'
                }}
                >
                    Sorry, we do not have any data for your selected filters. Try resetting filters or get in touch with us.
                <Button
                    sx={{
                        backgroundColor: "#189CDE",
                        color: "white",
                        borderRadius: "8px",
                        textTransform: "none",
                        marginRight: "1rem",
                        mt: '1rem'
                    }}
                    variant="contained"
                    onClick={() => {
                        setDrawerOpen(true);
                    }}
                    >
                    Talk to us Now
                </Button>
                    </Box>
            </Box>
            <RenewDrawer
                title={"Talk to us Now"}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                typeModal={'contact'}
            />

        </>
    )
}

export default NoDataDisplay