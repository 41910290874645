import { toast } from "react-toastify";
import { SYNAPSE_API } from "../../api/Api";

export function closureWithState({ dispatch, state }, fn) {
  return (...args) => fn(...args, { dispatch, state });
}

let companiesRequestController = null;
export const getCompaniesAPI = async (categoryId = null, STATE) => {
  if (!STATE) return;
  const { dispatch } = STATE;

  if (companiesRequestController) {
    companiesRequestController.abort();
  }

  companiesRequestController = new AbortController();
  const signal = companiesRequestController.signal;

  dispatch({ type: "SET_LOADING", payload: "PENDING" });

  await SYNAPSE_API.get(`/api/company/showCompaniesV3`, {
    params: {
      category_id: categoryId,
    },
    signal,
  })
    .then((response) => {
      dispatch({ type: "SET_COMPANIES", payload: response.data.companies });
      dispatch({ type: "SET_COMPANIES_SHOWING" });
    })
    .catch((err) => {
      if (err.name === "AbortError" || err.name === "CanceledError") return;
      toast.error("Failed to fetch companies");
    })
    .finally(() => {
      if (signal.aborted) return;
      dispatch({ type: "SET_LOADING", payload: "IDLE" });
    });
};

let companiesCategoryRequestController = null;
export const getCompaniesCategoryDataAPI = async (STATE) => {
  if (!STATE) return;
  const { dispatch } = STATE;

  if (companiesCategoryRequestController) {
    companiesCategoryRequestController.abort();
  }

  companiesCategoryRequestController = new AbortController();
  const signal = companiesCategoryRequestController.signal;

  dispatch({
    type: "SET_CATEGORY_TREE_LOADING",
    payload: "PENDING",
  });

  await SYNAPSE_API.get(`/api/company/companies-category-tree`, {
    signal,
  })
    .then((response) => {
      dispatch({
        type: "SET_CATEGORY_TREE",
        payload: response.data,
      });
    })
    .catch((err) => {
      if (err.name === "AbortError" || err.name === "CanceledError") return;
      toast.error("Failed to fetch category tree");
    })
    .finally(() => {
      if (signal.aborted) return;
      dispatch({
        type: "SET_CATEGORY_TREE_LOADING",
        payload: "IDLE",
      });
    });
};

let recentDevelopmentsRequestController = null;
export const getUserRecentDevelopmentsCompaniesAPI = async (STATE) => {
  if (!STATE) return;
  const { dispatch } = STATE;

  if (recentDevelopmentsRequestController) {
    recentDevelopmentsRequestController.abort();
  }

  recentDevelopmentsRequestController = new AbortController();
  const signal = recentDevelopmentsRequestController.signal;

  dispatch({
    type: "SET_RECENT_DEVELOPMENT_COMPANIES_LOADING",
    payload: "PENDING",
  });

  await SYNAPSE_API.get(`/api/company/user-companies-recent-development`, {
    signal,
  })
    .then((response) => {
      dispatch({
        type: "SET_RECENT_DEVELOPMENT_COMPANIES",
        payload: response.data.companies,
      });
    })
    .catch((err) => {
      if (err.name === "AbortError" || err.name === "CanceledError") return;
      toast.error("Failed to fetch companies");
    })
    .finally(() => {
      if (signal.aborted) return;
      dispatch({
        type: "SET_RECENT_DEVELOPMENT_COMPANIES_LOADING",
        payload: "IDLE",
      });
    });
};

let companiesCountriesRegionsFilterController = null;
export const getCompaniesCountriesRegionsAPI = async (STATE) => {
  if (!STATE) return;
  const { dispatch, state } = STATE;

  if (companiesCountriesRegionsFilterController) {
    companiesCountriesRegionsFilterController.abort();
  }

  companiesCountriesRegionsFilterController = new AbortController();
  const signal = companiesCountriesRegionsFilterController.signal;

  dispatch({
    type: "SET_GEOGRAPHY_LOADING",
    payload: "PENDING",
  });

  return await SYNAPSE_API.post(`/api/company/countries-regions`, {
    hub: null,
    category_id: state.selectedCategory?.id || null,
  }, { signal })
    .then((response) => {
      dispatch({
        type: "SET_GEOGRAPHY",
        payload: response.data,
      })
    })
    .catch((err) => {
      if (err.name === "AbortError" || err.name === "CanceledError") return;
      toast.error("Failed to fetch countries and regions");
    })
    .finally(() => {
      if (signal.aborted) return;
      dispatch({
        type: "SET_GEOGRAPHY_LOADING",
        payload: "IDLE",
      });
    });
};
