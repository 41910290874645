import { Box } from "@mui/material";
import React from "react";
import Icon1 from "../../../utils/img/icon1.svg";
import Icon2 from "../../../utils/img/icon2.svg";
import Icon3 from "../../../utils/img/icon3.svg";
import Icon4 from "../../../utils/img/icon4.svg";
import Icon5 from "../../../utils/img/icon5.svg";
import _ from "lodash";

const WhyUs = ({ data, dataPoints }) => {
  const iconIndex = [Icon1, Icon2, Icon3, Icon4, Icon5];
  // const sampleData = {
  //   why_us: {
  //     data: [
  //       {
  //         title: "Crisp, insightful analysis",
  //         description:
  //           "Our focus is NOT on word count. We present only the key trends impacting the market, so you don't waste time finding a needle in the haystack.",
  //       },
  //       {
  //         title: "True Bottom-Up Approach",
  //         description:
  //           "We study the industry, not just the market. Our bottom-up approach ensures we have a broad and deep understanding of the forces that shape the industry.",
  //       },
  //       {
  //         title: "Exhaustive Data",
  //         description:
  //           "Tracking a million data points on the meat and meat substitute industry. Our constant market tracking covers over 1 million+ data points spanning 29 countries and over 215 companies just for the meat and meat substitutes industry.",
  //       },
  //       {
  //         title: "Convenience",
  //         description:
  //           "Tables belong in a spreadsheet. We don't stuff our reports with data tables. We, like you, find a spreadsheet better suited for data evaluation. Each of our reports comes with an excel document that contains all data used in analyzing the market.",
  //       },
  //       {
  //         title: "Transparency",
  //         description:
  //           "Know where your data comes from. Most market reports today hide the sources used behind the veil of a proprietary model. We proudly present them so you can trust our information.",
  //       },
  //     ],
  //     dataPoints: [
  //       {
  //         data: 48000,
  //         title: "Data Points",
  //       },
  //       {
  //         data: 63,
  //         title: "Industry Reports",
  //       },
  //       {
  //         data: 21,
  //         title: "Market Segments",
  //       },
  //       {
  //         data: 218,
  //         title: "Companies with Market Shares",
  //       },
  //       {
  //         data: 71,
  //         title: "Countries from 6 regions",
  //       },
  //     ],
  //   },
  // };
  return (
    <Box>
      <h3
        style={{ marginBottom: "1.5rem", fontSize: "20px", fontWeight: "800" }}
      >
        {Number(dataPoints?.[0]?.data ?? 0).toLocaleString() ?? 0}+ Reasons to
        Work With Us
      </h3>
      {data ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "baseline",
              padding: "1rem",
              flexBasis: "100%",
              marginTop: "0.7rem",
              background:
                "var(--background-gradient, linear-gradient(92deg, rgba(24, 156, 222, 0.33) -39.01%, #EAFFEA -38.99%, #EAF6FE 50.05%, #E3E1FC 136.46%))",
            }}
            id="datapoints_section"
          >
            {dataPoints?.map((item, index) => {
              return (
                <div key={_.uniqueId()} style={{ flex: 1 }}>
                  <h2 style={{ textAlign: "center", color: "#446BA6" }}>
                    {index === 0 ? item.data?.toLocaleString() : item.data}
                  </h2>
                  <p
                    style={{
                      width: "calc(100% - 40px)",
                      margin: "auto",
                      fontSize: "14px",
                      textAlign: "center",
                      color: "#5A5A5A",
                    }}
                  >
                    {item.title}
                  </p>
                </div>
              );
            })}
          </Box>
          <Box id="data_section">
            {data?.map((item, index) => {
              return (
                <>
                  <div
                    key={_.uniqueId()}
                    style={{
                      display: "flex",
                      padding: "1rem",
                      marginTop: "1rem",
                    }}
                    className="data_section_points"
                  >
                    <div>
                      <img src={iconIndex[index]} />
                    </div>
                    <div style={{ paddingLeft: "1rem" }}>
                      <h3 style={{ fontSize: "16px" }}>{item.title}</h3>
                      <p style={{ color: "#5A5A5A", fontSize: "16px" }}>
                        {item.description}
                      </p>
                    </div>
                  </div>
                  <hr
                    style={{
                      background: "#E4E4E4",
                      border: "1px solid #E4E4E4",
                    }}
                  />
                </>
              );
            })}
          </Box>
        </>
      ) : (
        <div style={{ padding: "1rem" }}>No data available</div>
      )}
    </Box>
  );
};

export default WhyUs;
