import styled from "styled-components";

export const GraphContainer = styled.div`
  display: block;
  & > div {
    text-align: left;
    margin-bottom: 50px;
    padding: 10px;
  }
`;

export const HiddenGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > label {
    color: #002f75;
    font-size: "16px";
    font-weight: "500";
    font-family: "Avenir Medium";
    text-transform: uppercase;
  }
  & > div {
    border: 1px solid lightgrey;
    width: ${({ width }) => `${width - 50}px`};
    height: ${({ height }) => `${height - 50}px`};
    display: flex;
    justify-content: center;
    align-items: center;
    color: #002f75;
    font-size: 28px;
    font-weight: bold;
    font-family: "Avenir Medium";
    margin-top: 5px;
    margin-bottom: 15px;
  }
`;

export const NoGraphContainer = styled.div`
  border: 1px solid lightgrey;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
 
  color: #002f75;
  font-size: 28px;
  font-family: "Avenir Medium";
  font-weight: bold;
  margin-bottom: 10px;
`;
