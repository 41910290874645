import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext/Auth";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  InputBase,
  Pagination,
  CircularProgress,
} from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ArrowLeftOutlined } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import ViewListIcon from "@mui/icons-material/ViewList";
import RenewDrawer from "../Common/RenewDrawer";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import Loader from "../Common/Loader";
import Notification from "../Common/Notification";
import Cookies from "universal-cookie";
import API from "../../api/Api";

function CompanyMarket() {
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [downloadReport, setDownloadReport] = useState("");
  const [cacheSlugReports, setCacheSlugReport] = useState({});
  const [rdPreviewLoading, setRdPreviewLoading] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { config, notify, setNotify } = useAuth();
  const cookies = new Cookies();
  // const Data = title.toLowerCase().replace(/\s+/g, "-").trim();
  // let urlTitle = Data.substring(0, Data.indexOf("market") + 6);

  // new code
  const { state } = useLocation();
  const { id, companyId, name } = state || {};
  const navigate = useNavigate();
  const { Base_URL } = API;

  useEffect(() => {
    if (!state) {
      navigate("/hubs");
    }
  }, [navigate, state]);
  // new code

  useEffect(() => {
    getInfo();
  }, []);

  async function getInfo() {
    setLoading(true);

    axios
      .get(
        `${Base_URL}/api/company/showCompanyMarket?hubId=${id}&companyId=${companyId}`,
        config
      )
      .then((res) => {
        if (typeof res.data.data === "object") {
          setData(res?.data.data);
          setNoData(false);
        } else {
          setNoData(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function makeThisFavorite(reportId) {
    setLoading(true);
    const bodyParameters = {
      report_id: reportId,
    };
    const url = `${Base_URL}/api/hubs/favourite`;

    axios
      .post(url, bodyParameters, config)
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data?.message,
          type: "success",
          position: "right",
        });
        getInfo();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  //   for make This  unfavorite your report id  ⬇️

  async function makeThisUnFavorite(reportId) {
    setLoading(true);
    const bodyParameters = {
      report_id: reportId,
    };
    const url = `${Base_URL}/api/hubs/unFavourite`;

    axios
      .post(url, bodyParameters, config)
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data?.message,
          type: "success",
          position: "right",
        });
        getInfo();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  // for downloading the pdf

  async function fetchPdfData(hub_id, company_id, report_id, name, index) {
    // setLoading(true);
    setDownloadReport(report_id);
    try {
      const bodyParameters = {
        hub_id,
        company_id,
        report_id,
      };

      const res = await axios.post(
        `${Base_URL}/api/company/viewCompanyProfile`,
        bodyParameters,
        config
      );
      const pdfData = res.data.data;
      if (typeof pdfData === "object") {
        generatePpt(name, pdfData);
        // setData(res.data?.data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        console.log("network err");
      }
      console.log(err);
    } finally {
      // setLoading(false);
      setDownloadReport("");
    }
  }

  const generatePpt = async (name, pdfData) => {
    const bodyParameters = {
      file_title: name
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .replace(/\s+/g, "")
        .trim(),
      pptData: pdfData?.pptData,
    };

    try {
      //  setPptLoading(true); // start loading
      const response = await axios.post(
        `${Base_URL}/api/company/ppt-gen`,
        bodyParameters,
        config
      );
      const url = response.data?.data?.url;

      handleDownload(url);
    } catch (error) {
      console.error(error);
    }
    //  setPptLoading(false); // stop loading
  };

  function handleDownload(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = true;
    link.click();

    setLoading(false);
  }

  const filterCompanies = data?.filter((report) =>
    report.title.toLowerCase().includes(query.toLowerCase())
  );

  // This code is for the pagination⬇️⬇️

  const PER_PAGE = 10;

  useEffect(() => {
    setCount(() =>
      filterCompanies ? Math.ceil(filterCompanies?.length / PER_PAGE) : 0
    );
  }, [filterCompanies]);
  // const count = Math.ceil(filterCompanies?.length / PER_PAGE);

  const _DATA = filterCompanies?.slice((page - 1) * PER_PAGE, page * PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
  };

  const handleHtmlPageReport = async (slug) => {
    if (cacheSlugReports[slug]) {
      const newTab = window.open();
      newTab.document.write(cacheSlugReports[slug]);
      return;
    }
    setRdPreviewLoading((p) => ({ ...p, [slug]: true }));
    axios
      .get(`${Base_URL}/api/hubs/report-page/${slug}`, config)
      .then((rep) => {
        setCacheSlugReport((p) => ({ ...p, [slug]: rep.data.data }));
        const newTab = window.open();
        newTab.document.write(rep.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setRdPreviewLoading((p) => ({ ...p, [slug]: false }));
      });
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Box display="flex" alignItems="center" my={2}>
        <Button startIcon={<ArrowLeftOutlined />} onClick={() => navigate(-1)}>
          Back
        </Button>

        <Typography
          ml={4}
          textTransform="uppercase"
          variant="body2"
          sx={{ whiteSpace: "nowrap" }}
        >
          {name}
        </Typography>
      </Box>
      {noData ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="70vh"
        >
          No Company found
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              width: "50%",
              border: "1px solid black",
              borderRadius: "30px ",
              display: "flex",
              alignItems: "center",
              ml: "20px",
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search "
              inputProps={{ "aria-label": "search " }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Box>
          {/* Here is our companies card⬇️⬇️⬇️⬇️ */}
          {loading ? (
            <Loader />
          ) : (
            <Box
              sx={{
                my: "3%",
                display: "flex",
                flexDirection: "column",
                felx: "1",
                gap: "10px",
              }}
            >
              {_DATA?.map((item, i) => (
                <Box
                  key={_.uniqueId()}
                  // display="flex"
                  width="95%"
                  p={1}
                  mx={"auto"}
                  alignItems="center"
                  gap={1}
                  boxShadow={2}
                  sx={{
                    display: ["block", "block", "flex"],
                    justifyContent: "space-between",
                    "&:hover": {
                      boxShadow: 6,
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Show Company Profile" arrow placement="top">
                      <IconButton
                        onClick={() => {
                          navigate(`profile`, {
                            state: {
                              hubId: item.hub_id,
                              companyId: item.company_id,
                              reportId: item.report_id,
                              name: name,
                            },
                          });
                        }}
                        aria-label="delete"
                        size="large"
                      >
                        <ViewListIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={item.title} arrow placement="top">
                      <Link
                        to={`/hubs/${item.hubName
                          .toLowerCase()
                          .replace(/\s+/g, "-")}/${
                          item.title
                            .toLowerCase()
                            .replace(/\s+/g, "-")
                            .split("market")[0] + "market"
                        }`}
                      >
                        <Typography
                          // width="60%"
                          fontSize="14px"
                          fontWeight={600}
                          flex={1}
                          sx={{
                            overflow: "hidden",
                            whiteSpace: ["initial", "initial", "nowrap"],
                            textOverflow: "ellipsis",
                          }}
                          onClick={() => {
                            cookies.set("HUBID", item.report_id, { path: "/" });
                            cookies.set("HUBTITLE", item.title, { path: "/" });
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Link>
                    </Tooltip>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {item.favourite === "yes" ? (
                      <Tooltip
                        title="Make This Unfavorite"
                        arrow
                        placement="top"
                      >
                        <IconButton
                          onClick={() => {
                            makeThisUnFavorite(item.report_id);
                          }}
                          aria-label="delete"
                          size="large"
                        >
                          <StarIcon fontSize="inherit" sx={{ color: "gold" }} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make This Favorite" arrow placement="top">
                        <IconButton
                          onClick={() => {
                            makeThisFavorite(item.report_id);
                          }}
                          aria-label="delete"
                          size="large"
                        >
                          <StarBorderIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    )}

                    <Button
                      disabled={item.subscribed === "no" ? false : true}
                      onClick={() => setDrawerOpen(true)}
                    >
                      Buy Now
                    </Button>
                    <Tooltip
                      title={
                        item.subscribed === "no"
                          ? "Show Sample Info"
                          : "Show Info"
                      }
                      arrow
                      placement="top"
                    >
                      {rdPreviewLoading[item.slug] ? (
                        <CircularProgress
                          size={30}
                          sx={{ color: "#1976d2", marginLeft: "1.2rem" }}
                        />
                      ) : (
                        <IconButton
                          aria-label="delete"
                          size="large"
                          onClick={() => handleHtmlPageReport(item.slug)}
                        >
                          <VisibilityIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </Tooltip>
                    <Box sx={{ position: "relative" }}>
                      {item.report_id !== downloadReport ? (
                        <Tooltip
                          title="Download Company Profile"
                          arrow
                          placement="top"
                        >
                          <IconButton
                            disabled={item.subscribed !== "no" ? false : true}
                            onClick={() => {
                              fetchPdfData(
                                item.hub_id,
                                item.company_id,
                                item.report_id,
                                name
                              );
                              // saveAs(item.report_url, "Report.pdf");
                            }}
                            aria-label="delete"
                            size="large"
                          >
                            <FileDownloadIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <CircularProgress
                          size={50}
                          sx={{
                            color: "#1976d2",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
              <Box display={"flex"} justifyContent="center" my={5}>
                <Pagination
                  count={count}
                  page={page}
                  shape="rounded"
                  onChange={handleChange}
                />
              </Box>
            </Box>
          )}
          <RenewDrawer
            // report_id={report_id}
            title={"title"}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            typeModal={"buy"}
          />
        </Box>
      )}
    </>
  );
}

export default CompanyMarket;
