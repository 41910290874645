import styles from "./styles.module.css";
import ReportBriefDetail from "./ReportBriefDetail";
import { useEffect, useRef, useState } from "react";
import { Tabs, Tab, Paper, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SynapseLogo from '../../assets/Synapse_logo.svg' 
import SearchInput from "../../components/Common/SearchInput";

function ReportLayout({
  children,
  setCurrentTab,
  currentTab,
  hub,
  reportTabsToRender,
  fetchReport,
  reportData
}) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  function handleClose() {
    setExpanded(!expanded)
  }

  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: null,
        paragraph: null,
        bannerHeight: "464px",
      },
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainReportLayoutContainer}>
        <section className={styles.topSection}>
          <ReportBriefDetail hub={hub} fetchReport={fetchReport} />
        </section>
        <section className={styles.bottomSection}>
          <ReportTabs
            changeTab={setCurrentTab}
            tab={currentTab}
            reportTabsToRender={reportTabsToRender}
            reportData={reportData}
          />
          { reportData && <Box position={'sticky'} width={expanded ? '100%' : '220px'} left={'100%'} top={'0px'} zIndex={'100'} sx={{
             opacity: 0,mr: '5%',
             animation: 'fadeIn 9s forwards',
             '@keyframes fadeIn': {
               from: { opacity: 0 },
               to: { opacity: 1 },
             },
          }}>
            <SearchInput expanded={expanded} handleClose={handleClose} reportData={reportData} />
          </Box>}
          {children}
        </section>
      </div>
    </div>
  );
}

export default ReportLayout;

const ReportTabs = ({ changeTab, tab, reportTabsToRender, reportData }) => {
  const handleChange = (event, newValue) => {
    changeTab(newValue);
  };
  const [isSticky, setIsSticky] = useState(false);
  const [loadingForTabType, setLoadingForTabType] = useState("PENDING");
  const boxRef = useRef();

  const handleScroll = () => {
    const box = boxRef.current;
    if (box) {
      const boxTop = box.getBoundingClientRect().top;
      setIsSticky(boxTop <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    const waitTabFor = setTimeout(() => {
      setLoadingForTabType("IDLE");
    }, 2000);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(waitTabFor);
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      sx={{
        width: "100%",
        background: "white",
        position: "sticky",
        top: "0",
        zIndex: "20",
      }}
    >
      <Box
        sx={{
          mr: 2,
          display: isSticky ? { xs: "none", md: "flex" } : "none",
          fontFamily: "monospace",
          fontWeight: 700,
          letterSpacing: ".3rem",
          color: "inherit",
          textDecoration: "none",
          position: "fixed",
          top: "10px",
          zIndex: "21",
          width: "142px",
        }}
      >
        <Link to={"/reports"}>
          <img
            className="pic"
            style={{ width: "142px", height: "36px", marginLeft: "20px" }}
            src={SynapseLogo}
            // src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/logo.png"
            alt="logo"
          />
        </Link>
      </Box>
      <Tabs
        value={tab}
        onChange={handleChange}
        centered
        sx={{
          display: loadingForTabType === "IDLE" ? "flex" : "none",
          justifyContent: "center",
          width: "100%",
          height: "64px",
          boxShadow: "0 10px 20px 0 rgba(0, 47, 117, 0.08)",
          padding: "0px 24px 0px 24px",
          background: "#fff",
          gap: "8px",
          "& .css-1wf8b0h-MuiTabs-flexContainer": {
            gap: "40px",
            "& > button": {
              textTransform: "none",
              font: "400 16px / 25.6px Avenir Roman",
              outline: "0",
            },
          },
        }}
      >
        {reportTabsToRender.map((tab) => {          
          if(tab === 'Report Sample' && reportData?.blur_pdf_url){
            tab = 'Report'
          }
          return (
            <Tab
              label={tab}
              sx={{
                paddingBottom: "0px",
                textTransform: "capitalize",
              }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};
