import React from "react";
import { SortModalContainer} from "./CompanyHome.styles";
import { sortOptionsArray } from "./CompanyHome.config";

function SortModal(props){

let handleSortOption = (option)=>{
  props.getSortOption(option);
  setTimeout(()=>{ props.setOpenSortModal(false)}, 2000);
 ;
}

  return(
    <SortModalContainer>
      <div>
      <div>
      <h4>Sort By</h4>
      <span onClick={()=>{props.setOpenSortModal(false)}}>X</span>
      </div>
       <ul>
        {
          sortOptionsArray.map((option)=>{
            return <li onClick={()=>{handleSortOption(option)}}>{option}</li>
          })
        }
       </ul>
      </div>
    </SortModalContainer>
  )
}

export default SortModal;
