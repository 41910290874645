import styles from "./styles.module.css";
import { getReports, useReportsContext } from "./STATE";
import { getTabBasedOnLibrary } from "./reports.utils";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function Pagination() {

  const { state, dispatch } = useReportsContext();
  const { page, limit, totalMyReports, totalCatalogueReports, totalArchiveReports, loading, currentTab, isLibraryReports } = state;

  const tab = {
    "MY_REPORTS": totalMyReports,
    "CATALOGUE": totalCatalogueReports,
    "REPORTS_LIBRARY": totalArchiveReports
  }

  const countFrom = tab[currentTab] ?? 0;
  const countOf = Math.ceil(countFrom / limit);
  const totalPages = countOf < 1 ? 1 : countOf;

  const [,setSearchParams] = useSearchParams();
  const setParams = (key, value) => {
    setSearchParams((p) => {
      p.set(key, value);
      return p;
    });
  }


  if (loading === "PENDING" || countFrom === 0) return <></>;
  
  const setItemsPerPage = async (items) => {
    dispatch({ type: `SET_${getTabBasedOnLibrary(currentTab, isLibraryReports)}_LOADING`, payload: "PENDING" });
    dispatch({
      type: "SET_PAGINATION",
      payload: { limit: items, page: 1 },
      fn: async (state) => {
        setParams("limit", items);
        setParams("page", 1);
        await getReports(state, dispatch);
      }
    });
  };

  const setCurrentPage = async (currentPage) => {
    if(currentPage === page) return;
    const pageToSet = currentPage > totalPages ? totalPages : currentPage; 
    dispatch({ type: `SET_${getTabBasedOnLibrary(currentTab, isLibraryReports)}_LOADING`, payload: "PENDING" });
    await dispatch({
      type: "SET_PAGINATION",
      payload: { page: pageToSet },
      fn: async (state) =>{
         setParams("limit", limit);
         setParams("page", pageToSet);
         await getReports(state, dispatch)
        }
    });
  };

  return (
    <div className={styles.paginationMainContainer}>
      <div>
        <span>View reports per page:</span>
        <select
          value={limit}
          onChange={(e) => setItemsPerPage(Number(e.target.value))}
        >
          {[10, 25, 50, 75, 100].map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
      <div>{page + " of " + totalPages}</div>
      <div>
        <button onClick={() => setCurrentPage(page > 1 ? page - 1 : page)}>
          {"<"}
        </button>
        <button
          onClick={() => setCurrentPage(page < totalPages ? page + 1 : page)}
        >
          {">"}
        </button>
      </div>
    </div>
  );
}

export default Pagination;
