import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, Typography, Chip, Button, Box, Container, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { data } from '../../utils/sidebar/SideBar'
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { scrollToSection } from '../../utils/common';



function BookmarkReportsSidebar({ category, setCategory, subCategory, setSubCategory, nameBadge, setNameBadge, getReportData, subCategoryName, setSubCategoryName }) {

  const [expanded, setExpanded] = useState(false);

  // const [subCategoryName, setSubCategoryName] = useState([]);

  const handleClear = () => {
    setCategory([])
    setSubCategory([])
    setNameBadge([])
    setSubCategoryName([])
    getReportData("reset")
    scrollToSection({ id: "favourite-reports-search", modifyTop: 80 });
  }
  // const [subCategoryValues, setSubCategoryValues] = useState([]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // const getRCard = () => {

  // }




  const handleChange1 = (isChecked, items, itemsId) => {
    const value = items.map((item) => item.id);
    const name = items.map((item) => item.title);

    const newVal = value.filter((item) => !subCategory.includes(item));
    const newName = name.filter((item) => !subCategoryName.includes(item));
    const categoryIndex = category.indexOf(itemsId);



    // if (categoryIndex === -1) {
    //   setCategory((state) => [...state, itemsId]);
    // } else {
    //   setCategory(category.filter((id) => id !== itemsId));
    // }
    if (JSON.stringify(value) === JSON.stringify(newVal)) {
      if (categoryIndex === -1) {
        setCategory((state) => [...state, itemsId]);
      } else {
        setCategory(category.filter((id) => id !== itemsId));
      }
    }

    if (isChecked) {
      setSubCategory((prev) => [...prev, ...newVal]);
      setNameBadge((prev) => [...prev, ...newName]);
    } else {
      setSubCategory(subCategory.filter((val) => !value.includes(val)));
      setNameBadge(subCategoryName.filter((val) => !name.includes(val)));
    }
  };

  //   it will check Single subCategory

  const handleChange2 = (isChecked, subId, mainId, subData, title) => {
    const index = subCategory.indexOf(subId);
    const categoryIndex = category.indexOf(mainId);

    if (categoryIndex === -1) {
      setCategory((state) => [...state, mainId]);
    } else {
      setCategory((state) => [...state]);
    }

    if (isChecked) {
      setSubCategory((state) => [...state, subId]);
      setNameBadge((state) => [...state, title]);
    }
    else {
      setSubCategory(subCategory.filter((value) => value !== subId));
      setNameBadge(subCategoryName.filter((value) => value !== title));
    }
  };

  const handleApplyButton = () => {
    getReportData();
    setExpanded(false);
    setSubCategoryName(nameBadge);
    scrollToSection({ id: "favourite-reports-search", modifyTop: 80 });
  }

  return (
    <div>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          mb: "350px",
          // mt: "6px",
          width: "318px",
          position: "sticky",
          top: "80px", // Stick to the top
        }}
      >

        <Box
          boxShadow="0 2px 20px 0 rgba(0, 47, 117, 0.08)"
          borderRadius="8px">

          <Box
            width="100%"
            // my={3}
            height="75px"
            padding="5px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography ml={2} fontWeight={800} sx={{ fontSize: "16px" }}>
              Categories
            </Typography>
            <Button
              onClick={handleClear}
              sx={{ textTransform: "none", display: "flex", alignItems: "center", fontSize: "16px", color: "#189CDE" }}
              startIcon={<RestartAltOutlinedIcon sx={{ fontSize: "24px", width: "24px", height: "24px" }} />}
            >
              Reset All
            </Button>
          </Box>
          {data?.map((items) => {
            return items.reportsTotal !== 0 ?(
              <Accordion
                key={items.id}
                sx={{
                  width: "100%",
                }}
                expanded={expanded === items.id}
                onChange={handleChange(items.id)}
              >
                <AccordionSummary
                  sx={{
                    "&.Mui-expanded": {
                      borderLeft: "4px solid #189CDE",
                      background: "#F0F7FC",
                      height: "74px"
                    },
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{`${items.title}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    {subCategory &&
                      items.subcategory?.filter(item => item.reportsTotal !== 0).map((item, i) => (
                        <Box
                          key={i}
                          sx={{
                            pl: 3,
                            display: "flex",
                            alignItems: "center",

                            "&:hover": {
                              borderRight: "solid #1976d2 1px",
                              background: "#f1f1f1",
                            },
                          }}
                        >
                          <FormControlLabel
                            sx={{
                              // whiteSpace: "nowrap",
                              width: "30px",
                            }}
                            key={item.id}
                            control={
                              <Checkbox
                                checked={subCategory.includes(item.id)}
                                onChange={(event) =>
                                  handleChange2(
                                    event.target.checked,
                                    item.id,
                                    items.id,
                                    items.subcategory,
                                    item.title
                                  )
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                          />
                          <Typography sx={{ fontWeight: "400", fontSize: "14px", color: "#2D2D2D" }}>
                            {`${item.title}`}{" "}
                            <span style={{ color: "#878787" }}>{`(${item.reportsTotal} reports)`}</span>
                          </Typography>

                        </Box>
                      ))}
                  </Box>
                  <Box sx={{ display: "flex", padding: "0px 24px 24px 24px" }}>
                    <Button sx={{ backgroundColor: 'white', width: "127px", height: "40px", padding: "8px 0px 8px 0px", textTransform: "none" }}
                      onClick={() => handleChange1(false, items.subcategory, items.id)}
                    >
                      Clear All
                    </Button>
                    <Button onClick={handleApplyButton} fullWidth variant="contained" sx={{ backgroundColor: '#189CDE', width: "127px", height: "40px", padding: "8px 0px 8px 0px", textTransform: "none" }}>
                      Apply
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ) : null
          })}
        </Box>
      </Container>
     
    </div>
  );
}



export default BookmarkReportsSidebar