import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "lodash";
import { scrollToSection } from "../../utils/common";

const FilterSidebarMarketShare = ({
  data,
  handleDimensionChange,
  resetAll,
  handleRegionChange,
  region,
  regionData,
  recordState,
}) => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel, key) => (event, newExpanded) => {
    if (key === "region") {
      setRegionChanged(false);
    }
    setExpanded(newExpanded ? panel : false);
  };

  const [regionChanged, setRegionChanged] = useState(false);

  useEffect(() => {
    const mainData = {};
    const setData = Object?.keys(data)?.map((key) => {
      // setRecordState((prevState) => ({
      //   ...prevState,
      //   [key]: "",
      // }));
      mainData[key] = "";
    });

    // setRecordState(mainData);
  }, [data]);
  /*
  Data sample:
  {
    region:{
      [
        "All",
        "Asia-Pacific",
        "Europe",
        "Middle East and Africa",
        "North America"
      ]
    },
    country:{
      [
      "All",
      "India",
      "Australia",
      "UK",
      "South Korea",
      "United States",
      "Japan",
      "France"
      ]
    }
  }
  */
  const handleChange2 = (event, child, parent) => {
    scrollToSection({ id: "graph-download-market-share-section", modifyTop: 80 });
    // setRecordState((prevState) => ({
    //   ...prevState,
    //   [parent]: child,
    // }));
    if (parent === "region") {
      handleRegionChange(child);
      setRegionChanged(true);
    } else {
      handleDimensionChange(parent, child);
    }
  };


  function getHubsCard() {}
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        mb: "350px",
        mt: "6px",
        position: "sticky",
        top: "80px", // Stick to the top
      }}
    >
      <Box
        boxShadow="rgba(149, 157, 165, 0.4) 0px 8px 24px"
        borderRadius="10px"
        width="100%"
        sx={{ textTransform: "capitalize" }}
      >
        <Box
          width="100%"
          // my={3}
          height="75px"
          padding="5px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.08)",
          }}
        >
          <Typography ml={2} fontWeight={600}>
            Filters
          </Typography>
          <Button
            onClick={() => {
              // setRecordState({});
              scrollToSection({ id: "graph-download-market-share-section", modifyTop: 80 });
              resetAll();
            }}
            startIcon={<RestartAltOutlinedIcon />}
            sx={{ textTransform: "capitalize" }}
          >
            Reset All
          </Button>

          {/* {closeButton} */}
        </Box>
        {Object?.entries(data)?.map(([key, item = "sample"]) => {
          if (item.length === 1 && item[0] === "All" && key !== "region" && key !== 'country') {
            return null;
          }
          return (
            <Accordion
              key={item + Math.random()}
              expanded={
                expanded === item || (key === "region" && regionChanged)
              }
              onChange={handleChange(item, key)}
              disabled={item.length === 0}
              sx={{
                //Remove margin of Mui-expanded
                "&.MuiAccordion-root.Mui-expanded": {
                  margin: 0,
                },
                paddingBottom: "0.5rem",
              }}
            >
              <AccordionSummary
                sx={{
                  "&.Mui-expanded": {
                    borderLeft: "4px solid #189CDE",
                    background: "#F0F7FC",
                    height: "74px",
                  },
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{key.replace(/_/g, " ")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    pl: 3,
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    {key === "region" &&
                      _.orderBy(regionData, [
                        (item) => (item.dimensions.region === "All" ? 0 : 1), // "All" first, others second
                        "dimensions.region", // Sort by region alphabetically
                      ])?.map((value) => {
                        let originalValue = value?.dimensions?.region;
                        return (
                          <FormControlLabel
                            key={originalValue}
                            control={
                              <Radio
                                checked={
                                  key === "region"
                                    ? region === originalValue
                                    : recordState[key] === originalValue
                                }
                              />
                            }
                            label={originalValue}
                            onChange={(event) =>
                              handleChange2(
                                event.target.checked,
                                originalValue,
                                key
                              )
                            }
                            sx={{
                              "&:hover": {
                                borderRight: "solid #1976d2 1px",
                                background: "#f1f1f1",
                              },
                            }}
                          />
                        );
                      })}
                    {key !== "region" &&
                      item?.map((value) => {
                        return (
                          <FormControlLabel
                            key={value}
                            control={
                              <Radio
                                checked={
                                  key === "region"
                                    ? region === value
                                    : recordState?.[key] === value
                                }
                              />
                            }
                            label={value}
                            onChange={(event) =>
                              handleChange2(event.target.checked, value, key)
                            }
                            sx={{
                              "&:hover": {
                                borderRight: "solid #1976d2 1px",
                                background: "#f1f1f1",
                              },
                            }}
                          />
                        );
                      })}
                  </RadioGroup>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mb: 1,
                  }}
                ></Box>
                {/* <Button
                  style={{ marginBottom: "1rem" }}
                  onClick={getHubsCard}
                  fullWidth
                  variant="contained"
                >
                  Apply Filter
                </Button> */}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Container>
  );
};

export default FilterSidebarMarketShare;
