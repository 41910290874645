import { useReportsContext, getReports } from "../STATE";
import styles from "../styles.module.css";
import Loader from "../../../components/Common/Loader";
import SynapseReportsCard from "../../../components/HubsComponents/SynapseReportsCard";
import { getTabBasedOnLibrary } from "../reports.utils";
import HubsReportCardForReports from "../../../components/HubsComponents/HubsReportCardForReports";
import { Box } from "@mui/material";
import useDownloadBulkReports from "../SearchFilter/DownloadBulkReports";

function ArchiveReports() {
  const { state, dispatch } = useReportsContext();

  const DownloadCheckBox = useDownloadBulkReports();

  const getReportData = async () => {
    dispatch({
      type: `SET_${getTabBasedOnLibrary(
        state.currentTab,
        state.isLibraryReports
      )}_LOADING`,
      payload: "PENDING",
    });
    await getReports(state, dispatch);
  };

  return (
    <div className={styles.myReportsMainContainer}>
      <section>
        {state.archiveLoading === "PENDING" ? (
          <Loader />
        ) : state.archiveReports.length === 0 ? (
          <center>No Reports Found</center>
        ) : (
          state.archiveReports.map((report, i) => {
            if (report.hub_id) {
              return (
                <HubsReportCardForReports
                  key={i}
                  {...report}
                  currentTab={state.currentTab}
                  report_id={report.mi_reports_id}
                  getReportData={() => getReportData(report.id)}
                  fromLibrary={true}
                />
              );
            } else {
              return (
                <Box position="relative">
                {/* {state.currentTab === "MY_REPORTS" && (<DownloadCheckBox id={report.synapse_report_id} />)} */}
                <SynapseReportsCard
                key={i}
                {...report}
                currentTab={state.currentTab}
                report_id={report.synapse_report_id}
                getReportData={() => getReportData()}
                />
                </Box>
                
              );
            }
          })
        )}
      </section>
    </div>
  );
}

export default ArchiveReports;
