import React from "react";
import Dialog from "@mui/material/Dialog";

function DialogModal({ open, handleClose, children }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      {children}
    </Dialog>
  );
}

export default DialogModal;
