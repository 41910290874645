import React, { useEffect, useState } from 'react';
import {  useDispatch, useSelector } from "react-redux";
import { Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import './PowerBIEmbed.css'; // Import the CSS file
import API from "../../api/Api";
import axios from 'axios';


let models = window["powerbi-client"].models;


const PowerBIEmbed = () => {
  const userData = useSelector((state) => state.userData); // Adjust according to your state shape
  const [reports, setReports] = useState([]);
  const [selectedReportId, setSelectedReportId] = useState('');
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: "PowerBI Dashboard",
        paragraph: null,
        backButtonData: null,
      },
    });
  }, []);

  useEffect(() => {
    const getEmbedToken = async () => {
      try {
        const response = await fetch(`${API.Base_URL}/getEmbedToken`);
        if (!response.ok) {
          throw new Error('Failed to fetch embed token');
        }
        const embedData = await response.json();
        embedReport(embedData);
      } catch (error) {
        console.error('Error fetching embed token:', error);
      }
    };

    const embedReport = (embedData) => {
      const config = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: embedData.accessToken,
        embedUrl: embedData.embedUrl[0].embedUrl,
        settings: {
          panes: {
            filters: {
              expanded: true,
              visible: true
            }
          }
        }
      };

      const reportContainer = document.getElementById('reportContainer');
      if (reportContainer) {
        const report = window.powerbi.embed(reportContainer, config);

        report.on('loaded', () => {
          console.log('Report load successful');
        });

        report.on('rendered', () => {
          console.log('Report render successful');
        });

        report.on('error', (event) => {
          console.error('Embedding error:', event.detail);
        });
      }
    };
    const fetchReports = async () => {
      try {
        const params = { client_email: userData.email }; // Assuming userData has an email field
        const res = await axios.get(`${API.Base_URL}/power-bi`, { params });
        //console.log("Data===>", res.data)
        setReports(res.data.data[0].reportDetails); // Adjust according to your API response structure
      } catch (error) {
        console.error('Error fetching reports:', error);
        setError('Error fetching reports');
      }
    };

    fetchReports();

    getEmbedToken();
  }, [userData.email]);
  const handleReportChange = async (event) => {
    const reportId = event.target.value;
    setSelectedReportId(reportId);

    if (reportId) {
      try {
        const params = { email: userData.email, reportId }; 
       const embedReport = (embedData) => {
        //console.log("<<<<<",embedData);
        const config = {
          type: 'report',
          tokenType: models.TokenType.Embed,
          accessToken: embedData.accessToken,
          embedUrl: embedData.embedUrl[0].embedUrl,
          settings: {
            panes: {
              filters: {
                expanded: true,
                visible: true
              }
            }
          }
        };
  
        const reportContainer = document.getElementById('reportContainer');
        if (reportContainer) {
          const report = window.powerbi.embed(reportContainer, config);
  
          report.on('loaded', () => {
            console.log('Report load successful');
          });
  
          report.on('rendered', () => {
            console.log('Report render successful');
          });
  
          report.on('error', (event) => {
            console.error('Embedding error:', event.detail);
          });
        }
      };
      const embedData =  await axios.get(`${API.Base_URL}/getEmbedToken`, { params });
      console.log("===>", embedData);

       embedReport(embedData.data)

        console.log('Email sent successfully');
      } catch (error) {
        console.error('Error sending email:', error);
        setError('Error sending email');
      }
    }
  };

  return (
    <div style={{margin: "50px auto"}}>
      { reports.length>1 && (
            <FormControl
              variant="outlined"
              className="file-dropdown"
              sx={{
                width: 140,
                // maxWidth:140,
                position: "absolute",
                top: "243px",
                right: "30px",
                zIndex:5,
                marginRight: 0,
                color: "#189cde",
              }}
            >
              <InputLabel id="file-select-label">Select File</InputLabel>
              <Select
                labelId="file-select-label"
                value={selectedReportId}
                onChange={handleReportChange}
                label="Select File"
              >
                {reports.map((report) => (
                  <MenuItem key={report.reportId} value={report.reportId}>
                    {report.reportName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
      <div className="trial-banner">
      <div className="hide-trial-banner"></div> 
      <div id="reportContainer" className="embed-container">
      </div>
      </div>
      <div className="error-container" id="errorContainer"></div>
    </div>
  );
};

export default PowerBIEmbed;
