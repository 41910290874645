import { useRef, useState } from "react";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import "../../../styles/datasheetDetails.css";
import { FaFileExcel } from "react-icons/fa6";
import LockBox from "../../LockBox";
import { useAuth } from "../../../context/authContext/Auth";
import { useSelector } from "react-redux";
import { ReportAccess } from "../Modals/ReportAccess";
import LockBoxNew from "../../LockBox/LockBoxNew";

const DatasheetDetailsTab = ({ reportExcel, subscribed, title, id, getReportData }) => {
  const iframeRef = useRef(null);
  const { remaining_library_credit } = useSelector((state) => state);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(
    reportExcel[reportExcel.length - 1]
  );
  const handleCancel = () => {
    setShowModal(false);
    // getReportData(p => !p);
  };

  const fetchReport = ()=>{
    getReportData(p => !p);
  }

  const handleFileChange = (event) => {
    const selectedFile = reportExcel.find(
      (file) => file.file_name === event.target.value
    );
    setSelectedFile(selectedFile);
  };

  const downloadGoogleSheet = () => {
    if (!subscribed || !selectedFile) return;
    const a = document.createElement("a");
    a.href = selectedFile.url;
    a.target = "_blank";
    a.download = `${selectedFile.title}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const excelUI = encodeURIComponent(selectedFile?.url);

  const iframeUrl = `https://view.officeapps.live.com/op/view.aspx?src=${excelUI}&wdOrigin=BROWSELINK`;
  const { userInfo } = useAuth();

  if (!selectedFile) {
    return (
      <Box className="datasheet-container">
        <Box className="excel-container" sx={{ minHeight: "800px" }}>
          <LockBox purchaseCustomText="No datasheet available for this report." />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box className="datasheet-container">
        {showModal && (
          <ReportAccess
            report_id={id}
            title={title}
            closeModal={handleCancel}
            reloadFunction={fetchReport}
          />
        )}
        {!subscribed && (
          <div
            style={{
              position: "absolute",
              zIndex: "2",
              top: "13%",
              background: " #fff",
              minWidth: "300px",
              padding: "10px 37px",
              borderRadius: "10px",
              boxShadow: "4px 3px 20px 0px #0000001a",
            }}
            className="rect-title"
          >
            {selectedFile.title}
          </div>
        )}
        {!subscribed && (
          <LockBoxNew
            onclick={
              remaining_library_credit > 0 ? () => setShowModal(true) : false
            }
            purchaseCustomText="Get access to report to see Data Sheet"
            componentName={"Data Sheet"}
          ></LockBoxNew>
        )}

        <Box
          className="excel-container"
          sx={{
            filter: subscribed ? "none" : "blur(5px)",
            pointerEvents: subscribed ? "auto" : "none",
          }}
        >
          <div
            className={`rect-hide ${
              reportExcel.length === 1 ? "single-file" : ""
            }`}
          >
            <div className="title-download-container">
              <div className="rect-title">{selectedFile.title}</div>
            </div>
            {userInfo?.account_type === "view only"
              ? false
              : true && (
                  <Button
                    variant="outlined"
                    startIcon={<FaFileExcel />}
                    onClick={downloadGoogleSheet}
                    disabled={!subscribed}
                    sx={{
                      height: "40px",
                      padding: "8px 24px",
                      fontWeight: "500",
                      borderRadius: "8px",
                      position: "absolute",
                      top: "15.5%",
                      left: "20px",
                      textTransform: "capitalize",
                      borderColor: "#002F75",
                      border: "0.8px solid",
                      "&:hover": {
                        backgroundColor: "#446ba6",
                        color: "#fff",
                      },
                    }}
                  >
                    Download Sheet
                  </Button>
                )}
            {/* {reportExcel.length > 1 && ( */}
            <Box visibility={reportExcel.length > 1 ? "visible" : "hidden"}>
              <FormControl
                variant="outlined"
                className="file-dropdown"
                sx={{
                  minWidth: 100,
                  position: "absolute",
                  top: "15.5%",
                  right: "20px",
                  marginRight: 0,
                  color: "#189cde",
                }}
              >
                <InputLabel id="file-select-label">Select File</InputLabel>
                <Select
                  labelId="file-select-label"
                  value={selectedFile.file_name}
                  onChange={handleFileChange}
                  label="Select File"
                >
                  {reportExcel.map((file) => (
                    <MenuItem key={file.file_name} value={file.file_name}>
                      {file.fileName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* )} */}
          </div>
          <iframe
            ref={iframeRef}
            src={iframeUrl}
            title="Excel-Like Viewer"
          ></iframe>
        </Box>
      </Box>
    </>
  );
};

export default DatasheetDetailsTab;
