import styles from "./styles.module.css";
import { useEffect, useRef } from "react";
import { Divider, Box } from "@mui/material";

function FilterModal({ setOpenFilter, children, filterBy }) {

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenFilter(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenFilter]);


  return (
    <Box component="section" ref={modalRef} className={styles.filtersContainer} sx={{ width: "fit-content" }}>
      <section className={styles.topFilterSection}>
        <p>{filterBy}</p>
      </section>
      <Divider />
      <section>{children}</section>
      <Divider />
    </Box>
  );
}

export default FilterModal;
