import React, {useEffect} from "react";
import NoData from "../../components/Common/NoData";
import { useDispatch } from "react-redux";

function Indicators() {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch({
          type: "SET_NAVBAR_DATA",
          payload: {
              heading: null,
              paragraph: null,
              backButtonData: null,
              // bannerHeight: '260px'
          },
      });
  }, [])
  return (
    <div>
      <NoData />
    </div>
  );
}

export default Indicators;
