/* eslint-disable no-unused-vars */
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import { Box, Tab, CircularProgress, Button, Grid } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HubsHomeNavbar from "../../components/HubsComponents/HubsHomeNavbar";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import HubsReportsSection from "../../components/HubsComponents/HubsReportsSection";
import CompanyMarketShare from "../../components/CompaniesComponents/CompanyMarketShare";
import HubsCompaniesHome from "../../components/HubsComponents/HubsCompanies";
import Cookies from "universal-cookie";
import VisualiseCustom from "../../components/Visualise/VisualiseCustom";
import { useDispatch, useSelector } from "react-redux";
import HubsReportDSMultiSelect from "../../components/HubsComponents/HubsReportDSMultiSelect";
import HubsIndicators from "../../components/HubsComponents/HubsIndicators";
import API from "../../api/Api";
import { scrollToSection } from "../../utils/common";
import AnswerEngine from "../../components/AnswerEngine/AnswerEngine";
import Loader from "../../components/Common/Loader";
import SynapseLogo from "../../assets/Synapse_logo.svg";
// API URL="{{base_url}}/hubs/check-subscribed?hub_id=5"
//Response Sample:
// {
//     "data": {
//         "isSubscribed": true
//     },
//     "message": "success",
//     "code": 200
// }
export default function HubItem() {
  const [value, setValue] = useState("6");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [haveMyRaAccess, setHaveMyRaAccess] = useState(false);
  const { hubContentData, checkSub, checkHubId, hubSlug } = useSelector(
    (state) => state
  );
  const { config, userInfo } = useAuth();
  const { Base_URL, environment } = API;
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    id,
    name,
    changeName,
    isRedirectedFromReport,
    isRedirectFromCompanyReports,
  } = location.state || {};
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [modulesAccess, setModulesAccess] = useState([]);
  const [hubModuleLevel, setHubModuleLevel] = useState({});

  // useEffect(() => {
  //   setIsSubscribed(location.state.isSubscribed);
  // }, []);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const hubID = cookies.get("hubId");
  const sendState = { id: `${hubID}` };
  const myraAvailable = ["5"];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSticky, setIsSticky] = useState(false);
  const currentUrl = window.location.href;
  const segments = currentUrl.split("/");
  const hubsIndex = segments.indexOf("hubs");
  const boxRef = useRef();
  const targetSegment = segments?.[hubsIndex + 1]
    ?.replace(/-/g, " ")
    ?.toUpperCase();

  const hubTitle = targetSegment?.toLowerCase() ?? "";

  //  NAVBAR PAYLOAD TO SHOW HUBS CONTENT

  const URL = location.pathname;

  const heading = URL !== "/bookmarks" ? hubTitle : "";
  const hubContent = (val) => {
    if (val === undefined) {
      return "";
    }
    if (val.length > 1 && val !== undefined) {
      return val;
    } else {
      return "We offer fully tailored intelligence solutions to meet the unique business requirements of any organization. Our deep industry expertise coupled with cross-functional analyst teams ensures we can support the intelligence requirements of even the most specific of business problems.";
    }
  };

  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: heading,
        paragraph: URL !== "/bookmarks" ? hubContent(data?.hub_content) : "",
        backButtonData: URL !== "/bookmarks" ? "My Hubs" : null,
        talkToUs: URL === "/bookmarks" ? null : true,
        downloadBrochure: URL === "/bookmarks" ? null : id,
        bannerHeight: URL === "/bookmarks" ? "260px" : null,
      },
    });
  }, [data]);

  // ////////////////////////////////////
  useEffect(() => {
    if (!id) {
      navigate("/hubs");
    }
  }, [id, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const checkSubscribed = async () => {
    axios
      .get(`${Base_URL}/api/hubs/check-subscribed?hub_id=${hubID}`, config)
      .then((res) => {
        setIsSubscribed(res?.data?.data?.isSubscribed);
        setHaveMyRaAccess(res?.data?.data?.haveMyRaAccess);

        const hubLevelModule = res?.data?.data?.hubLevelModule;
        setHubModuleLevel(hubLevelModule);
        setModulesAccess(
          hubLevelModule.modules
            ? hubLevelModule.modules.map((item) => item.toLowerCase())
            : undefined
        );
        setLoading(false);

        dispatch({
          type: "SET_CHECK_SUB",
          payload: {
            checkSub: res?.data?.data?.isSubscribed,
            hubLevelModule: hubLevelModule,
          },
        });
      });
  };

  useEffect(() => {
    if (checkHubId === hubID) {
      setData(hubContentData);
    } else {
      getInfo();
    }
    setLoading(true);
    axios
      .get(`${Base_URL}/api/hubs/check-subscribed?hub_id=${hubID}`, config)
      .then((res) => {
        setIsSubscribed(res?.data?.data?.isSubscribed);
        setHaveMyRaAccess(res?.data?.data?.haveMyRaAccess);

        const hubLevelModule = res?.data?.data?.hubLevelModule;
        setHubModuleLevel(hubLevelModule);
        setModulesAccess(
          hubLevelModule.modules
            ? hubLevelModule.modules.map((item) => item.toLowerCase())
            : undefined
        );
        setLoading(false);

        dispatch({
          type: "SET_CHECK_SUB",
          payload: {
            checkSub: res?.data?.data?.isSubscribed,
            hubLevelModule: hubLevelModule,
          },
        });
      });
  }, []);
  // here we are getting the tab Value in sessionStorage

  useEffect(() => {
    // Retrieve data from session storage when component mounts
    // const storedData = sessionStorage.getItem("hubsTabId");

    // setValue(sessionStorage.getItem("hubsTabId") ? sessionStorage.getItem("hubsTabId") : "6");
    setValue("6"); // ALWAYS OPEN ABOUT TAB BY DEFAULT

    // Remove an item from session storage
    sessionStorage.removeItem("companiesId");
    sessionStorage.removeItem("hubsReportTabId");
  }, []);

  useEffect(() => {
    if (id && isRedirectedFromReport) {
      setValue("1");
    } else if (id && isRedirectFromCompanyReports) {
      getInfo();
      setValue("2");
    }
  }, [id, isRedirectedFromReport]);

  // here we are saving the tab Value in sessionStorage

  const handleChange = (event, newValue) => {
    setTimeout(() => {
      scrollToSection({ id: "navbar-bottom" });
    }, 0);
    sessionStorage.setItem("hubsTabId", newValue);
    setValue(newValue);
  };

  //  after clicking the hubs we will get the hubs data / so we can show on the home tab
  async function getInfo() {
    // setLoading(true);
    const bodyParameters = {
      hubId: id,
    };

    axios
      .post(`${Base_URL}/api/hubs/home`, bodyParameters, config)
      .then((res) => {
        setData(res.data.data);
        dispatch({
          type: "SET_HUB_CONTENT",
          payload: {
            checkHubId: hubID,
            hubContentData: res.data.data,
            hubSlug: location?.pathname?.split("/")[2],
          },
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  const handleScroll = () => {
    const box = boxRef.current;
    if (box) {
      const boxTop = box.getBoundingClientRect().top;
      setIsSticky(boxTop <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <TabContext value={value}>
        <Box
          ref={boxRef}
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "64px",
            boxShadow: "0 2px 20px 0 rgba(0, 47, 117, 0.08)",
            width: "100%",
            padding: "0px 24px 0px 24px",
            position: "sticky",
            top: "0",
            zIndex: 10,
            background: "white", // Set background color when sticky if needed
            gap: "8px",
          }}
        >
          <Box
            // ref={boxRef}
            className={`stickyLogo ${isSticky ? "sticky" : ""}`}
            sx={{
              display: isSticky ? { xs: "none", md: "flex" } : "none",
              justifyContent: "center",
              width: "150px",
              height: "64px",
              gap: "8px",
              position: "absolute",
              top: "10px",
              left: "0",
            }}
          >
            <Link to={"/reports"}>
              <img
                className="pic"
                style={{ width: "142px", height: "36px", marginLeft: "20px" }}
                src={SynapseLogo}
                // src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/logo.png"
                alt="logo"
              />
            </Link>
          </Box>
          <TabList
            variant="fullWidth"
            onChange={handleChange}
            sx={{
              display: "flex",
              textAlign: "center",
              "& .MuiButtonBase-root": {
                minWidth: "fit-content",
                overflow: "unset",
              },
            }}
          >
            <Tab
              label="About"
              value="6"
              sx={{
                "@media (max-width:600px)": {
                  padding: 0, // Remove padding on mobile devices
                },
                "& span": {
                  minWidth: 75,
                },
                textTransform: "capitalize",
                paddingBottom: "0px",
                whiteSpace: "nowrap",
              }}
            />
            <Tab
              label="Reports"
              value="1"
              sx={{
                "@media (max-width:600px)": {
                  padding: 0, // Remove padding on mobile devices
                },
                "& span": {
                  minWidth: 75,
                },
                textTransform: "capitalize",
                paddingBottom: "0px",
                whiteSpace: "nowrap",
                display:
                  modulesAccess?.includes("reports") || !modulesAccess
                    ? undefined
                    : "none",
              }}
            />
            {/* <Link style={{ color: "inherit" }} to="companies" state={{ id }}> */}
            <Tab
              label="Companies"
              value="2"
              sx={{
                width: "100%",
                height: "100%",
                "@media (max-width:600px)": {
                  padding: 0, // Remove padding on mobile devices
                },
                "& span": {
                  minWidth: 75,
                },
                textTransform: "capitalize",
                paddingBottom: "0px",
                whiteSpace: "nowrap",
                // display: modulesAccess?.includes('companies')  || !modulesAccess ? undefined : 'none',
              }}
            />
            {/* </Link> */}
            <Tab
              label="Market Share"
              value="3"
              sx={{
                "@media (max-width:600px)": {
                  padding: 0, // Remove padding on mobile devices
                },
                "& span": {
                  minWidth: 75,
                },
                textTransform: "capitalize",
                paddingBottom: "0px",
                whiteSpace: "nowrap",
                display:
                  modulesAccess?.includes("market share") || !modulesAccess
                    ? undefined
                    : "none",
              }}
            />
            <Tab
              label="Visualize"
              value="4"
              sx={{
                "@media (max-width:600px)": {
                  padding: 0, // Remove padding on mobile devices
                },
                "& span": {
                  minWidth: 75,
                },
                textTransform: "capitalize",
                paddingBottom: "0px",
                whiteSpace: "nowrap",
                display:
                  modulesAccess?.includes("visualize") || !modulesAccess
                    ? undefined
                    : "none",
              }}
            />

            <Tab
              label="Master Data"
              value="5"
              sx={{
                "@media (max-width:600px)": {
                  padding: 0, // Remove padding on mobile devices
                },
                "& span": {
                  minWidth: 75,
                },
                textTransform: "capitalize",
                paddingBottom: "0px",
                whiteSpace: "nowrap",
                display:
                  modulesAccess?.includes("master data") || !modulesAccess
                    ? undefined
                    : "none",
              }}
            />
            {/* Removing Indicator section for Confectionery and Soft Drink hub TEMP as we don't have its indicator data */}
            {!["28", "29"].includes(hubID) && isSubscribed && (
              <Tab
                label="Indicators"
                value="7"
                sx={{
                  "@media (max-width:600px)": {
                    padding: 0, // Remove padding on mobile devices
                  },
                  "& span": {
                    minWidth: 75,
                  },
                  textTransform: "capitalize",
                  paddingBottom: "0px",
                  whiteSpace: "nowrap",
                  display:
                    modulesAccess?.includes("indicators") || !modulesAccess
                      ? undefined
                      : "none",
                }}
              />
            )}
          </TabList>

          {(userInfo?.access_type !== "trial" && haveMyRaAccess) ||
          (myraAvailable.includes(hubID) &&
            userInfo?.access_type !== "trial" &&
            haveMyRaAccess) ? (
            <Button
              variant="contained"
              sx={{
                color: "rgb(169, 77, 25)",
                display:
                  modulesAccess?.some(
                    (module) => module.toLowerCase() === "myra ai".toLowerCase()
                  ) || !modulesAccess
                    ? undefined
                    : "none",
                backgroundColor: "rgb(255, 237, 199)",
                padding: "8px 24px",
                textTransform: "none",
                borderRadius: "8px",
                height: "50px",
                marginTop: "6px",
                "&:hover": {
                  backgroundColor: "rgb(255, 237, 199)",
                  color: "rgb(169, 77, 25)",
                },
              }}
              onClick={(event) => handleChange(event, "8")}
            >
              myRA AI
            </Button>
          ) : null}
        </Box>
        {/* <Container maxWidth={"xl"}> */}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container sx={{ marginTop: value === "8" ? "0px" : "50px" }}>
            {/* {windowWidth >= 900 && (
                <Grid item md={3}>
                  <FilterSidebar data={Object.keys(dim)} />
                </Grid>
              )} */}

            <Grid item xs={11} md={12}>
              <Box sx={{ width: "100%" }}>
                <TabPanel value="1">
                  <HubsReportsSection
                    id={id}
                    hubTitle={hubTitle}
                    content={data?.hub_content}
                    callCheckSubscribed={checkSubscribed}
                    // callCheckSubscribed={() => {}}
                  />
                </TabPanel>

                <TabPanel value="2">
                  <HubsCompaniesHome
                    hubId={hubID}
                    hubSlug={changeName ?? hubSlug}
                    hubTitle={hubTitle}
                    reportId={id}
                  />
                </TabPanel>

                <TabPanel value="3">
                  <CompanyMarketShare
                    hubId={{
                      id: `${hubID}`,
                      hubTitle: hubTitle,
                    }}
                    isSubscribed={isSubscribed}
                    reportId={id}
                  />
                </TabPanel>

                <TabPanel value="4">
                  {/* <NoData /> */}
                  <VisualiseCustom
                    visualiseLevel={"hub"}
                    isSubscribed={isSubscribed}
                  />
                </TabPanel>

                <TabPanel value="5">
                  <HubsReportDSMultiSelect
                    sheetType={"masterData"}
                    isSubscribed={isSubscribed}
                  />
                </TabPanel>
                <TabPanel value="7">
                  <HubsIndicators
                    // sheetType={"masterData"}
                    chekcIsSubscribed={isSubscribed}
                    // setIsSubscribed={setIsSubscribed}
                  />
                </TabPanel>

                <TabPanel value="6">
                  <HubsHomeNavbar
                    hubId={hubID}
                    {...data}
                    isSubscribed={["limited", "full"].includes(
                      hubModuleLevel?.access_type || "trial"
                    )}
                  />
                </TabPanel>

                <TabPanel value="8">
                  <AnswerEngine from="hub" />
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        )}
        {/* </Container> */}
      </TabContext>
    </Box>
  );
}
