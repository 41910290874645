import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import React, { useState } from "react";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import Notification from "../../components/Common/Notification";
import UserAccessHOC from "../../components/HOC/UserAccess";
import styles from "./styles.module.css";
import { LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import DialogModal from "../../components/Common/DialogModal";
import API from "../../api/Api";
import LabelInputField from "../../components/Common/LabelInputField";
const LinkSendImg =
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/link_sent.svg";

function ForgotPassword() {
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState(errorsState);
  const [loading, setLoading] = useState("IDEAL");
  const [open, setOpen] = useState(false);
  const { notify, setNotify } = useAuth();
  const { Base_URL } = API;

  const { email } = formState;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("PENDING");
    await axios
      .post(Base_URL + "/api/users/forgot-password-send-mail", {
        email,
      })
      .then((response) => {
        setOpen(true);
        setFormState(initialState);
        setErrors(errorsState);
      })
      .catch((error) => {
        console.error(error, "err");
        setNotify({
          isOpen: true,
          message: error.response.data?.message || "Some Error occurred",
          type: "error",
          position: "right",
        });
      })
      .finally(() => {
        setLoading("IDEAL");
      });
  };

  const handleFormChange = (e) => {
    let value = e.target.value.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailChange = e.target.name === "email" && value;
    let error = {};
    if (emailChange.length > 0 && !emailRegex.test(emailChange)) {
      error = { err_email: "Invalid Email", disabled: true };
      setErrors(error);
    } else {
      error = { err_email: "", disabled: false };
      setErrors(error);
    }
    setFormState((p) => ({
      ...p,
      [e.target.name]: value,
    }));
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <div className={styles.detailsBox}>
        <h1>Forgot your Password?</h1>
        <p>Enter your email address below, to receive password reset link</p>
        <div className={styles.inputContainer}>
          <form onSubmit={handleSubmit}>
            <LabelInputField>
              <LabelInputField.Label>
                Email
                <LabelInputField.Tooltip
                  value={"Enter your email associated with the password"}
                />
              </LabelInputField.Label>
              <LabelInputField.Input
                type="text"
                name="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => handleFormChange(e)}
              />
              <LabelInputField.Error errors={errors.err_email} />
            </LabelInputField>
            <div className={styles.loginContainer}>
              <button
                type="submit"
                disabled={errors.disabled}
                className={errors.disabled ? `${styles.disabledBtn}` : ""}
                onClick={handleSubmit}
              >
                Send reset link
              </button>
              {loading === "PENDING" && <LinearProgress />}
              <Link to="/">
                <KeyboardBackspaceIcon />
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
      <DialogModal open={open} handleClose={() => setOpen(false)}>
        <div className={styles.dialogModal}>
          <figure>
            <img src={LinkSendImg} alt="sent" />
          </figure>
          <h4>Reset Link sent!</h4>
          <p>Please check your email</p>
        </div>
      </DialogModal>
    </>
  );
}

export default ForgotPassword;

const initialState = {
  email: "",
};

const errorsState = { err_email: "", disabled: true };
