import { createContext, useContext } from "react";
import {
  createTags,
  createFilterQuery,
  removeFilter,
  getTabBasedOnLibrary,
} from "./reports.utils";
import { scrollToSection } from "../../utils/common";
import { SYNAPSE_API } from "../../api/Api";

const initialModalFilterState = {
  selectedRegion: [],
  selectedCountry: [],
};

export const sideBarCategoryInitialState = {
  currentCategory: null,
  path: "",
  title: "",
  fullPath: "",
  slug: "",
  pathArray: [],
};

const reportsState = {
  myReports: [],
  myReportsLoading: "PENDING",
  archiveReports: [],
  archiveLoading: "PENDING",
  catalogueReports: [],
  catalogueLoading: "PENDING",
  catalogueReportStatusFilter: "all_catalogue_reports",
  libraryReportsFilter: [],
  isReqReportsTab: false,
  libraryReportsStatusFilter: "all_library_reports",
  isArchiveReqReportsTab: false,
  limit: 10,
  page: 1,
  search: "",
  totalResult: 0,
  filterModalOpen: false,
  filterLoading: "PENDING",
  filterQuery: null,
  catalogueInstantAccessFilter: false,
  filterTags: [],
  modalFilter: initialModalFilterState,
  isLibraryHubReport: "hub_and_library_reports",
};

export const initialState = {
  ...reportsState,
  isLibraryReports: false,
  isCommissionedReports: false,
  currentTab: "MY_REPORTS",
  loading: "PENDING",
  totalMyReports: 0,
  totalCatalogueReports: 0,
  totalArchiveReports: 0,
  sideBarCategory: sideBarCategoryInitialState,
  onlyHubReportsForMyReports: false,
  bulkReportsToDownload: [],
  bulkLibraryReportsToDownload: [],
};

export const reducer = (state, action) => {
  let stateToSet = {};
  switch (action.type) {
    //  MAIN PARENT STATE ----------------------------------

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    case "SET_MY_REPORTS_CATALOGUE_COUNT":
      return {
        ...state,
        totalMyReports: action.payload.myReportsCount,
        totalCatalogueReports: action.payload.cataloguesCount,
        totalArchiveReports: action.payload.totalArchiveReports,
      };

    case "CHANGE_TAB":
      const [totalMyReports, totalCatalogueReports, totalArchiveReports] = [
        state.totalMyReports,
        state.totalCatalogueReports,
        state.totalArchiveReports,
      ];
      stateToSet = {
        ...initialState,
        isReqReportsTab:
          action.payload === "CATALOGUE" ? state.isReqReportsTab : false,
        totalMyReports,
        totalCatalogueReports,
        totalArchiveReports,
        currentTab: action.payload,
      };
      action.fn && action.fn(stateToSet);
      return stateToSet;

    case "SET_SIDEBAR_CATEGORY":
      stateToSet = {
        ...state,
        limit: 10,
        page: 1,
        sideBarCategory: {
          ...state.sideBarCategory,
          ...action.payload,
        },
      };
      action.fn && action.fn(stateToSet);
      return stateToSet;

    // REPORTS STATE -------------------------------

    case "SEARCH_INPUT":
      stateToSet = {
        ...state,
        search: action.payload,
        limit: 10,
        page: 1,
      };
      action.fn && action.fn(stateToSet);
      return stateToSet;

    case "SET_PAGINATION":
      setTimeout(() => {
        scrollToSection({ id: "report-search-section", modifyTop: 80 });
      }, 0);

      stateToSet = {
        ...state,
        ...action.payload,
      };
      action.fn && action.fn(stateToSet);
      return stateToSet;

    case "SET_FILTER_MODAL_LOADING":
      return {
        ...state,
        filterLoading: action.payload,
      };

    case "SET_FILTER_MODAL_DATA":
      return {
        ...state,
        modalFilter: {
          ...state.modalFilter,
          ...action.payload,
        },
      };

    case "CREATE_FILTER_QUERY":
      const query = createFilterQuery(state.modalFilter);
      const filteredTags = createTags(state.modalFilter);
      stateToSet = {
        ...state,
        limit: 10,
        page: 1,
        filterQuery: JSON.stringify(query),
        filterTags: filteredTags,
      };
      action.fn && action.fn(stateToSet);
      return stateToSet;

    case "CLEAR_FILTER":
      stateToSet = {
        ...state,
        modalFilter: initialModalFilterState,
        libraryReportsFilter: [],
        libraryReportsStatusFilter: "all_library_reports",
        limit: 10,
        page: 1,
        filterQuery: null,
        filterTags: [],
      };
      action.fn && action.fn(stateToSet);
      return stateToSet;

    case "REMOVE_TAG":
      const dataToSet = removeFilter({ ...action.payload }, state.modalFilter);
      const removedTag = state.filterTags.filter(
        (tag) => tag.id !== action.payload.id
      );
      const filterQueryAfterTags = createFilterQuery({
        ...state.modalFilter,
        ...dataToSet,
      });
      stateToSet = {
        ...state,
        limit: 10,
        page: 1,
        modalFilter: {
          ...state.modalFilter,
          ...dataToSet,
        },
        filterQuery: JSON.stringify(filterQueryAfterTags),
        filterTags: removedTag,
      };
      action.fn && action.fn(stateToSet);
      return stateToSet;

    case "SET_MY_REPORTS_REPORTS":
      return {
        ...state,
        myReports: action.payload,
      };

    case "SET_CATALOGUE_REPORTS":
      return {
        ...state,
        catalogueReports: action.payload,
      };

    case "SET_CATALOGUE_LOADING":
      return {
        ...state,
        catalogueLoading: action.payload,
      };

    case "SET_MY_REPORTS_LOADING":
      return {
        ...state,
        myReportsLoading: action.payload,
      };

    case "SET_REPORTS_LIBRARY_REPORTS":
      return {
        ...state,
        archiveReports: action.payload,
      };
    case "SET_REPORTS_LIBRARY_LOADING":
      return {
        ...state,
        archiveLoading: action.payload,
      };

    case "SET_IS_LIBRARY_REPORTS":
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        sideBarCategory: state.sideBarCategory,
        isLibraryReports: action.payload,
        isCommissionedReports: false,
        onlyHubReportsForMyReports: false,
      };

    case "SET_CATALOGUE_SWITCH":
      return {
        ...state,
        isReqReportsTab: action.payload,
      };

    case "SET_ARCHIVE_SWITCH":
      return {
        ...state,
        isArchiveReqReportsTab: action.payload,
      };

    case "SET_CATALOGUE_INSTANT_ACCESS":
      return {
        ...state,
        catalogueInstantAccessFilter: action.payload,
      };

    case "SET_CATALOGUE_REPORT_STATUS_FILTER":
      return {
        ...state,
        catalogueReportStatusFilter: action.payload,
      };

    case "SET_LIBRARY_REPORTS_FILTER":
      return {
        ...state,
        libraryReportsFilter: action.payload,
      };

    case "SET_LIBRARY_REPORT_STATUS_FILTER":
      return {
        ...state,
        libraryReportsStatusFilter: action.payload,
      };

    case "SET_IS_COMMISSIONED_REPORTS":
      return {
        ...state,
        isCommissionedReports: action.payload,
        isLibraryReports: false,
        onlyHubReportsForMyReports: false,
      };

    case "SET_IS_LIBRARY_HUB_REPORTS":
      return {
        ...state,
        isLibraryHubReport: action.payload,
      };

    case "SET_ONLY_HUB_REPORTS_FOR_MY_REPORTS":
      return {
        ...state,
        onlyHubReportsForMyReports: action.payload,
        isLibraryReports: false,
        isCommissionedReports: false,
      };

    case "BULK_REPORTS_DOWNLOAD":
      return {
        ...state,
        bulkReportsToDownload: action.payload,
      };

    case "BULK_LIBRARY_REPORTS_DOWNLOAD":
      return {
        ...state,
        bulkLibraryReportsToDownload: action.payload,
      };

    case "CLEAR_BULK_REPORTS":
      return {
        ...state,
        bulkReportsToDownload: [],
        bulkLibraryReportsToDownload: [],
      };

    default:
      return state;
  }
};

export const ReportsContext = createContext();
export const useReportsContext = () => useContext(ReportsContext);

let controller = null;

export const getReports = async (state, dispatch, add = {}) => {
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  const signal = controller.signal;

  const {
    limit,
    page,
    sideBarCategory,
    search,
    filterQuery,
    currentTab,
    isLibraryReports,
    catalogueReportStatusFilter,
    catalogueInstantAccessFilter,
    libraryReportsFilter,
    libraryReportsStatusFilter,
    isCommissionedReports,
    isLibraryHubReport,
    onlyHubReportsForMyReports,
  } = state;

  const endPointRequired = {
    MY_REPORTS: "myReports",
    CATALOGUE: "report-catalogue",
    REPORTS_LIBRARY: "archieve-reports",
  };

  const apiRouteTo = endPointRequired[currentTab] ?? null;

  const params = {
    search,
    limit,
    page,
    filter: filterQuery || "null",
    catId: sideBarCategory.currentCategory ?? "null",
    path: add?.path || "null",
  };

  let url = `/api/reports/${apiRouteTo}`;

  if (currentTab === "MY_REPORTS") {
    params["is_library"] = isLibraryReports;
    params["library_reports_filter"] = JSON.stringify(libraryReportsFilter);
    params["is_commissioned"] = isCommissionedReports;
    params["show_only_hub_reports"] = onlyHubReportsForMyReports;
  }

  if (currentTab === "REPORTS_LIBRARY") {
    params["is_library"] =
      libraryReportsStatusFilter === "purchased_library_reports";
    params["library_reports_filter"] = JSON.stringify(libraryReportsFilter);
    params["is_library_hub_report"] = isLibraryHubReport;
  }

  if (currentTab === "CATALOGUE") {
    if (catalogueReportStatusFilter === "requested_catalogue_reports") {
      url = `/api/reports/credit-reports`;
    } else if (catalogueReportStatusFilter === "purchased_catalogue_reports") {
      params["purchased_catalogue_reports"] = true;
    }
    if (catalogueInstantAccessFilter) {
      params["instant_access"] = true;
    }
  }

  await SYNAPSE_API.get(url, {
    method: "GET",
    params,
    signal,
  })
    .then((res) => {
      dispatch({
        type: `SET_${getTabBasedOnLibrary(
          currentTab,
          isLibraryReports
        )}_REPORTS`,
        payload: res.data.data.reports,
      });
      const countsAre = res.data.data;
      dispatch({
        type: "SET_MY_REPORTS_CATALOGUE_COUNT",
        payload: {
          myReportsCount: countsAre.my_reports_count,
          totalArchiveReports: countsAre.archive_reports_count,
          cataloguesCount: countsAre.catalogue_reports_count,
        },
      });
      if (res.data.data?.path) {
        setCategoryItem(
          res.data.data.path.id,
          res.data.data.path,
          state,
          dispatch
        );
      }
    })
    .catch((err) => {
      if (err.name === "AbortError" || err.name === "CanceledError") return;
      console.log(err);
    })
    .finally(() => {
      if (signal.aborted) return;
      dispatch({ type: "SET_LOADING", payload: "IDLE" });
      dispatch({
        type: `SET_${getTabBasedOnLibrary(
          currentTab,
          isLibraryReports
        )}_LOADING`,
        payload: "IDLE",
      });
      dispatch({ type: "SET_FILTER_MODAL_LOADING", payload: "IDLE" });
    });
};

export const setCategoryItem = (id, item, state, dispatch) => {
  if (state.sideBarCategory.currentCategory === id) return;
  dispatch({ type: "SET_LOADING", payload: "PENDING" });
  dispatch({
    type: `SET_${getTabBasedOnLibrary(
      state.currentTab,
      state.isLibraryReports
    )}_LOADING`,
    payload: "PENDING",
  });
  const data = {
    currentCategory: id,
    path: item.parent === "/" ? "" : item.parent,
    title: item.title,
    fullPath: item.path,
    slug: item.slug,
    pathArray: item.path.split("/"),
  };
  dispatch({
    type: "SET_SIDEBAR_CATEGORY",
    payload: data,
    fn: async (state) => await getReports(state, dispatch),
  });
};
