import React, {useState} from "react";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab, Tabs } from "@mui/material";
import {Container, TabsContainer} from "./HubCompanies.styles"
import HubsCompanies from "./HubCompanies";
import CompanyRecentDevelopment from "../../CompaniesComponents/CompanyRecentDevelopment";

function HubsCompaniesHome ({
  hubId,
  hubTitle,
  hubSlug,
  reportId
}){

  const [value, setValue] = useState("1");
  const [companiesCount, setCompaniesCount] = useState(0);

  const activeButtonStyle = {
    background: "rgba(68, 107, 166, 1)",
    color: "#fff",
    height: "40px",
    display: "inline-block",
    padding: "8px, 24px, 8px, 24px",
    borderRadius: "8px",
    margin: "16px 16px 24px 0",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "500",
    border: "1px solid red !important",
  };
  const buttonStyle = {
    color: "rgba(90, 90, 90, 1)",
    textTransform: "capitalize",
    display: "inline-block",
    padding: "8px, 24px, 8px, 24px",
    margin: "16px 16px 24px 0",
    fontSize: "16px",
    fontWeight: "500",
  };

  let handleTabChange = (event, newValue) => {
    setValue(newValue);
 };


  return(
    <Container>
    <TabsContainer>
    <TabContext value={value}>

   <Box 
     sx={{width:"500px"}}
   >
     <Tabs
       sx={{
         border:"1px solid red"
       }}
       variant="fullWidth" 
       onChange={handleTabChange}  
       className="tablist">

       <Tab 
        style={value === "1" ? activeButtonStyle : buttonStyle} 
        sx={{whiteSpace: 'nowrap'}}
        label={ companiesCount ?  `Companies Covered (${companiesCount})` : 'Companies Covered'} 
        value="1" />
      
       <Tab  
         style={ value === "2" ? activeButtonStyle :  buttonStyle} 
         label={'Recent Developments'}
         value="2" />
     </Tabs>
   </Box>

     <TabPanel value="1">
        <HubsCompanies 
         hubId={hubId} 
         hubTitle={hubTitle} 
         hubSlug={hubSlug} 
         setCompaniesCount={setCompaniesCount}
         />
     </TabPanel>
     <TabPanel value="2">
       <CompanyRecentDevelopment 
              hubId={hubId}
              reportId={reportId}
        />
     </TabPanel>
   </TabContext>
   </TabsContainer>
  </Container>
)};

export default HubsCompaniesHome;