import { Box, Button, Container, FormControl, InputAdornment, InputLabel, OutlinedInput, Typography, Tabs, Tab, Grid, TextField, FormControlLabel, Switch } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { useState, useEffect } from 'react'
import { useAuth } from '../../context/authContext/Auth';
import HubsReportCard from "../HubsComponents/HubsReportCard";
import NoData from "../Common/NoData";
import Loader from "../Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import Hubs from "../../pages/hub";
import HubsReportsSection from "../HubsComponents/HubsReportsSection";
import API from "../../api/Api";
import BookmarkReportsSidebar from "./BookmarkReportsSidebar";
import BookmarksReportsContainer from "./BookmarksReportsContainer";
import { RiSearchLine } from "react-icons/ri";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { setIn } from "formik";

const buttonStyle = {
    // width: '103px',
    height: '40px',
    padding: '8px 24px',
    fontWeight: '500',
    borderRadius: '8px'

}

function Favorites() {
    const [selectedTab, setSelectedTab] = useState(0);
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false);

    // for sidebar
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [nameBadge, setNameBadge] = useState([]);
    const [subCategoryName, setSubCategoryName] = useState([]);
    const [apiCallState, setApiCallState] = useState(1);
    const [isLibraryReports, setIsLibraryReports] = useState(false)
    const [sortedData, setSortedData] = useState([]);
    const [initialData, setInitialData] = useState([])
    const [inputValue, setInputValue] = useState("");

    const { Base_URL } = API
    const { config, countHubsBookmarks } = useAuth();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: "SET_NAVBAR_DATA",
            payload: {
                heading: null,
                paragraph: null,
                backButtonData: null,
                bannerHeight: '260px'
            },
        });
    }, [])


    //    async function getReportsCard () {

    //    }




    async function getReportData(reset) {
        setLoading(true);
        let categoryCheck = false;
        if (subCategory.length === 0 || reset === "reset") {
            setCategory([]);
            setSubCategory([]);
            //   setSubCategoryName([]);
            setNameBadge([]);
            categoryCheck = true;
        }

        try {
            await axios.get(
                `${Base_URL}/api/hubs/favourite-reports?category=${categoryCheck ? "" : category.toString()}&sub_category=${categoryCheck ? "" : subCategory.toString()}&is_library=${isLibraryReports}`,
                config
            ).then((response) => {
                setInitialData(response?.data?.data);
                setSortedData(
                    response?.data?.data.filter((val) =>
                        val?.title.toLowerCase().includes(inputValue.toLowerCase())
                    )
                )
            }).then(() => {
                setLoading(false);
            })
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleRemoveOption = (option, index) => {
        // Create a new array without the selected option
        const updatedOptions = subCategoryName.filter((item) => item !== option);
        // Update the selectedOptions array with the new array
        setSubCategoryName(updatedOptions);
        setNameBadge(updatedOptions);
        const number = apiCallState + 1;
        setApiCallState(number);

        const updatedSubCategory = subCategory
            .map((item, i) => (i !== index ? item : null))
            .filter(item => item !== null);

        setSubCategory(updatedSubCategory);

    };

    async function getCounts() {
        try {
            const response = await axios.get(
                `${Base_URL}/api/hubs/total-report-counts?is_library=${isLibraryReports}`,
                config
            );

            setData(response?.data);
        } catch (error) {
            console.error(error);
        } 
    }

    useEffect(() => {
        getCounts()
        getReportData()
    }, [apiCallState, isLibraryReports])

    const handleTabChange = (newValue) => {
        setSelectedTab(newValue);
    };

    const handleLibraryReports = (value) => {
        setSelectedTab(1);
        setLoading(true);
        setData([]);
        setInputValue('');
        setInitialData([]);
        setSortedData([]);
        setIsLibraryReports(value);
    }

    return (
        <Box mt={'-140px'} style={{ zIndex: '1000', position: 'relative' }}>
            <Container maxWidth={"xl"}  >
                <Typography component='h1' sx={{ fontSize: '24px', fontWeight: '800', color: '#191919', lineHeight: '36px', mb: '24px', paddingLeft: '35px' }}>
                    Bookmarks
                </Typography>
                <Box display={'flex'} gap={'16px'} mb={10} sx={{ paddingLeft: '35px' }} >
                    <Button
                        variant={selectedTab === 0 ? 'contained' : 'text'}
                        onClick={() => handleTabChange(0)}
                        sx={buttonStyle} // Adjust the width as needed
                        style={{ backgroundColor: selectedTab === 0 ? '#446ba6' : 'transparent', color: selectedTab === 0 ? '#fff' : '#5a5a5a', textTransform: 'capitalize' }}
                    >
                        Hubs { countHubsBookmarks ? `(${countHubsBookmarks})` : ''}
                    </Button>
                    <Button
                        variant={selectedTab === 1 ? 'contained' : 'text'}
                        onClick={() => handleTabChange(1)}
                        sx={buttonStyle} // Adjust the width as needed
                        style={{ backgroundColor: selectedTab === 1 ? '#446ba6' : 'transparent', color: selectedTab === 1 ? '#fff' : '#5a5a5a', textTransform: 'capitalize' }}
                    >
                        Reports{ initialData?.length ? `(${initialData?.length})` : ''}
                    </Button>
                </Box>
            </Container>
            <Box>
                <Box component="div" role="tabpanel" hidden={selectedTab !== 0}>
                    <Hubs />
                    {/* <Box display={'flex'}>
                        <Box width={"50%"}>
                            <BookmarkRportsSidebar/>
                        </Box>
                        <Box width={"50%"}> reports section</Box>

                    </Box> */}

                </Box>
                <Box component="div" role="tabpanel" hidden={selectedTab !== 1}>
                    <Container maxWidth='xl'>
                        <Grid container>
                            <Grid item md={3}>
                                <BookmarkReportsSidebar category={category} subCategory={subCategory} nameBadge={nameBadge} setCategory={setCategory} setSubCategory={setSubCategory} setNameBadge={setNameBadge} getReportData={getReportData} setSubCategoryName={setSubCategoryName} subCategoryName={subCategoryName} />
                            </Grid>
                            <Grid item md={9}>
                                <Container>
                                    <Box
                                        component="div"
                                        id="favourite-reports-search"
                                    >
                                        <FormControl fullWidth >
                                            {selectedTab && <FormControlLabel control={<Switch />} label="Library Reports" onChange={(e) => handleLibraryReports(e.target.checked)} checked={isLibraryReports} />}
                                            <TextField
                                                sx={{ "& .MuiInputBase-input": { borderRadius: '8px' }, }}
                                                placeholder="Search Reports"
                                                variant="outlined"
                                                fullWidth
                                                onChange={(e) => setInputValue(e.target.value)}
                                                value={inputValue}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <RiSearchLine style={{ fontSize: '24px', color: '#002F75' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "12px", marginTop: "20px" }}>
                                        {subCategoryName?.map((option, index) => (
                                            <Box key={option} sx={{ borderRadius: "48px", display: "flex", justifyContent: "space-between", alignItems: "center", border: "0.5px solid #1C937B", background: "#D7F6EB", height: "32px" }}>
                                                <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "21px", padding: "8px 0px 8px 12px" }}>{option}</Typography>
                                                <button style={{ border: "none", background: "none", padding: "8px 12px", cursor: "pointer" }} onClick={() => handleRemoveOption(option, index)}><CloseOutlinedIcon sx={{
                                                    width: "16px", height: "16px", color: "#2D2D2D"
                                                }} /></button>
                                            </Box>
                                        ))}
                                    </Box>
                                    <BookmarksReportsContainer getReportData={getReportData} initialData={initialData} sortedData={sortedData} isLibraryReports={isLibraryReports} loading={loading} />
                                </Container>
                            </Grid>

                        </Grid>

                    </Container>
                </Box>
            </Box>
        </Box>
    )
}

export default Favorites