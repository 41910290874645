import styles from "../styles.module.css";
import { Box } from "@mui/material";
import Search from "./Search";
import Filters from "./Filters";
import Tags from "./Tags";

function SearchFilter() {
  return (
    <div
      className={styles.mainSearchFilterContainer}
      id="report-search-section"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Search />
        <Filters />
      </Box>
      <section className={styles.searchContainer}>
        <Tags />
      </section>
    </div>
  );
}

export default SearchFilter;
