import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  CompaniesContainer,
  Main,
  SecondaryHeader,
  CompaniesList,
  CompanyCard,
  CompanyCardGrid,
  CompanyLogo,
  CompanyLogoGrid,
  CompanyInfo,
  CompanyInfoGrid,
  ReportsInfo,
  ReportsInfoGrid,
  Pagination,
  TagContainer,
  Tag,
} from "../../CompaniesComponents/CompanyHome/CompanyHome.styles";
import API from "../../../api/Api";
import { useAuth } from "../../../context/authContext/Auth";
import activeGridImg from "../../../assets/active-grid.svg";
import gridImg from "../../../assets/grid.svg";
import activeListImg from "../../../assets/active-list.svg";
import listImg from "../../../assets/list.svg";
import sortImg from "../../../assets/sort.svg";
import searchImg from "../../../assets/search.svg";
import filtersImg from "../../../assets/filters.svg";
import Loader from "../../Common/Loader";
import SortModal from "../../CompaniesComponents/CompanyHome/SortModal";
import FilterModal from "../../CompaniesComponents/CompanyHome/FilterModal";
import {
  NumberConversionConfig,
  colorsArray,
} from "../../CompaniesComponents/CompanyHome/CompanyHome.config";
import _ from "lodash";

function HubsCompanies({ hubId, hubTitle, hubSlug, setCompaniesCount }) {
  const [loading, setLoading] = useState(false);
  const [openSortModal, setOpenSortModal] = useState(false);
  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [pageView, setPageView] = useState("list");
  const [reportsPerPage, setReportsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [company, setCompany] = useState(null);
  const [companiesData, setCompaniesData] = useState([]);
  const [filteredCompaniesData, setFilteredCompaniesData] = useState([]);
  const [currentCompaniesData, setCurrentCompaniesData] = useState([]);
  const [filtersData, setFiltersData] = useState({
    revenueFilterData: [],
    employeeFilterData: [],
    regionFilterData: [],
    countryFilterData: [],
  });

  const { config } = useAuth();
  const { Base_URL } = API;
  const paginationArr = [10, 25, 50, 75, 100];
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  let debounceTimer;

  useEffect(() => {
    getCompanies();
  }, []);

  let getCompanies = () => {
    setLoading(true);
    let url =
      hubId === null
        ? `${Base_URL}/api/company/showCompaniesV2`
        : `${Base_URL}/api/company/showCompaniesV2?hubId=${hubId}`;
    axios
      .get(url, config)
      .then((res) => {
        let data = res?.data?.data;
        data = data.filter((company) => {
          if (company.total_reports !== 0) {
            return true;
          } else {
            return false;
          }
        });
        setCompaniesData(data);
        setFilteredCompaniesData(data);
        setCurrentCompaniesData(data.slice(0, 10));
        setTotalPages(Math.ceil(data.length / reportsPerPage));
        setCompaniesCount(data.length);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let changeView = (type) => {
    setPageView(type);
  };

  let handleOpenFiltersModal = () => {
    setOpenFiltersModal(!openFiltersModal);
    setOpenSortModal(false);
  };

  let handleOpenSortModal = () => {
    setOpenSortModal(!openSortModal);
    setOpenFiltersModal(false);
  };

  let changeReportsPerPage = (event) => {
    setReportsPerPage(event.target.value);
    setTotalPages(Math.ceil(filteredCompaniesData.length / event.target.value));
    setCurrentPage(1);
    setCurrentCompaniesData(filteredCompaniesData.slice(0, event.target.value));
  };

  let changeCurrentPage = (type) => {
    let newPage;
    if (type === "previous") {
      if (currentPage <= 1) {
        newPage = 1;
        setCurrentPage(newPage);
      } else {
        newPage = currentPage - 1;
        setCurrentPage(newPage);
      }
    } else if (type === "next") {
      if (currentPage >= totalPages) {
        newPage = totalPages;
        setCurrentPage(newPage);
      } else {
        newPage = currentPage + 1;
        setCurrentPage(newPage);
      }
    }
    setCurrentPage(newPage);
    let newData = filteredCompaniesData.slice(
      reportsPerPage * (newPage - 1),
      reportsPerPage * newPage
    );
    setCurrentCompaniesData(newData);
  };

  let getSortOption = (option) => {
    handleSort(option);
  };

  let handleSort = (type) => {
    switch (type) {
      case "Default": {
        let data = [...companiesData];
        setFilteredCompaniesData(data);
        setCurrentCompaniesData(data.slice(0, reportsPerPage));
        setTotalPages(Math.ceil(data.length / reportsPerPage));
        setCurrentPage(1);
        break;
      }
      case "Name (A-Z)": {
        let data = [...companiesData].sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setFilteredCompaniesData(data);
        setCurrentCompaniesData(data.slice(0, reportsPerPage));
        setTotalPages(Math.ceil(data.length / reportsPerPage));
        setCurrentPage(1);
        break;
      }
      case "Name (Z-A)": {
        let data = [...companiesData].sort((a, b) => {
          return b.name.localeCompare(a.name);
        });
        setFilteredCompaniesData(data);
        setCurrentCompaniesData(data.slice(0, reportsPerPage));
        setTotalPages(Math.ceil(data.length / reportsPerPage));
        setCurrentPage(1);
        break;
      }
      case "Revenue": {
        let data = [...companiesData].sort((a, b) => {
          return a.annual_revenue - b.annual_revenue;
        });
        setFilteredCompaniesData(data);
        setCurrentCompaniesData(data.slice(0, reportsPerPage));
        setTotalPages(Math.ceil(data.length / reportsPerPage));
        setCurrentPage(1);
        break;
      }
      case "Employees": {
        let data = [...companiesData].sort((a, b) => {
          return a.employees - b.employees;
        });
        setFilteredCompaniesData(data);
        setCurrentCompaniesData(data.slice(0, reportsPerPage));
        setTotalPages(Math.ceil(data.length / reportsPerPage));
        setCurrentPage(1);
        break;
      }
      case "Headquarters": {
        let data = [...companiesData].sort((a, b) => {
          return a.headquarters.localeCompare(b.headquarters);
        });
        setFilteredCompaniesData(data);
        setCurrentCompaniesData(data.slice(0, reportsPerPage));
        setTotalPages(Math.ceil(data.length / reportsPerPage));
        setCurrentPage(1);
        break;
      }
      default: {
        let data = [...companiesData];
        setFilteredCompaniesData(data);
        setCurrentCompaniesData(data.slice(0, reportsPerPage));
        setTotalPages(Math.ceil(data.length / reportsPerPage));
        setCurrentPage(1);
      }
    }

    setFiltersData({
      revenueFilterData: [],
      employeeFilterData: [],
      regionFilterData: [],
      countryFilterData: [],
    });
  };

  let getCompaniesBasedOnFilters = (filters) => {
    let {
      revenueFilterData,
      employeeFilterData,
      regionFilterData,
      countryFilterData,
    } = filters;

    setFiltersData(filters);

    let data = [...companiesData];

    if (regionFilterData?.length != 0) {
      data = data.filter((company) => {
        let flag = false;
        if (
          regionFilterData?.length != 0 &&
          regionFilterData.includes(company?.dimensions?.region)
        ) {
          flag = true;
        }
        return flag;
      });
    }

    if (countryFilterData?.length != 0) {
      data = data.filter((company) => {
        let flag = false;
        if (
          countryFilterData?.length != 0 &&
          countryFilterData.includes(company?.dimensions?.country)
        ) {
          flag = true;
        }
        return flag;
      });
    }

    if (revenueFilterData?.length != 0) {
      data = data.filter((company) => {
        let flag = false;

        for (let option of revenueFilterData) {
          if (
            company.annual_revenue >= option[0] &&
            company.annual_revenue < option[1]
          ) {
            flag = true;
            break;
          } else {
            flag = false;
          }
        }

        return flag;
      });
    }

    if (employeeFilterData?.length != 0) {
      data = data.filter((company) => {
        let flag = false;

        for (let option of employeeFilterData) {
          if (
            company.employees >= option[0] &&
            company.employees <= option[1]
          ) {
            flag = true;
            break;
          } else {
            flag = false;
          }
        }
        return flag;
      });
    }
    setFilteredCompaniesData(data);
    setCurrentCompaniesData(data.slice(0, reportsPerPage));
    setTotalPages(Math.ceil(data.length / reportsPerPage));
    setCurrentPage(1);
  };

  let deleteTagFromFilters = (tag, type) => {
    let newFilters = filtersData[type].filter((data) => {
      return JSON.stringify(data) != JSON.stringify(tag);
    });
    let newFilterObj = {
      ...filtersData,
      [type]: newFilters,
    };

    setFiltersData(newFilterObj);
    getCompaniesBasedOnFilters(newFilterObj);
  };

  let viewCompanyReports = (company) => {
    let slug = company.name
      .toLowerCase()
      .split(" ")
      .join("-")
      .replaceAll(".", "")
      .replaceAll(",", "")
      .replaceAll(`'`, "")
      .replaceAll("&", "and");
    setCompany(company);
    let currentPath = location.pathname;

    let state = {
      hubSlug: hubSlug,
      hubId: hubId,
      hubTitle: hubTitle,
      company: company,
      category: null,
    };
    navigate(`${currentPath}/companies/${slug}`, { state });
  };

  let search = (event) => {
    let query = event.target.value.toLowerCase().trim();

    let data = [...companiesData]?.filter((company) => {
      if (String(company?.name)?.toLowerCase().includes(query)) {
        return true;
      } else if (String(company?.headquarters)?.toLowerCase().includes(query)) {
        return true;
      } else if (String(company?.country)?.toLowerCase().includes(query)) {
        return true;
      } else {
        return false;
      }
    });

    setTotalPages(Math.ceil(data.length / reportsPerPage));
    setFilteredCompaniesData(data);
    setCurrentPage(1);
    setCurrentCompaniesData(
      data.slice(0, data.length >= 10 ? 10 : data.length)
    );
  };

  let handleDebounceSearch = (event) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      search(event);
    }, 500);
  };

  let totalTags =
    filtersData.countryFilterData.length +
    filtersData.regionFilterData.length +
    filtersData.revenueFilterData.length +
    filtersData.employeeFilterData.length;

  return (
    <CompaniesContainer style={{ maxWidth: "1738px" }}>
      {!company ? (
        <Main style={{ flex: "0 1 100%", width: "100%" }}>
          {loading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div>
              <SecondaryHeader>
                <div className="search">
                  <img src={searchImg} alt="" />
                  <input
                    placeholder="Search companies"
                    onChange={handleDebounceSearch}
                  />
                </div>
                <div>
                  <button onClick={handleOpenFiltersModal}>
                    <img src={filtersImg} alt="Filters Button" />
                    <span>
                      Filter
                      {totalTags ? ` (${totalTags})` : null}
                    </span>
                  </button>
                  <button onClick={handleOpenSortModal}>
                    <img src={sortImg} alt="Sort Button" />
                    <span>Sort</span>
                  </button>

                  <div className="view-type">
                    <div
                      onClick={() => changeView("grid")}
                      style={{
                        backgroundColor:
                          pageView == "grid" ? "#F0F7FC" : "#FFF",
                      }}
                    >
                      <img
                        src={pageView == "grid" ? activeGridImg : gridImg}
                        alt=""
                      />
                    </div>

                    <div
                      onClick={() => changeView("list")}
                      style={{
                        backgroundColor:
                          pageView == "list" ? "#F0F7FC" : "#FFF",
                      }}
                    >
                      <img
                        src={pageView == "list" ? activeListImg : listImg}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                {openSortModal ? (
                  <SortModal
                    getSortOption={getSortOption}
                    setOpenSortModal={setOpenSortModal}
                    data={companiesData}
                  />
                ) : null}
                {openFiltersModal ? (
                  <FilterModal
                    setOpenFiltersModal={setOpenFiltersModal}
                    setFiltersData={setFiltersData}
                    filtersData={filtersData}
                    getCompaniesBasedOnFilters={getCompaniesBasedOnFilters}
                    data={companiesData}
                  />
                ) : null}
              </SecondaryHeader>

              <TagContainer>
                {filtersData?.regionFilterData?.length
                  ? filtersData.regionFilterData.map((tag) => {
                      return (
                        <Tag key={_.uniqueId()}>
                          <span>{tag}</span>
                          <span
                            className="delete"
                            onClick={() =>
                              deleteTagFromFilters(tag, "regionFilterData")
                            }
                          >
                            X
                          </span>
                        </Tag>
                      );
                    })
                  : null}
                {filtersData?.countryFilterData?.length
                  ? filtersData.countryFilterData.map((tag) => {
                      return (
                        <Tag>
                          <span>{tag}</span>
                          <span
                            className="delete"
                            onClick={() =>
                              deleteTagFromFilters(tag, "countryFilterData")
                            }
                          >
                            X
                          </span>
                        </Tag>
                      );
                    })
                  : null}
                {filtersData?.revenueFilterData?.length
                  ? filtersData.revenueFilterData.map((tag) => {
                      return (
                        <Tag>
                          <span>
                            {tag[0] != 50000
                              ? `$ ${
                                  NumberConversionConfig[tag[0]] || "100K"
                                } - $ ${
                                  NumberConversionConfig[tag[1]]
                                } Annual Revenue`
                              : `> $ 50Bn Annual Revenue`}
                          </span>
                          <span
                            className="delete"
                            onClick={() =>
                              deleteTagFromFilters(tag, "revenueFilterData")
                            }
                          >
                            X
                          </span>
                        </Tag>
                      );
                    })
                  : null}
                {filtersData?.employeeFilterData?.length
                  ? filtersData.employeeFilterData.map((tag) => {
                      return (
                        <Tag>
                          <span>
                            {tag[0] != 100001
                              ? `${tag[0]} - ${tag[1]} Employees`
                              : `> 100000 Employees`}
                          </span>
                          <span
                            className="delete"
                            onClick={() =>
                              deleteTagFromFilters(tag, "employeeFilterData")
                            }
                          >
                            X
                          </span>
                        </Tag>
                      );
                    })
                  : null}
              </TagContainer>

              <CompaniesList>
                {!currentCompaniesData.length ? (
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "rgba(90, 90, 90, 1)",
                      margin: "40px",
                    }}
                  >
                    {" "}
                    No Companies Present{" "}
                  </p>
                ) : (
                  currentCompaniesData.map((company, i) => {
                    return pageView === "list" ? (
                      <CompanyCard
                        onClick={() => {
                          viewCompanyReports(company);
                        }}
                      >
                        <div>
                          <CompanyLogo>
                            {company.logo ? (
                              <img src={company.logo} alt={company.name} />
                            ) : (
                              <div
                                style={{
                                  backgroundColor:
                                    colorsArray[Math.floor(Math.random() * 26)],
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                  width: "50px",
                                  height: "50px",
                                }}
                              >
                                {company.name[0]}
                              </div>
                            )}
                          </CompanyLogo>

                          <CompanyInfo>
                            <h3 className="font-medium">{company.name}</h3>
                            <p className="font-roman">
                              {`Est. ${company.founded}`}
                              <span></span>
                              {company.headquarters}
                              <span></span>
                              {`$ ${company.annual_revenue} ${company.units} (${company.year})`}
                            </p>
                          </CompanyInfo>
                        </div>
                        <ReportsInfo>
                          <div>
                            <h4 className="font-medium">Reports</h4>
                            <p className="font-roman">
                              {company.total_reports}
                            </p>
                          </div>
                          <div>
                            <h4 className="font-heavy">Purchased Reports</h4>
                            <p className="font-roman">
                              {company.purchased_report}
                            </p>
                          </div>
                        </ReportsInfo>
                      </CompanyCard>
                    ) : (
                      <CompanyCardGrid
                        onClick={() => {
                          viewCompanyReports(company);
                        }}
                      >
                        <div>
                          <CompanyLogoGrid>
                            {company.logo ? (
                              <img src={company.logo} alt={company.name} />
                            ) : (
                              <div
                                style={{
                                  backgroundColor:
                                    colorsArray[Math.floor(Math.random() * 26)],
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                  width: "50px",
                                  height: "50px",
                                }}
                              >
                                {company.name[0]}
                              </div>
                            )}
                          </CompanyLogoGrid>
                        </div>
                        <div>
                          <CompanyInfoGrid>
                            <h3 className="font-medium">{company.name}</h3>
                            <p className="font-roman">
                              {`Est. ${company.founded}`}
                              <span></span>
                              {company.headquarters}
                              <span></span>
                              {`$ ${company.annual_revenue} ${company.units} (${company.year})`}
                            </p>
                          </CompanyInfoGrid>
                          <ReportsInfoGrid>
                            <div>
                              <p className="font-roman">
                                {" "}
                                <span>Reports : </span>
                                {company.total_reports}
                              </p>
                            </div>
                            <span></span>
                            <div>
                              <p className="font-roman">
                                {" "}
                                <span>Purchased Reports: </span>{" "}
                                {company.purchased_report}
                              </p>
                            </div>
                          </ReportsInfoGrid>
                        </div>
                      </CompanyCardGrid>
                    );
                  })
                )}
              </CompaniesList>

              <Pagination>
                <p>View companies per page</p>
                <select value={reportsPerPage} onChange={changeReportsPerPage}>
                  {paginationArr.map((n) => {
                    return <option value={n}>{n}</option>;
                  })}
                </select>

                <p>{`${currentPage} of ${totalPages || 1}`}</p>
                <button onClick={() => changeCurrentPage("previous")}>
                  {"<"}
                </button>
                <button onClick={() => changeCurrentPage("next")}>{">"}</button>
              </Pagination>
            </div>
          )}
        </Main>
      ) : (
        <Main></Main>
      )}
    </CompaniesContainer>
  );
}

export default HubsCompanies;
