// import { useAuth } from "../Context/AppContext";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";

function PrivateRoute({ children }) {
  const cookies = new Cookies();
  const location = useLocation();
  const [searchParams,] = useSearchParams();

  const allowedPublicAccess = () => { 
    if(cookies.get("TOKEN")) return false;
    if(searchParams.get("tab") === "REPORTS_LIBRARY") return true;
    return location.pathname.split("/").includes("reports-library"); 
  }

  const openToPublic = allowedPublicAccess();

  // every 6 hours it will check if the cookie is still there, if not it will reload the page and redirect to login 
  let refreshCookie;
  if (cookies.get("TOKEN") || openToPublic) {
    if(!refreshCookie && !openToPublic) {
      refreshCookie = setInterval(() => {
        if(!cookies.get("TOKEN")) {
          clearInterval(refreshCookie);
          window.location.reload();
        }
      }, 1000 * 60 * 60 * 6); 
    }
    return children;
  }
  refreshCookie && clearInterval(refreshCookie);
  return <Navigate to="/" />;
}

export default PrivateRoute;
