import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useAuth } from "../../context/authContext/Auth";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { Filter } from "@mui/icons-material";
import AccordianFilter from "./AccordianFilter";
import API from "../../api/Api";

function VisualiseSideBar({
  graphType,
  onChartTypeChange,
  onApplyFilters,
  stackedOptions,
  selectedStackedOption,
  handleStackedOptionChange,
  visualiseLevel,
  ReportID,
  graphLoading,
  filterForGraph,
  setFilterForGraph,
  setChipsData,
  spiltKeys,
  setSplitKeys,
  newFilterNames,
  setNewFilterNames,
  filterData,
  setFilterData,
  setGraph,
}) {
  const cookies = new Cookies();
  const hubID = cookies.get("hubId");
  const [selectedMetric, setSelectedMetric] = useState("");
  const [data, setData] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [metricsData, setMetricsData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [rightColumn, setRightColumn] = useState([]);
  // const [filterForGraph, setFilterForGraph] = useState([]);
  const [draggedOption, setDraggedOption] = useState(null); // Track dragged option
  const [draggedCategory, setDraggedCategory] = useState(null);
  const [initialStackedOption, setInitialStackedOption] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const [mainFilterData, setMainFilterData] = useState([]);
  const [region, setRegion] = useState([]);
  const [allSelectedValues, setAllSelectedValues] = useState([]);
  const containerRef = useRef(null);
  const [parentChildConfig, setParentChildConfig] = useState([]);
  const [uniqueDataConfig, setUniqueDataConfig] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const { config } = useAuth();
  const { selectedReportDetail } = useSelector((state) => state);
  const ReportId = selectedReportDetail?.report_id;
  const [selectedYearOption, setSelectedYearOption] = useState("");
  const [yearOptions, setYearOptions] = useState([]);
  const [baseYear, setBaseYear] = useState("");
  const [initialYearsOption, setInitialYearsOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [splitByKeys, setSplitByKeys] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [toExpand, setToExpand] = useState(null);

  const [graphTypeChild, setGraphTypeChild] = useState("bar");

  const { Base_URL } = API;

  const categories = Object.keys(data);
  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  const handleStackedOption = (event) => {
    handleStackedOptionChange(event);
  };

  const handleStartYearChange = (value) => {
    setStartYear(value);
  };

  const handleEndYearChange = (value) => {
    setEndYear(value);
  };

  useEffect(() => {
    getFiltersData();
  }, []);

  // useEffect(() => {
  //   // if (graphTypeChild === "stackedColumn") {
  //     axios.get(
  //       `https://api-subscription.mordorlabs.com/api/hubs/visualize/hub/groupBy?hub_id=${parseInt(hubID, 10)}`,
  //       // `http://localhost:8080/api/hubs/visualize/hub/groupBy?hub_id=${parseInt(hubID, 10)}`,
  //       config
  //     )
  //       .then(response => {
  //         setInitialStackedOption(response.data.data)
  //       })
  //       .catch(error => {
  //         console.error('Error:', error.response.data.message);
  //       });
  //   // }
  // },[])

  const changeKeysToNames = (dimensionKey, nameModifier) => {
    const result = {};
    Object.keys(dimensionKey).forEach((key) => {
      const modifiedKey = nameModifier[key] || key;
      result[modifiedKey] = dimensionKey[key];
    });
    return result;
  };

  useEffect(() => {
    const formattingData = convertToDataOneFormat(filterForGraph);
    const modifiedGraphData = changeKeysToNames(formattingData, newFilterNames);
    setChipsData(modifiedGraphData);
  }, [filterForGraph]);

  const getFiltersData = () => {
    try {
      axios
        .post(
          `${Base_URL}/api/hubs/visualize/hub/filters/complete`,
          // `http://localhost:8080/api/hubs/visualize/hub/filters/complete`,
          {
            hub_id: parseInt(hubID, 10),
            ...(visualiseLevel === "report"
              ? { report_id: parseInt(ReportId, 10) || ReportID }
              : ""),
          },
          config
        )
        .then((response) => {
          // setFilterData(response?.data?.data?.filters);
          setMainFilterData(response?.data?.data?.filters);
          setParentChildConfig(response?.data?.data?.uniquedata.data);
          setUniqueDataConfig(response?.data?.data?.parentChildConfig);
          // const keysArray = Object.keys(response?.data?.data?.filters);
          // setSplitKeys(keysArray);
          const modifiedFilter = modifyData(
            response?.data?.data?.filters,
            response?.data?.data?.parentChildConfig
          );
          const withoutAllFilter = removeAllValues(modifiedFilter);
          const outputArray = keepLastObject(modifiedFilter);
          const finalKeys = extractLastChildrenNames(
            response?.data?.data?.parentChildConfig
          );
          setSplitKeys(finalKeys);
          setFilterData(outputArray);
          setData(outputArray);
          setDataCopy(outputArray);
          const yearsArray = Array.from(
            {
              length:
                response?.data?.data?.domainInfo.studyPeriod[1] -
                response?.data?.data?.domainInfo?.studyPeriod[0] +
                1,
            },
            (_, index) =>
              response?.data?.data?.domainInfo?.studyPeriod[0] + index
          );
          setInitialYearsOptions(yearsArray);
          setBaseYear(response?.data?.data?.domainInfo.baseYear);
          setNewFilterNames(response?.data?.data?.dimension_name_modifier);
          setMetricsData(response?.data?.data?.metrics);
          setSelectedMetric(response?.data?.data?.metrics?.[0]);
          setGraph(response?.data?.data?.initial_graph_data);
          // setBaseYear(response?.data?.data?.domainInfo.baseYear)
        })
        .catch((error) => {
          // setDisplayText(error.response.data.message);
          // console.error('Error:', error.response.data.message);
          // setGraphLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function extractLastChildrenNames(data) {
    const lastChildrenNames = [];

    function findLastChild(node) {
      if (node.children && Object.keys(node.children).length !== 0) {
        findLastChild(node.children);
      } else {
        lastChildrenNames.push(node.type);
      }
    }

    data.forEach((item) => {
      findLastChild(item);
    });

    return lastChildrenNames;
  }
  function keepLastObject(dataArray) {
    return dataArray.map((item) => {
      if (item.data && item.data.length > 0) {
        item.data = [item.data[item.data.length - 1]];
      }
      return item;
    });
  }

  function removeAllValues(data) {
    // Iterate over the array of objects
    return data.map((group) => ({
      // Copy the existing object properties
      ...group,
      // Modify the "data" property
      data: group.data.map((item) => ({
        // Copy the existing item properties
        ...item,
        // Modify the array values, but skip 'All' for 'Region'
        ...Object.fromEntries(
          Object.entries(item).map(([key, values]) => [
            key,
            key === "region"
              ? values
                  .map((value) => (value === "All" ? "Global" : value))
                  .filter(Boolean)
              : values
                  .map((value) => (value === "All" ? undefined : value))
                  .filter(Boolean),
          ])
        ),
      })),
    }));
  }

  function modifyData(filters, parentChildConfig) {
    let modified_data = [];
    function recursive(data, arr) {
      arr.push(data.type);
      if (data?.children?.type) {
        recursive(data.children, arr);
      }
    }
    for (let item of parentChildConfig) {
      let arr = [],
        obj = {
          heading: item.heading ? item.heading : "",
          data: [],
        };
      recursive(item, arr);
      for (let item of arr) {
        obj.data.push({
          [item]: filters.hasOwnProperty(item) ? filters[item] : [],
        });
      }
      modified_data.push(obj);
    }
    return modified_data;
  }

  function filterFilterFunction(
    parentChildConfig,
    selectedCategory,
    selectedValue
  ) {
    const result = [];
    function findCategory(category, values) {
      parentChildConfig.forEach((configArray) => {
        configArray.forEach((item) => {
          if (item.type === category && values.includes(item.value)) {
            if (item.children) {
              item.children.forEach((child) => {
                const childType = child.type;
                const childValue = child.value;
                result.push([childType, childValue]);
                findCategory(childType, childValue, child);
              });
            } else if (selectedCategory === item.category) {
            } else {
              result.push([category, values]);
            }
          } else if (item.children) {
            // Recursively search in children
            findCategoryInChildren(category, values, item.children);
          }
        });
      });
    }
    function findCategoryInChildren(category, values, children) {
      if (children.type === category && values.includes(children.value)) {
        if (children.children) {
          children.children.forEach((child) => {
            const childType = child.type;
            const childValue = child.value;
            result.push([childType, childValue]);
            findCategoryInChildren(childType, childValue, child);
          });
        } else {
          result.push([category, values]);
        }
      } else if (children.children) {
        findCategoryInChildren(category, values, children);
      } else {
        children.forEach((child) => {
          if (child.type === category && values.includes(child.value)) {
            if (child.children) {
              child.children.forEach((child) => {
                const childType = child.type;
                const childValue = child.value;
                result.push([childType, childValue]);
                findCategoryInChildren(childType, childValue, child);
              });
            } else {
              result.push([category, values]);
            }
          } else if (child.children) {
            // Recursively search in grandchildren
            findCategoryInChildren(category, values, child.children);
          }
        });
      }
    }

    if (Array.isArray(selectedValue)) {
      for (const value of selectedValue) {
        findCategory(selectedCategory, [value]);
      }
    } else {
      findCategory(selectedCategory, [selectedValue]);
    }
    return result;
  }

  const updateConfigWithUniqueData = (parentChildConfig, uniqueDataConfig) => {
    return uniqueDataConfig.map((uniqueDataItem, index) => {
      const matchingParentConfigItem = parentChildConfig[index];

      if (matchingParentConfigItem && uniqueDataItem.children) {
        // Map the children of the matching parent config item
        const updatedChildren = matchingParentConfigItem.children.map(
          (parentChildItem) => {
            const matchingUniqueChild = uniqueDataItem.children.find(
              (uniqueChildItem) => uniqueChildItem.type === parentChildItem.type
            );

            if (
              matchingUniqueChild &&
              (!parentChildItem.children ||
                parentChildItem.children.length === 0)
            ) {
              // If matchingUniqueChild has children but parentChildItem doesn't, add the children
              return {
                ...parentChildItem,
                children: matchingUniqueChild.children || [],
              };
            }

            return parentChildItem;
          }
        );

        // Add any remaining children from uniqueDataItem that are not in parentChildConfig
        const remainingUniqueChildren = uniqueDataItem.children.filter(
          (uniqueChildItem) =>
            !matchingParentConfigItem.children ||
            !matchingParentConfigItem.children.find(
              (parentChildItem) => parentChildItem.type === uniqueChildItem.type
            )
        );

        const updatedItem = {
          ...matchingParentConfigItem,
          children: updatedChildren.concat(remainingUniqueChildren),
        };

        return updatedItem;
      }

      return matchingParentConfigItem || uniqueDataItem; // Return the matching item or the original uniqueDataItem if no match
    });
  };

  function addMissingChildren(config, data) {
    config.forEach((configItem) => {
      const matchingDataItem = data.find(
        (item) => item.type === configItem.type
      );

      if (matchingDataItem) {
        // Check if children property exists in both config and data
        if ("children" in configItem && "children" in matchingDataItem) {
          addMissingChildren(configItem.children, matchingDataItem.children);
        }
      } else {
        // Add missing item to data
        const missingItem = {
          type: configItem.type,
          value: "",
          children: [],
        };

        // If the configItem has defined children, add placeholders for each child
        if (configItem.children && Array.isArray(configItem.children)) {
          configItem.children.forEach((child) => {
            missingItem.children.push({
              type: child.type,
              value: "",
              children: [],
            });
          });
        }

        data.push(missingItem);
      }
    });

    return data;
  }

  const handleYearOption = (event) => {
    setSelectedYearOption(event);
    // You can perform additional actions on option change if needed
  };

  // useEffect to set initial year options on component mount
  useEffect(() => {
    setYearOptions(initialYearsOption);
  }, [initialYearsOption]);

  useEffect(() => {
    let DataCopyQuick = { ...data };
    Object.entries(filteredData).forEach(
      ([selectedCategory, selectedValues]) => {
        const result = filterFilterFunction(
          parentChildConfig,
          selectedCategory,
          selectedValues
        );
        const uniqueData = result.reduce((data, [key, value]) => {
          data[key] = data[key] || [];
          if (!data[key].includes(value)) {
            data[key].push(value);
          }
          return data;
        }, {});
        updateDataValues(uniqueData);
      }
    );
    setDataCopy({ ...DataCopyQuick });
  }, [filteredData]);

  function updateDataValues(updates) {
    setData((prevData) => {
      const newData = [...prevData];

      for (const updateKey in updates) {
        const updatedValues = updates[updateKey];

        newData.forEach((section) => {
          section.data.forEach((subsection) => {
            const keys = Object.keys(subsection);
            if (keys.includes(updateKey)) {
              subsection[updateKey] = updatedValues.map((value) => value);
            }
          });
        });
      }
      return newData;
    });
  }

  // const handleChartTypeChange = (event) => {
  //   setGraphType(event.target.value);
  // };

  const handleSelectChange = (value) => {
    // const newGraphType = event.target.value;
    // if (graphTypeChild === "stackedColumn" && newGraphType !== "stackedColumn" || graphTypeChild !== "stackedColumn" && newGraphType === "stackedColumn") {
    //   setGraphTypeChild(newGraphType)
    //   onChartTypeChange(newGraphType);
    //   applyFilters()
    // }
    // else {
    setGraphTypeChild(value);
    // setStartYear("");
    // setEndYear("");
    if (value === "stackedColumn" || value === "pie") {
      handleStackedOptionChange(spiltKeys[0]);
      if (splitByKeys.length > 0) {
        setSelectedKeys(splitByKeys);
      }
    } else {
      setSelectedKeys([]);
    }
    // onChartTypeChange(newGraphType); // Send the updated value to the parent component
    // }
  };

  const handleSelectDragStart = (e, item, category) => {
    e.dataTransfer.setData("text/plain", item);
    setDraggedOption(item);
    setDraggedCategory(category);
  };

  // useEffect(() => {
  //   setTheFilters();
  // },[filteredData])

  const handleSelectDrop = (e) => {
    e.preventDefault();
    const item = e.dataTransfer.getData("text/plain");

    // Find the category key from which the item was dragged
    let sourceCategory = draggedCategory;
    // Update the filteredData state by adding the dropped item to the appropriate category
    setFilteredData((prevData) => {
      const updatedData = { ...prevData };

      if (!updatedData[sourceCategory]) {
        updatedData[sourceCategory] = [item];
      } else if (!updatedData[sourceCategory].includes(item)) {
        updatedData[sourceCategory].push(item);
      }
      return updatedData;
    });

    // Update the rightColumn state
    setRightColumn((prevRightColumn) => {
      if (!prevRightColumn.includes(item)) {
        return [...prevRightColumn, item];
      }
      return prevRightColumn;
    });

    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const handleRemoveFromSelectedColumn = (itemToRemove) => {
    const updatedRightColumn = rightColumn.filter(
      (item) => item !== itemToRemove
    );
    setRightColumn(updatedRightColumn);

    const updatedData = { ...filteredData };

    for (const sourceCategory of Object.keys(updatedData)) {
      if (updatedData[sourceCategory].includes(itemToRemove)) {
        updatedData[sourceCategory] = updatedData[sourceCategory].filter(
          (item) => item !== itemToRemove
        );
        if (updatedData[sourceCategory].length === 0) {
          delete updatedData[sourceCategory];
        }
      }
    }
    setFilteredData(updatedData);
  };

  useEffect(() => {
    if (splitByKeys.length > 0) {
      setSelectedKeys(splitByKeys);
    }
  }, [splitByKeys]);

  useEffect(() => {
    setSplitByKeys([]);
    setSelectedKeys([]);
    const formattingData = convertToDataOneFormat(selectedValues);
    const keysWithArrayLengthMoreThanOne = [];

    for (const key in formattingData) {
      if (
        Array.isArray(formattingData[key]) &&
        formattingData[key].length > 1
      ) {
        keysWithArrayLengthMoreThanOne.push(key);
      }
    }

    if (selectedStackedOption) {
      let finalValue = keysWithArrayLengthMoreThanOne.filter(
        (key) => key !== selectedStackedOption
      );

      // Add "name" and "value" keys to each element in the array
      const splitByKeysWithNamesAndValues = finalValue.map((value) => ({
        name: newFilterNames[value],
        value,
      }));
      setSplitByKeys(splitByKeysWithNamesAndValues);
    }
  }, [selectedStackedOption, selectedValues]);

  const handleSelectionChange = (event, value) => {
    setSelectedKeys(value);
  };

  const applyFilters = () => {
    // Clean the filteredData to remove keys with empty arrays
    const cleanedFilteredData = { ...filteredData };
    for (const key in cleanedFilteredData) {
      if (cleanedFilteredData[key].length === 0) {
        delete cleanedFilteredData[key];
      }
    }
    const formattingData = convertToDataOneFormat(filterForGraph);
    onApplyFilters(
      formattingData,
      selectedMetric,
      graphTypeChild,
      selectedKeys,
      startYear,
      endYear,
      splitByKeys
    );
  };

  const convertToDataOneFormat = (array) => {
    const result = {};

    array.forEach(({ value, group }) => {
      if (!result[group]) {
        result[group] = [value];
      } else {
        result[group].push(value);
      }
    });

    return result;
  };

  const getCategoryItems = (category) => data[category] || [];

  // useEffect(() => {
  //   if (Object.keys(filterData).length > 0) {
  //     // Remove "All" from all values
  //     const filterDataWithoutAll = {};
  //     for (const key in filterData) {
  //       filterDataWithoutAll[key] = filterData[key].filter(item => item !== 'All');
  //     }

  //     // Remove keys with no values
  //     const filteredFilterData = {};
  //     for (const key in filterDataWithoutAll) {
  //       if (filterDataWithoutAll[key].length > 0) {
  //         filteredFilterData[key] = filterDataWithoutAll[key];
  //       }
  //     }
  //     // setSelectedMetric('');
  //     setData(filteredFilterData);
  //     setDataCopy(filteredFilterData);

  //     // Set metrics and units data, defaults to empty arrays if keys are not present
  //     // setMetricsData(filteredFilterData['metrics'] || []);
  //     // setUnitsData(filteredFilterData['units'] || []);
  //   }
  // }, [filterData]);

  const handleApplyFilter = (filterType, selectedValues) => {
    // Add your logic to apply the filter here
  };

  // Function to handle filter clearing
  const handleClearFilter = (filterType) => {
    // Add your logic to clear the filter here
  };

  let number = 1;

  return (
    <>
      {filterData.length === 0 ? (
        ""
      ) : (
        <Box sx={{ height: "100vh" }}>
          <Box sx={{ width: "100%", pr: 1 }}>
            {/* Filter 1 */}
            {/* {graphType === 'stackedColumn' ? (
                "" 
              ) : ( */}
            <Box sx={{ width: "100%" }}>
              <h3 style={{ color: "#1976d2", marginBottom: "5px" }}>
                Chart Type
              </h3>
              <Select
                fullWidth
                // sx={{ width: "200px" }}
                value={graphTypeChild}
                onChange={(event) => handleSelectChange(event.target.value)}
              >
                <MenuItem value="bar">Bar</MenuItem>
                <MenuItem value="line">Line</MenuItem>
                <MenuItem value="pie">Pie</MenuItem>
                <MenuItem value="stackedColumn">Stacked Bar</MenuItem>
              </Select>
            </Box>
            {/* )} */}
            {/* Filter 2 */}
            <Box
              sx={{
                flex: 1,
                marginRight: "10px",
                width: "100%",
                marginTop: "5px",
              }}
            >
              <h3 style={{ color: "#1976d2", marginBottom: "5px" }}>Metrics</h3>
              {/* <FormControl>
                  <InputLabel id="metric-select-label">Metrics</InputLabel> */}
              <Select
                fullWidth
                value={selectedMetric}
                onChange={(event) => handleMetricChange(event)}
                // sx={{ textAlign: 'left', width: "200px" }}
              >
                {metricsData?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item.charAt(0).toUpperCase() + item.slice(1)}
                  </MenuItem>
                ))}
              </Select>
              {/* </FormControl> */}
            </Box>
          </Box>

          <Box sx={{ display: "flex", marginTop: "10px" }}>
            <Box sx={{ flex: 1, marginRight: "5px" }}>
              {graphTypeChild === "stackedColumn" ||
              graphTypeChild === "pie" ? (
                <Box>
                  <h3
                    style={{
                      // color: (stackedOptions?.length === 0 ? "grey" : '#1976d2'),
                      color: "#1976d2",
                      marginBottom: "5px",
                    }}
                  >
                    Stack Graph By
                  </h3>
                  <FormControl fullWidth>
                    {/* <InputLabel id="options-select-label">By Type</InputLabel> */}
                    <Select
                      // labelId="option-select-label"
                      id="option-select"
                      // value={stackedOptions?.length!==0? selectedStackedOption : ""}
                      value={selectedStackedOption}
                      // label="Options"
                      onChange={(event) =>
                        handleStackedOption(event.target.value)
                      }
                      // sx={{ textAlign: 'left', width: '200px' }}
                      // disabled={stackedOptions?.length===0}
                    >
                      {spiltKeys?.length > 0
                        ? spiltKeys?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {index === 0
                                ? `${newFilterNames[item]} (default)`
                                : newFilterNames[item]}
                            </MenuItem>
                          ))
                        : initialStackedOption?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {newFilterNames[item]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                ""
              )}
              <Box>
                {/* left Column */}
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  {/* <Box sx={{width:"200px"}}> */}
                  <Box>
                    <h3 style={{ color: "#1976d2", marginBottom: "5px" }}>
                      Filters
                    </h3>
                    <Box
                      sx={{
                        maxHeight: "300px", // Set your desired height
                        overflowX: "auto", // Enable horizontal scrolling
                      }}
                    >
                      {filterData.map((filter, index) => (
                        <AccordianFilter
                          key={index}
                          heading={`Selection ${index + 1}`}
                          data={filter?.data}
                          allSelectedValues={allSelectedValues}
                          setAllSelectedValues={setAllSelectedValues}
                          superIndex={index}
                          setFilteredData={setFilteredData}
                          setFilterForGraph={setFilterForGraph}
                          selectedValues={selectedValues}
                          setSelectedValues={setSelectedValues}
                          toExpand={toExpand}
                          setToExpand={setToExpand}
                          newFilterNames={newFilterNames}
                        />
                      ))}
                    </Box>
                  </Box>
                  {/* <h3 style={{ color: '#1976d2', marginBottom: '5px' }}>Selected Options</h3>
                <Box
                  ref={containerRef}
                sx={{
                                      border: "1px solid #c7c7c7",
                  height: "300px",
                  width: "200px",
                  overflow: "auto",
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleSelectDrop(e)}
                > */}
                  {/* <h4>Selected Options</h4> */}
                  {/* {filterForGraph.length < 1 ?
                      <Box sx={{ width: "100%", height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <p>Filters shown here</p>
                      </Box>
                      :
                  
                      (
                        filterForGraph.map((option, index) => (
                          <Box
                            key={index}
                            // onDragStart={(e) => handleSelectDragStart(e, option)}
                            // draggable
                            sx={{
                              border: '1px solid #c7c7c7',
                              borderRadius: '4px',
                              padding: '1px',
                              margin: '5px',
                              cursor: 'grab',
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            {option.value}
                          </Box>
                        ))
                      )
                    }
                    </Box> */}
                  {(graphTypeChild === "stackedColumn" ||
                    graphTypeChild === "pie") &&
                  splitByKeys.length !== 0 &&
                  stackedOptions !== "" &&
                  false ? (
                    // hiding the graph with false condition
                    <Box>
                      <h3
                        style={{
                          // color: (stackedOptions?.length === 0 ? "grey" : '#1976d2'),
                          color: "#1976d2",
                          marginBottom: "5px",
                        }}
                      >
                        Split The Graph By
                      </h3>
                      <Autocomplete
                        multiple
                        id="keys-autocomplete"
                        // sx={{ textAlign: 'left', width: '200px' }}
                        options={splitByKeys}
                        value={selectedKeys}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                          option.value === value.value
                        }
                        onChange={handleSelectionChange}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Keys"
                            variant="outlined"
                          />
                        )}
                        style={{ maxHeight: 70, overflowY: "auto" }}
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                  {/* {graphTypeChild !== "stackedColumn" ? */}
                  <Box>
                    <Box>
                      <h3 style={{ color: "#1976d2", marginBottom: "5px" }}>
                        Start Year
                      </h3>
                      <FormControl fullWidth>
                        <Select
                          fullWidth
                          id="start-year-select"
                          value={startYear}
                          onChange={(event) =>
                            handleStartYearChange(event.target.value)
                          }
                          sx={{ textAlign: "left", marginRight: "10px" }}
                          // sx={{ textAlign: 'left', width: '200px', marginRight: '10px' }}
                        >
                          {yearOptions.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <h3 style={{ color: "#1976d2", marginBottom: "5px" }}>
                        End Year
                      </h3>
                      <FormControl fullWidth>
                        <Select
                          fullWidth
                          id="end-year-select"
                          value={endYear}
                          onChange={(event) =>
                            handleEndYearChange(event.target.value)
                          }
                          sx={{ textAlign: "left" }}
                          // sx={{ textAlign: 'left', width: '200px' }}
                          disabled={!startYear}
                        >
                          {yearOptions
                            .filter((year) => year >= startYear)
                            .map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  {/* : ""
                      } */}
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "space-around",
                      gap: 1,
                    }}
                  >
                    <Button
                      fullWidth
                      disabled={graphLoading}
                      variant="contained"
                      component="label"
                      marginTop="10px"
                      onClick={applyFilters}
                    >
                      Apply
                    </Button>
                    <Button
                      fullWidth
                      variant="outlined"
                      component="label"
                      marginTop="10px"
                      onClick={() => {
                        setSelectedMetric(metricsData?.[0]);
                        handleSelectChange("bar");
                        setFilteredData({});
                        setRightColumn([]);
                        onApplyFilters("reset");
                        setAllSelectedValues([]);
                        setFilterForGraph([]);
                        handleStackedOption("");
                        handleYearOption("");
                        setSelectedValues([]);
                        setStartYear("");
                        setEndYear("");
                      }}
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default VisualiseSideBar;
// NewDNDSideBarCopyCopy
