import styled from "styled-components";

export const TemplateContainer = styled.div`
    box-sizing: border-box;
    width:1080px;
    height: 610px;
    padding: 4px;
    padding-bottom: 40px;
    position:relative;
    margin: 0 auto;
`;

export const TemplateView = styled.div`
    box-sizing: border-box;
    width:100%;
    height: 100%;
    display: flex;
    padding: 4px;
    justify-content: ${({ justifyContent }) => justifyContent};
    align-items: ${({ alignItems }) => alignItems};
    flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const Row = styled.div`
    box-sizing: border-box;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    margin:4px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const Column = styled.div`
    box-sizing: border-box;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    margin:4px;
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection};
`;


export const SwitchContainer = styled.div`
     width: 100%;
     text-align: right;
     padding-bottom: 20px;
`;

export const Heading = styled.h1`
    width:100%;
    margin-bottom: 20px;
`;

export const SubHeading = styled.h2`
    width:100%;
    margin-bottom: 10px;
`;