import { useSelector } from 'react-redux';

// CHECK IF THE DATA IS ALLOWED TO SHOW ON THE Hub Tab
const ShowComponentCheck = (tab) => {
    const hubLevelModule = useSelector(state => state.hubLevelModule);
    if(!hubLevelModule.modules) {
        return false;
    }
    if (hubLevelModule.modules.some(str => str.toLowerCase() === tab.toLowerCase())) {
        return true;
    } else {
        return false;
    }
}

export default ShowComponentCheck;
