import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import { COLORS } from "../graphs.configs";
import { breakLabel, maximumStack } from "../graphs.helperFunctions";
import mordor_icon from "../../utils/img/Mi_logo.png";
import SynapseLogo from '../../../src/assets/Synapse_logo.svg'

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

let COLOR = COLORS.split(",").map((item) => {
  return item.trim();
});

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxValue: 0,
      total_legend_length: "",
      max_legend_length: 0,
      datas: "",
      button_click: false,
      loading: false,
      loadingData: false,
      data: [
        {
          seriesname: "Food Products",
          data: [
            {
              value: "121000",
            },
            {
              value: "135000",
            },
            {
              value: "123500",
            },
            {
              value: "145000",
            },
          ],
        },
        {
          seriesname: "Non-Food Products",
          data: [
            {
              value: "131400",
            },
            {
              value: "154800",
            },
            {
              value: "98300",
            },
            {
              value: "131800",
            },
          ],
        },
      ],
      catagory: [
        {
          label: "Q1",
          showValue: true,
        },
        {
          label: "Q2",
        },
        {
          label: "Q3",
        },
        {
          label: "Q4",
        },
      ],
    };
    this.bytesToString = this.bytesToString.bind(this);
  }

  componentDidMount() {
    if (this.props.data && this.props.data.data) {
      // let datas = {...this.props.data.data};
      let datas = JSON.parse(JSON.stringify(this.props.data.data));
      let maxValue = maximumStack(datas);
      let catagory = JSON.parse(JSON.stringify(this.props.data.catagory)),
        new_data = JSON.parse(JSON.stringify(this.props.data.data));
      let total_legend_length = "",
        max_legend_length = 0;
      let new_data2 = [...new_data].map((item) => {
        if (item.seriesname.length > max_legend_length)
          max_legend_length = item.seriesname.length;
        // item.seriesname = this.bytesToString(item.seriesname, 35)
        item.seriesname = breakLabel(item.seriesname, 25);
        if (this.props.data.data.length <= 3)
          total_legend_length += item.seriesname;
        return item;
      });

      this.setState({
        datas: datas,
        loadingData: false,
        data: new_data2,
        catagory: catagory,
        maxValue,
        total_legend_length,
        max_legend_length,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      JSON.stringify(prevProps.data.data) !==
        JSON.stringify(this.props.data.data)
    ) {
      let datas = JSON.parse(JSON.stringify(this.props.data.data));

      let maxValue = maximumStack(datas);

      let catagory = JSON.parse(JSON.stringify(this.props.data.catagory)),
        new_data = JSON.parse(JSON.stringify(this.props.data.data));
      let total_legend_length = "",
        max_legend_length = 0;

      let new_data2 = [...new_data].map((item) => {
        if (item.seriesname.length > max_legend_length)
          max_legend_length = item.seriesname.length;
        item.seriesname = item.seriesname.replace(/<br\/>/g, "");
        item.seriesname = breakLabel(item.seriesname, 25);

        if (this.props.data.data.length <= 3)
          total_legend_length += item.seriesname;
        return item;
      });

      this.setState({
        datas: datas,
        loadingData: false,
        data: new_data2,
        catagory: catagory,
        maxValue,
        total_legend_length,
        max_legend_length,
      });
    }
  }

  bytesToString(d, width) {
    let str = "";
    let computed_array = [];

    let string_arr = d.trim().split(" ");
    for (let item of string_arr) {
      if ((item + str).length >= width) {
        computed_array.push(str);
        str = "";
      }
      str += " " + item;
    }
    if (str !== "") computed_array.push(str);

    let caption = "";
    for (let i = 0; i < computed_array.length; i++) {
      if (i !== computed_array.length - 1)
        caption += computed_array[i] + "<br/>";
      else caption += computed_array[i];
    }
    return caption;
  }

  render() {
    let data = "",
      catagory = "",
      rotateValues = 1,
      legendsPositionDynamic = false,
      max_legend_length = 0,
      valueFontSize = 12,
      width = 1080 * 0.95,
      height = 610 * 0.925,
      id = `${Date.now()}`,
      header = "",
      legendLength = 14,
      seriesName = [],
      legendsData = [],
      others = [],
      newData = [];

    data = this.state.data;
    catagory = this.state.catagory;

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.header) header = this.props.header;
    if (this.props.valueFontSize) valueFontSize = this.props.valueFontSize;
    if (this.props.rotateValues === 1) rotateValues = this.props.rotateValues;
    if (this.props.legendsPositionDynamic)
      legendsPositionDynamic = this.props.legendsPositionDynamic;
    if (this.props.id) id = this.props.id;

    if (data.length > 5) legendLength = 11;
    if (valueFontSize === 11) legendLength = data.length > 5 ? 11 : 14;
    if (legendsPositionDynamic) legendLength = 11;
    if (this.state.max_legend_length < 35)
      max_legend_length = 35 - this.state.max_legend_length;

    data.map((item) => {
      seriesName.push({ seriesname: item.seriesname });
    });

    seriesName = seriesName
      .sort((a, b) => {
        return a.seriesname.localeCompare(b.seriesname);
      })
      .map((series, index) => {
        return { ...series, color: COLOR[index] };
      });
    let x1 = new RegExp("^other.*"),
      x2 = new RegExp("^rest.*");
    seriesName = seriesName.filter((series) => {
      if (
        x1.test(series.seriesname.toLowerCase()) ||
        x2.test(series.seriesname.toLowerCase())
      ) {
        others.push(series);
        return false;
      } else {
        return true;
      }
    });
    if (others.length !== 0)
      others.map((series_item) => seriesName.push(series_item));

    data.map((item) => {
      seriesName.map((series) => {
        if (item.seriesname === series.seriesname) {
          newData.push({ ...item, color: series.color });
        }
      });
    });

    data = [...newData];
    const customSource = this.props.customObj?.pie?.source;
    const customMordorIcon = this.props.customObj?.pie?.mordor_icon;

    const computeYAxis = (preCal, customIncrease = 21, limit = 8) => {
      if (data?.length > limit) {
        let yAxis = 0;
        let totalRow = data?.length / 5;
        let totalRowRounded = Math.ceil(totalRow);
        let final = (totalRowRounded * customIncrease).toString();
        return preCal + "+" + final.toString();
      } else {
        return preCal;
      }
    };

    let chartConfigs = {
      type: "stackedcolumn2d",
      dataFormat: "json",
      width: "100%",
      height: height,
      id: id,
      dataSource: {
        chart: {
          logoURL: SynapseLogo,
          logoPosition: "BR",
          theme: "fusion",
          animation: false,
          showAnchors: 1,
          exportEnabled: 1,
          exportShowMenuItem: this.props.id ? 0 : 1,
          exportFileName: `${header}`,
          exportFormats: "svg||png||jpg||pdf",
          exportAction: "download",

          formatNumberScale: 1,
          numberScaleValue: "1000,1000,1000,1000",
          numberScaleUnit: "k,M,B,T",

          adjustDiv: "0",
          numDivLines: "4",
          divLineColor: "#E5E5E5",
          divLineAlpha: "80",
          divLineDashed: "0",

          decimals: 1,
          paletteColors: COLORS,
          showPlotBorder: "0",
          minPlotHeightForValue: 10,
          plotSpacePercent: 40,

          showsum: 1,
          sumPadding: "15",

          showValues: 0,
          valueFont: "Avenir Heavy",
          valueFontColor: "#002F75",
          valueFontSize: valueFontSize,
          valueFontBold: true,
          placeValuesInside: "0",

          labelFontColor: "#7f7f7f",
          labelFontSize: this.props.templateName === "1111" ? 10 : 14,

          legendItemFont: "Avenir Medium",
          legendItemFontSize: legendLength,
          legendItemFontColor: "#7f7f7f",
          // legendNumRows: 2,
          legendNumColumns: 5,

          legend: {
            enabled: "1",
            position: "bottom",
            alignment: "start",
          },

          showYAxisLine: false,
          yAxisNamePadding: 0,
          yAxisLineThickness: 1,
          showYAxisValues: false,
          yAxisValueFontSize: 14,
          yAxisValueDecimals: 1,
          yAxisValueFontColor: "#7f7f7f",
          yAxisLineColor: "#4A90E2",
          yAxisMaxValue: this.state.maxValue,

          showXAxisLine: false,
          xAxisLineThickness: 1,
          xAxisLineColor: "#4A90E2",
          xAxisNameFont: "Avenir Medium",
          xAxisNameFontColor: "#444444",
          xAxisValueFontSize: width <= 566 ? 10 : 14,
          centerXaxisName: false,

          chartTopMargin: 60 + this.props.chartHeadingGap,
          chartLeftMargin: 4,

          baseFont: "Avenir Medium",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  // bgColor: "#E0EFFA",
                  width: `100%`,
                  fontsize: this.props?.headingColor ? 15 : 16,
                  font: "Avenir Medium",
                  text: (header + "").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: this.props?.wrapWidth,
                  x: `${this.props.xHeadingMargin + 20}`,
                  y: `${this.props.yHeadingMargin + 7}`,
                },

                {
                  id: "dyn-label-bg",
                  type: "rectangle",

                  fillcolor: this.props.headingColor,
                  x: "$canvasStartY-80",
                  y: "$canvasStartY-170",
                  tox: "$canvasEndX+300",
                  toy: "$canvasStartY-15",
                },
                {
                  id: "source",
                  type: "text",
                  align: "left",
                  fillcolor: "#7f7f7f",
                  fontsize: 12,
                  font: "Avenir Medium",
                  text: "Source: Mordor Intelligence",
                  bold: "0",
                  wrap: "1",
                  x: "$canvasStartX",
                  y: "$legendEndY+20",
                },
              ],
            },
          ],
        },
        categories: [
          {
            category: catagory,
          },
        ],
        dataset: data,
      },
    };

    if (this.props.subheading) {
      chartConfigs.dataSource.annotations.groups.push({
        id: "sub_caption",
        items: [
          {
            id: "dyn-sub-caption",
            type: "text",
            align: "left",
            fillcolor: "#002F75",
            width: `${width}`,
            fontsize: this.props?.headingColor ? 13 : 14,
            font: "Avenir Medium",
            text: this.props.subheading.toUpperCase(),
            bold: "0",
            wrap: "1",
            wrapWidth: this.props.wrapWidth,
            x: `${20}`,
            y: `${50}`,
          },
          {
            id: "dyn-label-bg",
            type: "rectangle",
            fillcolor: this.props.headingColor,
            // x: "$canvasStartY-80",
            // y: "$canvasStartY-170",
            // tox: "$canvasEndX+300",
            // toy: "$canvasStartY-36",
          },
        ],
      });
    }

    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}
