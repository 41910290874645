import { Grid } from "@mui/material";
import { useRecentDevelopment } from "../STATE";
import SelectionDropDown from "./SelectionDropDown";
import { SYNAPSE_API } from "../../../../api/Api";
import { toast } from "react-toastify";

function CompanySelection() {
  const { state, dispatch } = useRecentDevelopment();

  const getCompanyRecentDevelopments = async (companies) => {
    dispatch({ type: "SET_LOADING", payload: "PENDING" });
    await SYNAPSE_API.post("/api/company/recentDevelopmentV3", {
      company_ids: companies.map((company) => company.value),
    })
    .then((response) => {
      dispatch({ type: "SET_DATA", payload: response.data.recent_development });
      dispatch({ type: "SET_DEVELOPMENT_TYPES", payload: response.data.development_types });
      dispatch({ type: "SET_LOADING", payload: "IDLE" });
    })
    .catch((error) => {
      dispatch({ type: "SET_LOADING", payload: "IDLE" });
      toast.error("Failed to fetch recent developments");
    })
  };

  const handleApplyForCompanySelection = (selectedCompany) => {
    dispatch({ type: "SET_DATA", payload: [] });
    dispatch({ type: "SET_SELECTED_COMPANIES", payload: selectedCompany });
    getCompanyRecentDevelopments(selectedCompany);
  };

  const handleClearAllForCompanySelection = () => {
    dispatch({ type: "SET_DATA", payload: [] });
    dispatch({ type: "SET_SELECTED_DEVELOPMENT_TYPE", payload: [] });
    dispatch({ type: "SET_DEVELOPMENT_TYPES", payload: [] });
  }

  const handleTagDeleteForCompanySelection = (filterCompanies) => {
    const filterTheseCompanies = filterCompanies.map((company) => company.value);
    const filteredCompanies = state.data.filter((company) => filterTheseCompanies.includes(company.company_id));
    dispatch({ type: "SET_DATA", payload: filteredCompanies });
  }

  const handleApplyForDevelopmentTypeSelection = (selectedDevelopmentType) => {
    dispatch({ type: "SET_SELECTED_DEVELOPMENT_TYPE", payload: selectedDevelopmentType.map(type => type.value) });
  };

  const handleClearAllForDevelopmentTypeSelection = () => {
    dispatch({ type: "SET_SELECTED_DEVELOPMENT_TYPE", payload: [] });
    dispatch({ type: "SET_DATA_TO_DISPLAY", payload: state.data });
  };

  return (
    <Grid container>
      <Grid item xs={5.5}>
        <SelectionDropDown
          selectionFor={"Company"}
          data={state.companies.map(getLabelForCompany)}
          handleApplyAction={handleApplyForCompanySelection}
          handleClearAllAction={handleClearAllForCompanySelection}
          handleTagDelete={handleTagDeleteForCompanySelection}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5.5}>
        <SelectionDropDown
          selectionFor={"Development Type"}
          data={state.developmentTypes.map(getLabelForDevelopmentType)}
          handleApplyAction={handleApplyForDevelopmentTypeSelection}
          handleClearAllAction={handleClearAllForDevelopmentTypeSelection}
          handleTagDelete={handleApplyForDevelopmentTypeSelection}
        />
      </Grid>
    </Grid>
  );
}

export default CompanySelection;

function getLabelForCompany(company) {
  return {
    label: company.name,
    value: company.company_id,
    ...company,
  };
}

function getLabelForDevelopmentType(developmentType) {
  return {
    label: developmentType,
    value: developmentType,
  };
}
