import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useAuth } from "../../context/authContext/Auth";
import API from "../../api/Api";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

function RenewDrawer({
  report_id,
  title,
  drawerOpen,
  setDrawerOpen,
  typeModal,
  style,
}) {
  const [renewalMessage, setRenewalMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { config, setNotify, userInfo } = useAuth();

  const { Base_URL } = API;

  const toggleDrawer = () => {
    setDrawerOpen(false);
    setRenewalMessage("");
  };

  const handleCtaType = () => {
    if (typeModal === "renewal") {
      return "Renew-cta";
    }
    if (typeModal === "buy") {
      return "Buy-Now-cta";
    }
    if (typeModal === "request") {
      return "Request-Access-cta";
    }
    if (typeModal === "request sample") {
      return "Request-Sample-cta";
    }
  };

  const getTypeModalLabel = (typeModal) => {
    switch (typeModal) {
      case "renewal":
        return "Request Renewal";
      case "contact":
      case "buy":
        return "Submit";
      case "request sample":
        return "Request Sample";
      case "request":
        return "Request Access";
      default:
        return "";
    }
  };

  const submitRenewalRequest = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (
      typeModal === "renewal" ||
      typeModal === "request" ||
      typeModal === "buy" ||
      typeModal === "request sample"
    ) {
      const bodyParameters = {
        name: userInfo?.username,
        email: userInfo?.email,
        phone: userInfo?.mobile?.phone_number,
        report_id: report_id,
        title: title,
        company_name: userInfo?.company_name,
        message: renewalMessage,
        cta_type: handleCtaType(),
        url: window.location.href,
      };

      try {
        const response = await axios.post(
          `${Base_URL}/api/cta/addCta`,
          bodyParameters,
          config
        );

        // if (response?.data.code === 201) {
        setNotify({
          isOpen: true,
          message: response.data?.message,
          type: "success",
          position: "right",
        });
        // }
      } catch (error) {
        console.error(error);
        setNotify({
          isOpen: true,
          message: error.response.data?.message || "Some Error occurred",
          type: "error",
          position: "right",
        });
      } finally {
        toggleDrawer();
        setLoading(false);
      }
    }

    if (typeModal === "contact") {
      const bodyParameters = {
        message: renewalMessage,
      };

      axios
        .post(`${Base_URL}/api/hubs/contactUs`, bodyParameters, config)
        .then((res) => {
          // setData(res.data.data);
          setNotify({
            isOpen: true,
            message: res.data?.data,
            position: "right",
          });
        })
        .catch((err) => {})
        .finally(() => {
          toggleDrawer();
          setLoading(false);
        });
    }
    toggleDrawer();
    setLoading(false);
  };
  return (
    <>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <div style={{ width: "451px", height: "88%", position: "relative" }}>
          <Stack
            p={"24px"}
            sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)" }}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "800",
                lineHeight: "25.6px",
                color: "#191919",
              }}
            >
              {/* { typeModal === 'contact' &&  "Contact Us"} */}
              {title}
            </Typography>
            <IconButton sx={{ color: "#002F75" }} onClick={toggleDrawer}>
              <CloseIcon color="#002F75" />
            </IconButton>
          </Stack>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              height: "100%",
            }}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Box>
                <Typography
                  sx={{ my: "10px", fontWeight: "600", fontSize: "14px" }}
                >
                  Name
                </Typography>
                <TextField
                  sx={{
                    background: "#E4E4E4",
                    color: "#878787",
                    textTransform: "capitalize",
                  }}
                  disabled
                  placeholder="Enter Name"
                  value={userInfo?.username}
                  // onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                />
                {/* <Typography
                  sx={{ my: "10px", fontWeight: "600", fontSize: "14px" }}
                >
                  Company Name
                </Typography>
                <TextField
                  sx={{ background: "#E4E4E4", color: "#878787" }}
                  disabled
                  placeholder="Enter Company Name"
                  name="Comapny name"
                  value={userInfo?.company_name}
                  fullWidth
                /> */}
              </Box>
              <Box>
                <Typography
                  sx={{
                    mt: "20px",
                    mb: "7px",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  disabled
                  sx={{ background: "#E4E4E4", color: "#878787" }}
                  variant="outlined"
                  placeholder="Enter Email"
                  value={userInfo?.email}
                  fullWidth
                  //   error={!isValid}
                />
                {/* <Typography
                  sx={{
                    mt: "20px",
                    mb: "7px",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Phone
                </Typography>
                <TextField
                  disabled
                  sx={{ background: "#E4E4E4", color: "#878787" }}
                  variant="outlined"
                  placeholder="Enter Phone Number"
                  value={userInfo?.mobile?.phone_number}
                  // onChange={handleEmailChange}
                  fullWidth
                /> */}
              </Box>
            </Box>
            <Typography
              sx={{
                mt: "20px",
                mb: "7px",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              Message <span style={{ color: "red" }}>*</span>{" "}
            </Typography>
            <TextField
              sx={{ mb: "40px" }}
              value={renewalMessage}
              onChange={(e) => setRenewalMessage(e.target.value)}
              multiline
              rows={4}
              variant="outlined"
            />

            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                position: "relative",
              }}
            >
              <hr
                style={{
                  width: "444px",
                  position: "absolute",
                  bottom: "70px",
                  left: "-14px",
                  border: "none",
                  boxShadow: "0px -2px 8px rgba(0, 0, 0, 1)",
                  height: "1px",
                  marginBottom: "-10px",
                  // backgroundColor: "#333"
                }}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ textTransform: "capitalize", mb: "10px" }}
                type="submit"
                disabled={loading || renewalMessage?.trim().length < 5}
                onClick={submitRenewalRequest}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  getTypeModalLabel(typeModal)
                )}
              </Button>
            </Box>
          </form>
        </div>
      </Drawer>
    </>
  );
}

export default RenewDrawer;
