import { SYNAPSE_API } from "../../api/Api";
import { Box } from "@mui/material";
import AllGraphsDashboard from "./AllGraphsDashboard";
import ExecSummary from "./ExecSummary";
import Contents from "./Toc";

const fetchData = (reportData) => {
  const body = {};
  if (reportData.upload_id) {
    body["uploadId"] = reportData.upload_id;
    body["reportId"] = reportData.synapse_report_id;
  }
  return [
    SYNAPSE_API.post(`/api/library-reports-details/toc`, body),
    SYNAPSE_API.post(`/api/library-reports-details/snippet`, body),
    SYNAPSE_API.post(
      `/api/library-reports-details/dashboard-get-all-graphs-by-tags`,
      body
    ),
    SYNAPSE_API.post(`/api/library-reports-details/graph-data`, body),
    SYNAPSE_API.post(`/api/library-reports-details/exec-summary`, body),
  ];
};

export const getAllRequiredDetailsForReport = async (
  reportData,
  setTriggerApiCall,
  dispatch,
  setCurrentTab
) => {
  try {
    const promises = fetchData(reportData);
    const [toc, snippet, dashboard, listOfGraph, execSummary] =
      await Promise.all(promises);

    const tabArray = [];

    if (toc.data.isDataAvailable)
      tabArray.push({
        name: "Contents",
        component: (
          <Contents
            data={toc.data.data.TOC}
            dispatch={dispatch}
            setCurrentTab={setCurrentTab}
          />
        ),
      });

    if (dashboard.data.isDataAvailable)
      tabArray.push({
        name: "Dashboard",
        component: (
          <AllGraphsDashboard
            data={dashboard.data.data}
            orderOfTags={dashboard.data.orderOfTags}
            isSubscribed={reportData.subscribed === "yes"}
            title={reportData.title}
            id={reportData.synapse_report_id}
            compName={"Dashboard"}
            setTriggerApiCall={setTriggerApiCall}
            dispatch={dispatch}
            setCurrentTab={setCurrentTab}
          />
        ),
      });

    if (execSummary.data.isDataAvailable)
      tabArray.push({
        name: "Executive Summary",
        component: (
          <ExecSummary
            data={execSummary.data.data.exec_summary}
            isSubscribed={reportData.subscribed === "yes"}
            title={reportData.title}
            id={reportData.synapse_report_id}
            setTriggerApiCall={setTriggerApiCall}
            snippetData={snippet.data.data.snippets}
            reportMeta={{
              title: reportData.title,
              publishedOn: reportData.published,
              forcastYear: reportData.forecast_year,
              websiteSlug: reportData.website_slug,
              countries: reportData.countries,
              regions: reportData.regions,
              reportExpiry: reportData.report_expiry,
              subscribedOn: reportData.subscribed_on,
            }}
          />
        ),
      });

    return tabArray;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const NoDataFoundForThis = () => {
  return (
    <Box
      sx={{
        minHeight: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box component="center" sx={{ fontSize: "1rem" }}>
        No Data Found
      </Box>
    </Box>
  );
};

export const doNotShowMyRaBtnForTheseLibraryReports = [
  23779, 23905, 23908, 23930, 24646, 24663, 24816, 24236, 23321, 24284, 24330,
  23404, 24430, 23526, 23529, 25634, 25637, 24529, 25996, 26238, 26272, 26302,
  26315, 26370, 26449, 26450, 26458, 26459, 26461, 26466, 26603, 26645, 26663,
  26754, 26815, 25629, 26823, 26882,
];
