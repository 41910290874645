import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@mui/icons-material";
import axios from "axios";
import { useAuth } from "../../context/authContext/Auth";
import Loader from "../Common/Loader";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import API from "../../api/Api";

function CompanyProfile(props) {
  const [loading, setLoading] = useState(false);
  const [pptLoading, setPptLoading] = useState(false);
  const [data, setData] = useState(null);

  const { config } = useAuth();
  const { Base_URL } = API;

  // new code
  const { state } = useLocation();
  const { hubId, companyId, reportId, name } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate("/hubs");
    }
  }, [navigate, state]);
  // new code

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const bodyParameters = {
          hub_id: hubId,
          company_id: companyId,
          report_id: reportId,
        };

        const res = await axios.post(
          `${Base_URL}/api/company/viewCompanyProfile`,
          bodyParameters,
          config
        );
        if (typeof res.data.data === "object") {
          setData(res.data?.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          console.log("network err");
        }
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  function handleDownload(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = true;
    link.click();
  }

  const generatePpt = async () => {
    const bodyParameters = {
      file_title: name
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .replace(/\s+/g, "")
        .trim(),
      pptData: data?.pptData,
    };

    try {
      setPptLoading(true); // start loading
      const response = await axios.post(
        `${Base_URL}/api/company/ppt-gen`,
        bodyParameters,
        config
      );
      const url = response.data?.data?.url;

      handleDownload(url);
    } catch (error) {
      console.error(error);
    }
    setPptLoading(false); // stop loading
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container sx={{ mt: "20px", mb: "200px" }}>
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <Button startIcon={<ArrowLeftOutlined />} onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={generatePpt}
          disabled={pptLoading}
          // props={{ loading: pptLoading }}
        >
          Export company profile
        </Button>
      </Stack>

      {/* Global Overview ⬇️⬇️⬇️⬇️⬇️⬇️⬇️ */}

      <Box>
        <Box
          sx={{
            color: "#1f4986",
            fontWeight: "600",
            fontSize: "26px",
            textTransform: "capitalize",
            fontFamily: "Avenir Heavy",
            my: "30px",
          }}
        >
          {name} - Global overview
        </Box>
        <Stack flexDirection={"row"} gap={2.5}>
          <Box
            sx={{
              flex: "1",
              // my: "6px",
            }}
          >
            <img
              height="130px"
              width="100%"
              src={data?.global_company.logo_url}
              alt="company-logo"
              style={{ objectFit: "contain" }}
            />
            <Box sx={{ p: "20px", background: "#e6e3fc" }}>
              <Typography
                sx={{
                  pb: "15px",
                  color: "#002f75",
                  fontSize: "16px",
                  fontFamily: "Avenir Medium",
                  textTransform: "uppercase",
                  mb: "20px",
                }}
                // className={classes.overview__heading}
              >
                {data?.global_company?.analysis?.heading}
              </Typography>
              {data?.global_company?.analysis?.analysis?.map((item, i) => (
                <Typography
                  key={i}
                  sx={{
                    fontSize: "14px",
                    lineHeight: "25px",
                    py: "10px",
                    color: "#002f75",
                    fontFamily: "Avenir Medium",
                    textTransform: "captalize",
                  }}
                  // className={classes.overview__subheading}
                >
                  {item.text}
                </Typography>
              ))}
            </Box>
          </Box>

          {/* 2nd */}

          <Stack flex={1} gap={1.5} flexDirection={"column"} color={"#1f4986"}>
            <Box
              sx={{
                background: [data?.global_company?.revenue?.bgColor],
                // color: "#1f4986",
                height: "130px",
                // px: "10px",
                // m: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              <Box
                sx={{
                  fontSize: "26px",
                  fontFamily: "Avenir Heavy",
                  fontStyle: "normal",
                }}
              >
                {Number(data?.global_company?.revenue?.value).toLocaleString(
                  "en-US"
                )}{" "}
                {data?.global_company?.revenue?.units[0]}
              </Box>
              <Box sx={{ fontSize: "14px" }}>
                Global Revenue in {data?.global_company?.revenue?.currency}
              </Box>
              <Box sx={{ fontSize: "14px" }}>
                FY {data?.global_company?.revenue?.year} All Segments
              </Box>
            </Box>
            <Stack
              flex={1}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                background: [data?.global_company?.employees?.bgColor],
              }}
            >
              <Typography
                sx={{
                  fontSize: "26px",
                  fontFamily: "Avenir Heavy",
                  fontStyle: "normal",
                }}
              >
                {data?.global_company?.employees.value.toLocaleString("en-US")}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {data?.global_company?.employees.text}
              </Typography>
            </Stack>
            <Stack
              flex={1}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                background: [data?.global_company?.founded?.bgColor],
              }}
            >
              <Typography
                sx={{
                  fontSize: "26px",
                  fontFamily: "Avenir Heavy",
                  fontStyle: "normal",
                }}
              >
                {data?.global_company?.founded.value}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {data?.global_company?.founded.text}
              </Typography>
            </Stack>
            <Stack
              flex={1}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                background: [data?.global_company?.headquarters?.bgColor],
              }}
            >
              <Typography
                sx={{
                  fontSize: "26px",
                  fontFamily: "Avenir Heavy",
                  fontStyle: "normal",
                }}
              >
                {data?.global_company?.headquarters.value}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {data?.global_company?.headquarters.text}
              </Typography>
            </Stack>
          </Stack>

          {/* 3rd */}

          <Box flex={1} sx={{ background: "#eef1f2" }}>
            <Box sx={{ p: "20px" }}>
              <Typography
                sx={{
                  pb: "15px",
                  color: "#002f75",
                  fontSize: "16px",
                  fontFamily: "Avenir Medium",
                  textTransform: "uppercase",
                  mb: "20px",
                }}
                // className={classes.overview__heading}
              >
                {data?.global_company?.core_business_segments?.heading}
              </Typography>
              {data?.global_company?.core_business_segments?.business_segments?.map(
                (item, i) => (
                  <Typography
                    key={i}
                    sx={{
                      fontSize: "14px",
                      lineHeight: "25px",
                      p: "5px",
                      color: "#002f75",
                      fontFamily: "Avenir Medium",
                      textTransform: "captalize",
                      fontWeight: "700",
                    }}
                    // className={classes.overview__subheading}
                  >
                    {item?.heading}
                  </Typography>
                )
              )}
            </Box>
          </Box>
        </Stack>
      </Box>

      <hr style={{ height: "1px", padding: "0 20px", margin: "50px 0px" }} />

      {/* Market Overview ⬇️⬇️⬇️⬇️⬇️⬇️⬇️ */}

      <Box>
        <Box
          sx={{
            // whiteSpace: "nowrap",
            // p: "16px",
            color: "#1f4986",
            // lineHeight: "18px",
            fontWeight: "600",
            fontSize: "26px",
            textTransform: "capitalize",
            fontFamily: "Avenir Heavy",
            my: "30px",
          }}
        >
          {name} - Market overview
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box
            sx={{
              background: "#e6e3fc",
              flex: "1",
              my: "6px",
            }}
          >
            <Box sx={{ p: "20px" }}>
              <Typography
                sx={{
                  pb: "15px",
                  color: "#002f75",
                  fontSize: "16px",
                  fontFamily: "Avenir Medium",
                  textTransform: "uppercase",
                  mb: "20px",
                }}
              >
                {data?.market_company?.core_products?.heading}
              </Typography>
              {data?.market_company?.core_products?.products?.map((item, i) => (
                <Typography
                  key={i}
                  sx={{
                    fontSize: "14px",
                    lineHeight: "25px",
                    py: "10px",
                    color: "#002f75",
                    fontFamily: "Avenir Medium",
                    textTransform: "captalize",
                  }}
                  // className={classes.overview__subheading}
                >
                  {item}
                </Typography>
              ))}
            </Box>
          </Box>

          {data?.market_company?.middle_container.length > 0 ? (
            <Stack flex={1} gap={"3px"} justifyContent={"space-between"}>
              {data?.market_company?.middle_container?.map((item, i) => {
                return (
                  <Box
                    // className={classes.gridItem}
                    key={i}
                    sx={{
                      background: [item.bgColor],
                      color: [i === 0 ? "#fff" : "#002f75"],
                      height: [i === 0 ? "130px" : "100px"],
                      p: "10px",
                      m: "6px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "10px",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: [i === 0 ? "16px" : "26px"],
                        fontFamily: "Avenir Heavy",
                        fontStyle: "normal",
                      }}
                    >
                      {data.isSubscribed
                        ? item.heading
                        : i === 0
                        ? item.heading
                        : "XX"}
                    </Box>
                    <Box>{item.text}</Box>
                  </Box>
                );
              })}
            </Stack>
          ) : (
            <Stack flex={1} gap={"3px"}>
              <Stack
                // className={classes.gridItem}

                sx={{
                  height: "80px",
                  background: "#539de4",
                  // m: "0px",
                  color: "#fff",
                  p: "20px",
                  m: "6px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "1",
                  fontWeight: "600",
                }}
              >
                <Typography
                  sx={{
                    pb: "10px",
                    fontSize: "16px",
                    fontFamily: "Avenir Medium",
                    textTransform: "uppercase",
                  }}
                >
                  {data?.market_company?.meta?.market_segment}
                </Typography>
                <Typography fontSize={"14px"}>Market Segment</Typography>
              </Stack>
              <Box
                // className={classes.gridItem}
                flex={1}
                sx={{
                  background: "#539de4",
                  color: "#fff",
                  p: "20px",
                  m: "6px",
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  // flexDirection: "column",
                  // gap: "10px",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                <Typography
                  sx={{
                    pb: "15px",
                    // color: "#002f75",
                    fontSize: "16px",
                    fontFamily: "Avenir Medium",
                    textTransform: "uppercase",
                    mb: "20px",
                  }}
                >
                  Companay Highlights
                </Typography>
                {data?.market_company?.meta?.company_highlights?.map(
                  (item, i) => (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "25px",
                        py: "10px",
                        // color: "#002f75",
                        fontFamily: "Avenir Medium",
                        textTransform: "captalize",
                      }}
                      key={i}
                    >
                      {" "}
                      {item}{" "}
                    </Typography>
                  )
                )}
              </Box>
            </Stack>
          )}
          <Stack flex={1} gap={2} sx={{ my: "6px" }}>
            <Stack
              // className={classes.gridItem}
              sx={{
                minHeight: "80px",
                background: "#3fd0d9",
                m: "0px",
                color: "#fff",
                p: "20px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                fontWeight: "600",
              }}
            >
              <Typography
                sx={{
                  pb: "10px",
                  fontSize: "16px",
                  fontFamily: "Avenir Medium",
                  textTransform: "uppercase",
                }}
              >
                {data?.market_company?.meta?.company_vertical}
              </Typography>
              <Typography fontSize={"14px"}>Company Vertical</Typography>
            </Stack>
            <Stack sx={{ background: "#e6e3fc", p: "20px", flex: "1" }}>
              <Typography
                sx={{
                  pb: "15px",
                  color: "#002f75",
                  fontSize: "16px",
                  fontFamily: "Avenir Medium",
                  textTransform: "uppercase",
                  mb: "20px",
                }}
                // className={classes.overview__heading}
              >
                {data?.market_company?.meta?.other_key_information?.heading}
              </Typography>
              {data?.market_company.meta?.other_key_information?.key_information?.map(
                (item, i) => (
                  <Typography
                    key={i}
                    sx={{
                      fontSize: "14px",
                      lineHeight: "25px",
                      py: "10px",
                      color: "#002f75",
                      fontFamily: "Avenir Medium",
                      textTransform: "captalize",
                    }}
                    // className={classes.overview__subheading}
                  >
                    {item}
                  </Typography>
                )
              )}
            </Stack>
          </Stack>
        </Box>
      </Box>

      {/* Products and Services ⬇️⬇️⬇️⬇️*/}

      <hr style={{ height: "1px", padding: "0 20px", margin: "50px 0px" }} />

      {/* {data?.products?.[1] && ( */}
      {data?.products?.slice(1).map((item, i) => {
        return (
          <Box key={i} sx={{ my: "20px" }}>
            <Box
              sx={{
                color: "#1f4986",

                fontWeight: "600",
                fontSize: "26px",
                textTransform: "capitalize",
                fontFamily: "Avenir Heavy",
              }}
            >
              {name} - product & services
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: "30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",

                  width: "50%",
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    p: "0 5px",
                    color: "#002f75",
                    lineHeight: "28px",
                    fontWeight: "600",
                    fontSize: "18px",
                    fontFamily: "Avenir",
                    width: "40%",
                  }}
                >
                  Popular Brand
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    p: "0 5px",
                    color: "#002f75",
                    lineHeight: "28px",
                    fontWeight: "600",
                    fontSize: "18px",
                    fontFamily: "Avenir",
                    width: "60%",
                  }}
                >
                  Description
                </Typography>
              </Box>
              {item.length > 1 && (
                <Box
                  sx={{
                    display: "flex",
                    //   justifyContent: "space-between",
                    width: "50%",
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      p: "0 5px",
                      color: "#002f75",
                      lineHeight: "28px",
                      fontWeight: "600",
                      fontSize: "18px",
                      fontFamily: "Avenir",
                      width: "40%",
                    }}
                  >
                    Popular Brand
                  </Typography>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      p: "0 5px",
                      color: "#002f75",
                      lineHeight: "28px",
                      fontWeight: "600",
                      fontSize: "18px",
                      fontFamily: "Avenir",
                      width: "60%",
                    }}
                  >
                    Description
                  </Typography>
                </Box>
              )}
            </Box>
            <Box>
              <Grid container rowSpacing={1}>
                {
                  // data?.products?.[1] &&
                  // data?.products?.[1]
                  item?.map((item, i) => {
                    return (
                      <Grid
                        sx={{
                          background: [i % 2 === 0 ? "#e7f7ff" : "#d7f6eb"],
                          border: "1px solid #fff",
                          mb: "5px",
                          p: "16px",
                        }}
                        key={i}
                        item
                        xs={6}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            p: "20px",
                            //   justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              width: "40%",
                              color: "#002f75",
                              lineHeight: "18px",
                              fontWeight: "500",
                              fontSize: "14px",
                              fontFamily: "Avenir Roman",
                            }}
                          >
                            {item.first_level_brand}
                          </Typography>
                          <Typography
                            sx={{
                              width: " 60%",
                              color: "#002f75",
                              lineHeight: "18px",
                              fontWeight: "500",
                              fontSize: "14px",
                              fontFamily: "Avenir Roman",
                              wordBreak: "break-word",
                            }}
                          >
                            {item.description.text}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })
                }
              </Grid>
            </Box>
          </Box>
        );
      })}
      {/* )} */}

      {/* Key Developments ⬇️⬇️⬇️⬇️⬇️⬇️⬇️ */}

      {data?.Key_development.length >= 1 && (
        <hr style={{ height: "1px", padding: "0 20px", margin: "50px 0px" }} />
      )}

      {data?.Key_development.length >= 1 && (
        <Box>
          <Box
            sx={{
              // whiteSpace: "nowrap",
              // p: "16px",
              color: "#1f4986",
              // lineHeight: "18px",
              fontWeight: "600",
              fontSize: "26px",
              textTransform: "capitalize",
              fontFamily: "Avenir Heavy",
              my: "20px",
            }}
          >
            {name} - Key Developments
          </Box>

          <Box>
            {" "}
            {/* <TableContainer component={Paper}> */}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        p: "16px",
                        color: "#002f75",
                        lineHeight: "28px",
                        fontWeight: "600",
                        fontSize: "18px",
                        fontFamily: "Avenir",
                        // p: "0 5px",
                        // width: "40%",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        p: "16px",
                        color: "#002f75",
                        lineHeight: "28px",
                        fontWeight: "600",
                        fontSize: "18px",
                        fontFamily: "Avenir",
                        // p: "0 5px",
                        // width: "40%",
                      }}
                    >
                      Type of Development
                    </TableCell>
                    <TableCell
                      //   sx={{
                      //     whiteSpace: "nowrap",
                      //     color: "#002f75",
                      //     lineHeight: "18px",
                      //     fontWeight: "600",
                      //     fontSize: "18px",
                      //   }}

                      sx={{
                        whiteSpace: "nowrap",
                        p: "16px",
                        color: "#002f75",
                        lineHeight: "28px",
                        fontWeight: "600",
                        fontSize: "18px",
                        fontFamily: "Avenir",
                        // p: "0 5px",
                        // width: "40%",
                      }}
                    >
                      Details
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.Key_development[0]?.map((row, index) => (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{
                        background: [index % 2 === 0 ? "#e7f7ff" : "#d7f6eb"],
                        border: "1px solid #fff",
                        mb: "5px",
                      }}
                      // className={index % 2 === 0 ? classes.tableRowOdd : ""}
                    >
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#002f75",
                            fontFamily: "Avenir",
                            p: "16px",
                            fontSize: "14px",
                          }}
                        >
                          {row.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Typography
                          sx={{
                            color: "#002f75",
                            fontFamily: "Avenir",
                            p: "16px",
                            whiteSpace: "nowrap",
                            fontSize: "14px",
                          }}
                        >
                          {row.type}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#002f75",
                            fontFamily: "Avenir",
                            p: "16px",
                            fontSize: "14px",
                          }}
                        >
                          {row.description[0].text}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default CompanyProfile;
