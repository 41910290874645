import styles from "../styles.module.css";
import { useReportsContext, getReports } from "../STATE";
import _ from "lodash";

function Tags() {
  const { state, dispatch } = useReportsContext();

  const handleFilterTagRemove = (tag, filterFrom, id) => {
    dispatch({ type: `SET_${state.currentTab}_LOADING`, payload: "PENDING" });
    dispatch({
      type: "REMOVE_TAG",
      payload: { tag, filterFrom, id },
      fn: async (state) => getReports(state, dispatch),
    });
  };

  return (
    <div className={styles.tagsMainContainer}>
      {state?.filterTags?.map((tag, i) => {
        return (
          <p key={_.uniqueId()}>
            {tag.name}{" "}
            <span
              onClick={() => handleFilterTagRemove(tag.name, tag.from, tag.id)}
            >
              x
            </span>
          </p>
        );
      })}
    </div>
  );
}

export default Tags;
