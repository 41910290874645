import { FormControlLabel, Switch } from "@mui/material";
import { usePurchaseHistoryCtx } from "../STATE";

function LibrarySwitch() {
  const {
    state: { isLibraryReports },
    dispatch,
  } = usePurchaseHistoryCtx();
  const handleLibraryChecked = (checked) => {
    dispatch({ type: "SET_IS_LIBRARY_REPORTS", payload: checked });
  };
  return (
    <FormControlLabel
      control={<Switch />}
      label="Show Library Reports"
      checked={isLibraryReports}
      onChange={(e) => handleLibraryChecked(e.target.checked)}
    />
  );
}

export default LibrarySwitch;
