import ReportLayout from "./ReportLayout";
import CompanyProfile from "./CompanyProfile";
import Snippet from "../../ReportSection/Snippet";
import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import HubsReportDSMultiSelect from "../../HubsComponents/HubsReportDSMultiSelect";
import VisualiseCustom from "../../Visualise/VisualiseCustom";
import { useDispatch } from "react-redux";
import ReportDetailsTab from "../../../pages/ReportDetails/ReportDetailsTab";
import { Dashboard } from "../../../pages/ReportDetails/Dashboard";
import { useAuth } from "../../../context/authContext/Auth";
import axios from "axios";
import API from "../../../api/Api";
import { downloadPdfFromUrlAndMemoized } from "../../../utils/common";

function CompanyReport() {
  const [currentTab, setCurrentTab] = useState(3);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [repUrls,setRepUrls]=useState({sample:null,report:null})
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const navigate = useNavigate();

  const { config } = useAuth();
  const { Base_URL } = API;

  const { id: hub, slug } = useParams();

  useEffect(() => {
    const moveToTop = setTimeout(() => {
      window.scroll(0, 465);
    }, 0);
    return () => {
      clearTimeout(moveToTop);
    };
  }, [currentTab]);

  const fetchReportDataBySlug = async (slug, config, navigate) => {
    setLoading(true);
    try {
      const reportData = await axios.get(
        `${Base_URL}/api/hubs/report-by-slug/${slug}`,
        config
      );
      const data = reportData.data.data;
      const requiredPdfUrlToDownload = data?.subscribed === "yes" ? data?.report_url : data?.report_sample_url;
      downloadPdfFromUrlAndMemoized(requiredPdfUrlToDownload)
        .then((pdf) => {
          dispatch({
            type: "SET_INITIAL_REPORT_BUFFER",
            payload: {
              url: requiredPdfUrlToDownload,
              data: pdf,
            }
          })
        })
      return reportData.data.data;
    } catch (error) {
      navigate("/hubs", { replace: true });
      console.error(error);
      return {};
    } finally {
      setLoading(false);
    }
  };

  async function fetchData() {
    const data = await fetchReportDataBySlug(
      state?.slug || slug,
      config,
      navigate
    );

    setIsSubscribed(data?.subscribed === "yes" ? true : false);
    setRepUrls({
      sample:data.report_sample_url,
      report:data.report_url
    })
    localStorage.setItem("newReportId", data?.report_id);

    dispatch({
      type: "SET_CURRENT_REPORT_DETAIL",
      payload: data,
    });
    
  }

  useEffect(() => {
    fetchData();

    if (state?.tab === "company-profile") {
      setCurrentTab(2);
    } else if (state?.tab === "dashboard") {
      setCurrentTab(0);
    } else if (state?.tab === "report-pdf") {
      setCurrentTab(3);
    }

  }, [slug]);

  const tabFactory = {
    0: <Dashboard reportId={state?.ids.reportId} isSubscribed={isSubscribed} />,
    1: <Snippet slug={state?.slug} />,
    2: (
      <CompanyProfile
        hubId={state?.ids.hubId}
        companyId={state?.ids.companyId}
        reportId={state?.ids.reportId}
        name={state?.title}
        companyName={state?.company?.name}
        subscribed={isSubscribed}
      />
    ),
    3: (
      <ReportDetailsTab
        subscribed={isSubscribed}
        sampleUrl={state?.report_sample_url ? state?.report_sample_url : repUrls.sample}
        reportUrl={state?.report_url ? state?.report_url : repUrls.report}
        id={state?.ids.reportId}
        title={state?.title}
      />
    ),

    4: (
      <HubsReportDSMultiSelect
        sheetType={"dataSheet"}
        ReportID={state?.report_id}
        isSubscribed={isSubscribed}
      />
    ),
    5: (
      <VisualiseCustom
        visualiseLevel={"report"}
        ReportID={state?.report_id}
        isSubscribed={isSubscribed}
      />
    ),
  };

  return (
    <ReportLayout {...{ setCurrentTab, currentTab, state,fetchData }}>
      <main
        style={{
          width: "100%",
          padding: "0 15px",
        }}
      >
        {tabFactory[currentTab] ?? <h1>Lost</h1>}
      </main>
    </ReportLayout>
  );
}

export default CompanyReport;
