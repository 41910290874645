import {useEffect} from 'react'
import NoData from '../Common/NoData'
import { useDispatch } from 'react-redux';

function Credits() {

    const dispatch = useDispatch();
    useEffect(() => {
  
      dispatch({
        type: "SET_NAVBAR_DATA",
        payload: {
          heading: null,
          paragraph: null,
          backButtonData: null, 
          //   bannerHeight: "0px",
        },
      });
    }, [])

  return (
    <div>
        <NoData/>
    </div>
  )
}

export default Credits