import styles from "../style.module.css";
import { Box } from "@mui/material";
import Search from "./Search";

function SearchFilter() {
  return (
    <div
      className={styles.mainSearchFilterContainer}
      id="report-search-section"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "4rem",
        }}
      >
        <Search />
      </Box>
      <section className={styles.searchContainer}></section>
    </div>
  );
}

export default SearchFilter;
