import React, { useEffect, useState } from "react";
import ExcelUploadButton from "../Common/ExcelUploadButton";
import {
  Box,
  Chip,
  Stack,
 
} from "@mui/material";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import Loader from "../Common/Loader";
import axios from "axios";
import { useAuth } from "../../context/authContext/Auth";
import { WindowSharp } from "@mui/icons-material";
import Cookies from "universal-cookie";
import LockBox from "../LockBox";
import VisualiseSideBar from "./VisualiseSideBar";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import RenewDrawer from "../Common/RenewDrawer";
import API from "../../api/Api";
import ViewVisualise from "./ViewVisualise";
import ShowComponentCheck from "../Common/ShowComponentCheck/ShowComponentCheck";
function VisualiseCustom({
  visualiseLevel,
  ReportID,
  isSubscribed,
  fromDashboard = false,
}) {
  const cookies = new Cookies();
  const hubID = cookies.get("hubId");
  const ReportId = cookies.get("HUBID");
  const { config } = useAuth();
  const [loading, setLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);

  const [graph, setGraph] = useState([]);
  const [metrics, setMetrics] = useState("");
  const [graphType, setGraphType] = useState("bar");
  const [stackedOptions, setStackedOptions] = useState([]);
  const [selectedStackedOption, setSelectedStackedOption] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [displayText, setDisplayText] = useState(
    "Select data parameters using the filters available in navigation panel"
  );
 
  const [refreshData, setRefreshData] = useState(null);
  const [spiltKeys, setSplitKeys] = useState([]);
  const [chipsData, setChipsData] = useState([]);
  const [isTable, setIsTable] = useState(false);
  const [filterForGraph, setFilterForGraph] = useState([]);
  const [newFilterNames, setNewFilterNames] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [detailsToSend, setDetailsToSend] = useState("");
  const { heading } = useSelector((state) => state);
  const {
    selectedReportDetail,
  } = useSelector((state) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialGraphTrueCheck, setInitialGraphTrueCheck] = useState(true);
  const [expandAll, setExpandAll] = useState(false);
  const [changeExpand, setChangeExpand] = useState(0);
  const [reportsbot, setreportsbot] = useState(0);
  // console.log(selectedReportDetail.hub_id,"ijasnkjasbf")
  function handleSetExpandAll(value) {
    setExpandAll(value);
    setChangeExpand(changeExpand + 1);
  }

  // for chicps colors
  const boxBackgroundColor = ["#deebf7", "#e2f0d9", "#FFEDC7", "#E6E3FC"];
  const chicpBackgroundColor = ["#1976d2", "#548235", "#f38141", "#9889f2"];

  const handleChartTypeChange = (newGraphType) => {
    setGraphType(newGraphType);
  };

  const toShow =
    visualiseLevel !== "report" ? ShowComponentCheck("visualize") : true;

  const handleStackedOptionChange = (option) => {
    setSelectedStackedOption(option);
  };

  const { userInfo } = useAuth();
  const { Base_URL } = API;

  const handleDrawerOpen = (heading, alternateHeading) => {
    setDrawerOpen(true);
    setDetailsToSend(`Heading: ${heading}, Sub-Heading: ${alternateHeading}`);
  };

  const extractValues = (dataArray) => {
    return dataArray.map((item) => item.value);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  const handleApplyFilters = (
    value,
    selectedMetric,
    graphTypeChild,
    selectedKeys,
    startYear,
    endYear,
    splitByKeys
  ) => {
    setRefreshData({
      value,
      selectedMetric,
      graphTypeChild,
      selectedKeys,
      startYear,
      endYear,
      splitByKeys,
    });

    scrollToTop();
    setGraphLoading(true);
    if (value === "reset") {
      setGraph([]);
      setDisplayText(
        "Select data parameters using the filters available in navigation panel"
      );
      setGraphLoading(false);
      return;
    }
    setGraph([]);
    const updatedSelectedKeys = extractValues(selectedKeys);
    if (selectedMetric === "") {
      setDisplayText("Select the Metrics");
      setGraphLoading(false);
    } else if (Object.keys(value).length === 0) {
      setDisplayText("Select atleast one filter");
      setGraphLoading(false);
    } else if (startYear && !endYear && graphTypeChild !== "pie") {
      setDisplayText("Select End Year");
      setGraphLoading(false);
      // } else if (graphTypeChild === "stackedColumn" && selectedStackedOption === "") {
      //   setDisplayText("Select Stack Graph By");
      //   setGraphLoading(false);
    } else if (
      splitByKeys.length > 0 &&
      (graphTypeChild === "stackedColumn" || graphTypeChild === "pie") &&
      updatedSelectedKeys.length === 0
    ) {
      setDisplayText("Select Split Graph By");
      setGraphLoading(false);
    } else {
      setInitialGraphTrueCheck(false);
      setMetrics(selectedMetric);
      try {
        axios
          .post(
            `${Base_URL}/api/hubs/visualize/hub`,
            // `http://localhost:8080/api/hubs/visualize/hub`,
            {
              dimensionsArray: value,
              groupBy:
                graphTypeChild !== "stackedColumn" && graphTypeChild !== "pie"
                  ? null
                  : selectedStackedOption,
              // || "country",
              graphType: graphTypeChild,
              metricRequested: selectedMetric.toLowerCase(),
              hub_id:
                visualiseLevel !== "report"
                  ? parseInt(hubID, 10)
                  : parseInt(selectedReportDetail.hub_id, 10),
              ...(visualiseLevel === "report"
                ? { report_id: parseInt(ReportId, 10) || ReportID }
                : {}),
              splitBy:
                updatedSelectedKeys.length > 0 ? updatedSelectedKeys : null,
              startYear: startYear,
              endYear: endYear,
            },
            config
          )
          .then((response) => {
            // console.log(response.data.graphData[0]?.data,"checkingkanfka")
            //       if (graphTypeChild === 'stackedColumn') {
            //         var jsonDataWithoutMeta = { ...theOtherData };
            //         setStackedOptions(jsonDataWithoutMeta.groupByFields)
            //         delete jsonDataWithoutMeta.groupByFields;
            //         // Rename the "category" key to "catagory"
            //         jsonDataWithoutMeta.catagory = jsonDataWithoutMeta.category;
            //         delete jsonDataWithoutMeta.category;
            // setGraph([jsonDataWithoutMeta]);
            // setGraphValueStore(jsonDataWithoutMeta);
            //         console.log([jsonDataWithoutMeta],"checkinging")
            //       }
            //       else {
            setreportsbot(0);
            setStackedOptions([]);
            setGraph(response?.data?.graphData);
            // }
            if (response?.data?.graphData?.length === 0) {
              setGraph(response?.data?.graphData);
              // setDisplayText("No Data to show, change filters")
              setDisplayText(response?.data?.message);
            }
            setGraphType(graphTypeChild);
            setGraphLoading(false);
            setExpandAll(false);
          })
          .catch((error) => {
            setDisplayText("Data is Empty change filters");
            // setDisplayText(error.response.data.message);
            // console.error('Error:', error.response.data.message);
            setGraphLoading(false);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  function generateHeading(input) {
    const filteredInput = Object.fromEntries(
      Object.entries(input).filter(([key, value]) => {
        return (
          value !== "All" &&
          (!Array.isArray(value) || value.length > 1 || value[0] !== "All")
        );
      })
    );
    const result = Object.fromEntries(
      Object.entries(filteredInput)
        .filter(([key, value]) => spiltKeys.includes(key))
        .map(([key, value]) => [newFilterNames[key], value])
    );
    const heading = Object.entries(result).map(([key, value]) => {
      const formattedKey = key.replace(/_/g, " ");

      // Handle the case when value is "N_All"
      if (value === "N_All") {
        return `By ${formattedKey}`;
      }

      if (Array.isArray(value)) {
        const formattedValues = value
          .map((v) => (v === "N_All" ? `By ${formattedKey}` : v))
          .join(", ");
        return `${formattedKey}: ${formattedValues}`;
      } else {
        return `${formattedKey}: ${value}`;
      }
    });

    // Move "By Distribution Channel" to the beginning
    const indexOfByDistributionChannel = heading.findIndex((text) =>
      text.startsWith("By Distribution Channel")
    );
    if (indexOfByDistributionChannel !== -1) {
      const byDistributionChannelText = heading.splice(
        indexOfByDistributionChannel,
        1
      )[0];
      heading.unshift(byDistributionChannelText);
    }
    if (heading.join(" | ") === "" || heading.join(" | ") === " ") {
      if (input.region !== "All" && initialGraphTrueCheck) {
        return `Region : ${input.region}`;
      }
      return "Global";
    }
    return heading.join(" | ");
  }

  const graphConfig = {
    pie: {
      source: {
        x: "$canvasStartX",
        y: "$canvasEndY-10",
      },
      mordor_icon: {
        x: "$canvasEndX-20",
        y: "$canvasEndY-20",
      },
    },
    stackColumn: {
      source: {
        x: "$canvasStartX",
        y: "$canvasEndY+57",
      },
      mordor_icon: {
        x: "$canvasEndX+250",
        y: "$canvasEndY+40",
      },
    },
    line: {
      source: {
        x: "$canvasStartX+420",
        y: "$canvasEndY+40",
      },
      mordor_icon: {
        x: "$canvasEndX-30",
        y: "$canvasEndY+20",
      },
    },
    column: {
      source: {
        x: "$canvasStartX",
        y: "$canvasEndY+40",
      },
      mordor_icon: {
        x: "$canvasEndX-30",
        y: "$canvasEndY+20",
      },
    },
  };
  const showModal = () => {
   
    setIsModalOpen(true);
  };


  return (
    <>
      <Box sx={{ width: "95%", margin: "auto", position: "relative" }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            {/* {((!toShow && !loading) || (!isSubscribed && !loading)) && (
              <LockBox
                sx={{
                  top: "40%",
                  left: "50%",
                  zIndex: "5",
                }}
                componentName={"Visualize"}
                purchaseCustomText={
                  fromDashboard
                    ? "Purchase the report to gain access."
                    : "Purchase the hub to gain access."
                }
                toShowText={!toShow?"You don't have access for ":false}  
              />
            )} */}
            <Box
              // className={!toShow && !loading || !isSubscribed && !loading ? "blur" : ""}
              display="flex"
            >
              {/* {!toShow && !loading || !isSubscribed && !loading && (
                <div className="overlay-blur"></div>
              )} */}
              {/* -----------For Graph ----------*/}
              <Box flex={8} sx={{ width: "100%", height: "100%" }}>
                {chipsData.length < 1 ? (
                  <></> // Render nothing when filterForGraph is empty
                ) : (
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      flexWrap: "wrap",
                      mx: "auto",
                      // position: "sticky", top: "80px", zIndex: "5", background: "#e1f5fe",
                      padding: "5px",
                      gap: 1,
                    }}
                  >
                    {Object.keys(chipsData).map((key, index) => (
                      <Box
                        key={index}
                        sx={{
                          // border: "1px solid black",
                          backgroundColor:
                            boxBackgroundColor[
                              index % boxBackgroundColor.length
                            ],
                          marginRight: "5px",
                          borderRadius: "3px",
                          padding: "10px",
                          marginBottom: "5px",
                        }}
                      >
                        <p
                          style={{
                            color: "#203864",
                            padding: "5px 0px 20px",
                            fontWeight: "600",
                          }}
                        >
                          {key}
                        </p>
                        <Stack direction="row" sx={{ flexWrap: "wrap" }}>
                          {chipsData[key].map((option, chipIndex) => (
                            <Chip
                              key={chipIndex}
                              label={option}
                              sx={{
                                marginRight: 1,
                                marginBottom: 1,
                                background: "#1976d2",
                                color: "white",
                                p: 1.5,
                                backgroundColor:
                                  chicpBackgroundColor[
                                    index % chicpBackgroundColor.length
                                  ],
                              }}
                            />
                          ))}
                        </Stack>
                      </Box>
                    ))}
                  </Box>
                )}
                {graph.length > 1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      width: "90%",
                      padding: "5px",
                      gap: 1,
                      zIndex: "8",
                      margin: "auto",
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => handleSetExpandAll(true)}
                    >
                      Expand All Graphs
                    </Button>
                    <Button
                      variant="outlined"
                      component="label"
                      onClick={() => handleSetExpandAll(false)}
                    >
                      Collapse All Graphs
                    </Button>
                  </Box>
                ) : (
                  ""
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box width={"90%"}>
                    {graphLoading || filterData.length === 0 ? (
                      <Loader />
                    ) : (
                      <>
                        {!graph || graph.length === 0 ? (
                          <Box
                            height={"70vh"}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {displayText}
                          </Box>
                        ) : isTable ? (
                          <TableComponent data={graph} />
                        ) : (
                          <>
                            <Box sx={{ width: "100%" }}>
                              {graph.map((item) => (
                                <>
                                  <ViewVisualise
                                    showModal={showModal}
                                    blurred={
                                      item.userAccess === null
                                        ? false
                                        : !item.userAccess
                                    }
                                    alternateHeading={
                                      generateHeading(item.query) +
                                      (item.graphType === "pie"
                                        ? ` | Year:${item?.year}`
                                        : "")
                                    }
                                    heading={
                                      heading
                                        ? `${heading} ${
                                            item?.unit
                                              ? `, Market size in ${item.unit}`
                                              : ""
                                          }`
                                        : `${selectedReportDetail.title} ${
                                            item?.unit
                                              ? `, Market size in ${item.unit}`
                                              : ""
                                          }`
                                    }
                                    data={item}
                                    handleDrawerOpen={handleDrawerOpen}
                                    graphLength={graph.length < 2}
                                    expandAll={expandAll}
                                    changeExpand={changeExpand}
                                    hubId={parseInt(
                                      hubID || selectedReportDetail.hub_id,
                                      10
                                    )}
                                    reportId={parseInt(
                                      ReportId || ReportID,
                                      10
                                    )}
                                    visualiseLevel={visualiseLevel}
                                  />
                                </>
                              ))}
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
              {/* Left Side Filters and Accordian */}
              <Box
                flex={2}
                // sx={{maxWidth: '300px',overflow: 'scroll',height:"100vh", }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%%",
                  maxWidth: "100%%",
                  mr: "10px",
                  // flex: "1",
                  position: "sticky",
                  top: "50px",
                  // overflow: "hidden",
                  // overflowY: "scroll",
                  height: "800px",
                  maxHeight: "90vh",
                  py: "20px",
                  overflowY: "scroll",
                }}
              >
                <VisualiseSideBar
                  graphType={graphType}
                  onChartTypeChange={handleChartTypeChange}
                  onApplyFilters={handleApplyFilters}
                  stackedOptions={stackedOptions}
                  selectedStackedOption={selectedStackedOption}
                  handleStackedOptionChange={handleStackedOptionChange}
                  visualiseLevel={visualiseLevel}
                  ReportID={ReportID}
                  graphLoading={graphLoading}
                  filterForGraph={filterForGraph}
                  setFilterForGraph={setFilterForGraph}
                  setChipsData={setChipsData}
                  spiltKeys={spiltKeys}
                  setSplitKeys={setSplitKeys}
                  newFilterNames={newFilterNames}
                  setNewFilterNames={setNewFilterNames}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  setGraph={setGraph}
                />
                <br />
              </Box>
            </Box>
          </>
        )}
        <RenewDrawer
          title={detailsToSend}
          report_id={""}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          typeModal={"request"}
        />
      </Box>
    </>
  );
}

export default VisualiseCustom;

const TableComponent = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.label}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
