import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import { COLORS } from "../graphs.configs";
import { breakHeader, breakLabel } from "../graphs.helperFunctions";
import mordor_icon from "../../utils/img/Mi_logo.png";
import SynapseLogo from '../../../src/assets/Synapse_logo.svg'

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

let COLOR = COLORS.split(",").map((item) => {
  return item.trim();
});

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: "",
      button_click: false,
      loading: false,
      loadingData: false,
      data: [
        {
          seriesname: "Food Products",
          data: [
            {
              value: "121000",
            },
            {
              value: "135000",
            },
            {
              value: "123500",
            },
            {
              value: "145000",
            },
          ],
        },
        {
          seriesname: "Non-Food Products",
          data: [
            {
              value: "131400",
            },
            {
              value: "154800",
            },
            {
              value: "98300",
            },
            {
              value: "131800",
            },
          ],
        },
      ],
      catagory: [
        {
          label: "Q1",
        },
        {
          label: "Q2",
        },
        {
          label: "Q3",
        },
        {
          label: "Q4",
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.data && this.props.data.data) {
      let str, new_str, new_catagory;

      let datas = [...this.props.data.data].map((item, i) => {
        if (this.props.templateName !== "1100") {
          if (item?.seriesname?.length > 24) {
            str = breakLabel(String(item.seriesname), 25);

            return {
              ...item,
              seriesname: str,
            };
          }
        }

        return {
          ...item,
        };
      });

      datas = datas
        .map((elm) => {
          let result = elm.data.map((it) => {
            if (it.value > 10) {
              return {
                showValue: 0,
                value: it.value,
              };
            } else if (it.value <= 10 && it.value >= 4) {
              return {
                showValue: 0,
                value: Math.floor(it.value),
              };
            } else if (
              it.value < 4 &&
              this.props.template !== "keyStrategicMoves"
            ) {
              return {
                showValue: "0",
                value: Math.floor(it.value),
              };
            } else if (it.value > 0) {
              return {
                showValue: 0,
                value: Math.floor(it.value),
              };
            }
          });
          return {
            seriesname: breakLabel(elm.seriesname, 35),
            data: [...result],
          };
        })
        .sort((a) => {
          return a.seriesname;
        });

      new_catagory = [...this.props.data.catagory].map((item) => {
        if (item?.label?.length > 24) {
          new_str = (item.label + " ")
            .match(/.{1,25}/g)
            .map((text) => {
              if (text[text?.length - 1] === " ") {
                return text;
              } else if (text[text?.length - 1] !== " ") {
                return text + "-";
              } else {
                return text;
              }
            })
            .join(" {br} ");

          return {
            ...item,
            label: new_str,
          };
        }
        return {
          ...item,
        };
      });

      this.setState({
        loadingData: false,
        data: datas,
        catagory: new_catagory,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      JSON.stringify(prevProps.data.data) !==
        JSON.stringify(this.props.data.data)
    ) {
      let str, new_str, new_catagory;

      let datas = [...this.props.data.data].map((item, i) => {
        if (this.props.templateName !== "1100") {
          if (item?.seriesname?.length > 24) {
            str = breakLabel(String(item.seriesname), 25);

            return {
              ...item,
              seriesname: str,
            };
          }
        }
        return {
          ...item,
        };
      });

      datas = datas.map((elm) => {
        let result = elm.data.map((it) => {
          if (it.value > 10) {
            return {
              showValue: "0",
              value: it.value,
            };
          } else if (5 <= it.value && it.value <= 10) {
            return {
              showValue: "0",
              value: Math.floor(it.value),
            };
          } else if (
            it.value < 4 &&
            this.props.template !== "keyStrategicMoves"
          ) {
            return {
              showValue: "0",
              value: Math.floor(it.value),
            };
          } else if (it.value > 0) {
            return {
              showValue: "0",
              value: Math.floor(it.value),
            };
          }
        });
        return {
          seriesname: breakLabel(elm.seriesname, 35),
          data: [...result],
        };
      });

      new_catagory = [...this.props.data.catagory].map((item) => {
        if (item?.label?.length > 24) {
          new_str = (item.label + " ")
            .match(/.{1,25}/g)
            .map((text) => {
              if (text[text?.length - 1] === " ") {
                return text;
              } else if (text[text?.length - 1] !== " ") {
                return text + "-";
              } else {
                return text;
              }
            })
            .join(" {br} ");

          return {
            ...item,
            label: new_str,
          };
        }
        return {
          ...item,
        };
      });

      this.setState({
        loadingData: false,
        data: datas,
        catagory: new_catagory,
      });
    }
  }

  render() {
    let data = "",
      catagory = "",
      width = 740,
      height = 220,
      legendItemFontSize = 14,
      header = "",
      seriesName = [],
      legendsData = [],
      newData = [],
      others = [],
      id = `${Date.now()}`;

    data = this.state.data;
    catagory = this.state.catagory;

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.header) header = this.props.header;
    if (this.props.id) id = this.props.id;

    if (data?.length >= 8) legendItemFontSize = 11;

    data.map((item) => {
      seriesName.push({ seriesname: item.seriesname });
    });

    seriesName = seriesName
      .sort((a, b) => {
        return a.seriesname.localeCompare(b.seriesname);
      })
      .map((series, index) => {
        return { ...series, color: COLOR[index] };
      });

    let x1 = new RegExp("^other.*"),
      x2 = new RegExp("^rest.*");
    seriesName = seriesName.filter((series) => {
      if (
        x1.test(series.seriesname.toLowerCase()) ||
        x2.test(series.seriesname.toLowerCase())
      ) {
        others.push(series);
        return false;
      } else {
        return true;
      }
    });
    if (others.length !== 0)
      others.map((series_item) => seriesName.push(series_item));

    data.map((item) => {
      seriesName.map((series) => {
        if (item.seriesname === series.seriesname) {
          newData.push({ ...item, color: series.color });
        }
      });
    });

    data = [...newData];

    let chartConfigs = {
      type: "stackedbar2d",
      dataFormat: "json",
      width: width,
      height: height,
      id: id,
      dataSource: {
        chart: {
          theme: "fusion",
          animation: false,
          showAnchors: 1,
          exportEnabled: 1,
          exportFormats: "svg||png||jpg||pdf",
          exportAction: "download",
          exportShowMenuItem: this.props.id ? 0 : 1,
          exportFileName: `${header}`,

          formatNumberScale: 1,
          numberScaleValue: "1000,1000,1000,1000",
          numberScaleUnit: "k,M,B, T",

          adjustDiv: "0",
          numDivLines: "4",
          divLineColor: "#ffffff",
          divLineAlpha: "80",
          divLineDashed: "0",

          paletteColors: COLORS,
          minPlotHeightForValue: 10,
          plotSpacePercent: 40,

          showsum: 1,
          showValues: 0,
          valueFont: "Avenir Heavy",
          valueFontSize: 12,
          valueFontBold: true,
          // valueFontColor: "#ffffff",
          placeValuesInside: "0",

          labelFontColor: "#7f7f7f",
          labelFontSize: 12,
          labelDisplay: "WRAP",

          // legendNumColumns: 1,
          legendNUmRows: 4,
          legend: {
            enabled: "1",
            position: "bottom",
            alignment: "start",
          },
          legendItemFontColor: "#7f7f7f",
          legendItemFont: "Avenir Medium",
          legendItemFontSize:
            width <= 566 && this.state.data?.length >= 4 ? 12 : 14,

          showYAxisValues: false,
          showYAxisLine: false,
          yAxisNamePadding: 0,
          yAxisMaxValue: this.props.value_percentage ? 101 : 0,

          showXAxisLine: false,
          centerXaxisName: false,

          chartTopMargin: 40,
          chartBottomMargin: this.props.templateName === "1100" ? 200 : 0,
          chartRightMargin: this.props.value_percentage ? 250 : 200,
          // chartLeftMargin: 0,

          baseFont: "Avenir Medium",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  // bgColor: "#E0EFFA",
                  width: `${width}`,
                  fontsize: this.props?.headingColor ? 15 : 16,
                  font: "Avenir Medium",
                  text: (header + "").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: this.props.wrapWidth,
                  x: `${this.props.xHeadingMargin + 20}`,
                  y: `${this.props.yHeadingMargin + 7}`,
                },

                {
                  id: "dyn-label-bg",
                  type: "rectangle",
                  // showBorder: "1",
                  // borderColor: "12345d",

                  fillcolor: this.props.headingColor,
                  x: "$canvasStartY-140",
                  y: "$canvasStartY-60",
                  tox: "$canvasEndX+300",
                  toy: "$canvasStartY-20",
                },
                {
                  id: "source",
                  type: "text",
                  align: "left",
                  fillcolor: "#7f7f7f",
                  fontsize: 12,
                  font: "Avenir Medium",
                  text: this.props.source ?? "Source: Mordor Intelligence",
                  bold: "0",
                  wrap: "1",
                  wrapWidth: width,
                  x: "$canvasStartX+300",
                  y: "$canvasEndY+65",
                },
                {
                  id: "mordor-icon",
                  type: "image",
                  url: SynapseLogo,
                  x: "$canvasEndX+20",
                  y: "$canvasEndY+40",
                  tox: "$canvasStartX+330",
                  toy: "$canvasEndY+70",
                },
              ],
            },
          ],
        },
        categories: [
          {
            category: catagory,
          },
        ],
        dataset: data,
      },
    };

    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}
