import React from 'react';
import { useAuth } from '../../../context/authContext/Auth';

const DownloadAccessChecker = (level, id ) => {
    const { userInfo } = useAuth();
    
    if (userInfo?.account_type === "view only" && userInfo?.access_type === "full") {
        if (level === "hub") {
            return userInfo?.viewOnlyFullAccessHubs.includes(id);
        } else if (level === "report") {
            return userInfo?.viewOnlyFullAccess.includes(id);
        }
    }
    else if (userInfo?.account_type === "view only") { 
        return false;
    }
        return true;
}

export default DownloadAccessChecker;
