import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import ReportCover from "../../utils/img/Report_Cover.jpg";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useAuth } from "../../context/authContext/Auth";
import { useLocation } from "react-router-dom";
import axios from "axios";
import API from "../../api/Api";
import { hubTitleWordsModify } from "../../utils/common";

function HubsCard({
  id,
  name,
  picture,
  reports,
  subscribedReports,
  isBookmarked,
  setBookmarkCheckApi,
  bookmarkCheckApi
}) {
  const cookies = new Cookies();
  const { config, setNotify } = useAuth();
  const { Base_URL } = API
  let setHubId = (id) => {
    cookies.set("hubId", id, { path: "/" });
  };
  const location = useLocation();
  const URL = location.pathname;
  const [bookmarkCheck, setBookmarkCheck] = useState(isBookmarked);
  const changeName = name.toLowerCase().replace(/\s+/g, "-").replace(/,/g, "").trim();

  const handleBookmarkChange = (id) => {
    axios
      .post(
        `${Base_URL}/api/hubs/${
          !bookmarkCheck ? "favourite-hub" : "unfavourite-hub"
        }`,
        { hub_id: parseInt(id, 10) },
        config
      )
      .then((res) => {
        setNotify({
          isOpen: true,
          message: res.data?.message,
          type: "success",
          position: "right",
        });
      })
      .catch((err) => {
        console.log(err);
        setNotify({
          isOpen: true,
          message: err.response.data?.message || "Some Error occurred",
          type: "error",
          position: "right",
        });
      }).finally(() => {
        setBookmarkCheck(!bookmarkCheck);
        if (URL === "/bookmarks") {
          setBookmarkCheckApi((pre) => pre + 1);
        }
      });
    
  };

  return (
    // <Link to={`/hubs/${name}`} state={{ id, name }}>
    <Box
      key={id}
      // width={318}
      // height={278}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      boxShadow={3}
      sx={{
        width: {
          xs: 260,
          lg: 270,
          xl: 318
        },
        height: {
          xs: 230,
          lg: 250,
          xl: 278
        },
        // border: !bookmarkCheck ? "1px solid rgba(0, 47, 117, 0.08)" : "1px solid #189CDE",
        border: "1px solid rgba(0, 47, 117, 0.08)",
        borderRadius: "8px",
        transition: "all 0.3s",
        "&:hover": {
          // boxShadow: 7,
          border: "1px solid #189CDE",
          // boxShadow: "rgba(0, 0, 139, 0.4) 0px 7px 29px 0px"
        },
      }}
    >
      <Link
        to={URL === "/bookmarks" ?`hubs/${changeName}` : `${changeName}`}
        state={{ id, changeName, isSubscribed: subscribedReports > 0 }}
        onClick={() => setHubId(id)}
      >
        <Box
          // width={286}
          // height={182}
          margin={"16px"}
          sx={{
            width: {
              xs: 180,
              lg: 240,
              xl: 286
            },
            height: {
              xs: 140,
              lg: 160,
              xl: 182
            },
            borderRadius: "4px",
            backgroundImage: `url(${
              !picture
                ? "https://media.istockphoto.com/id/1372196138/photo/assorted-legumes-in-burlap-sacks-in-a-row-as-a-full-frame-background.webp?b=1&s=170667a&w=0&k=20&c=8_xm_1Xv24ehm6Sn3GGYyAx0LrwxMpoUrXInuoTeCoc="
                : picture
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* No <img> tag is needed for the background image */}
        </Box>
      </Link>

      {/* <Button fullWidth sx={{ py: "13px" }}>
          {name}
        </Button> */}
      <Box
        sx={{
          width: "90%",
          color: "black",
          margin: "auto",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link
          to={`/hubs/${changeName}`}
          state={{ id, changeName }}
          onClick={() => setHubId(id)}
        >
          <Box>
            <Typography
              sx={{ fontSize: "16px", color: "#191919", fontWeight: "800" }}
            >
              {hubTitleWordsModify(name)}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#5A5A5A",
                lineHeight: "18px",
                fontWeight: "400",
              }}
            >
              Reports: {reports} <span style={{margin:"0 6px"}}>|</span> Purchased Reports: {subscribedReports}
            </Typography>
          </Box>
        </Link>
        <Box
          onClick={() => handleBookmarkChange(id)}
          sx={{ cursor: "pointer" }}
        >
          {bookmarkCheck ? (
            <BookmarkIcon fontSize="24px" sx={{ color: "#002F75" }} />
          ) : (
            <BookmarkBorderIcon fontSize="24px" sx={{ color: "#002F75" }} />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default HubsCard;
