import styles from "./style.module.css";
import { useCompaniesReportContext } from "./STATE";
import { Grid } from "@mui/material";

function Layout({ children }) {
  const { state, dispatch } = useCompaniesReportContext();

  const currentTab = state.currentTab;

  const handleTabChange = (tab) => {
    dispatch({ type: "CHANGE_TAB", payload: tab });
  };

  const tabs = [
    {
      name: `All Reports (${state.totalReports})`,
      value: "ALL_REPORTS",
    },
    {
      name: `My Reports (${state.totalMyReports})`,
      value: "MY_REPORTS",
    },
  ];

  return (
    <Grid xs={9} px={3} sx={{ height: "fit-content" }} display="flex" flexDirection="column" gap="1rem">
      <Grid item xs={12} className={styles.companyReportsButtonCnt}>
        {tabs.map((tab) => (
          <button
            disabled={state.loading === "PENDING"}
            key={tab.value}
            className={currentTab === tab.value ? `${styles.activeBtn}` : ""}
            onClick={() => handleTabChange(tab.value)}
          >
            {tab.name}
          </button>
        ))}
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}

export default Layout;
