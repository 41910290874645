import { createContext, useContext } from "react";
import { getModifiedCompanies, getModifiedCompanyCount } from "./helper";

const paginationInitialState = {
  limit: 10,
  page: 1,
};

const filterInitialState = {
  loading: "IDLE",
  geography: {
    regions: [],
    countries: [],
  },
  revenue: [],
  employees: [],
  sortBy: "Default",
};

const geographyInitialState = {
  regions: [],
  countries: [],
  loading: "IDLE",
}

export const initialState = {
  loading: "IDLE",
  recentDevelopmentsCompaniesLoading: "IDLE",
  categoriesLoading: "IDLE",
  currentTab: "COMPANIES_COVERED",
  search: "",
  companies: [],
  companiesShowing: [],
  recentDevelopmentsCompanies: [],
  totalCompanies: 0,
  categoryTree: [],
  selectedCategory: null,
  pagination: paginationInitialState,
  selectedCompany: null,
  filters: filterInitialState,
  geography: geographyInitialState,
  userAccessDenied: false,
};

export const reducer = (state, action) => {
  let newState = {};
  switch (action.type) {
    case "SET_COMPANIES":
      return {
        ...state,
        companies: action.payload,
        totalCompanies: action.payload.length,
      };

    case "SET_COMPANIES_SHOWING":
      return {
        ...state,
        companiesShowing: getModifiedCompanies(state),
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    case "CHANGE_TAB":
      return {
        ...state,
        currentTab: action.payload,
      };

    case "SET_SEARCH":
      newState = {
        ...state,
        pagination: paginationInitialState,
        search: action.payload,
      };
      return {
        ...newState,
        companiesShowing: getModifiedCompanies(newState),
        totalCompanies: getModifiedCompanyCount(newState),
      };

    case "SET_PAGINATION_LIMIT":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          limit: action.payload.limit,
        },
      };

    case "SET_PAGINATION_PAGE":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
      };

    case "SET_CATEGORY_TREE":
      return {
        ...state,
        categoryTree: action.payload,
      };

    case "RESET_PAGINATION":
      return {
        ...state,
        pagination: paginationInitialState,
      };

    case "SET_SELECTED_CATEGORY":
      return {
        ...state,
        selectedCategory: action.payload,
      };

    case "SET_SELECTED_COMPANY":
      return {
        ...state,
        selectedCompany: action.payload,
      };

    case "SET_RECENT_DEVELOPMENT_COMPANIES":
      return {
        ...state,
        recentDevelopmentsCompanies: action.payload,
      };

    case "SET_RECENT_DEVELOPMENT_COMPANIES_LOADING":
      return {
        ...state,
        recentDevelopmentsCompaniesLoading: action.payload,
      };

    case "SET_REGIONS_COUNTRIES":
      return {
        ...state,
        filters: {
          ...state.filters,
          geography: action.payload,
        },
      };

    case "SET_FILTERS_LOADING":
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: action.payload,
        },
      };

    case "SET_SORT_BY":
      newState = {
        ...state,
        filters: {
          ...state.filters,
          sortBy: action.payload,
        },
      };
      return {
        ...newState,
        companiesShowing: getModifiedCompanies(newState),
      };

    case "RESET_FILTERS":
      newState = {
        ...state,
        pagination: paginationInitialState,
        filters: filterInitialState,
      }
      return {
        ...newState,
        companiesShowing: getModifiedCompanies(newState),
        totalCompanies: getModifiedCompanyCount(newState),
      };

    case "SET_REGION_COUNTRY":
      return {
        ...state,
        filters: {
          ...state.filters,
          geography: action.payload,
        },
      };

    case "SET_REVENUE":
      return {
        ...state,
        filters: {
          ...state.filters,
          revenue: action.payload,
        },
      };

    case "SET_EMPLOYEES":
      return {
        ...state,
        filters: {
          ...state.filters,
          employees: action.payload,
        },
      };

    case "APPLY_FILTERS":
      newState = {
        ...state,
        pagination: paginationInitialState,
      };
      return {
        ...newState,
        companiesShowing: getModifiedCompanies(newState),
        totalCompanies: getModifiedCompanyCount(newState),
      };

    case "SET_GEOGRAPHY_LOADING":
      return {
        ...state,
        geography: {
          ...state.geography,
          loading: action.payload,
        },
      };

    case "SET_GEOGRAPHY":
      return {
        ...state,
        geography: {
          ...state.geography,
          ...action.payload
        },
      };

    case "MOCK_LOADING":
      setTimeout(() => {
        action.fn({ type: "SET_LOADING", payload: "IDLE" });
      }, 1000);
      return {
        ...state,
        loading: "PENDING",
      };

    case "SET_USER_ACCESS_DENIED":
      return {
        ...state,
        userAccessDenied: action.payload,
      };

    case "SET_CATEGORY_TREE_LOADING":
      return {
        ...state,
        categoriesLoading: action.payload,
      };

    default:
      return state;
  }
};

export const CompanyContext = createContext();
export const useCompanyContext = () => useContext(CompanyContext);
