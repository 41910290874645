const whichReportsToShow = (selectedTab, reports) => {
  const newReports = [];

  const getReportBasedOnCondition = (condition) => {
    for (let i = 0; i < reports.length; i++) {
      if (condition(reports[i])) {
        newReports.push(reports[i]);
      }
    }
  };

  switch (selectedTab) {
    case "ALL":
      return reports;
    case "EXPIRED":
      getReportBasedOnCondition(
        (item) => new Date(item.report_expiry) < new Date()
      );
      break;
    case "RENEWAL":
      const date = new Date();
      const upComing30Days = new Date();
      upComing30Days.setDate(date.getDate() + 30);
      getReportBasedOnCondition(
        (item) =>
          new Date(item.report_expiry) > date &&
          new Date(item.report_expiry) <= upComing30Days
      );
      break;
    default:
      return reports;
  }

  return newReports;
};

const setReportsBasedOnSearch = (reports, search) => {
  return reports.filter((report) =>
    report.title.toLowerCase().startsWith(search.toLowerCase())
  );
};

const setReportsBasedOnPagination = (reports, { page, limit }) => {
  const start = (page - 1) * limit;
  const end = page * limit;
  const newReports = [];

  for (let i = start; i < end; i++) {
    if (reports[i]) {
      newReports.push(reports[i]);
    }
  }
  return newReports;
};

const setReportsCountBasedOnSubscribedData = (reports) => {
  const getReportCountBasedOnCondition = (condition) => {
    let count = 0;
    for (let i = 0; i < reports.length; i++) {
      if (condition(reports[i])) {
        count++;
      }
    }
    return count;
  };

  const count = {};
  // ALL THE REPORTS AVAILABLE
  count["all"] = reports.length;

  // REPORT HAS EXPIRED "report_expiry" < today
  count["expired"] = getReportCountBasedOnCondition(
    (report) => new Date(report.report_expiry) < new Date()
  );

  // REPORT IS RENEWABLE today + 30 days > "report_expiry"
  const date = new Date();
  const upComing30Days = new Date();
  upComing30Days.setDate(date.getDate() + 30);
  count["renewal"] = getReportCountBasedOnCondition(
    (report) =>
      new Date(report.report_expiry) > date &&
      new Date(report.report_expiry) <= upComing30Days
  );

  return count;
};

const setReportBasedOnFilterType = (reports, filterType) => {
  const newReports = [];
  const now = new Date();

  const getReportBasedOnCondition = (condition) => {
    for (let i = 0; i < reports.length; i++) {
      if (condition(reports[i])) {
        newReports.push(reports[i]);
      }
    }
  };

  switch (filterType) {
    case 30:
      // REPORTS FROM LAST 30 DAYS
      const thirtyDaysAgo = new Date(now);
      thirtyDaysAgo.setDate(now.getDate() - 30);
      getReportBasedOnCondition(
        (item) => new Date(item.subscribed_on) >= thirtyDaysAgo
      );
      break;
    case 6 * 30:
      // REPORTS FROM LAST 6 MONTHS
      const sixMonthsAgo = new Date(now);
      sixMonthsAgo.setMonth(now.getMonth() - 6);
      getReportBasedOnCondition(
        (item) => new Date(item.subscribed_on) >= sixMonthsAgo
      );
      break;
    case 2023:
      // REPORTS FROM 2023
      getReportBasedOnCondition(
        (item) => new Date(item.subscribed_on).getFullYear() === 2023
      );
      break;
    case 2022:
      // REPORTS FROM 2022
      getReportBasedOnCondition(
        (item) => new Date(item.subscribed_on).getFullYear() === 2022
      );
      break;
    case Infinity:
      // REPORTS OLDER THAN 2022
      getReportBasedOnCondition(
        (item) => new Date(item.subscribed_on).getFullYear() < 2022
      );
      break;

    default:
      return reports;
  }

  return newReports;
};

const setReportsSort = (reports, sortType) => {
  switch (sortType) {
    case "LATEST":
      return reports.sort(
        (a, b) =>
          new Date(b.subscribed_on).getTime() -
          new Date(a.subscribed_on).getTime()
      );
    case "OLDEST":
      return reports.sort(
        (a, b) =>
          new Date(a.subscribed_on).getTime() -
          new Date(b.subscribed_on).getTime()
      );
    default:
      return reports;
  }
};

export const getModifiedReports = (state) => {
  return setReportsBasedOnPagination(
    whichReportsToShow(
      state.selectedTab,
      setReportsSort(
        setReportBasedOnFilterType(
          setReportsBasedOnSearch(state.everyReports, state.search),
          state.filterType
        ),
        state.sortType
      )
    ),
    state.pagination
  );
};

export const getModifiedReportCount = (state) => {
  return setReportsCountBasedOnSubscribedData(
    setReportBasedOnFilterType(
      setReportsBasedOnSearch(state.everyReports, state.search),
      state.filterType
    )
  );
};
