import {
  Box,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useReportsContext } from "../../STATE";

function LibraryReportStatus() {
  const { state, dispatch } = useReportsContext();

  const handleLibraryReportStatusChange = (e) => {
    dispatch({
      type: "SET_LIBRARY_REPORT_STATUS_FILTER",
      payload: e.target.value,
    });
  };

  return (
    <Box sx={{ pl: 2 }}>
      <FormControl>
        <RadioGroup
          row
          value={state.libraryReportsStatusFilter}
          onChange={handleLibraryReportStatusChange}
        >
          <FormControlLabel
            value="all_library_reports"
            control={<Radio />}
            label="All Reports"
          />
          <FormControlLabel
            value="purchased_library_reports"
            control={<Radio />}
            label="Purchased"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default LibraryReportStatus;
