import { useReducer, useEffect } from "react";
import { CompanyContext, reducer, initialState } from "./STATE";
import { Grid } from "@mui/material";
import Layout from "./Layout";
import CompanyCatTree from "../../components/Common/CompanyCatTree";
import SearchFilter from "./SearchFilter";
import Loader from "../../components/Common/Loader";
import Pagination from "./Pagination";
import CompanyRowCard from "./Cards/CompanyRowCard";
import CompanyReportList from "./CompanyReportList";
import RecentDevelopment from "./RecentDevelopment";
import { closureWithState, getCompaniesAPI, getCompaniesCategoryDataAPI, getUserRecentDevelopmentsCompaniesAPI, getCompaniesCountriesRegionsAPI } from "./fetchData";
import { useSelector } from "react-redux";
import LockBox from "../../components/LockBox";

function Company() {

  const { userData } = useSelector((state) => state);

  const [state, dispatch] = useReducer(reducer, initialState);

  const getCompanies = closureWithState({ dispatch, state }, getCompaniesAPI);
  const getCompaniesCategoryData = closureWithState({ dispatch, state }, getCompaniesCategoryDataAPI);
  const getUserRecentDevelopmentsCompanies = closureWithState({ dispatch, state }, getUserRecentDevelopmentsCompaniesAPI);
  const getCompaniesCountriesRegionsFilter = closureWithState({ dispatch, state }, getCompaniesCountriesRegionsAPI);

  useEffect(() => {
    if(!userData?.hubs || userData.hubs.length === 0) {
      dispatch({ type: "SET_USER_ACCESS_DENIED", payload: true  });
      return;
    }
    dispatch({ type: "SET_USER_ACCESS_DENIED", payload: false });
    const getData = async () => {
      await Promise.all([getCompanies(), getCompaniesCategoryData(), getUserRecentDevelopmentsCompanies(), getCompaniesCountriesRegionsFilter()]);
    };
    getData();
  }, [userData]);

  useEffect(() => {
    dispatch({ type: "SET_COMPANIES_SHOWING" });
  }, [state.pagination]);

  useEffect(() => {
    if (!userData?.hubs || userData.hubs.length === 0) {
      return;
    }
    if (state.selectedCompany === null) {
      getCompanies(state.selectedCategory?.id);
      getCompaniesCountriesRegionsFilter();
    }
  }, [state.selectedCategory, state.selectedCompany, userData]);

  const handleCategorySelection = (_, item) => {
    dispatch({ type: "SET_SELECTED_COMPANY", payload: null });
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_LOADING", payload: "PENDING" });
    dispatch({ type: "RESET_PAGINATION" });
    dispatch({ type: "SET_SEARCH", payload: "" });
    dispatch({ type: "SET_SELECTED_CATEGORY", payload: item });
  };

  const setSelectedCompany = (company) => {
    dispatch({
      type: "SET_SELECTED_COMPANY",
      payload: {
        ...company,
        categoryId: state.selectedCategory?.id || undefined,
      },
    });
  };

  if (state.currentTab === "RECENT_DEVELOPMENT") {
    return (
      <>
      {state.userAccessDenied && <LockBox componentName={"Companies"} sx={{ zIndex: "5" }} />}
      <CompanyContext.Provider value={{ state, dispatch }}>
        <Layout>
          <RecentDevelopment companies={state.recentDevelopmentsCompanies} />
        </Layout>
      </CompanyContext.Provider>
      </>
    );
  }

  return (
    <>
    {state.userAccessDenied && <LockBox componentName={"Companies"} sx={{ zIndex: "5" }} />}
    <CompanyContext.Provider value={{ state, dispatch }}>
      <Layout>
        <Grid container display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={3} sx={{ width: "350px" }}>
            <CompanyCatTree
              categoryTreeData={state.categoryTree}
              setCategoryItem={handleCategorySelection}
              currentCat={state.selectedCategory?.id || null}
              loading={state.categoriesLoading === "PENDING"}
            />
          </Grid>
          <ContentToShowForCompaniesCovered
            state={state}
            setSelectedCompany={setSelectedCompany}
          />
        </Grid>
      </Layout>
    </CompanyContext.Provider>
    </>
  );
}

export default Company;

const ContentToShowForCompaniesCovered = ({ state, setSelectedCompany }) => {
  if (state.selectedCompany !== null) {
    return <CompanyReportList selectedCompany={state.selectedCompany} />;
  }

  return (
    <Grid container item xs={9} px={3} sx={{ height: "fit-content" }}>
      <Grid item xs={12}>
        <SearchFilter />
      </Grid>
      <Grid item xs={12}>
        <Companies state={state} setSelectedCompany={setSelectedCompany} />
        <Pagination />
      </Grid>
    </Grid>
  );
};

const Companies = ({ state, setSelectedCompany }) => {
  if (state.loading === "PENDING") {
    return <Loader />;
  }

  if (state.companiesShowing.length === 0) {
    return <center>No Companies Found</center>;
  }

  return state.companiesShowing.map((company) => (
    <CompanyRowCard
      key={company.id}
      company={company}
      setSelectedCompany={setSelectedCompany}
    />
  ));
};
