import React, { Component } from "react";

import { COLORS } from "../graphs.configs";
import { breakHeader } from "../graphs.helperFunctions";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import mordor_icon from "../../utils/img/Mi_logo.png";
import SynapseLogo from '../../../src/assets/Synapse_logo.svg'

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const COLOR = (COLORS + "").split(",").map((color) => color.trim());

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxYaxisValue: 0,
      data: [
        {
          label: "S-1 {br} 2011",
          value: "25",
          color: "#3fd0d9",
        },
        {
          label: "S-2 {br} 2011",
          value: "40",
          color: "#3fd0d9",
        },
        {
          label: "S-3 {br} 2011",
          value: "35",
          color: "#3fd0d9",
        },
        {
          label: "Total",
          issum: "1",
          color: "#3fd0d9",
        },
        {
          label: "S-3 {br} 2012",
          value: "-35",
          color: "#3fd0d9",
        },
        {
          label: "S-2 {br} 2012",
          value: "-40",
          color: "#3fd0d9",
        },
        {
          label: "S-1 {br} 2012",
          value: "-25",
          color: "#3fd0d9",
        },
      ],
      legendsData: [],
      yearsData: [],
      dataValuesData: [],
    };
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.formatDataForChart(this.props.data, COLOR).graphData,
        legendsData: this.formatDataForLegends(this.props.meta, COLOR),
        yearsData: this.formatDataForYears(this.props.meta),
        dataValuesData: this.formatDataForChart(this.props.data, COLOR)
          .dataValues,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let graphData = this.formatDataForChart(this.props.data, COLOR).graphData;
    let legendData = this.formatDataForLegends(this.props.meta, COLOR);
    let yearData = this.formatDataForYears(this.props.meta);
    let dataValuesData = this.formatDataForChart(
      this.props.data,
      COLORS
    ).dataValues;
    if (
      prevState &&
      JSON.stringify(prevState?.data) !== JSON.stringify(graphData)
    ) {
      if (this.props.data && this.props.data) {
        this.setState({
          data: graphData,
          legendsData: legendData,
          yearsData: yearData,
          dataValuesData: dataValuesData,
        });
      }
    }
  }

  formatDataForLegends(meta, COLOR) {
    let legendData = [];
    let colorArray = [];

    if (meta?.legends_list?.length) {
      meta.legends_list.map((item, i) => {
        colorArray.push(COLOR[i]);
      });
    }

    if (meta?.legends_list) {
      let legendsArr = [...meta.legends_list];

      function genRejex(str) {
        let pix = 0,
          len = 0,
          reg;

        switch (legendsArr.length) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6: {
            pix = 230;
            len = 30;
            reg = (str + " ")
              .match(/.{1,40}/g)
              .map((item, i) => {
                return item[item.length - 1] === " " ? item : item + " -";
              })
              .join(" {br} ");
            break;
          }
        }

        return { pix, len, reg };
      }

      legendsArr.map((item, i) => {
        let iconDetails = {
          id: `shape-${i}`,
          type: "polygon",
          startangle: "45",
          sides: "4",
          radius: "10",
          color: COLOR[i],
          x: `${
            i >= 2 && i < 4
              ? 75 + (i - 2) * genRejex(item).pix
              : i >= 4
              ? 75 + (i - 4) * genRejex(item).pix
              : 75 + i * genRejex(item).pix
          }`,
          y: `${i >= 2 && i < 4 ? "530" : i >= 4 ? "550" : "510"}`,
        };

        let legendDetails = {
          id: `label-${i}`,
          type: "text",
          align: "left",
          fillcolor: "#7f7f7f",
          fontsize: "12",
          font: "Avenir Medium",
          text: genRejex(item).reg,
          bold: "0",
          wrap: "1",
          x: `${
            i >= 2 && i < 4
              ? 90 + (i - 2) * genRejex(item).pix
              : i >= 4
              ? 90 + (i - 4) * genRejex(item).pix
              : 90 + i * genRejex(item).pix
          }`,
          y: `${i >= 2 && i < 4 ? "530" : i >= 4 ? "550" : "510"}`,
        };
        legendData.push(iconDetails, legendDetails);
      });

      return legendData;
    }
  }

  formatDataForYears(meta) {
    let yearData = [];

    if (meta.years) {
      let yearsArr = [...meta.years];
      yearsArr.map((year, i) => {
        let yearDetails = {
          id: `label-year-${i}`,
          type: "text",
          align: "left",
          fillcolor: "#7f7f7f",
          fontsize: "14",
          font: "Avenir Medium",
          text: year,
          bold: "0",
          wrap: "1",
          x: `${145 + i * 200}`,
          y: `480`,
        };
        yearData.push(yearDetails);
      });
    }

    return yearData;
  }

  formatDataForChart(data, COLOR) {
    let dataValues = [];
    let graphData = [];
    let colorArray = [];
    let pixals = 0;

    if (data.data0.length === 5) {
      pixals = 18;
    } else if (data.data0.length === 4) {
      pixals = 22;
    } else if (data.data0.length === 3) {
      pixals = 26;
    } else if (data.data0.length === 2) {
      pixals = 28;
    } else {
      pixals = 30;
    }

    if (data.data0.length === data.data1.length) {
      data.data0.map((item, i) => {
        colorArray.push(COLOR[i]);
      });
    }

    if (data.data0) {
      let data0Arr = [...data.data0];

      data0Arr.map((item, i) => {
        let itemDetails = {
          label: item.label,
          value: item.value,
          color: colorArray[i],
          tooltext: `${item.label} {br} ${item.value.toFixed(1)}%`,
          displayValue: `${item.value.toFixed(1)}%`,
        };

        let dataValueDetails = {
          id: `dv-${i}`,
          type: "text",
          align: "center",
          fillcolor: "#002F75",
          fontsize: 12,
          font: "Avenir Medium",
          text: `${item.value.toFixed(1)}%`,
          bold: "1",
          wrap: "1",
          wrapWidth: `${40}`,
          x: `$dataset.0.set.${i}.startx+${pixals}`,
          y: `$dataset.0.set.${i}.starty-12`,
        };

        graphData.push(itemDetails);
        dataValues.push(dataValueDetails);
      });

      graphData.push({
        label: "Total",
        issum: "1",
        color: COLOR[COLOR.length - 1],
        tooltext: `Total {br} 100%`,
        displayValue: `100%`,
      });

      dataValues.push({
        id: `dv-total`,
        type: "text",
        align: "center",
        fillcolor: "#002F75",
        fontsize: 12,
        font: "Avenir Medium",
        text: `100%`,
        bold: "1",
        wrap: "1",
        wrapWidth: `${40}`,
        x: `$dataset.0.set.${data0Arr.length}.startx+${pixals}`,
        y: `$dataset.0.set.${data0Arr.length + 1}.starty-12`,
      });
    }

    if (data.data1) {
      let data1Arr = [...data.data1];
      data1Arr.reverse().map((item, i) => {
        let itemDetails = {
          label: item.label,
          value: `-${item.value}`,
          color: colorArray[colorArray.length - i - 1],
          tooltext: `${item.label} {br} ${item.value.toFixed(1)}%`,
          displayValue: `${item.value.toFixed(1)}%`,
        };

        let dataValueDetails = {
          id: `dv-${data1Arr.length + i}`,
          type: "text",
          align: "center",
          fillcolor: "#002F75",
          fontsize: 12,
          font: "Avenir Medium",
          text: `${item.value.toFixed(1)}%`,
          bold: "1",
          wrap: "1",
          wrapWidth: `${40}`,
          x: `$dataset.0.set.${data1Arr.length + i + 1}.startx+${pixals}`,
          y: `$dataset.0.set.${data1Arr.length + i + 1}.starty-12`,
        };
        graphData.push(itemDetails);
        dataValues.push(dataValueDetails);
      });
    }

    return { graphData, dataValues };
  }

  render() {
    let width, 
        height, 
        header, 
        footer, 
        data,
        id = `${Date.now()}`;

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.header) header = this.props.header;
    if (this.props.footer) footer = this.props.footer;
    if (this.props.id) id = this.props.id;

    if (this.state.data) data = this.state.data;

    let chartConfigs = {
      type: "waterfall2d",
      dataFormat: "json",
      width: width,
      height: height,
      id: id,
      dataSource: {
        chart: {
          theme: "fusion",
          animation: false,
          showAnchors: 1,
          exportEnabled: 1,
          exportShowMenuItem: 1,
          exportFileName: `${header}`,

          decimals: "1",
          connectorDashed: "0",
          sumlabel: "Total {br} Profit",
          positiveColor: "#3fd0d9",
          negativeColor: "#3fd0d9",
          showSumAtEnd: "0",
          connectorAlpha: "0",
          connectorThickness: "0",

          showValues: "0",
          placeValuesInside: "0",

          showYAxisValues: "0",
          yAxisValueFontColor: "#002F75",
          yAxisMinValue: "0",
          yAxisMaxValue: "100",
          numbersuffix: "%",

          showXAxisLine: true,
          xAxisLineColor: "#E5E5E5",
          showXAxisValues: false,

          chartTopMargin: "100",
          chartBottomMargin: "100",

          baseFont: "Avenir Medium",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: this.props?.headingColor ? 15 : 16,
                  font: "Avenir Medium",
                  text: (header + "").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: this.props.wrapWidth,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin + 15}`,
                },
              ],
            },
            {
              id: "legends",
              items: [
                {
                  id: `label-total`,
                  type: "text",
                  align: "left",
                  fillcolor: this.props.headingColor,
                  fontsize: "14",
                  font: "Avenir Medium",
                  text: `Total`,
                  bold: "0",
                  wrap: "1",
                  x: `240`,
                  y: `480`,
                },
              ],
            },
            {
              id: "data-value",
              items: [],
            },
            {
              id: "source",
              type: "text",
              align: "left",
              fillcolor: "#7f7f7f",
              fontsize: 12,
              font: "Avenir Medium",
              text: this.props.source ?? "Source: Mordor Intelligence",
              bold: "0",
              wrap: "1",
              wrapWidth: width,
              x: "$canvasStartX+300",
              y: "$canvasEndY+65",
            },
            {
              id: "mordor-icon",
              type: "image",
              url: SynapseLogo,
              x: "$canvasEndX+20",
              y: "$canvasEndY+40",
              tox: "$canvasStartX+330",
              toy: "$canvasEndY+70",
            },
          ],
        },
        data: data,
      },
    };

    chartConfigs.dataSource.annotations.groups[1].items.push(
      ...this.state.legendsData,
      ...this.state.yearsData
    );

    if (!this.props.dataHidden) {
      chartConfigs.dataSource.annotations.groups[2].items.push(
        ...this.state.dataValuesData
      );
    }

    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}
