import React from "react";
import styled from "styled-components";
import Mask from "./Mask";

export const Ul = styled.ul`
font-size: 14px;
color: #22487e;
font-family: "Avenir Medium";
`;

export const Li = styled.li`
font-size: 15px;
color: #22487e;
font-family: "Avenir Medium";
margin-bottom: 10px;
padding-left: 5px;
text-align:justify;
line-height:1.2;
`;

export default class Analysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analysisData: [],
    };
  }

  componentDidMount() {
    if (this.props?.analysisData) {
      this.setState({
        analysisData: this.props.analysisData,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      if (this.props?.analysisData) {
        this.setState({
          analysisData: this.props.analysisData,
        });
      }
    }
  }

  render() {
    return (
      <Ul>
        {
          this.state.analysisData.map(analysis => {
            let hyperLink = analysis.hyperLink;
            let text = analysis.text;
            text = text.replaceAll('&nbsp;', ' ').replaceAll('&amp;', '&').replaceAll('<br>', '').replaceAll('<br/>', '')
              if(text === "hidden"){
                return (
                  <Mask message=""/>
                )
              }else{
                return (<a onClick={(e) => {
                  e.preventDefault();
    
                }} href={hyperLink}> <Li> {text}</Li></a>)
              }
          })
        }
      </Ul>
    );
  }
}
