export const countryFlag = [
    {
        country_code: "BD",
        country_name: "Bangladesh",
        calling_code: "880"
    },
    {
        country_code: "US",
        country_name: "United States",
        calling_code: "1"
    },
    {
        country_code: "BE",
        country_name: "Belgium",
        calling_code: "32"
    },
    {
        country_code: "BF",
        country_name: "Burkina Faso",
        calling_code: "226"
    },
    {
        country_code: "BG",
        country_name: "Bulgaria",
        calling_code: "359"
    },
    {
        country_code: "BA",
        country_name: "Bosnia and Herzegovina",
        calling_code: "387"
    },
    {
        country_code: "BB",
        country_name: "Barbados",
        calling_code: "1-246"
    },
    {
        country_code: "WF",
        country_name: "Wallis and Futuna",
        calling_code: "681"
    },
    {
        country_code: "BL",
        country_name: "Saint Barthelemy",
        calling_code: "590"
    },
    {
        country_code: "BM",
        country_name: "Bermuda",
        calling_code: "1-441"
    },
    {
        country_code: "BN",
        country_name: "Brunei",
        calling_code: "673"
    },
    {
        country_code: "BO",
        country_name: "Bolivia",
        calling_code: "591"
    },
    {
        country_code: "BH",
        country_name: "Bahrain",
        calling_code: "973"
    },
    {
        country_code: "BI",
        country_name: "Burundi",
        calling_code: "257"
    },
    {
        country_code: "BJ",
        country_name: "Benin",
        calling_code: "229"
    },
    {
        country_code: "BT",
        country_name: "Bhutan",
        calling_code: "975"
    },
    {
        country_code: "JM",
        country_name: "Jamaica",
        calling_code: "1-876"
    },
    {
        country_code: "BV",
        country_name: "Bouvet Island",
        calling_code: "47"
    },
    {
        country_code: "BW",
        country_name: "Botswana",
        calling_code: "267"
    },
    {
        country_code: "WS",
        country_name: "Samoa",
        calling_code: "685"
    },
    {
        country_code: "BQ",
        country_name: "Bonaire, Saint Eustatius and Saba",
        calling_code: "599"
    },
    {
        country_code: "BR",
        country_name: "Brazil",
        calling_code: "55"
    },
    {
        country_code: "BS",
        country_name: "Bahamas",
        calling_code: "1-242"
    },
    {
        country_code: "JE",
        country_name: "Jersey",
        calling_code: "44-1534"
    },
    {
        country_code: "BY",
        country_name: "Belarus",
        calling_code: "375"
    },
    {
        country_code: "BZ",
        country_name: "Belize",
        calling_code: "501"
    },
    {
        country_code: "RU",
        country_name: "Russia",
        calling_code: "7"
    },
    {
        country_code: "RW",
        country_name: "Rwanda",
        calling_code: "250"
    },
    {
        country_code: "RS",
        country_name: "Serbia",
        calling_code: "381"
    },
    {
        country_code: "TL",
        country_name: "East Timor",
        calling_code: "670"
    },
    {
        country_code: "RE",
        country_name: "Reunion",
        calling_code: "262"
    },
    {
        country_code: "TM",
        country_name: "Turkmenistan",
        calling_code: "993"
    },
    {
        country_code: "TJ",
        country_name: "Tajikistan",
        calling_code: "992"
    },
    {
        country_code: "RO",
        country_name: "Romania",
        calling_code: "40"
    },
    {
        country_code: "TK",
        country_name: "Tokelau",
        calling_code: "690"
    },
    {
        country_code: "GW",
        country_name: "Guinea-Bissau",
        calling_code: "245"
    },
    {
        country_code: "GU",
        country_name: "Guam",
        calling_code: "1-671"
    },
    {
        country_code: "GT",
        country_name: "Guatemala",
        calling_code: "502"
    },
    {
        country_code: "GS",
        country_name: "South Georgia and the South Sandwich Islands",
        calling_code: "500"
    },
    {
        country_code: "GR",
        country_name: "Greece",
        calling_code: "30"
    },
    {
        country_code: "GQ",
        country_name: "Equatorial Guinea",
        calling_code: "240"
    },
    {
        country_code: "GP",
        country_name: "Guadeloupe",
        calling_code: "590"
    },
    {
        country_code: "JP",
        country_name: "Japan",
        calling_code: "81"
    },
    {
        country_code: "GY",
        country_name: "Guyana",
        calling_code: "592"
    },
    {
        country_code: "GG",
        country_name: "Guernsey",
        calling_code: "44-1481"
    },
    {
        country_code: "GF",
        country_name: "French Guiana",
        calling_code: "594"
    },
    {
        country_code: "GE",
        country_name: "Georgia",
        calling_code: "995"
    },
    {
        country_code: "GD",
        country_name: "Grenada",
        calling_code: "1-473"
    },
    {
        country_code: "GB",
        country_name: "United Kingdom",
        calling_code: "44"
    },
    {
        country_code: "GA",
        country_name: "Gabon",
        calling_code: "241"
    },
    {
        country_code: "SV",
        country_name: "El Salvador",
        calling_code: "503"
    },
    {
        country_code: "GN",
        country_name: "Guinea",
        calling_code: "224"
    },
    {
        country_code: "GM",
        country_name: "Gambia",
        calling_code: "220"
    },
    {
        country_code: "GL",
        country_name: "Greenland",
        calling_code: "299"
    },
    {
        country_code: "GI",
        country_name: "Gibraltar",
        calling_code: "350"
    },
    {
        country_code: "GH",
        country_name: "Ghana",
        calling_code: "233"
    },
    {
        country_code: "OM",
        country_name: "Oman",
        calling_code: "968"
    },
    {
        country_code: "TN",
        country_name: "Tunisia",
        calling_code: "216"
    },
    {
        country_code: "JO",
        country_name: "Jordan",
        calling_code: "962"
    },
    {
        country_code: "HR",
        country_name: "Croatia",
        calling_code: "385"
    },
    {
        country_code: "HT",
        country_name: "Haiti",
        calling_code: "509"
    },
    {
        country_code: "HU",
        country_name: "Hungary",
        calling_code: "36"
    },
    {
        country_code: "HK",
        country_name: "Hong Kong",
        calling_code: "852"
    },
    {
        country_code: "HN",
        country_name: "Honduras",
        calling_code: "504"
    },
    {
        country_code: "HM",
        country_name: "Heard Island and McDonald Islands",
        calling_code: "672"
    },
    {
        country_code: "VE",
        country_name: "Venezuela",
        calling_code: "58"
    },
    {
        country_code: "PR",
        country_name: "Puerto Rico",
        calling_code: "1-787"
    },
    {
        country_code: "PS",
        country_name: "Palestinian Territory",
        calling_code: "970"
    },
    {
        country_code: "PW",
        country_name: "Palau",
        calling_code: "680"
    },
    {
        country_code: "PT",
        country_name: "Portugal",
        calling_code: "351"
    },
    {
        country_code: "SJ",
        country_name: "Svalbard and Jan Mayen",
        calling_code: "47"
    },
    {
        country_code: "PY",
        country_name: "Paraguay",
        calling_code: "595"
    },
    {
        country_code: "IQ",
        country_name: "Iraq",
        calling_code: "964"
    },
    {
        country_code: "PA",
        country_name: "Panama",
        calling_code: "507"
    },
    {
        country_code: "PF",
        country_name: "French Polynesia",
        calling_code: "689"
    },
    {
        country_code: "PG",
        country_name: "Papua New Guinea",
        calling_code: "675"
    },
    {
        country_code: "PE",
        country_name: "Peru",
        calling_code: "51"
    },
    {
        country_code: "PK",
        country_name: "Pakistan",
        calling_code: "92"
    },
    {
        country_code: "PH",
        country_name: "Philippines",
        calling_code: "63"
    },
    {
        country_code: "PN",
        country_name: "Pitcairn",
        calling_code: "870"
    },
    {
        country_code: "PL",
        country_name: "Poland",
        calling_code: "48"
    },
    {
        country_code: "PM",
        country_name: "Saint Pierre and Miquelon",
        calling_code: "508"
    },
    {
        country_code: "ZM",
        country_name: "Zambia",
        calling_code: "260"
    },
    {
        country_code: "EH",
        country_name: "Western Sahara",
        calling_code: "212"
    },
    {
        country_code: "EE",
        country_name: "Estonia",
        calling_code: "372"
    },
    {
        country_code: "EG",
        country_name: "Egypt",
        calling_code: "20"
    },
    {
        country_code: "ZA",
        country_name: "South Africa",
        calling_code: "27"
    },
    {
        country_code: "EC",
        country_name: "Ecuador",
        calling_code: "593"
    },
    {
        country_code: "IT",
        country_name: "Italy",
        calling_code: "39"
    },
    {
        country_code: "VN",
        country_name: "Vietnam",
        calling_code: "84"
    },
    {
        country_code: "SB",
        country_name: "Solomon Islands",
        calling_code: "677"
    },
    {
        country_code: "ET",
        country_name: "Ethiopia",
        calling_code: "251"
    },
    {
        country_code: "SO",
        country_name: "Somalia",
        calling_code: "252"
    },
    {
        country_code: "ZW",
        country_name: "Zimbabwe",
        calling_code: "263"
    },
    {
        country_code: "SA",
        country_name: "Saudi Arabia",
        calling_code: "966"
    },
    {
        country_code: "ES",
        country_name: "Spain",
        calling_code: "34"
    },
    {
        country_code: "ER",
        country_name: "Eritrea",
        calling_code: "291"
    },
    {
        country_code: "ME",
        country_name: "Montenegro",
        calling_code: "382"
    },
    {
        country_code: "MD",
        country_name: "Moldova",
        calling_code: "373"
    },
    {
        country_code: "MG",
        country_name: "Madagascar",
        calling_code: "261"
    },
    {
        country_code: "MF",
        country_name: "Saint Martin",
        calling_code: "590"
    },
    {
        country_code: "MA",
        country_name: "Morocco",
        calling_code: "212"
    },
    {
        country_code: "MC",
        country_name: "Monaco",
        calling_code: "377"
    },
    {
        country_code: "UZ",
        country_name: "Uzbekistan",
        calling_code: "998"
    },
    {
        country_code: "MM",
        country_name: "Myanmar",
        calling_code: "95"
    },
    {
        country_code: "ML",
        country_name: "Mali",
        calling_code: "223"
    },
    {
        country_code: "MO",
        country_name: "Macao",
        calling_code: "853"
    },
    {
        country_code: "MN",
        country_name: "Mongolia",
        calling_code: "976"
    },
    {
        country_code: "MH",
        country_name: "Marshall Islands",
        calling_code: "692"
    },
    {
        country_code: "MK",
        country_name: "Macedonia",
        calling_code: "389"
    },
    {
        country_code: "MU",
        country_name: "Mauritius",
        calling_code: "230"
    },
    {
        country_code: "MT",
        country_name: "Malta",
        calling_code: "356"
    },
    {
        country_code: "MW",
        country_name: "Malawi",
        calling_code: "265"
    },
    {
        country_code: "MV",
        country_name: "Maldives",
        calling_code: "960"
    },
    {
        country_code: "MQ",
        country_name: "Martinique",
        calling_code: "596"
    },
    {
        country_code: "MP",
        country_name: "Northern Mariana Islands",
        calling_code: "1-670"
    },
    {
        country_code: "MS",
        country_name: "Montserrat",
        calling_code: "1-664"
    },
    {
        country_code: "MR",
        country_name: "Mauritania",
        calling_code: "222"
    },
    {
        country_code: "IM",
        country_name: "Isle of Man",
        calling_code: "44-1624"
    },
    {
        country_code: "UG",
        country_name: "Uganda",
        calling_code: "256"
    },
    {
        country_code: "TZ",
        country_name: "Tanzania",
        calling_code: "255"
    },
    {
        country_code: "MY",
        country_name: "Malaysia",
        calling_code: "60"
    },
    {
        country_code: "MX",
        country_name: "Mexico",
        calling_code: "52"
    },
    {
        country_code: "IL",
        country_name: "Israel",
        calling_code: "972"
    },
    {
        country_code: "FR",
        country_name: "France",
        calling_code: "33"
    },
    {
        country_code: "IO",
        country_name: "British Indian Ocean Territory",
        calling_code: "246"
    },
    {
        country_code: "SH",
        country_name: "Saint Helena",
        calling_code: "290"
    },
    {
        country_code: "FI",
        country_name: "Finland",
        calling_code: "358"
    },
    {
        country_code: "FJ",
        country_name: "Fiji",
        calling_code: "679"
    },
    {
        country_code: "FK",
        country_name: "Falkland Islands",
        calling_code: "500"
    },
    {
        country_code: "FM",
        country_name: "Micronesia",
        calling_code: "691"
    },
    {
        country_code: "FO",
        country_name: "Faroe Islands",
        calling_code: "298"
    },
    {
        country_code: "NI",
        country_name: "Nicaragua",
        calling_code: "505"
    },
    {
        country_code: "NL",
        country_name: "Netherlands",
        calling_code: "31"
    },
    {
        country_code: "NO",
        country_name: "Norway",
        calling_code: "47"
    },
    {
        country_code: "NA",
        country_name: "Namibia",
        calling_code: "264"
    },
    {
        country_code: "VU",
        country_name: "Vanuatu",
        calling_code: "678"
    },
    {
        country_code: "NC",
        country_name: "New Caledonia",
        calling_code: "687"
    },
    {
        country_code: "NE",
        country_name: "Niger",
        calling_code: "227"
    },
    {
        country_code: "NF",
        country_name: "Norfolk Island",
        calling_code: "672"
    },
    {
        country_code: "NG",
        country_name: "Nigeria",
        calling_code: "234"
    },
    {
        country_code: "NZ",
        country_name: "New Zealand",
        calling_code: "64"
    },
    {
        country_code: "NP",
        country_name: "Nepal",
        calling_code: "977"
    },
    {
        country_code: "NR",
        country_name: "Nauru",
        calling_code: "674"
    },
    {
        country_code: "NU",
        country_name: "Niue",
        calling_code: "683"
    },
    {
        country_code: "CK",
        country_name: "Cook Islands",
        calling_code: "682"
    },
    {
        country_code: "XK",
        country_name: "Kosovo",
        calling_code: "383"
    },
    {
        country_code: "CI",
        country_name: "Ivory Coast",
        calling_code: "225"
    },
    {
        country_code: "CH",
        country_name: "Switzerland",
        calling_code: "41"
    },
    {
        country_code: "CO",
        country_name: "Colombia",
        calling_code: "57"
    },
    {
        country_code: "CN",
        country_name: "China",
        calling_code: "86"
    },
    {
        country_code: "CM",
        country_name: "Cameroon",
        calling_code: "237"
    },
    {
        country_code: "CL",
        country_name: "Chile",
        calling_code: "56"
    },
    {
        country_code: "CC",
        country_name: "Cocos Islands",
        calling_code: "61"
    },
    {
        country_code: "CA",
        country_name: "Canada",
        calling_code: "1"
    },
    {
        country_code: "CG",
        country_name: "Republic of the Congo",
        calling_code: "242"
    },
    {
        country_code: "CF",
        country_name: "Central African Republic",
        calling_code: "236"
    },
    {
        country_code: "CD",
        country_name: "Democratic Republic of the Congo",
        calling_code: "243"
    },
    {
        country_code: "CZ",
        country_name: "Czech Republic",
        calling_code: "420"
    },
    {
        country_code: "CY",
        country_name: "Cyprus",
        calling_code: "357"
    },
    {
        country_code: "CX",
        country_name: "Christmas Island",
        calling_code: "61"
    },
    {
        country_code: "CR",
        country_name: "Costa Rica",
        calling_code: "506"
    },
    {
        country_code: "CW",
        country_name: "Curacao",
        calling_code: "599"
    },
    {
        country_code: "CV",
        country_name: "Cape Verde",
        calling_code: "238"
    },
    {
        country_code: "CU",
        country_name: "Cuba",
        calling_code: "53"
    },
    {
        country_code: "SZ",
        country_name: "Swaziland",
        calling_code: "268"
    },
    {
        country_code: "SY",
        country_name: "Syria",
        calling_code: "963"
    },
    {
        country_code: "SX",
        country_name: "Sint Maarten",
        calling_code: "599"
    },
    {
        country_code: "KG",
        country_name: "Kyrgyzstan",
        calling_code: "996"
    },
    {
        country_code: "KE",
        country_name: "Kenya",
        calling_code: "254"
    },
    {
        country_code: "SS",
        country_name: "South Sudan",
        calling_code: "211"
    },
    {
        country_code: "SR",
        country_name: "Suriname",
        calling_code: "597"
    },
    {
        country_code: "KI",
        country_name: "Kiribati",
        calling_code: "686"
    },
    {
        country_code: "KH",
        country_name: "Cambodia",
        calling_code: "855"
    },
    {
        country_code: "KN",
        country_name: "Saint Kitts and Nevis",
        calling_code: "1-869"
    },
    {
        country_code: "KM",
        country_name: "Comoros",
        calling_code: "269"
    },
    {
        country_code: "ST",
        country_name: "Sao Tome and Principe",
        calling_code: "239"
    },
    {
        country_code: "SK",
        country_name: "Slovakia",
        calling_code: "421"
    },
    {
        country_code: "KR",
        country_name: "South Korea",
        calling_code: "82"
    },
    {
        country_code: "SI",
        country_name: "Slovenia",
        calling_code: "386"
    },
    {
        country_code: "KP",
        country_name: "North Korea",
        calling_code: "850"
    },
    {
        country_code: "KW",
        country_name: "Kuwait",
        calling_code: "965"
    },
    {
        country_code: "SN",
        country_name: "Senegal",
        calling_code: "221"
    },
    {
        country_code: "SM",
        country_name: "San Marino",
        calling_code: "378"
    },
    {
        country_code: "SL",
        country_name: "Sierra Leone",
        calling_code: "232"
    },
    {
        country_code: "SC",
        country_name: "Seychelles",
        calling_code: "248"
    },
    {
        country_code: "KZ",
        country_name: "Kazakhstan",
        calling_code: "7"
    },
    {
        country_code: "KY",
        country_name: "Cayman Islands",
        calling_code: "1-345"
    },
    {
        country_code: "SG",
        country_name: "Singapore",
        calling_code: "65"
    },
    {
        country_code: "SE",
        country_name: "Sweden",
        calling_code: "46"
    },
    {
        country_code: "SD",
        country_name: "Sudan",
        calling_code: "249"
    },
    {
        country_code: "DO",
        country_name: "Dominican Republic",
        calling_code: "1-809"
    },
    {
        country_code: "DM",
        country_name: "Dominica",
        calling_code: "1-767"
    },
    {
        country_code: "DJ",
        country_name: "Djibouti",
        calling_code: "253"
    },
    {
        country_code: "DK",
        country_name: "Denmark",
        calling_code: "45"
    },
    {
        country_code: "VG",
        country_name: "British Virgin Islands",
        calling_code: "1-284"
    },
    {
        country_code: "DE",
        country_name: "Germany",
        calling_code: "49"
    },
    {
        country_code: "YE",
        country_name: "Yemen",
        calling_code: "967"
    },
    {
        country_code: "DZ",
        country_name: "Algeria",
        calling_code: "213"
    },
    {
        country_code: "UY",
        country_name: "Uruguay",
        calling_code: "598"
    },
    {
        country_code: "YT",
        country_name: "Mayotte",
        calling_code: "262"
    },
    {
        country_code: "UM",
        country_name: "United States Minor Outlying Islands",
        calling_code: "1"
    },
    {
        country_code: "LB",
        country_name: "Lebanon",
        calling_code: "961"
    },
    {
        country_code: "LC",
        country_name: "Saint Lucia",
        calling_code: "1-758"
    },
    {
        country_code: "LA",
        country_name: "Laos",
        calling_code: "856"
    },
    {
        country_code: "TV",
        country_name: "Tuvalu",
        calling_code: "688"
    },
    {
        country_code: "TW",
        country_name: "Taiwan",
        calling_code: "886"
    },
    {
        country_code: "TT",
        country_name: "Trinidad and Tobago",
        calling_code: "1-868"
    },
    {
        country_code: "TR",
        country_name: "Turkey",
        calling_code: "90"
    },
    {
        country_code: "LK",
        country_name: "Sri Lanka",
        calling_code: "94"
    },
    {
        country_code: "LI",
        country_name: "Liechtenstein",
        calling_code: "423"
    },
    {
        country_code: "LV",
        country_name: "Latvia",
        calling_code: "371"
    },
    {
        country_code: "TO",
        country_name: "Tonga",
        calling_code: "676"
    },
    {
        country_code: "LT",
        country_name: "Lithuania",
        calling_code: "370"
    },
    {
        country_code: "LU",
        country_name: "Luxembourg",
        calling_code: "352"
    },
    {
        country_code: "LR",
        country_name: "Liberia",
        calling_code: "231"
    },
    {
        country_code: "LS",
        country_name: "Lesotho",
        calling_code: "266"
    },
    {
        country_code: "TH",
        country_name: "Thailand",
        calling_code: "66"
    },
    {
        country_code: "TF",
        country_name: "French Southern Territories",
        calling_code: "3166-1"
    },
    {
        country_code: "TG",
        country_name: "Togo",
        calling_code: "228"
    },
    {
        country_code: "TD",
        country_name: "Chad",
        calling_code: "235"
    },
    {
        country_code: "TC",
        country_name: "Turks and Caicos Islands",
        calling_code: "1-649"
    },
    {
        country_code: "LY",
        country_name: "Libya",
        calling_code: "218"
    },
    {
        country_code: "VA",
        country_name: "Vatican",
        calling_code: "379"
    },
    {
        country_code: "VC",
        country_name: "Saint Vincent and the Grenadines",
        calling_code: "1-784"
    },
    {
        country_code: "AE",
        country_name: "United Arab Emirates",
        calling_code: "971"
    },
    {
        country_code: "AD",
        country_name: "Andorra",
        calling_code: "376"
    },
    {
        country_code: "AG",
        country_name: "Antigua and Barbuda",
        calling_code: "1-268"
    },
    {
        country_code: "AF",
        country_name: "Afghanistan",
        calling_code: "93"
    },
    {
        country_code: "AI",
        country_name: "Anguilla",
        calling_code: "1-264"
    },
    {
        country_code: "VI",
        country_name: "U.S. Virgin Islands",
        calling_code: "1-340"
    },
    {
        country_code: "IS",
        country_name: "Iceland",
        calling_code: "354"
    },
    {
        country_code: "IR",
        country_name: "Iran",
        calling_code: "98"
    },
    {
        country_code: "AM",
        country_name: "Armenia",
        calling_code: "374"
    },
    {
        country_code: "AL",
        country_name: "Albania",
        calling_code: "355"
    },
    {
        country_code: "AO",
        country_name: "Angola",
        calling_code: "244"
    },
    {
        country_code: "AQ",
        country_name: "Antarctica",
        calling_code: "672"
    },
    {
        country_code: "AS",
        country_name: "American Samoa",
        calling_code: "1-684"
    },
    {
        country_code: "AR",
        country_name: "Argentina",
        calling_code: "54"
    },
    {
        country_code: "AU",
        country_name: "Australia",
        calling_code: "61"
    },
    {
        country_code: "AT",
        country_name: "Austria",
        calling_code: "43"
    },
    {
        country_code: "AW",
        country_name: "Aruba",
        calling_code: "297"
    },
    {
        country_code: "IND",
        country_name: "India",
        calling_code: "91"
    },
    {
        country_code: "AX",
        country_name: "Aland Islands",
        calling_code: "358-18"
    },
    {
        country_code: "AZ",
        country_name: "Azerbaijan",
        calling_code: "994"
    },
    {
        country_code: "IE",
        country_name: "Ireland",
        calling_code: "353"
    },
    {
        country_code: "ID",
        country_name: "Indonesia",
        calling_code: "62"
    },
    {
        country_code: "UA",
        country_name: "Ukraine",
        calling_code: "380"
    },
    {
        country_code: "QA",
        country_name: "Qatar",
        calling_code: "974"
    },
    {
        country_code: "MZ",
        country_name: "Mozambique",
        calling_code: "258"
    }
]