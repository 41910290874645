import { Box, Container, Typography } from "@mui/material";
import Tabs from "./Tabs";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

function Layout({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: null,
        paragraph: null,
        backButtonData: null,
        bannerHeight: "260px",
      },
    });
  }, [dispatch]);

  return (
    <Box mt={"-140px"} style={{ zIndex: "1000", position: "relative" }}>
      <Container maxWidth={"xl"}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Typography
            component="h1"
            sx={{
              fontSize: "24px",
              fontWeight: "800",
              color: "#191919",
              lineHeight: "36px",
            }}
          >
            Purchase History
          </Typography>
          <Tabs />
        </Box>
        {children}
      </Container>
    </Box>
  );
}

export default Layout;
