import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { pdfjs } from "react-pdf";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Loader from "../Common/Loader";
import { getPdfUrlUnSigned } from "../../utils/common";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfViewer({ fileUrl, allowDownload }) {
  const licenseKey = process.env.REACT_PDF_VIEWER_LICENSE_KEY;

  const transform = (slot) => ({
    ...slot,
    Download: allowDownload ? slot.Download : () => <></>,
    DownloadMenuItem: allowDownload ? slot.DownloadMenuItem : () => <></>,
    Print: allowDownload ? slot.Print : () => <></>,
    Open: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    // SwitchTheme: () => <></>,
    // SwitchThemeMenuItem: () => <></>,
  });

  const dispatch = useDispatch();
  const { initialReportBuffer, currentLibrarySlide } = useSelector((state) => state);

  const [defaultScale] = useState(1.25); // Set default zoom level to 125%
  const [modifiedFileUrl, setModifiedFileUrl] = useState(null);

  const renderToolbar = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    defaultScale: "PageFit",
  });
  const { renderDefaultToolbar } =
    defaultLayoutPluginInstance.toolbarPluginInstance;

  const handleDoc = (e) => {
    const { activateTab } = defaultLayoutPluginInstance;
    activateTab(0);
  };

  useEffect(() => {
    const unsignedUrl = getPdfUrlUnSigned(fileUrl);
    if (initialReportBuffer && initialReportBuffer[unsignedUrl]) {
      setModifiedFileUrl(initialReportBuffer[unsignedUrl]);
    } else {
      // Replace URL key if needed
      const modifiedFileUrl = fileUrl?.includes(
        "lorien-live-reports.s3.us-west-2.amazonaws.com"
      )
        ? fileUrl.replace(
            "lorien-live-reports.s3.us-west-2.amazonaws.com",
            "lorien-live.mordorintelligence.com"
          )
        : fileUrl;
      setModifiedFileUrl(modifiedFileUrl);
    }
    return () => {
      // THIS MIGHT NOT WORK AS EXPECTED IN DEV MODE SINCE REACT RE-RERENDERS THE COMPONENTS
      dispatch({
        type: "SET_CURRENT_LIBRARY_SLIDE",
        payload: 0,
      })
    }
  }, [fileUrl, initialReportBuffer]);

  if (!modifiedFileUrl) {
    return <Loader />;
  }

  return (
    <Worker
      workerUrl={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`}
    >
      <div style={{ height: "90vh" }}>
        <div
          className="rpv-core__viewer"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            height: "900px",
          }}
        >
          <div style={{ flex: 1, overflow: "hidden" }}>
            <Viewer
              theme="dark"
              fileUrl={modifiedFileUrl}
              plugins={[defaultLayoutPluginInstance]}
              licenseKey={licenseKey}
              defaultScale="PageFit"
              onDocumentLoad={handleDoc}
              initialPage={currentLibrarySlide}
            />
          </div>
        </div>
      </div>
    </Worker>
  );
}

export default PdfViewer;
