import { getReports } from "../../STATE";
import styles from "../../styles.module.css";
import { useEffect, useRef } from "react";
import { useReportsContext } from "../../STATE";
import { Divider } from "@mui/material";
import { getTabBasedOnLibrary } from "../../reports.utils";

function FilterModal({ setOpenFilter, children }) {
  const { state, dispatch } = useReportsContext();

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenFilter(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenFilter]);

  const handleFiltersApply = () => {
    dispatch({ type: `SET_${getTabBasedOnLibrary(state.currentTab, state.isLibraryReports)}_LOADING`, payload: "PENDING" });
    dispatch({
      type: "CREATE_FILTER_QUERY",
      fn: async (state) => getReports(state, dispatch),
    });
    setOpenFilter(false);
  };

  const clearSelection = () => {
    dispatch({ type: `SET_${getTabBasedOnLibrary(state.currentTab, state.isLibraryReports)}_LOADING`, payload: "PENDING" });
    dispatch({ type: "SET_CATALOGUE_INSTANT_ACCESS", payload: false });
    dispatch({
      type: "CLEAR_FILTER",
      fn: async (state) => getReports(state, dispatch),
    });
    setOpenFilter(false);
  };

  return (
    <section ref={modalRef} className={styles.filtersContainer}>
      <section className={styles.topFilterSection}>
        <p>Filter Reports</p>
      </section>
      <Divider />
      <section>{children}</section>
      <Divider />
      <section className={styles.bottomFilterSection}>
        <button onClick={clearSelection}>Clear All</button>
        <button onClick={handleFiltersApply} className={styles.activeFilterBtn}>
          Apply
        </button>
      </section>
    </section>
  );
}

export default FilterModal;
