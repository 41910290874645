import { Button, Box } from "@mui/material";
import { FileDownload, PrintOutlined } from "@mui/icons-material";
import { useState } from "react";
import mordor_icon from "../../../../utils/img/Mi_logo.png";
import html2PDF from "jspdf-html2canvas";
import { useReactToPrint } from "react-to-print";

function DevelopmentDownload({ tableRef }) {

  const [pdfLoading, setPdfLoading] = useState(false);

  let generatePrint = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: `Recent-Developments`,
  });

  const generatePdfFileFromTable = () => {
    const content = document.getElementById("printableTable");
    html2PDF([content], {
      jsPDF: {
        format: "a4",
      },
      imageType: "image/png",
      margin: {
        top: 18,
        bottom: 14,
        right: 5,
        left: 5,
      },

      watermark: {
        src: mordor_icon,
        handler({ pdf, imgNode, pageNumber, totalPageNumber }) {
          pdf.setTextColor("rgb(90,90,90)");
          pdf.setFontSize(7);

          pdf.text(
            pdf.internal.pageSize.width - 24,
            pdf.internal.pageSize.height - 3,
            `${pageNumber}/${totalPageNumber}`
          );

          pdf.text(5, 12, `Source: Mordor Intelligence`);
          pdf.addImage(
            imgNode,
            "PNG",
            pdf.internal.pageSize.width - 25,
            0,
            20,
            20
          );
        },
      },

      init: function () {
        setPdfLoading(true);
      },
      success: function (pdf) {
        pdf.save("Recent-Developments.pdf");
        setPdfLoading(false);
      },
    });
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        gap: "2rem",
      }}
    >
      <Button
        variant="outlined"
        color="primary"
        sx={{
          borderRadius: "5px",
          border: "1px solid rgb(0, 47, 117)",
          color: "rgb(0, 47, 117)",
          textTransform: "none",
          fontSize: "14px",
        }}
        startIcon={<FileDownload />}
        onClick={generatePdfFileFromTable}
        disabled={pdfLoading}
      >
        Download PDF
      </Button>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          borderRadius: "5px",
          border: "1px solid rgb(0, 47, 117)",
          color: "rgb(0, 47, 117)",
          textTransform: "none",
          fontSize: "14px",
        }}
        startIcon={<PrintOutlined />}
        onClick={generatePrint}
      >
        Print
      </Button>
    </Box>
  );
}

export default DevelopmentDownload;
