import { Box } from "@mui/material";
import Search from "./Search";
import Anytime from "./Anytime";
import Sort from "./Sort";
import LibrarySwitch from "./LibrarySwitch";

function Filters() {
  return (
    <Box
      sx={{
        marginBlock: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.8rem",
        justifyContent: "flex-start",
      }}
    >
      <LibrarySwitch />
      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <Search />
        <Anytime />
        <Sort />
      </Box>
    </Box>
  );
}

export default Filters;
