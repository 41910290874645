import ReportLayout from "./ReportLayout";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import DatasheetDetailsTab from "./Datasheet";
import ReportDetailsTab from "./ReportDetailsTab";
import Loader from "../../components/Common/Loader";
import { Alert, AlertTitle, Snackbar, Box } from "@mui/material";
import { SYNAPSE_API } from "../../api/Api";
import { getAllRequiredDetailsForReport, doNotShowMyRaBtnForTheseLibraryReports } from "./fetchData";
import { useDispatch } from "react-redux";
import AnswerEngine from "../AnswerEngine/AnswerEngine";
import  { downloadPdfFromUrlAndMemoized, MyRABtn } from "../../utils/common";

function ArchiveReportDetails() {
  const dispatch = useDispatch();

  const { id: hub, slug } = useParams();

  const [currentTab, setCurrentTab] = useState(0);
  const [, setReportId] = useState("");
  const [, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [detailsData, setDetailsData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [reportTabsToRender, setReportTabsToRender] = useState([]);
  const [triggerApiCall, setTriggerApiCall] = useState(true);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const moveToTop = setTimeout(() => {
      window.scroll(0, 350);
    }, 0);
    return () => {
      clearTimeout(moveToTop);
    };
  }, [currentTab]);

  const fetchReport = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchReportDataBySlug(slug, setSnackbarOpen);
      downloadPdfFromUrlAndMemoized(data?.custom?.report_pdfs[0]?.url)
        .then((pdf) => {
          dispatch({
            type: "SET_INITIAL_REPORT_BUFFER",
            payload: {
              url: data?.custom?.report_pdfs[0]?.url,
              data: pdf,
            }
          });
        })
      let tabsToShow = [];

      // ONLY FOR UPLOADED REPORTS
      if(data.upload_id){
        tabsToShow = await getAllRequiredDetailsForReport(
          data,
          setTriggerApiCall,
          dispatch,
          setCurrentTab,
        );
      }

      if (data === null) {
        setReportTabsToRender([]);
        setCurrentTab(null);
        return;
      }

      if (data?.custom.report_pdfs.length > 0 || data?.ppt_url.length > 0) {
        tabsToShow.push({
          name: "Report",
          component: (
            <ReportDetailsTab
              id={data?.synapse_report_id}
              title={data?.title}
              reportPdf={data?.custom.report_pdfs || []}
              reportPpt={data?.ppt_url || []}
              subscribed={data?.subscribed === "yes"}
              fetchReport={fetchReport}
            />
          ),
        });
      }

      if (data?.custom.report_excel.length > 0) {
        tabsToShow.push({
          name: "Data Sheet",
          component: (
            <DatasheetDetailsTab
              reportExcel={data?.custom?.report_excel}
              subscribed={data?.subscribed === "yes"}
              title={data.title}
              id={data.synapse_report_id}
              getReportData={setTriggerApiCall}
            />
          ),
        });
      }

      if(data.myra_chat_available && data?.custom.report_pdfs.length > 0) {
        // SOME EXCEPTIONS IN WHICH MYRA BUTTON SHOULD NOT BE SHOWN FOR THESE LIBRARY REPORTS
        if(doNotShowMyRaBtnForTheseLibraryReports.includes(data.synapse_report_id) === false) {
          tabsToShow.push({
            name: <MyRABtn />,
            component: (
              <AnswerEngine
                subscribed={data?.subscribed === "yes"}
                fetchReport={fetchReport}
                libraryReportId={data.synapse_report_id}
                libraryReportPurchased={data?.subscribed}
                title={data.title}
                id={data.synapse_report_id}
                from={"library"}
              />
            ),
          });
        } 
      }

      setReportTabsToRender(tabsToShow.map((tab) => tab.name));
      setDetailsData(tabsToShow);
      setReportId(data?.report_id);
      setIsSubscribed(data?.subscribed === "yes" ? true : false);
      setReportData(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    fetchReport();
  }, [triggerApiCall,slug]);

  const DoesCurrentTabExist = ({ toRender }) => {
    if (reportTabsToRender.length === 0) return <InvalidReportUi />;
    if (toRender) {
      setCurrentTab(0);
      return toRender;
    }
    return <InvalidReportUi />;
  };

  const createTabBasedOnReport = (currentTab) => {
    const tabFactory = {};
    detailsData.forEach((tab, i) => {
      tabFactory[i] = tab.component;
    });

    return (
      tabFactory[currentTab] ?? <DoesCurrentTabExist toRender={tabFactory[0]} />
    );
  };

  return (
    <ReportLayout
      {...{
        setCurrentTab,
        currentTab,
        hub,
        reportTabsToRender,
        reportData,
        fetchReport,
        loading,
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <Box
          component="main"
          sx={{
            display: "flex",
            justifyContent: " center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%", maxWidth: 
            "1680px", padding: "1rem" }}>
            {createTabBasedOnReport(currentTab)}
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleSnackbarClose} severity="error">
              <AlertTitle>Error</AlertTitle>
              Report not found — <strong>check it out!</strong>
            </Alert>
          </Snackbar>
        </Box>
      )}
    </ReportLayout>
  );
}

export default ArchiveReportDetails;

const fetchReportDataBySlug = async (slug, setSnackbarOpen) => {
  try {
    let reportData = await SYNAPSE_API.get(`/api/archive-report-by-slug/${slug}`);
    return reportData?.data?.data;
  } catch (error) {
    setSnackbarOpen(true);
    console.error(error);
    return null;
  }
};

const InvalidReportUi = () => {
  return (
    <Box
      component="center"
      sx={{
        height: "30vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>This Report is invalid or some error has occurred</h1>
    </Box>
  );
};
