import React, { useState } from "react";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import Notification from "../../components/Common/Notification";
import UserAccessHOC from "../../components/HOC/UserAccess";
import styles from "./styles.module.css";
import { LinearProgress } from "@mui/material";
import PasswordValidation from "../../components/Common/PasswordValidation";
import { useParams, useNavigate } from "react-router-dom";
import DialogModal from "../../components/Common/DialogModal";
import PasswordVisibilityInput from "../../components/Common/PasswordVisibiltyInput";
import API from "../../api/Api";
import LabelInputField from "../../components/Common/LabelInputField";
const LinkSendImg =
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/link_sent.svg";

function NewPassword() {
  const { key } = useParams();
  const navigate = useNavigate();

  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState(errorsState);
  const [loading, setLoading] = useState("IDEAL");
  const [open, setOpen] = useState(false);
  const { notify, setNotify } = useAuth();
  const { Base_URL } = API;

  const { password, confirm_password } = formState;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("PENDING");
    await axios
      .post(Base_URL + "/api/users/forgot-password/" + key, {
        password,
      })
      .then((response) => {
        setFormState(initialState);
        setErrors(errorsState);
        setOpen(true);
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 3000);
      })
      .catch((error) => {
        console.error(error, "err");
        setNotify({
          isOpen: true,
          message: error.response.data?.message || "Some error occurred",
          type: "error",
          position: "right",
        });
      })
      .finally(() => {
        setLoading("IDEAL");
      });
  };

  const handleFormChange = (e) => {
    let error = errors;
    let value = e.target.value.trim();

    switch (e.target.name) {
      case "password":
        error = {
          ...errors,
          password: true,
          disabled: value.length === 0,
        };
        setErrors(error);
        break;
      case "confirm_password":
        if (password !== value || value.length === 0)
          error = { ...errors, confirm_password: "Password not matched" };
        else error = { ...errors, confirm_password: false };
        break;
      default:
        break;
    }

    setErrors(error);

    const { confirm_password, passwordValid } = error;
    if (
      typeof confirm_password === "boolean" &&
      !confirm_password &&
      passwordValid
    ) {
      setErrors((p) => ({ ...p, disabled: false }));
    } else {
      setErrors((p) => ({ ...p, disabled: true }));
    }

    setFormState((p) => ({
      ...p,
      [e.target.name]: value,
    }));
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <div className={styles.detailsBox}>
        <h1>Set a New Password</h1>
        <div className={styles.inputContainer}>
          <form onSubmit={handleSubmit}>
            <LabelInputField>
              <LabelInputField.Label>
                New Password
                <LabelInputField.Tooltip value={"Enter valid password"} />
              </LabelInputField.Label>
              <LabelInputField.CustomInput>
                <PasswordVisibilityInput
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => handleFormChange(e)}
                />
              </LabelInputField.CustomInput>
              <LabelInputField.CustomError>
                {errors.password && (
                  <PasswordValidation
                    password={password}
                    setErrors={setErrors}
                  />
                )}
              </LabelInputField.CustomError>
            </LabelInputField>
            <LabelInputField>
              <LabelInputField.Label>
                Confirm Password
                <LabelInputField.Tooltip value={"Confirm the password"} />
              </LabelInputField.Label>
              <LabelInputField.CustomInput>
                <PasswordVisibilityInput
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm your password"
                  value={confirm_password}
                  onChange={(e) => handleFormChange(e)}
                />
              </LabelInputField.CustomInput>
              <LabelInputField.Error errors={errors.confirm_password} />
            </LabelInputField>
            <div className={styles.loginContainer}>
              <button
                type="submit"
                disabled={errors.disabled}
                className={errors.disabled ? `${styles.disabledBtn}` : ""}
                onClick={handleSubmit}
              >
                Save Password
              </button>
              {loading === "PENDING" && <LinearProgress />}
            </div>
          </form>
        </div>
      </div>
      <DialogModal open={open} handleClose={() => setOpen(false)}>
        <div className={styles.dialogModal}>
          <figure>
            <img src={LinkSendImg} alt="sent" />
          </figure>
          <h4>Password created successfully!</h4>
          <p>Redirecting to Login...</p>
        </div>
      </DialogModal>
    </>
  );
}

export default NewPassword;

const initialState = {
  password: "",
  confirm_password: "",
};

const errorsState = {
  ...initialState,
  password: false,
  disabled: true,
  passwordValid: false,
};
