import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { Email } from "@mui/icons-material";
import HubsHomeChatDialog from "../GlobalComponents/ContactUs";
import { useAuth } from "../../context/authContext/Auth";
import LockIcon from "../../assets/lock.svg";
import RenewDrawer from "../Common/RenewDrawer";

const LockBoxNew = ({
  sx,
  componentName,
  purchaseCustomText = "Purchase the hub to gain access.",
  reportType = "",
  report_id,
  title,
  toShowText,
  children,
  onclick = false,
}) => {
  const [open, setOpen] = useState(false);
  const { notify, setNotify } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 2px 20px 0px rgb(0 0 0 / 28%)",
          borderRadius: "8px",
          background: "#ffffff",
          textAlign: "center",
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          padding: "1rem",
          zIndex: "100",
          width: "auto",
          color: "black",
          ...sx,
        }}
      >
        {/* <img
          src={LockIcon}
          alt="lock"
          style={{
            fontSize: "30px",
            color: "#189CDE",
            marginBottom: "1rem",
            position: "absolute",
            left: "2px",
            width: "12px",
            top: "25px",
          }}
        /> */}
        {children}
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>Get access to unlock {componentName} </div>
          <div
            style={{
              background: "#d32f2f14",
              color: "#d32f2f",
              padding: "2px",
              borderRadius: "5px",
              fontWeight: "600",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!onclick) {
                setDrawerOpen(true);
              } else if (onclick() === false) {
                setDrawerOpen(true);
              } else {
                onclick();
              }
            }}
          >
            Click Here
          </div>
        </div>
      </Box>
      {reportType === "sample" ? (
        <RenewDrawer
          report_id={report_id}
          title={title}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          typeModal={"request sample"}
        />
      ) : (
        <RenewDrawer
          title={"Talk To Us"}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          typeModal={"contact"}
        />
      )}
      {/* <HubsHomeChatDialog
        open={open}
        setOpen={setOpen}
        notify={notify}
        setNotify={setNotify}
      /> */}
    </>
  );
};

export default LockBoxNew;
