import _ from "lodash";
import styles from "../../styles.module.css";
import { Box } from "@mui/material";
import { useReportsContext } from "../../STATE";
import Loader from "../../../../components/Common/Loader";

function RegionCountries({ filters }) {
  const { state, dispatch } = useReportsContext();

  const {
    modalFilter: { selectedRegion, selectedCountry },
    currentTab,
  } = state;

  const handleSelect = (e, type) => {
    const dataToSet = {};
    switch (type) {
      case "REGION":
        const regionSet = new Set(selectedRegion);
        if (e.target.checked) regionSet.add(e.target.name);
        else regionSet.delete(e.target.name);
        dataToSet.selectedRegion = Array.from(regionSet);
        break;
      case "COUNTRY":
        const countrySet = new Set(selectedCountry);
        if (e.target.checked) countrySet.add(e.target.name);
        else countrySet.delete(e.target.name);
        dataToSet.selectedCountry = Array.from(countrySet);
        break;
      default:
        return;
    }

    dispatch({
      type: "SET_FILTER_MODAL_DATA",
      payload: dataToSet,
    });
  };

  if (state.filterLoading === "PENDING") {
    return (
      <Box
        sx={{
          height: "fit-content",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader sx={{ height: "30vh" }} />
      </Box>
    );
  }

  return (
    <Box className={styles.middleFilterSection}>
      <Box>
        <h6>By Region</h6>
        <section>
          {_.sortBy(filters.regions)
          .filter((region) => {
            if (!filters.count.regions[region] || filters.count.regions[region] === 0) {
              return false;
            }
            return true;
          })
          .map((region, i) => {
            return (
              <Box key={i}>
                <input
                  type="checkbox"
                  onChange={(e) => handleSelect(e, "REGION")}
                  name={region}
                  checked={selectedRegion.includes(region)}
                />
                <label htmlFor={region}>{region}</label>
                {currentTab === "REPORTS_LIBRARY" && <Box sx={{ color: "rgb(135, 135, 135)", fontSize: "14px"}}>{`(${filters.count.regions[region]})`}</Box>}
              </Box>
            );
          })}
        </section>
      </Box>
      <Box>
        <h6>By Country</h6>
        <section>
          {_.sortBy(filters.countries)
            .filter((country) => {
              if (!filters.count.countries[country] || filters.count.countries[country] === 0) {
                return false;
              }
              return true;
            })
            .map((country, i) => {
              return (
                <Box key={i}>
                  <input
                    type="checkbox"
                    onChange={(e) => handleSelect(e, "COUNTRY")}
                    name={country}
                    checked={selectedCountry.includes(country)}
                  />
                  <label htmlFor={country}>{country}</label>
                  {currentTab === "REPORTS_LIBRARY" && <Box sx={{ color: "rgb(135, 135, 135)", fontSize: "14px"}}>{`(${filters.count.countries[country]})`}</Box>}
                </Box>
              );
            })}
        </section>
      </Box>
    </Box>
  );
}

export default RegionCountries;
