import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../context/authContext/Auth";
import { Box } from "@mui/material";
import API from "../../api/Api";
import PdfViewer from "./PdfViewer";

const HubsReportShowPdf = ({ report_id, showDownload }) => {

  const [pdfUrl, setPdfUrl] = useState(report_id);
  const [, setLoading] = useState(false);

  const { Base_URL } = API;
  const { config } = useAuth();

  useEffect(() => {
    setPdfUrl(report_id);
  }, [report_id])

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu from appearing
  };

  // to get the data for report pdf
  useEffect(() => {
    async function getReportPdf() {
      setLoading(true);
      const bodyParameters = {
        reportId: report_id,
      };

      axios
        .post(`${Base_URL}/api/hubs/showReport`, bodyParameters, config)
        .then((res) => {
          if (typeof res.data.data === "object") {
            setPdfUrl(res.data?.data?.report_url);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          setLoading(false);
        });
    }

    // getReportPdf();
  }, []);

  if (!report_id) {
    return (
      <Box
        height="100vh"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}
      >
        No Report Found
      </Box>
    );
  }

  return (
    <Box onContextMenu={handleContextMenu} >
      <PdfViewer fileUrl={pdfUrl} allowDownload={showDownload} />
    </Box>
  );
};

export default HubsReportShowPdf;
