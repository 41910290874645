import { Chip, Box } from "@mui/material";
import { useCompanyContext } from "../STATE";

function Tags() {
  const { state, dispatch } = useCompanyContext();

  const handleGeographyDelete = (valueToDelete) => {
    const filterRegion = state.filters.geography.regions.filter(
      (region) => region !== valueToDelete
    );
    const filterCountry = state.filters.geography.countries.filter(
      (country) => country !== valueToDelete
    );
    dispatch({ type: "MOCK_LOADING", fn: dispatch });
    dispatch({
      type: "SET_REGION_COUNTRY",
      payload: { regions: filterRegion, countries: filterCountry },
    });
    dispatch({ type: "APPLY_FILTERS" });
  };

  const handleDeleteRevenue = (valueToDelete) => {
    const filterRevenue = state.filters.revenue.filter(
      (revenue) => revenue !== valueToDelete
    );
    dispatch({ type: "MOCK_LOADING", fn: dispatch });
    dispatch({ type: "SET_REVENUE", payload: filterRevenue });
    dispatch({ type: "APPLY_FILTERS" });
  };

  const handleDeleteEmployees = (valueToDelete) => {
    const filterEmployees = state.filters.employees.filter(
      (employee) => employee !== valueToDelete
    );
    dispatch({ type: "MOCK_LOADING", fn: dispatch });
    dispatch({ type: "SET_EMPLOYEES", payload: filterEmployees });
    dispatch({ type: "APPLY_FILTERS" });
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
      {state.filters.geography.regions.map((region) => (
        <TagChip
          label={region}
          value={region}
          handleDelete={() => handleGeographyDelete(region)}
        />
      ))}
      {state.filters.geography.countries.map((country) => (
        <TagChip
          label={country}
          value={country}
          handleDelete={() => handleGeographyDelete(country)}
        />
      ))}
      {state.filters.revenue.map((revenue) => (
        <TagChip
          label={`${revenue} Annual Revenue`}
          value={revenue}
          handleDelete={() => handleDeleteRevenue(revenue)}
        />
      ))}
      {state.filters.employees.map((employee) => (
        <TagChip
          label={`${employee} Employees`}
          value={employee}
          handleDelete={() => handleDeleteEmployees(employee)}
        />
      ))}
    </Box>
  );
}

export default Tags;

const TagChip = ({ label, value, handleDelete }) => {
  return (
    <Chip
      key={value}
      label={label}
      size="small"
      onDelete={() => handleDelete(value)}
      sx={{
        padding: "16px 4px",
        margin: "2px",
        backgroundColor: "#e0effa",
        border: "1px solid #446ba6",
        color: "#446ba6",
        "& .MuiChip-deleteIcon": {
          color: "#446ba6",
          "&:hover": { color: "#000000" },
        },
      }}
    />
  );
};
