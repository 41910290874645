import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../context/authContext/Auth";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useLocation } from "react-router-dom";
import API from "../../api/Api";
import { scrollToSection } from "../../utils/common";
import _ from "lodash";

function Sidebar({
  data,
  currentHubType,
  setHubsData,
  setMessage,
  setHubLoading,
  closeButton,
  handleClose,
  setSubCategoryName,
  subCategoryName,
  setNameBadge,
  nameBadge,
  setSubCategory,
  subCategory,
  apiCallState,
  bookmarkCheckApi,
  setBookmarkCheckApi,
}) {
  const [expanded, setExpanded] = useState(false);
  const [category, setCategory] = useState([]);
  // const [subCategory, setSubCategory] = useState([]);

  const location = useLocation();
  const URL = location.pathname;
  const { Base_URL } = API;

  // const [subCategoryValues, setSubCategoryValues] = useState([]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { config, setCountHubsBookmarks } = useAuth();

  async function checkHubsData() {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (windowWidth < 900) {
      handleClose();
    }
    setHubsData([]);
    setMessage("");
    setHubLoading(true);
    if (subCategory.length === 0) {
      setCategory([]);

      getHubsCard();
    } else {
      axios
        .get(
          // `https://api-subscription.mordorlabs.com/api/hubs/${currentHubType}?category=${category.toString()}&subCategory=${subCategory.toString()}`,
          `${Base_URL}/api/hubs/getCategorySubCategory?all=yes`,
          config
        )
        .then((res) => {
          if (typeof res.data.data === "object") {
            setHubsData(res.data?.data);
          } else {
            setMessage(res.data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setHubLoading(false);
        });
    }
  }

  async function getHubsCard(reset) {
    const currentPosition = window.scrollY;
    setHubsData([]);
    setMessage("");
    setHubLoading(true);
    let categoryCheck = false;
    if (subCategory.length === 0 || reset === "reset") {
      setCategory([]);
      setSubCategory([]);
      setSubCategoryName([]);
      setNameBadge([]);
      categoryCheck = true;
    }

    axios
      .post(
        // `https://api-subscription.mordorlabs.com/api/hubs/${currentHubType}?category=${""}&subCategory=${""}`,
        `${Base_URL}/api/hubs/hubList`,
        {
          all: currentHubType,
          category: categoryCheck ? [] : category,
          sub_category: categoryCheck ? [] : subCategory,
        },
        config
      )
      .then((res) => {
        if (typeof res.data.data === "object") {
          const resData = res.data?.data;
          if (URL === "/bookmarks") {
            const bookmarksData = resData.filter(
              (item) => item?.isBookmarked === true
            );
            setHubsData(bookmarksData);
            setCountHubsBookmarks(bookmarksData.length);
          } else {
            setHubsData(res.data?.data);
          }
        } else {
          setMessage(res.data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setHubLoading(false);
        // setBookmarkCheckApi(true);
      });
  }

  // useEffect(() => {
  //   getHubsCard();
  // }, []);

  useEffect(() => {
    // checkHubsData();
    getHubsCard();
  }, [currentHubType, apiCallState, bookmarkCheckApi]);

  // useEffect(() => {
  //   // checkHubsData();
  //   if (!bookmarkCheckApi) {
  //     getHubsCard();
  //   }
  // }, [bookmarkCheckApi]);

  // Here we are going to use the SideItem function here ⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️

  // It will check all the select subCategory

  const handleChange1 = (isChecked, items, itemsId) => {
    const value = items.map((item) => item.id);
    const name = items.map((item) => item.title);

    const newVal = value.filter((item) => !subCategory.includes(item));
    const newName = name.filter((item) => !subCategoryName.includes(item));
    const categoryIndex = category.indexOf(itemsId);

    // if (categoryIndex === -1) {
    //   setCategory((state) => [...state, itemsId]);
    // } else {
    //   setCategory(category.filter((id) => id !== itemsId));
    // }
    if (JSON.stringify(value) === JSON.stringify(newVal)) {
      if (categoryIndex === -1) {
        setCategory((state) => [...state, itemsId]);
      } else {
        setCategory(category.filter((id) => id !== itemsId));
      }
    }

    if (isChecked) {
      setSubCategory((prev) => [...prev, ...newVal]);
      setNameBadge((prev) => [...prev, ...newName]);
    } else {
      setSubCategory(subCategory.filter((val) => !value.includes(val)));
      setNameBadge(subCategoryName.filter((val) => !name.includes(val)));
    }
  };

  //   it will check Single subCategory

  const handleChange2 = (isChecked, subId, mainId, subData, title) => {
    const index = subCategory.indexOf(subId);
    const categoryIndex = category.indexOf(mainId);

    if (categoryIndex === -1) {
      setCategory((state) => [...state, mainId]);
    } else {
      setCategory((state) => [...state]);
    }

    if (isChecked) {
      setSubCategory((state) => [...state, subId]);
      setNameBadge((state) => [...state, title]);
    } else {
      setSubCategory(subCategory.filter((value) => value !== subId));
      setNameBadge(subCategoryName.filter((value) => value !== title));
    }
  };

  const [categoryApplied, setCategoryApplied] = useState(false);

  const handleApplyButton = () => {
    setCategoryApplied(true);
    getHubsCard();
    setExpanded(false);
    setSubCategoryName(nameBadge);
  };

  useEffect(() => {
    if (!categoryApplied) return;
    const moveTo = setTimeout(() => {
      scrollToSection({ id: "hubs-search-field", modifyTop: 80 });
      setCategoryApplied(false);
    }, 300);
    return () => clearTimeout(moveTo);
  }, [categoryApplied]);

  // Here we are going to use the SideItem function here ⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        mb: "350px",
        // mt: "6px",
        width: "318px",
        position: "sticky",
        top: "80px", // Stick to the top
      }}
    >
      <Box boxShadow="0 2px 20px 0 rgba(0, 47, 117, 0.08)" borderRadius="8px">
        <Box
          width="100%"
          // my={3}
          height="75px"
          padding="5px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography ml={2} fontWeight={800} sx={{ fontSize: "16px" }}>
            Categories
          </Typography>
          <Button
            onClick={() => {
              setCategory([]);
              setSubCategory([]);
              getHubsCard("reset");
              scrollToSection({ id: "hubs-search-field", modifyTop: 80 });
              const windowWidth =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
              if (windowWidth < 900) {
                handleClose();
              }
            }}
            sx={{
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              color: "#189CDE",
            }}
            startIcon={
              <RestartAltOutlinedIcon
                sx={{ fontSize: "24px", width: "24px", height: "24px" }}
              />
            }
          >
            Reset All
          </Button>
          {closeButton}
        </Box>
        {/* <SidebarItems
        data={data}
        checkHubsData={checkHubsData}
        category={category}
        setCategory={setCategory}
        setSubCategory={setSubCategory}
        subCategory={subCategory}
      /> */}
        {data?.map((items) => {
          return items.reportsTotal !== 0 ? (
            <Accordion
              key={items.id}
              sx={{
                width: "100%",
              }}
              expanded={expanded === items.id}
              onChange={handleChange(items.id)}
            >
              <AccordionSummary
                sx={{
                  "&.Mui-expanded": {
                    borderLeft: "4px solid #189CDE",
                    background: "#F0F7FC",
                    height: "74px",
                  },
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{`${items.title}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* <Box
                sx={{
                  pl: 3,
                  display: "flex",
                  alignItems:"center",
                  "&:hover": {
                    borderRight: "solid #1976d2 1px",
                    background: "#f1f1f1",
                  },
                }}
              >
                <FormControlLabel
                  sx={{ width: "30px", fontWeight: "400", fontSize: "14px", color: "#2D2D2D" }}
                  
                  control={
                    <Checkbox
                      sx={{ fontSize: "10px" }}
                      checked={items.subcategory.every((element) =>
                        subCategory.includes(element.id)
                      )}
                      onChange={(event) =>
                        handleChange1(
                          event.target.checked,
                          items.subcategory,
                          items.id
                        )
                      }
                    />
                  }
                />
                <Typography sx={{fontWeight:"400",fontSize:"14px",color:"#2D2D2D"}}>
                  All {" "}
                  <span style={{ color: "#878787" }}>{`(${items.reportsTotal} reports)`}</span>
                      </Typography>
              </Box> */}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mb: 1,
                  }}
                >
                  {subCategory &&
                    items.subcategory
                      .filter((item) => item.reportsTotal !== 0) // Filter out items with reportsTotal === 0
                      .map((item, i) => (
                        <Box
                          key={_.uniqueId()}
                          sx={{
                            pl: 3,
                            display: "flex",
                            alignItems: "center",
                            "&:hover": {
                              borderRight: "solid #1976d2 1px",
                              background: "#f1f1f1",
                            },
                          }}
                        >
                          <FormControlLabel
                            sx={{
                              width: "30px",
                            }}
                            key={item.id}
                            control={
                              <Checkbox
                                checked={subCategory.includes(item.id)}
                                onChange={(event) =>
                                  handleChange2(
                                    event.target.checked,
                                    item.id,
                                    items.id,
                                    items.subcategory,
                                    item.title
                                  )
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                          />
                          <Typography
                            sx={{
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "#2D2D2D",
                            }}
                          >
                            {`${item.title}`}{" "}
                            <span
                              style={{ color: "#878787" }}
                            >{`(${item.reportsTotal} reports)`}</span>
                          </Typography>
                        </Box>
                      ))}
                </Box>
                <Box sx={{ display: "flex", padding: "0px 24px 24px 24px" }}>
                  <Button
                    sx={{
                      backgroundColor: "white",
                      width: "127px",
                      height: "40px",
                      padding: "8px 0px 8px 0px",
                      textTransform: "none",
                    }}
                    onClick={() =>
                      handleChange1(false, items.subcategory, items.id)
                    }
                  >
                    Clear All
                  </Button>
                  <Button
                    onClick={handleApplyButton}
                    fullWidth
                    variant="contained"
                    sx={{
                      backgroundColor: "#189CDE",
                      width: "127px",
                      height: "40px",
                      padding: "8px 0px 8px 0px",
                      textTransform: "none",
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          ) : null;
        })}
      </Box>
    </Container>
  );
}

export default Sidebar;
