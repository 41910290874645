import { useCompanyContext } from "../../STATE";
import { useEffect, useState, useMemo } from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Checkbox,
  Button,
  Typography,
  List,
  ListItem,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "../../../../components/Common/Loader";

const ITEMS_PER_PAGE = 50;

const SelectionDropDown = ({ selectionFor, data, handleApplyAction, handleClearAllAction, handleTagDelete }) => {
  const { state: companyCtx } = useCompanyContext();

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [displayCount, setDisplayCount] = useState(ITEMS_PER_PAGE);

  const handleDelete = (valueToDelete) => {
    const filterItems = selectedItems.filter((item) => item.value !== valueToDelete);
    const filterCompanies = selectedCompanies.filter((company) => company.value !== valueToDelete);
    setSelectedItems(filterItems);
    setSelectedCompanies(filterCompanies);
    handleTagDelete(filterItems);
  };

  // Using useMemo to optimize filtering performance for large datasets
  const filteredCompanies = useMemo(() => {
    return data.filter((company) =>
      company.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [data, searchQuery]);

  // Reset display count when search query changes
  useEffect(() => {
    setDisplayCount(ITEMS_PER_PAGE);
  }, [searchQuery]);

  const handleCompanyToggle = (company) => {
    setSelectedCompanies((prev) =>
      prev.find((item) => item.value === company.value)
        ? prev.filter((c) => c.value !== company.value)
        : [...prev, company]
    );
  };

  const handleClearAll = () => {
    setSelectedCompanies([]);
    setSelectedItems([]);
    handleClearAllAction();
  };

  const handleApply = () => {
    setSelectedItems(selectedCompanies);
    setIsOpen(false);
    handleApplyAction(selectedCompanies);
  };

  const handleLoadMore = () => {
    setDisplayCount((prev) => prev + ITEMS_PER_PAGE);
  };

  // Get the current visible items
  const visibleCompanies = filteredCompanies.slice(0, displayCount);
  const hasMore = displayCount < filteredCompanies.length;

  return (
    <Box sx={{ position: "relative" }}>
      <Typography variant="subtitle1" my={1}>
        {selectionFor}
      </Typography>
      <Card elevation={1} sx={{ width: "100%" }}>
        <CardContent sx={{ padding: "0px !important" }}>
          {/* Header / Trigger */}
          <Box
            onClick={() => setIsOpen(!isOpen)}
            sx={{
              p: 2,
              minHeight: "4rem",
              height: "fit-content",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: isOpen ? 1 : 0,
              borderColor: "divider",
            }}
          >
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {selectedItems.length > 0 ? (
                selectedItems.map((item) => (
                  <Chip
                    key={item.value}
                    label={item.label}
                    size="small"
                    onDelete={() => handleDelete(item.value)}
                    sx={{
                      padding: "16px 4px",
                      margin: "2px",
                      backgroundColor: "#e0effa",
                      border: "1px solid #446ba6",
                      color: "#446ba6",
                      "& .MuiChip-deleteIcon": {
                        color: "#446ba6",
                        "&:hover": { color: "#000000" },
                      },
                    }}
                  />
                ))
              ) : (
                <Typography variant="body2" color={"#c4c4c4"}>
                  Search for {selectionFor}
                </Typography>
              )}
            </Box>
            <IconButton size="small">
              <ExpandMoreIcon
                sx={{
                  transform: isOpen ? "rotate(180deg)" : "rotate(0)",
                  transition: "transform 0.2s",
                }}
              />
            </IconButton>
          </Box>

          {/* Dropdown Content */}
          {isOpen && (
            <Box
              sx={{
                p: 2,
                position: "absolute",
                backgroundColor: "#fff",
                zIndex: 10,
                width: "100%",
                border: 1,
                borderColor: "#c4c4c4",
                borderRadius: 1,
                boxShadow: 1,
              }}
            >
              {/* Search Field */}
              <TextField
                fullWidth
                size="small"
                placeholder={`Search ${selectionFor}`}
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />

              {/* Company List */}
              <Box
                sx={{
                  maxHeight: 250,
                  overflow: "auto",
                }}
              >
                <List
                  sx={{
                    "& .MuiListItem-root": {
                      p: 0,
                      "&:hover": {
                        backgroundColor: "action.hover",
                      },
                    },
                  }}
                >
                  {visibleCompanies.map((company) => (
                    <ListItem key={company.value} disablePadding>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCompanies.some(
                              (item) => item.value === company.value
                            )}
                            onChange={() => handleCompanyToggle(company)}
                            size="small"
                          />
                        }
                        label={company.label}
                        sx={{
                          width: "100%",
                          m: 0,
                          py: 0.5,
                          px: 1,
                          "& .MuiFormControlLabel-label": {
                            fontSize: "0.875rem",
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>

                {/* Load More Button */}
                {hasMore && (
                  <Box sx={{ textAlign: "center", mt: 1 }}>
                    <Button
                      onClick={handleLoadMore}
                      color="primary"
                      sx={{
                        textTransform: "none",
                        fontSize: "0.875rem",
                      }}
                    >
                      Load More ({filteredCompanies.length - displayCount}{" "}
                      remaining)
                    </Button>
                  </Box>
                )}

                {/* Show total results */}
                {companyCtx.recentDevelopmentsCompaniesLoading === "PENDING" ? (
                  <Loader sx={{ height: "10vh" }} />
                ) : (
                  <Typography
                    variant="caption"
                    sx={{
                      display: "block",
                      textAlign: "center",
                      mt: 1,
                      color: "text.secondary",
                    }}
                  >
                    Showing {visibleCompanies.length} of{" "}
                    {filteredCompanies.length} items
                  </Typography>
                )}
              </Box>

              {/* Action Buttons */}
              <Divider sx={{ mt: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 4,
                  mt: 2,
                  pt: 2,
                }}
              >
                <Button
                  onClick={handleClearAll}
                  color="primary"
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    borderColor: "#c4c4c4",
                    border: 1,
                  }}
                  disabled={
                    selectedCompanies.length === 0 ||
                    companyCtx.loading === "PENDING"
                  }
                >
                  Clear All
                </Button>
                <Button
                  onClick={handleApply}
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    backgroundColor: "#189cde",
                  }}
                  disabled={
                    selectedCompanies.length === 0 ||
                    companyCtx.loading === "PENDING"
                  }
                >
                  Apply
                </Button>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default SelectionDropDown;
