import React, { useEffect } from "react";
import axios from "axios";
import Notification from "../Common/Notification";
import { useState } from "react";
import { Box, Typography, Button, LinearProgress } from "@mui/material";
import { useAuth } from "../../context/authContext/Auth";
import PasswordVisibilityInput from "../Common/PasswordVisibiltyInput";
import PasswordValidation from "../Common/PasswordValidation";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import API from "../../api/Api";
const LinkSendImg =
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/link_sent.svg";
const driftImg =
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/Vector_drift_design.svg";

function ChangePassword() {
  const { config, notify, setNotify } = useAuth();

  const [open, setOpen] = useState("RESET_PASSWORD");

  const dispatch = useDispatch();
  useEffect(() => {

    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: null,
        paragraph: null,
        bannerHeight: "0px",
        backButtonData: null, 
      },
    });
  }, [])

  const resetPasswordFactory = {
    RESET_PASSWORD: <PasswordContainer {...{ config, setNotify, setOpen }} />,
    PASSWORD_CHANGE: <PasswordChangeContainer />,
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        sx={{
          marginTop: "2rem",
          height: "100%",
          minHeight: "45rem",
          width: "100%",
          padding: "3% 1%",
          background:
            "linear-gradient(92.47deg, rgba(24, 156, 222, 0.33) -39.01%, #EAFFEA -38.99%, #EAF6FE 50.05%, #E3E1FC 136.46%), linear-gradient(83.11deg, rgba(24, 156, 222, 0.33) -52.63%, #EAFFEA -52.61%, #EAF6FE 61.6%, #E3E1FC 172.45%), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
        }}
      >
        <Box
          component="img"
          src={driftImg}
          alt="dft"
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
          }}
        />
        {resetPasswordFactory[open] ?? <>No Data</>}
      </Box>
    </>
  );
}

export default ChangePassword;

const PasswordContainer = ({ config, setNotify, setOpen }) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(errorState);
  const [loading, setLoading] = useState("IDLE");

  const { Base_URL } = API

  const { confirm_password, old_password, new_password } = formData;
  const handleFormChange = (e) => {
    let error = {};
    switch (e.target.name) {
      case "old_password":
        if (e.target.value.length < 8)
          error = {
            ...errors,
            old_password: {
              valid: true,
              message: "Password length should be atleast 8 characters",
            },
          };
        else error = { ...errors, old_password: { valid: false, message: "" } };
        break;
      case "new_password":
        error = { ...errors, new_password: { valid: true, message: "" } };
        setErrors(error);
        break;
      case "confirm_password":
        if (!e.target.value || new_password !== e.target.value)
          error = {
            ...errors,
            confirm_password: { valid: true, message: "Password not matched" },
          };
        else
          error = {
            ...errors,
            confirm_password: { valid: false, message: "" },
          };
        break;
      default:
        break;
    }
    setErrors(error);
    setFormData((p) => ({ ...p, [e.target.name]: e.target.value }));

    const { confirm_password, old_password, passwordValid } = error;
    if (
      typeof confirm_password.valid === "boolean" &&
      typeof old_password.valid === "boolean"
    ) {
      if (!confirm_password.valid && !old_password.valid) {
        passwordValid && setErrors((p) => ({ ...p, submit_disabled: false }));
      } else {
        setErrors((p) => ({ ...p, submit_disabled: true }));
      }
    }
  };

  const resetPassword = async (data) => {
    setLoading("PENDING");
    axios
      .patch(
        `${Base_URL}/api/users/reset-password`,
        data,
        config
      )
      .then(() => {
        setFormData(initialState);
        setErrors(errorState);
        setNotify({
          isOpen: true,
          message: "Successfully reset the password",
          type: "success",
          position: "right",
        });
        setOpen("PASSWORD_CHANGE");
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.response.data.message,
          type: "error",
          position: "right",
        });
      })
      .finally(() => {
        setLoading("IDLE");
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    resetPassword({ old_password, new_password });
  };

  return (
    <Box
      width="444px"
      height="fit-content"
      padding="32px"
      bgcolor="#fff"
      borderRadius="12px"
      boxShadow="0px 2px 20px 0px #002F7514"
      zIndex="1"
    >
      <Box component="section">
        <Typography
          variant="h2"
          sx={{
            font: "800 24px / 36px Avenir Roman",
            color: "#191919",
          }}
        >
          Change Password
        </Typography>
      </Box>
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          marginBlock: "2rem",
          "& > div": {
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            "& > label": {
              font: "800 14px / 21px Avenir Roman",
              color: "#2D2D2D",
            },
            "& > p": {
              color: "red",
              font: "400 14px / 21px Avenir Roman",
            },
          },
        }}
      >
        <Box>
          <label htmlFor="current_password">Current password</label>
          <PasswordVisibilityInput
            name="old_password"
            onChange={(e) => handleFormChange(e)}
            value={old_password}
            placeholder="Enter current password"
          />
          {errors.old_password.valid && (
            <Box component="p">{errors.old_password.message}</Box>
          )}
        </Box>
        <Box>
          <label htmlFor="new_password">New password</label>
          <PasswordVisibilityInput
            name="new_password"
            onChange={(e) => handleFormChange(e)}
            value={new_password}
            placeholder="Enter new password"
          />
          {errors.new_password.valid && (
            <PasswordValidation password={new_password} setErrors={setErrors} />
          )}
        </Box>
        <Box>
          <label htmlFor="confirm_password">Confirm Password</label>
          <PasswordVisibilityInput
            name="confirm_password"
            onChange={(e) => handleFormChange(e)}
            value={confirm_password}
            placeholder="Re-enter new password"
          />
          {errors.confirm_password.valid && (
            <Box component="p">{errors.confirm_password.message}</Box>
          )}
        </Box>
      </Box>
      <Box>
        <Button
          disabled={errors.submit_disabled}
          sx={{
            marginTop: "2rem",
            marginBottom: "1rem",
            width: "100%",
            height: "52px",
            borderRadius: "8px",
            padding: "8px 24px",
            backgroundColor: errors.submit_disabled ? "#D5D5D5" : "#189CDE",
            textTransform: "none",
            color: errors.submit_disabled ? "#878787" : "#fff",
            font: "800 16px / 25.6px Avenir Roman",
            "&:hover": {
              backgroundColor: errors.submit_disabled ? "#D5D5D5" : "#119CDF",
            },
          }}
          onClick={handleFormSubmit}
        >
          Save
        </Button>
      </Box>
      {loading === "PENDING" && <LinearProgress />}
    </Box>
  );
};

const PasswordChangeContainer = () => {
  return (
    <>
      <Box
        component="div"
        height="238px"
        width="504px"
        bgcolor="#fff"
        boxShadow="0px 2px 20px 0px #002F7514"
        borderRadius="8px"
        padding="24px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="1rem"
        zIndex="1"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
        >
          <Box
            component="img"
            src={LinkSendImg}
            alt="send"
            sx={{ width: "42.67px", height: "52.78px" }}
          />
          <Typography
            component="p"
            sx={{ font: "800 16px / 25.6px Avenir Roman" }}
          >
            Password changed successfully
          </Typography>
        </Box>
        <Box>
          <Link to="/reports">
            <Button
              sx={{
                bgcolor: "#189CDE",
                padding: "8px 24px",
                borderRadius: "8px",
                width: "456px",
                height: "52px",
                color: "#fff",
                font: "800 16px / 25.6px Avenir Roman",
                textTransform: "none",
                "&:hover": {
                  bgcolor: "#189CDE",
                },
              }}
            >
              Go to Reports
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

const initialState = {
  old_password: "",
  new_password: "",
  confirm_password: "",
};

const errorState = {
  old_password: { valid: "", message: "" },
  new_password: { valid: "", message: "" },
  confirm_password: { valid: "", message: "" },
  passwordValid: false,
  submit_disabled: true,
};
