import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import { useReportsContext } from "../../STATE";
import { useState } from "react";

function LibraryReportPublishYear() {

  const years = [];
  for (let i = 2020; i <= new Date().getFullYear(); i++) {
    years.push({ label: i, value: i.toString() });
  }

  const { state, dispatch } = useReportsContext();
  const [selectedYears, setSelectedYears] = useState(
    new Set(state.libraryReportsFilter)
  );

  const handleLibraryReportPublishYearChange = (e) => {
    const value = e.target.value;
    if (selectedYears.has(value)) {
      selectedYears.delete(value);
    } else {
      selectedYears.add(value);
    }
    setSelectedYears(new Set(selectedYears));
    dispatch({
      type: "SET_LIBRARY_REPORTS_FILTER",
      payload: Array.from(selectedYears),
    });
  };

  return (
    <Box
      p={2}
      sx={{
        maxHeight: "300px",
        overflowY: "auto",
      }}
    >
      <Typography>Report Publish Year</Typography>
      <FormGroup>
        {years?.map((year, i) => (
          <FormControlLabel
            key={i}
            control={<Checkbox />}
            label={year.label}
            value={year.value}
            checked={selectedYears.has(year.value)}
            onChange={handleLibraryReportPublishYearChange}
          />
        ))}
      </FormGroup>
    </Box>
  );
}

export default LibraryReportPublishYear;

