import React, { useEffect, useState } from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {FilterModalContainer, RegionCountryContainer, AnnualRevenueContainer, EmployeesContainer} from "./CompanyHome.styles";
import { RegionCountryConfig, NumberConversionConfig, NumberConversionConfig2, employeeOptionsArray, revenueOptionsArray } from "./CompanyHome.config";

function FilterModal(props){

 const [uniqueRegions, setUniqueRegions ] = useState([]);
 const [uniqueCountries, setUniqueCountries ] = useState([]);
 const [uniqueCountriesBasedOnRegion, setUniqueCountriesBasedOnRegion ] = useState([]);
 const [selectedRegions, setSelectedRegions ] = useState([]);
 const [selectedCountries, setSelectedCountries ] = useState([]);
 const [selectedRevenueRange, setSelectedRevenueRange ] = useState([]);
 const [selectedEmployeeRange, setSelectedEmployeeRange ] = useState([]);
 const [isRegionCountryContainerOpen, setIsRegionCountryContainerOpen] = useState(true);
 const [isAnnualRevenueContainerOpen, setIsAnnualRevenueContainerOpen] = useState(true);
 const [isEmployeesContainerOpen, setIsEmployeesContainerOpen] = useState(true);

 useEffect(()=>{
  getFiltersData();
}, []);

let getFiltersData = ()=>{
  let regions=[];
  let countries=[]; 
  let data = props.data.map((company)=>{
    if((company?.dimensions?.region)?.toLowerCase()?.trim() !== 'all'){
      regions.push(company?.dimensions?.region);
    }
     
    if((company?.dimensions?.country)?.toLowerCase()?.trim() !== 'all'){
      countries.push(company?.dimensions?.country);
    }

    if(props.filtersData.regionFilterData?.length){
      setSelectedRegions(props.filtersData.regionFilterData);
    }

    if(props.filtersData.countryFilterData?.length){
      setSelectedCountries(props.filtersData.countryFilterData)
    }

    if(props.filtersData.revenueFilterData?.length){
      let revData = [...props.filtersData.revenueFilterData].map((elm)=>{
        if(elm[0] === 50000 || elm[1] === 500000){
          return '> $ 50Bn';
         }
        let a = NumberConversionConfig[String(elm[0])]
        let b = NumberConversionConfig[String(elm[1])]
         return `$ ${a} - $ ${b}`
      })
    
      setSelectedRevenueRange(revData)
    }

    if(props.filtersData.employeeFilterData?.length){
    let empData = [...props.filtersData.employeeFilterData].map((elm)=>{
      if(elm[0] === 100001 || elm[1] === 1000000){
        return '> 100000';
       }
      return elm.join(' - ');
    })
    setSelectedEmployeeRange(empData);
    }

  });


   regions = getUniqueData(regions);
   regions = regions.sort();
   countries = getUniqueData(countries);
   countries = countries.sort();
   setUniqueRegions(regions);
   setUniqueCountries(countries);
   setUniqueCountriesBasedOnRegion(countries);
}

let getUniqueData = (arr)=>{
   arr = arr.map((e)=>{
      return String(e).replaceAll('&', 'And').replaceAll('-', ' ').split(' ').map((w)=>{
        return w[0].toUpperCase() + w.substring(1);
      }).join(' ');
   })

  var o = {},
      a = [];

  for (let i = 0; i< arr.length ;i++){
    let e= arr[i];
    if(e != "" && e != "Undefined"){
      o[e] = 1;
    }
  };

  for (let e in o) {a.push (e)};

  return a;
 }

 let onRegionSelect =(event, region)=>{
  let selectedReg = [...selectedRegions];
  let newCountriesArr = [];
  let newCoun = [];

  if(selectedReg.includes(region)){
    selectedReg = selectedReg.filter((elem)=> elem !== region );
 }else{
   selectedReg.push(region);
 }
setSelectedRegions(selectedReg);

  if(selectedReg.length === 0 || selectedReg.includes('All')){
    setSelectedCountries([]);
    setUniqueCountriesBasedOnRegion(uniqueCountries);
  }else{
      selectedReg.forEach((elem)=>{
         newCountriesArr.push(RegionCountryConfig[elem]);
      })

      newCountriesArr = newCountriesArr.flat(1);

    uniqueCountries.map((elem)=>{
      if(newCountriesArr.includes(elem)){
        newCoun.push(elem);
      }
     })

     setSelectedCountries([]);
     setUniqueCountriesBasedOnRegion(newCoun);
    }
 }


 let onCountrySelect =(event,country)=>{
  let selectedCoun = [...selectedCountries];
  if(selectedCoun.includes(country)){
    selectedCoun = selectedCoun.filter((elem)=> elem !== country );
  }else{
   selectedCoun.push(country);
 }
setSelectedCountries(selectedCoun);
}

 let onRevenueSelect =(event, range)=>{
  let data = [...selectedRevenueRange];
  if(data.includes(range)){
   data = data.filter((elem)=> elem !== range );
  }else{
   data.push(range);
  }
  setSelectedRevenueRange(data);
 }

let onEmployeeSelect =(event, range)=>{
  let data = [...selectedEmployeeRange]
 if(data.includes(range)){
  data = data.filter((elem)=> elem !== range );
 }else{
  data.push(range);
 }
 setSelectedEmployeeRange(data);
 }

let clearFilters  =()=>{
  setSelectedCountries([]);
  setSelectedRegions([]);
  setSelectedRevenueRange([]);
  setSelectedEmployeeRange([]);

  props.getCompaniesBasedOnFilters(
    {
      revenueFilterData: [],
      employeeFilterData:[],
      regionFilterData: [],
      countryFilterData:[],
    }
  );
}

let applyFilters  =()=>{
  let finalEmployeesRange =  [...selectedEmployeeRange].map((r)=>{
    if(r === '> 100000'){
      return [100001,1000000];
    }else{
      return r.split('-').map(n=> Number(n.trim()))
    }
   });
   
   let finalRevenueRange =  [...selectedRevenueRange].map((r)=>{
    if(r === '> $ 50Bn'){
      return [50000,500000];
    }else{
     let newStrArr =  r.replaceAll('$', '').replaceAll(' ', '').split('-').map(n=> NumberConversionConfig2[n]);
      return newStrArr;
    }
   });


   props.getCompaniesBasedOnFilters(
    {
      revenueFilterData: finalRevenueRange,
      employeeFilterData:finalEmployeesRange,
      regionFilterData: selectedRegions,
      countryFilterData: selectedCountries,
    }
  );

setTimeout(() => {
  props.setOpenFiltersModal(false)
}, 1000);
}

return(
<FilterModalContainer>
<div>
  <header>
  <h4>Filter By</h4>
  <span onClick={()=>{props.setOpenFiltersModal(false)}} >X</span>
  </header>
  <main>

<RegionCountryContainer>
  <div 
    onClick={()=>{
      setIsRegionCountryContainerOpen(!isRegionCountryContainerOpen)
    }}>
    <p>Region/Country</p>
    {isRegionCountryContainerOpen ? <ExpandLess/> : <ExpandMore/>}
    </div>
    <div className="select-dim"
         style={{display: isRegionCountryContainerOpen ? 'flex': 'none'}}  >
          <div className="select-region">
            <p>Regions</p>
            <div className="opt-box">
            <FormControlLabel onChange={(e)=>onRegionSelect(e,'All')} control={<Checkbox  />}  checked={selectedRegions.includes('All')}   label={'All'} />
            {
              uniqueRegions.length ? uniqueRegions.map((region)=>{
                return (
                <FormControlLabel sx={{width:"97%"}} onChange={(e)=>onRegionSelect(e,region)}
                 control={<Checkbox />} 
                 checked={selectedRegions.includes(region)}  label={region} />
                )
              }) : null
            }
            </div>
  
          </div>
          <div className="select-country">
          <p>Countries</p>
          <div className="opt-box">
          <FormControlLabel  onChange={(e)=>onCountrySelect(e,'All')} control={<Checkbox    />} 
          checked={selectedCountries.includes('All')}  label={'All'} />
          {
              uniqueCountriesBasedOnRegion.length ? uniqueCountriesBasedOnRegion.map((country)=>{
                return (<FormControlLabel  onChange={(e)=>onCountrySelect(e,country)} checked={selectedCountries.includes(country)}  control={<Checkbox />}   label={country} />)
              }) : null
            }
            </div>
            </div>
    </div>
</RegionCountryContainer>

<AnnualRevenueContainer>
  <div onClick={()=>{
        setIsAnnualRevenueContainerOpen(!isAnnualRevenueContainerOpen)}}>
    <p>Annual Revenue</p>
    {isAnnualRevenueContainerOpen ? <ExpandLess/> : <ExpandMore/>} 
  </div>
  <div 
    className="select-revenue" 
    style={{
     display: isAnnualRevenueContainerOpen ? 'flex' : 'none'
    }}>
   {
    revenueOptionsArray.map((range)=>{
      return <FormControlLabel control={<Checkbox />} label={range}  
              checked={selectedRevenueRange.includes(range)}
              onChange={(e)=>onRevenueSelect(e,range)} />
    })
   }      
  </div>       
</AnnualRevenueContainer>

<EmployeesContainer>
  <div 
    onClick={()=>{
      setIsEmployeesContainerOpen(!isEmployeesContainerOpen)}}>
    <p>Total Employees</p>
    {isEmployeesContainerOpen ? <ExpandLess/> : <ExpandMore/>} 
  </div>
  <div 
     className="select-revenue" 
     style={{
             display: isEmployeesContainerOpen ? 'flex' : 'none'
            }}>

    {
      employeeOptionsArray.map((range)=>{
        return <FormControlLabel control={<Checkbox />} label={range}
                checked={selectedEmployeeRange.includes(range)}
                onChange={(e)=>onEmployeeSelect(e,range)} />
      })
    }
  </div>
</EmployeesContainer>
</main>

<footer>
  <button className="clear-btn" onClick={clearFilters}>
    Clear All
  </button>
  <button className="apply-btn" onClick={applyFilters}>
    Apply
  </button>
</footer>

</div>
</FilterModalContainer>
  )
}

export default FilterModal;