import { Link, useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { Box, Tab, Button, Typography, Tabs } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ArrowLeftOutlined } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import Loader from "../../components/Common/Loader";
import CompanyHome from "../../components/CompaniesComponents/CompanyHome/index";
import CompanyMarketShare from "../../components/CompaniesComponents/CompanyMarketShare";
import CompanyRecentDevelopment from "../../components/CompaniesComponents/CompanyRecentDevelopment/CompanyRecentDevelopment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import LockBox from "../../components/LockBox";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function Companies() {
  const dispatch = useDispatch();
  const { companiesPage } = useSelector((state) => state);

  const [value, setValue] = useState("1");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [marketShareData, setMarketShareData] = useState();
  const [activeTab, setActiveTab] = useState("companies-covered");
  const [currentCat, setCurrentCat] = useState(null);
  const [currentComp, setCurrentComp] = useState(null);
  const [companiesCovered, setCompaniesCovered] = useState(0);
  const [checkRes, setCheckRes] = useState(false);

  const { config } = useAuth();

  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    dispatch({
      type: "SET_NAVBAR_DATA",
      payload: {
        heading: null,
        paragraph: null,
        backButtonData: null,
        talkToUs: null,
        downloadBrochure: null,
        companiesPage: true,
        bannerHeight: "300px",
      },
    });
  }, []);

  // const { id } = location.state;

  // here we are getting the tab Value in sessionStorage

  useEffect(() => {
    // Retrieve data from session storage when component mounts
    if (state) {
      const storedData = sessionStorage.getItem("companiesId");
      setValue(storedData ? storedData : "1");
    } else {
      const data = sessionStorage.getItem("companies");
      setValue(data ? data : "1");
    }
  }, []);

  // newCode

  const location = useLocation();

  const urlArray = location.pathname.split("/");
  const hubName = urlArray[2]?.toUpperCase()?.replace(/-/g, " ");
  //Remove the hyphen from the hub name
  useEffect(() => {
    if (!state && urlArray.length > 3) {
      navigate("/hubs");
    }
  }, [navigate]);

  // newCode

  // here we are saving the tab Value in sessionStorage

  const handleChange = (event, newValue) => {
    if (state) {
      sessionStorage.setItem("companiesId", newValue);
      setValue(newValue);
    } else {
      sessionStorage.setItem("companies", newValue);
      setValue(newValue);
    }
  };

  // useEffect(() => {
  // async function getInfo() {
  //   setLoading(true);
  //   const newId = state ? state.id : "";

  //   axios
  //     .get(
  //       `https://api-subscription.mordorlabs.com/api/company/showCompanies?hubId=${newId}`,
  //       // bodyParameters,
  //       config
  //     )
  //     .then((res) => {
  //       setData(res?.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }
  // getInfo();
  // }, []);

  const activeButtonStyle = {
    background: "rgba(68, 107, 166, 1)",
    color: "#fff",
    height: "40px",
    display: "inline-block",
    padding: "8px, 24px, 8px, 24px",
    borderRadius: "8px",
    margin: "16px 16px 24px 0",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "500",
    border: "1px solid red !important",
  };

  const buttonStyle = {
    color: "rgba(90, 90, 90, 1)",
    textTransform: "capitalize",
    display: "inline-block",
    padding: "8px, 24px, 8px, 24px",
    margin: "16px 16px 24px 0",
    fontSize: "16px",
    fontWeight: "500",
  };

  const dotStyles = {
    display: "inline-block",
    height: "4px",
    width: "4px",
    background: "#000",
    borderRadius: "50%",
    margin: "0 10px",
  };

  const spanStyles = {
    color: "rgba(45, 45, 45, 1)",
    marginRight: "5px",
  };

  let handleTab = (type) => {
    setActiveTab(type);
  };

  let setCurrentCatagory = (item) => {
    setCurrentCat(item);
    setCurrentComp(null);
  };

  let setCurrentCompany = (item) => {
    setCurrentComp(item);
    setCurrentCat(null);
  };

  let handleBcakNavigation = () => {
    if (currentComp) {
      window.location.reload();
      setCurrentCat(null);
      setCurrentComp(null);
    } else {
      // window.location.reload();
      setCurrentCat(null);
      setCurrentComp(null);
    }
  };

  let res = "";

  if (currentCat) {
    res = currentCat?.parent;
  } else if (currentComp) {
    res = currentComp?.currCat?.path;
  }

  res = res
    ?.split("/")
    ?.map((str) => {
      if (str != "") {
        return str[0].toUpperCase() + str.slice(1) + " Companies";
      } else {
        return "";
      }
    })
    .join(" / ")
    .split("-")
    ?.map((str) => {
      if (str != "") {
        return str[0].toUpperCase() + str.slice(1);
      } else {
        return "";
      }
    })
    .join(" ");

  res = res?.trim();

  if (res?.startsWith("/")) {
    res = res?.replace("/", "");
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            width: "100%",
          }}
          className={checkRes && "blur"}
        >
          <div
            style={{
              width: "100%",
              maxWidth: "1710px",
              margin: "0 auto",
              marginTop: "-120px",
              padding: "0 15px",
              zIndex: "1000",
              position: "relative",
            }}
          >
            {currentCat && (
              <div style={{ marginBottom: "50px" }}>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "26px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "rgba(90, 90, 90, 1)",
                    marginBottom: "10px",
                    marginLeft: "-10px",
                  }}
                >
                  <Button onClick={handleBcakNavigation}>
                    <ArrowBackIosNewIcon />
                  </Button>

                  {`Companies Covered ` + (res ? `/ ${res}` : "")}
                </p>
                <h2
                  style={{
                    color: "rgba(25, 25, 25, 1)",
                    fontSize: "24px",
                    fontWeight: "800",
                    lineHeight: "36px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    marginLeft: "14px",
                    marginBottom: "6px",
                  }}
                >{`${currentCat.title} Companies ${
                  companiesCovered ? `(${companiesCovered})` : ""
                } `}</h2>
              </div>
            )}

            {currentComp && (
              <div
                style={{
                  marginTop: "-45px",
                  marginBottom: "20px",
                  paddingBottom: "20px",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "26px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#5a5a5a",
                    marginBottom: "10px",
                    marginLeft: "-10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={handleBcakNavigation}
                  >
                    <span>
                      <ArrowBackIosIcon
                        sx={{ color: "#03b2cb", fontSize: "12px" }}
                      />{" "}
                    </span>
                  </div>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      color: "#5a5a5a",
                    }}
                    onClick={handleBcakNavigation}
                  >
                    {`Companies Covered ` + (res ? `/ ${res}` : "")}
                  </Button>
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      marginLeft: "50px",
                    }}
                  >
                    <img
                      style={{ objectFit: "cover", width: "100%" }}
                      src={currentComp?.company?.logo}
                    />
                  </div>
                  <div>
                    <h2
                      style={{
                        color: "rgba(25, 25, 25, 1)",
                        fontSize: "24px",
                        fontWeight: "800",
                        lineHeight: "36px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        marginLeft: "14px",
                        marginBottom: "4px",
                      }}
                    >
                      {currentComp?.company?.name}
                    </h2>
                    <p
                      style={{
                        color: "rgba(90, 90, 90, 1)",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "14px",
                      }}
                    >
                      <span style={spanStyles}>Est.:</span>

                      {currentComp?.company?.founded}
                      <span style={dotStyles}></span>
                      <span style={spanStyles}>Country: </span>
                      {currentComp?.company?.country}

                      <span style={dotStyles}></span>
                      <span style={spanStyles}>Headquarters: </span>
                      {currentComp?.company?.headquarters}
                      <span style={dotStyles}></span>
                      <span style={spanStyles}>Annual Revenue: </span>
                      {`$ ${currentComp?.company?.annual_revenue} ${currentComp?.company?.units}`}
                      <span style={dotStyles}></span>
                      <span style={spanStyles}>Employees: </span>
                      {currentComp?.company?.employees}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <TabContext value={value}>
              {!currentCat && !currentComp && (
                <Box
                  sx={{
                    paddingTop: "40px",
                    borderBottom: 1,
                    marginLeft: "15px",
                    borderColor: "transparent",
                    width: ["100%", "70%", "35%"],
                  }}
                >
                  <Tabs
                    sx={{
                      border: "1px solid red",
                    }}
                    variant="fullWidth"
                    onChange={handleChange}
                    className="tablist"
                  >
                    <Tab
                      style={
                        activeTab === "companies-covered"
                          ? activeButtonStyle
                          : buttonStyle
                      }
                      onClick={() => handleTab("companies-covered")}
                      label={`Companies Covered ${
                        companiesCovered ? `(${companiesCovered})` : ""
                      }`}
                      value="1"
                    />

                    <Tab
                      style={
                        activeTab === "recent-development"
                          ? activeButtonStyle
                          : buttonStyle
                      }
                      onClick={() => handleTab("recent-development")}
                      label="Recent Developments"
                      value="3"
                    />
                  </Tabs>
                </Box>
              )}

              <TabPanel value="1">
                <CompanyHome
                  dataSide={data}
                  setCurrentCatagory={setCurrentCatagory}
                  currentCat={currentCat}
                  setCurrentCompany={setCurrentCompany}
                  currentComp={currentComp}
                  setCompaniesCovered={setCompaniesCovered}
                  setCheckRes={setCheckRes}
                />
              </TabPanel>
              <TabPanel value="2">
                <CompanyMarketShare hubId={state} />
              </TabPanel>
              <TabPanel value="3">
                <CompanyRecentDevelopment hubId={state} />
              </TabPanel>
            </TabContext>
          </div>
        </Box>
      )}
      {checkRes && (
        <LockBox
          componentName={"Companies"}
          sx={{
            zIndex: "5",
          }}
        />
      )}
    </>
  );
}
