import { Paper, Typography, Box } from "@mui/material";
import { useRecentDevelopment } from "./STATE";
import gateLogo from "../../../assets/gate.svg";
import Loader from "../../../components/Common/Loader";

function ChooseCompanyBox() {
  const { state } = useRecentDevelopment();
  return (
    <Paper
      sx={{
        my: "3rem",
        minHeight: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {state.loading === "PENDING" ? (
          <Loader />
        ) : (
          <>
            <Box
              component="img"
              src={gateLogo}
              alt="gate"
              sx={{ width: "80px" }}
            />
            <Typography variant="h6" sx={{ fontSize: "1rem" }}>
              Choose a company to see its recent development
            </Typography>
          </>
        )}
      </Box>
    </Paper>
  );
}

export default ChooseCompanyBox;
