import { useReportsContext } from "../../STATE";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useState, useEffect } from "react";

function useDownloadBulkReports() {
  const { state, dispatch } = useReportsContext();

 
  const [reportsIds, setReportsIds] = useState(state.currentTab === "MY_REPORTS" ? state.bulkReportsToDownload : state.bulkLibraryReportsToDownload);

  useEffect(() => {
    if(state.currentTab === "MY_REPORTS" && !state.isLibraryReports) {
      setReportsIds(state.bulkReportsToDownload);
    } else {
      setReportsIds(state.bulkLibraryReportsToDownload);
    }
  }, [state.currentTab, state.bulkReportsToDownload, state.bulkLibraryReportsToDownload, state.isLibraryReports]);

  const manageReportsToDownload = (checked, id) => {
    let newReports = [];
    if (checked) {
      newReports = [...reportsIds, id];
    } else {
      newReports = reportsIds.filter(
        (reportId) => reportId !== id
      );
    }
    if(state.currentTab === "MY_REPORTS" && !state.isLibraryReports) {
      dispatch({ type: "BULK_REPORTS_DOWNLOAD", payload: newReports });
    } else {
      dispatch({ type: "BULK_LIBRARY_REPORTS_DOWNLOAD", payload: newReports });
    }
  };

  return ({ id }) => <DownloadCheckBox manageReportsToDownload={manageReportsToDownload} reportsIds={reportsIds} id={id} />;
}

export default useDownloadBulkReports;

function DownloadCheckBox({ manageReportsToDownload, reportsIds, id }) {
  return (
    <FormGroup
      sx={{
        position: "absolute",
        top: "-15px",
        right: "-30px",
        ".css-z45csd-MuiButtonBase-root-MuiCheckbox-root": {
          padding: "5px",
        },
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            defaultChecked={reportsIds.includes(id)}
            onChange={(e) => manageReportsToDownload(e.target.checked, id)}
            sx={{
              backgroundColor: "#fff",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
          />
        }
      />
    </FormGroup>
  );
}
