import { Container } from "@mui/material";
import CompanySelection from "./CompanySelection";

function Layout({ children }) {
  return (
    <Container maxWidth={"xl"}>
      <section>
        <CompanySelection />
      </section>
      <section>{children}</section>
    </Container>
  );
}

export default Layout;
