import infoToolTipIcon from "../../../assets/info_tool_tip.svg";
import styles from "./styles.module.css";
import { Tooltip } from "@mui/material";

/**
 * Renders a label input field component.
 * We are using functional component Composition to extend various components to parent.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be rendered inside the component.
 * @returns {JSX.Element} The rendered label input field component.
 */
function LabelInputField({ children }) {
  return <div className={styles.mainInputContainer}>{children}</div>;
}

LabelInputField.Label = function InputLabel({ children }) {
  return <label className={styles.inputLabel}>{children}</label>;
};

LabelInputField.CustomInput = function CustomInputField({ children }) {
  return <>{children}</>;
};

LabelInputField.Input = function InputField(props) {
  return <input className={styles.inputField} {...props} />;
};

LabelInputField.Error = function ErrorState({ errors }) {
  return <>{errors && <p className={styles.errorInput}>{errors}</p>}</>;
};

LabelInputField.Tooltip = function LabelTooltip({ value }) {
  return (
    <>
      <Tooltip title={value}>
        <img src={infoToolTipIcon} alt="info" />
      </Tooltip>
    </>
  );
};

LabelInputField.CustomError = function CustomErrorState({ children }) {
  return <>{children}</>;
};

export default LabelInputField;
