import styles from "./styles.module.css";
import { useCompanyContext } from "../STATE";

function Pagination() {
  const { state, dispatch } = useCompanyContext();
  const {
    pagination: { page, limit },
    totalCompanies,
    loading,
  } = state;

  const countFrom = totalCompanies;
  const countOf = Math.ceil(countFrom / limit);
  const totalPages = countOf < 1 ? 1 : countOf;

  if (loading === "PENDING" || countFrom === 0) return <></>;

  const setItemsPerPage = (items) => {
    dispatch({
      type: "SET_PAGINATION_PAGE",
      payload: { page: 1 },
    });
    dispatch({
      type: "SET_PAGINATION_LIMIT",
      payload: { limit: items },
    });
  };

  const setCurrentPage = (currentPage) => {
    if (currentPage === page) return;
    const pageToSet = currentPage > totalPages ? totalPages : currentPage;
    dispatch({
      type: "SET_PAGINATION_PAGE",
      payload: { page: pageToSet },
    });
  };

  return (
    <div className={styles.paginationMainContainer}>
      <div>
        <span>View Companies per page:</span>
        <select
          value={limit}
          onChange={(e) => setItemsPerPage(Number(e.target.value))}
        >
          {[10, 25, 50, 75, 100].map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
      <div>{page + " of " + totalPages}</div>
      <div>
        <button onClick={() => setCurrentPage(page > 1 ? page - 1 : page)}>
          {"<"}
        </button>
        <button
          onClick={() => setCurrentPage(page < totalPages ? page + 1 : page)}
        >
          {">"}
        </button>
      </div>
    </div>
  );
}

export default Pagination;
