import FilterModal from "../FilterModal";
import sortImg from "../../../../../assets/sort.svg";
import { useState } from "react";
import { useCompanyContext } from "../../../STATE";
import { Box } from "@mui/material";
import { sortOptionsArray } from "../../../../../components/CompaniesComponents/CompanyHome/CompanyHome.config";

function Sort() {
  const { dispatch } = useCompanyContext();
  const [openFilter, setOpenFilter] = useState(false);

  const handleSort = (sortBy) => {
    dispatch({ type: "MOCK_LOADING", fn: dispatch });
    dispatch({ type: "RESET_PAGINATION" });
    dispatch({ type: "SET_SORT_BY", payload: sortBy });
  }

  return (
    <>
      <button onClick={() => setOpenFilter((p) => !p)}>
        <img src={sortImg} alt="filter" />
        Sort
      </button>
      {openFilter && (
        <FilterModal setOpenFilter={setOpenFilter} filterBy={"Sort By"}>
          <Box sx={{ minWidth: "350px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {sortOptionsArray.map((option, i) => {
                return (
                  <>
                    <Box
                      key={i}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        gap: "0.8rem",
                        alignItems: "center",
                        fontSize: "15px",
                        padding: "1rem",
                        "&:hover": {
                          color: "rgb(24, 156, 222)",
                          backgroundColor: "rgb(240, 247, 252)",
                        },
                      }}
                      onClick={() => handleSort(option)}
                    >
                      <Box
                        component="label"
                        htmlFor={option}
                        sx={{ cursor: "pointer" }}
                      >
                        {option}
                      </Box>
                    </Box>
                  </>
                );
              })}
            </Box>
          </Box>
        </FilterModal>
      )}
    </>
  );
}

export default Sort;
