export const colors = [
  "#E0EFFA",
  "#E0EFFA",
  "#D7F6EB",
  "#D7F6EB",
  "#FFEDC7",
  "#FFEDC7",
  "#E6E3FC",
  "#E6E3FC",
  "#FCECEC",
  "#FCECEC",
  "#F7E6FF",
  "#F7E6FF",
  "#A5CBE6",
  "#A5CBE6",
  "#A5E6C6",
  "#A5E6C6",
  "#FFE6A5",
  "#FFE6A5",
  "#C6B1FF",
  "#C6B1FF",
  "#FFC6C6",
  "#FFC6C6",
  "#FFB1FF",
  "#FFB1FF",
  "#F281A5",
  "#F281A5",
  "#AEDCD4",
  "#AEDCD4",
  "#AEDCDF",
  "#AEDCDF",
];
