import { FormControlLabel, Checkbox, Box } from "@mui/material";
import { useReportsContext } from "../../STATE";

function CatalogueInstantAccess() {
  const { state, dispatch } = useReportsContext();

  const handleCatalogueInstantAccess = (checked) => {
    dispatch({ type: "SET_CATALOGUE_INSTANT_ACCESS", payload: checked });
  };
  
  return (
    <Box sx={{ pl: 2 }}>
      <FormControlLabel
        onClick={(e) => handleCatalogueInstantAccess(e.target.checked)}
        control={
          <Checkbox defaultChecked={state.catalogueInstantAccessFilter} />
        }
        label="Instant Access"
      />
    </Box>
  );
}

export default CatalogueInstantAccess;
