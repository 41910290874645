import { createContext, useContext } from "react";

export const initialState = {
  loading: "IDLE",
  developmentTypes: [],
  companies: [],
  data: [],
  dataToDisplay: [],
  selectedDevelopmentTypes: [],
  selectedCompanies: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    case "SET_DEVELOPMENT_TYPES":
      return {
        ...state,
        developmentTypes: action.payload,
      };

    case "SET_COMPANIES":
      return {
        ...state,
        companies: action.payload,
      };

    case "SET_DATA":
      return {
        ...state,
        data: action.payload,
        dataToDisplay:
          state.selectedDevelopmentTypes.length > 0
            ? action.payload.filter((company) =>
                state.selectedDevelopmentTypes.includes(company.type)
              )
            : action.payload,
      };

    case "SET_DATA_TO_DISPLAY":
      return {
        ...state,
        dataToDisplay: action.payload,
      };

    case "SET_SELECTED_DEVELOPMENT_TYPE":
      return {
        ...state,
        selectedDevelopmentTypes: action.payload,
        dataToDisplay:
          action.payload.length > 0
            ? state.data.filter((company) =>
                action.payload.includes(company.type)
              )
            : state.data,
      };

    case "SET_SELECTED_COMPANIES":
      return {
        ...state,
        selectedCompanies: action.payload,
      };

    default:
      return state;
  }
};

export const RecentDevelopmentContext = createContext();
export const useRecentDevelopment = () => useContext(RecentDevelopmentContext);
