import { Button, Box, Alert, AlertTitle, Snackbar } from "@mui/material";
import { useState } from "react";
import { useReportsContext } from "../../STATE";
import API, { SYNAPSE_API } from "../../../../api/Api";
import DownloadIcon from "@mui/icons-material/Download";

function DownloadBulkBtn() {
  const { state, dispatch } = useReportsContext();
  const [downloading, setDownloading] = useState("IDEAL");
  const [snackbarOpen, setSnackbarOpen] = useState("CLOSE");

  const count =
    state.currentTab === "MY_REPORTS" && !state.isLibraryReports
      ? state.bulkReportsToDownload.length
      : state.bulkLibraryReportsToDownload.length;

  if (count === 0) {
    return <></>;
  }

  const downloadBulkReports = async () => {
    const url = API.Base_URL + "/api/reports/download-bulk-reports";
    setDownloading("PENDING");
    setSnackbarOpen("DOWNLOADING");
    try {
      const response = await SYNAPSE_API.post(
        url,
        {
          reports: state.bulkReportsToDownload,
          library_reports: state.bulkLibraryReportsToDownload,
        },
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], { type: "application/zip" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "files.zip");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      setSnackbarOpen("ERROR");
      console.error("Error downloading files:", err);
    } finally {
      setDownloading("IDEAL");
      dispatch({ type: "CLEAR_BULK_REPORTS" });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen("CLOSED");
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "fit-content",
          marginLeft: "auto",
        }}
      >
        <Button
          disabled={downloading === "PENDING"}
          variant="contained"
          sx={{
            fontWeight: "600",
            textTransform: "none",
          }}
          startIcon={<DownloadIcon />}
          onClick={downloadBulkReports}
        >
          {count} Reports
        </Button>
      </Box>
      <OnErrorSnackbar isError={snackbarOpen === "ERROR"} handleSnackbarClose={handleSnackbarClose} />
      <DownloadingSnackbar isDownloading={snackbarOpen === "DOWNLOADING"} handleSnackbarClose={handleSnackbarClose} />
    </>
  );
}

export default DownloadBulkBtn;

const DownloadingSnackbar = ({isDownloading, handleSnackbarClose}) => {
  return (
    <Snackbar open={isDownloading} autoHideDuration={3000} onClose={handleSnackbarClose}>
      <Alert severity="info">
        <AlertTitle>Downloading</AlertTitle>
        Your files are downloading and it will take some time. Please wait.
      </Alert>
    </Snackbar>
  );
}

const OnErrorSnackbar = ({ isError, handleSnackbarClose }) => {
  return (
    <Snackbar open={isError} autoHideDuration={3000} handleSnackbarClose={handleSnackbarClose}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Error downloading files. Please try again later.
      </Alert>
    </Snackbar>
  );
};
