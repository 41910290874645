import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { useAuth } from '../../context/authContext/Auth';
import API from '../../api/Api';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function EmailModal({ open, handleClose, getUsers }) {
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(true);

    const { config } = useAuth();
    const { Base_URL } = API

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        setEmail(inputValue);
        // Regular expression to check if the email is valid
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        setIsValid(emailPattern.test(inputValue));
    };


    async function addUser() {
        const bodyParameters = {
           email
          };
          
        try {
          const response = await axios.post(
            `${Base_URL}/api/users/add-alias`,
            bodyParameters,
            config
          );
    
    
        } catch (error) {
          console.error(error);
        } finally {
          getUsers()
          handleClose()
        }
      }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper sx={style} >
                <Typography py={2} id="modal-modal-title" variant="h6" component="h2">
                    Add User Email address
                </Typography>
                <Box >
                    <TextField
                        sx={{ my: 4 }}
                        fullWidth
                        label="Email"
                        placeholder="Enter your email"
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                        error={!isValid}
                        helperText={!isValid ? 'Please enter a valid email address.' : ''}
                    />
                    {isValid ? (
                        <Button fullWidth disabled={email.length ? false : true} variant="contained" onClick={addUser} endIcon={<SendIcon />}>
                        Send
                      </Button>
                    ) : (<>
                       <Button disabled fullWidth variant="contained" endIcon={<SendIcon />}>
                        Send
                      </Button>
                    </>
                    )}
                </Box>
            </Paper>
        </Modal>
    )
}

export default EmailModal