import { FormControl,TextField, InputAdornment } from "@mui/material";
import { RiSearchLine } from "react-icons/ri";
import { usePurchaseHistoryCtx } from "../STATE";
import { useState, useEffect } from "react";
import useDebounce from "../../../../hooks/useDebounce";

function Search() {
  const {
    state: { search },
    dispatch,
  } = usePurchaseHistoryCtx();

  const [searchValue, setSearchValue] = useState("");
  const debouncedSearch = useDebounce(searchValue, 500);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  }

  useEffect(() => {
    setSearchValue(search);
  }, [search]);

  useEffect(() => {
    dispatch({ type: "SET_SEARCH", payload: debouncedSearch });
  }, [debouncedSearch]);

  return (
    <FormControl fullWidth>
      <TextField
        sx={{ "& .MuiInputBase-input": { borderRadius: "8px" } }}
        placeholder="Search Reports"
        variant="outlined"
        fullWidth
        onChange={handleSearch}
        value={searchValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <RiSearchLine style={{ fontSize: "24px", color: "#002F75" }} />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
}

export default Search;
