import { useState, useEffect, useRef, forwardRef } from "react";
import { NoDataFoundForThis } from "../fetchData";
import parse, { domToReact } from "html-react-parser";
import html2PDF from "jspdf-html2canvas";
import { useReactToPrint } from "react-to-print";
import styles from "./styles.module.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockBox from "../../LockBox";
import LockBoxNew from "../../LockBox/LockBoxNew";
import { useSelector } from "react-redux";
import { ReportAccess } from "../Modals/ReportAccess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DownloadIcon from "../../../assets/download_icon2.svg";
import DownloadIconGray from "../../../assets/download_icon_gray.svg";
import PrintIcon from "../../../assets/print_icon2.svg";
import mordor_icon from "../../../utils/img/Mi_logo.png";
import Snippet from "../Snippet";
import { formatDate, formatMonth } from "../../../utils/common";
import _ from "lodash";

function ExecSummary({
  data,
  isSubscribed = false,
  title,
  id,
  setTriggerApiCall,
  snippetData,
  reportMeta,
}) {
  const [execSummaryData, setExecSummaryData] = useState([]);
  const { remaining_library_credit } = useSelector((state) => state);
  const KeyFindingsRef = useRef();
  const AnalystsNoteRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [currentResearch, setCurrentResearch] = useState("Key Findings");
  // const [expanded, setExpanded] = useState(false); // State to control expand/collapse
  // State to track expanded state of each accordion
  const [expandedAccordions, setExpandedAccordions] = useState({});
  const [expandAll, setExpandAll] = useState(true);
  // Function to extract data from table

  const extractTableData = (children) => {
    let rowsData = [];
    children.forEach((tr) => {
      if (tr.name === "tr") {
        let rowData = {};
        tr.children.forEach((td, index) => {
          if (td.name === "td" || td.name === "th") {
            rowData[`column${index}`] = domToReact(td.children);
          }
        });
        rowsData.push(rowData);
      }
    });
    return rowsData;
  };
  const handleCancel = () => {
    setShowModal(false);
    // setTriggerApiCall();
  };
  useEffect(() => {
    if (!data) return;
    const newData = data.map((item) => {
      if (typeof item.content === "string") {
        const options = {
          replace: ({ name, children }) => {
            if (name === "table") {
              const tableData = extractTableData(children);
              // Here you can either update the item with the extracted table data
              // or handle the table data as needed.
              return { ...item, tableData }; // Example of adding extracted data to item
            }
          },
        };
        parse(item.content, options);
      }
      return item;
    });
    setExecSummaryData(newData);
  }, [data]);

  useEffect(() => {
    //If all values are true, set expandAll to true
    if (Object.values(expandedAccordions).every((val) => val === true)) {
      setExpandAll(true);
    }
    //If all values are false, set expandAll to false
    else if (Object.values(expandedAccordions).every((val) => val === false)) {
      setExpandAll(false);
    }
  }, [expandedAccordions]);

  // Effect to toggle all accordions based on expandAll state

  useEffect(() => {
    const allAccordionsState = execSummaryData.reduce((acc, item, index) => {
      acc[index] = expandAll;
      return acc;
    }, {});
    setExpandedAccordions(allAccordionsState);
  }, [expandAll, execSummaryData]);

  // Function to toggle expand/collapse all accordions
  // Toggle individual accordion

  const handleAccordionChange = (index) => {
    setExpandedAccordions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const printExecSummary = useReactToPrint({
    content: () =>
      currentResearch === "Key Findings"
        ? KeyFindingsRef.current
        : AnalystsNoteRef.current,
    documentTitle:
      currentResearch === "Key Findings"
        ? `${reportMeta.title} - Key Findings`
        : `${reportMeta.title} - Analyst's Note`,
  });

  if (!data) return <NoDataFoundForThis />;

  const rendercolorexpand = () => {
    if (!expandAll) {
      return <AddIcon />;
    } else {
      return <RemoveIcon />;
    }
  };

  return (
    <Box
      className="space-y-4 max-w-4xl"
      sx={{
        position: "relative",
        cursor: "",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
        }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          {currentResearch === "Key Findings" ? (
            <Button
              onClick={() => setExpandAll(!expandAll)}
              aria-label={
                expandAll &&
                //One item is expanded
                Object.values(expandedAccordions)?.some((item) => item === true)
                  ? "Collapse All"
                  : "Expand All"
              }
              sx={{}}
              variant="outlined"
            >
              {rendercolorexpand()}
            </Button>
          ) : null}
          <Box sx={{ textTransform: "capitalize", marginLeft: "1rem" }}>
            {currentResearch === "Key Findings" ? (
              <Button
                style={{
                  backgroundColor: "#446BA6",
                  padding: "8px 24px",
                  textTransform: "capitalize",
                  marginRight: "1rem",
                }}
                variant="contained"
              >
                Key Findings
              </Button>
            ) : (
              //Show only text type button
              <Button
                variant="text"
                sx={{
                  color: "#5A5A5A",
                  textTransform: "capitalize",
                  marginRight: "1rem",
                }}
                onClick={() => {
                  setCurrentResearch("Key Findings");
                }}
              >
                Key Findings
              </Button>
            )}
            {currentResearch === " Analyst’s Note" ? (
              <Button
                style={{
                  backgroundColor: "#446BA6",
                  padding: "8px 24px",
                  textTransform: "capitalize",
                  marginRight: "1rem",
                }}
                variant="contained"
              >
                Analyst’s Note
              </Button>
            ) : (
              //Show only text type button
              <Button
                variant="text"
                sx={{
                  color: "#5A5A5A",
                  textTransform: "capitalize",
                  marginRight: "1rem",
                }}
                onClick={() => {
                  setCurrentResearch(" Analyst’s Note");
                }}
              >
                Analyst’s Note
              </Button>
            )}
          </Box>
        </Box>

        <div>
          <Button
            onClick={() => {
              printExecSummary();
            }}
            aria-label={"Print Executive Summary"}
            sx={{ marginLeft: "10px" }}
            variant="outlined"
          >
            <img
              width="20px"
              height="20px"
              src={PrintIcon}
              alt="Print Executive Summary"
            />
          </Button>
        </div>
      </Box>

      {showModal && (
        <ReportAccess 
        report_id={id} 
        title={title} 
        closeModal={handleCancel} 
        reloadFunction={setTriggerApiCall}
        />
      )}
      {!isSubscribed && currentResearch !== " Analyst’s Note" && (
        <LockBoxNew
          onclick={
            remaining_library_credit > 0 ? () => setShowModal(true) : false
          }
          purchaseCustomText="Get access to report to see Executive Summary"
          componentName={"Executive Summary"}
        ></LockBoxNew>
      )}
      {currentResearch === "Key Findings" ? (
        <Box id={"printableComponent"}>
          {execSummaryData?.map((item, index) => (
            <div key={_.uniqueId()} className={styles.infoContainer}>
              <Accordion
                expanded={expandedAccordions[index] || false}
                onChange={() => handleAccordionChange(index)}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#449ac6",
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{
                    backgroundColor: "rgb(240, 247, 252)",
                    paddingLeft: "0.5rem",
                    //Decrease margin when accordion is expanded
                  }}
                >
                  <Typography
                    style={{
                      backgroundColor: "rgb(240, 247, 252)",
                      // paddingLeft: "0.1rem",
                      color: "#449ac6",
                      fontSize: "1.2rem",
                      fontWeight: "bold",

                      //Decrease margin when accordion is expanded
                    }}
                  >
                    {item.heading}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: "0px",
                    filter: isSubscribed ? "none" : "blur(3.5px)",
                  }}
                >
                  <Typography
                    sx={{
                      filter: isSubscribed ? "none" : "blur(3.5px)",
                    }}
                    className={styles.content}
                  >
                    {parse(item.content)}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </Box>
      ) : (
        <Box>
          <Snippet data={snippetData} />
        </Box>
      )}

      <PrintableKeyFindings
        isSubscribed={isSubscribed}
        execSummaryData={execSummaryData}
        reportMeta={reportMeta}
        ref={KeyFindingsRef}
      />
      <PrintableAnalystsNote
        snippetData={snippetData}
        isSubscribed={isSubscribed}
        reportMeta={reportMeta}
        ref={AnalystsNoteRef}
      />
    </Box>
  );
}

export default ExecSummary;

const PrintableKeyFindings = forwardRef(
  ({ execSummaryData, isSubscribed, reportMeta }, printRef) => {
    return (
      <div style={{ display: "none" }}>
        <div ref={printRef} className="print-page">
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <small style={{ color: "gray", fontSize: "16px" }}>
                Source: Mordor Intelligence
              </small>
              <img
                width={"34px"}
                height={"34px"}
                src={mordor_icon}
                alt="Logo Of Mordor Intelligence"
              />
            </Box>
            <h1
              style={{
                color: "#449AC6",
                textAlign: "center",
                fontSize: "36px",
              }}
            >
              {reportMeta.title}
            </h1>
            <h2
              style={{
                color: "#449AC6",
                textAlign: "center",
                fontSize: "28px",
              }}
            >
              {`Executive Summary (Key Findings)`}
            </h2>
            <Box>
              <hr style={{ color: "gray", margin: "10px 0" }} />
              {reportMeta.websiteSlug ? (
                <p>
                  Report URL :{" "}
                  <a
                    href={`https://www.mordorintelligence.com/industry-reports/${reportMeta.websiteSlug}`}
                  >
                    {`www.mordorintelligence.com/industry-reports/${reportMeta.websiteSlug}`}
                  </a>
                </p>
              ) : null}
              <hr style={{ color: "gray", margin: "10px 0" }} />
              {reportMeta.forcastYear ? (
                <p>Forcast Year : {reportMeta.forcastYear}</p>
              ) : null}
              {reportMeta.publishedOn ? (
                <p>Published On : {formatMonth(reportMeta.publishedOn)} </p>
              ) : null}
              {reportMeta.subscribedOn ? (
                <p>Purchased On : {formatDate(reportMeta.subscribedOn)}</p>
              ) : null}
              {reportMeta.reportExpiry ? (
                <p>Renewal On : {formatDate(reportMeta.reportExpiry)}</p>
              ) : null}
            </Box>
          </Box>
          <Box>
            {execSummaryData?.map((item, index) => (
              <div key={_.uniqueId()} className={styles.infoContainer}>
                <Accordion expanded={true} className="accordian">
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      backgroundColor: "rgb(240, 247, 252)",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    <Typography
                      style={{
                        backgroundColor: "rgb(240, 247, 252)",
                        color: "#449ac6",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                    >
                      {item.heading}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "0px",
                      filter: isSubscribed ? "none" : "blur(3.5px)",
                    }}
                  >
                    <Typography
                      sx={{
                        filter: isSubscribed ? "none" : "blur(3.5px)",
                      }}
                      className={styles.content}
                    >
                      {parse(item.content)}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </Box>
        </div>
      </div>
    );
  }
);

const PrintableAnalystsNote = forwardRef(
  ({ isSubscribed, snippetData, reportMeta }, printRef) => {
    return (
      <div style={{ display: "none" }}>
        <div ref={printRef} className="print-page">
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <small style={{ color: "gray", fontSize: "16px" }}>
                Source: Mordor Intelligence
              </small>
              <img
                width={"34px"}
                height={"34px"}
                src={mordor_icon}
                alt="Logo Of Mordor Intelligence"
              />
            </Box>
            <h1
              style={{
                color: "#449AC6",
                textAlign: "center",
                fontSize: "36px",
              }}
            >
              {reportMeta.title}
            </h1>
            <h2
              style={{
                color: "#449AC6",
                textAlign: "center",
                fontSize: "28px",
              }}
            >
              {`Executive Summary (Analyst's Note)`}
            </h2>
            <Box>
              <hr style={{ color: "gray", margin: "10px 0" }} />
              {reportMeta.websiteSlug ? (
                <p>
                  Report URL :{" "}
                  <a
                    href={`https://www.mordorintelligence.com/industry-reports/${reportMeta.websiteSlug}`}
                  >
                    {`www.mordorintelligence.com/industry-reports/${reportMeta.websiteSlug}`}
                  </a>
                </p>
              ) : null}
              <hr style={{ color: "gray", margin: "10px 0" }} />
              {reportMeta.forcastYear ? (
                <p>Forcast Year : {reportMeta.forcastYear}</p>
              ) : null}
              {reportMeta.publishedOn ? (
                <p>Published On : {formatMonth(reportMeta.publishedOn)} </p>
              ) : null}
              {reportMeta.subscribedOn ? (
                <p>Purchased On : {formatDate(reportMeta.subscribedOn)}</p>
              ) : null}
              {reportMeta.reportExpiry ? (
                <p>Renewal On : {formatDate(reportMeta.reportExpiry)}</p>
              ) : null}
            </Box>
          </Box>
          <Box>
            <Snippet data={snippetData} />
          </Box>
        </div>
      </div>
    );
  }
);
