import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Typography,
  Container,
  Button,
} from "@mui/material";
import { ChevronRight, ExpandMore, ExpandLess } from "@mui/icons-material";
import axios from "axios";
import { useAuth } from "../../context/authContext/Auth";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useLocation } from "react-router-dom";

import Loader from "./Loader";
import API from "../../api/Api";

function RecursiveListItem({
  currentId,
  item,
  depth = 0,
  onIdClick,
  noHubCard = false,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentId == null) {
      setOpen(false);
    }
  }, [currentId]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleId = (value, event) => {
    onIdClick(value.id, value);
  };

  const listItemStyle = {
    paddingLeft: `${16 + depth * 16}px`,
    borderTop: depth == 0 ? `1px solid rgba(228, 228, 228, 1)` : "none",
    borderLeft:
      open || currentId == item.id ? "5px solid #189CDE" : "1px solid #D5D5D5",
    color: currentId == item.id ? "#189CDE" : "#000000",
    background: currentId == item.id && "#F0F7FC",
    marginLeft: depth == 0 ? `0px` : `${depth * 16}px`,
    width: depth == 0 ? `100%` : `calc(100% - ${depth * 16}px)`,
    position: "relative",
    left: "0px",
    cursor: "pointer",
  };

  const verticalLineStyle = {
    content: '""',
    position: "absolute",
    left: `${16 + depth * 16}px`,
    top: "0",
    bottom: "0",
    width: "1px",
    background: "#D5D5D5",
    zIndex: "-1",
    display: open && item.children.length > 0 ? "block" : "none",
  };

  const listItemTextStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    color: "black",
    transition: "color 0.3s",
  };

  const hoveredTextStyle = {
    color: "#007BFF",
  };

  return (
    <Box
      key={item._id}
      style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px" }}
    >
      <ListItem style={listItemStyle}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <div
            onClick={() => handleId(item)}
            style={{ width: "100%", height: "100%" }}
          >
            <ListItemText
              primary={item.title}
              sx={{
                fontWeight:
                  currentId == item.id ? "600 !important" : "400 !important",
                "& .MuiTypography-root": {
                  fontWeight:
                    currentId == item.id ? "600 !important" : "400 !important",
                },
                "& .css-10hburv-MuiTypography-root": {
                  fontWeight:
                    currentId == item.id ? "600 !important" : "400 !important",
                },
              }}
            />
          </div>
          {item.children.length > 0 && (
            <IconButton className={`icon-button`} onClick={handleClick}>
              {open ? (
                <ExpandLess style={{ color: "#189CDE" }} />
              ) : (
                <ExpandMore />
              )}
            </IconButton>
          )}
        </div>
      </ListItem>
      {item.children.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div style={verticalLineStyle}></div>

            {item.children.map((child) => (
              <RecursiveListItem
                currentId={currentId}
                key={child._id}
                item={child}
                depth={depth + 1}
                onIdClick={onIdClick}
              />
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  );
}

function CompanyCatTree({
  categoryTreeData,
  closeButton,
  handleClose,
  setCategoryItem = () => {},
  currentCat,
  loading = false,
}) {
  const [categoryData, setCategoryData] = useState([]);
  const [currentId, setCurrentId] = useState("");


  let location = useLocation();

  const handleIdClick = (id, item) => {
    setCurrentId(id);
    setCategoryItem(id, item);
    window.scroll(0, 0);
  };

  useEffect(() => {
    setCategoryData(categoryTreeData);
  }, [categoryTreeData]);

  useEffect(() => {
    if (currentCat == null) {
      setCurrentId(null);
    }
  }, [currentCat]);


  const notShowResetBtn = new Set(["/companies", "/companies/", "/reports"]);

  return (
    <Container
      sx={{
        mb: "350px",
        borderRadius: "6px",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
        padding: "0px !important",
        paddingLeft: "0px !important",
      }}
    >
      <Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: "60px",
            paddingInline: "18px",
            font: "800 16px / 25.6px Avenir Roman",
          }}
        >
          <Typography fontWeight={600}>Categories</Typography>
          {notShowResetBtn.has(location.pathname) ? null : (
            <Button
              onClick={() => {
                setCurrentId("");
                const windowWidth =
                  window.innerWidth ||
                  document.documentElement.clientWidth ||
                  document.body.clientWidth;
                if (windowWidth < 900) {
                  handleClose();
                }
              }}
              endIcon={<RestartAltOutlinedIcon />}
            >
              Reset All
            </Button>
          )}

          {closeButton}
        </Box>

        {loading ? (
          <Loader height={"60vh"} />
        ) : (
          <List component="nav" sx={{ padding: "0 !important" }}>
            {categoryData?.map((item) => (
              <RecursiveListItem
                currentId={currentId}
                key={item._id}
                item={item}
                onIdClick={(id, item) => handleIdClick(id, item)}
              />
            ))}
          </List>
        )}
      </Box>
    </Container>
  );
}

export default CompanyCatTree;
