import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

function CompanyShowPdf() {
  const location = useLocation();
  const { pdfUrl } = location.state;

  return (
    <Box sx={{ height: "85vh" }}>
      <object
        data={pdfUrl}
        type="application/pdf"
        width="100%"
        height="100%"
      ></object>
    </Box>
  );
}

export default CompanyShowPdf;
