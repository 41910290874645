import React from "react";
import styled from "styled-components";

export const Container = styled.div`
font-size: 16px;
font-family: "Avenir Medium";
display:flex;
justify-content: space-between;
align-items:flex-start;
flex-direction: row;
flex-wrap:wrap;
`;

export const Box = styled.div`
font-size: 16px;
color: #fff;
font-family: "Avenir Medium";
margin:10px;
padding:10px;
background-color: ${({ backgroundColor }) => `${backgroundColor}`};
display:flex;
flex-direction: column;
justify-content: center;
align-items:center;
flex-wrap:wrap;
flex: 0 1 40%;
`;

export default class Boxes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boxesData: [],
    };
  }

  componentDidMount() {
    if (this.props?.boxesData) {
        this.setState({
            boxesData: this.props.boxesData,
        });
      }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
        if (this.props?.boxesData) {
            this.setState({
                boxesData: this.props.boxesData,
            });
          }
        }
  }

  render() {
    const backgroundColors = this.props.backgroundColors || [];
    let total = 0;
    let newBoxData = []

    if( this.props.slideName === 'marketShares'){
        this.state.boxesData.map(item=>{
            total = total + item.value;
        })

        newBoxData =  this.state.boxesData.map(item=>{
             return {
                value : (item.value*(100/total)).toFixed(2)+`%`,
                label : item.label
             }
        })
    }else{
        newBoxData = this.state.boxesData;
    }

    return (
       <Container >
        {
            newBoxData.map((box, i)=>{
                return <Box backgroundColor={backgroundColors[i]}>
                  <h2>{box.value}</h2>
                  <p>{box.label}</p> 
                    </Box>
            })
        }
       </Container>
    );
  }
  }
