import { Fab, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function ScrollToTopButton() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > window.innerHeight) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleButtonClick() {
    localStorage.setItem('theTrue', false);
    localStorage.setItem('segmentationClose', false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
    localStorage.setItem('theTrue', true);
    
    }, 2000); 
  }

  return (
    <Tooltip title="Scroll to top" placement="top">
      <Fab
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(19),
          right: (theme) => theme.spacing(2),
          display: showButton ? "flex" : "none",
          width: "56px",
          height: "56px",
          borderRadius: "50%",
          // backgroundColor: "#000",
          backgroundColor: "#fff",
          // color: "#fff",
          // boxShadow:
          //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          // "&:hover": {
          //   boxShadow:
          //   "0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.3)",
          // },
          justifyContent: "center",
          alignItems: "center",
          p: "16px",
          border: 'solid 0.5px #ECECEC' 
        }}
        variant="extended"
        // color="primary"
        aria-label="add"
        // onClick={handleClickOpen}
        onClick={handleButtonClick}
        size="small"
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </Tooltip>
  );
}

export default ScrollToTopButton;
