export const SET_REPORT_DATA = "SET_REPORT_DATA";
export const SET_REPORT_FILE_DATA = "SET_REPORT_FILE_DATA";
export const SET_NAVBAR_DATA = "SET_NAVBAR_DATA";
export const SET_CURRENT_REPORT_DETAIL = "SET_CURRENT_REPORT_DETAIL";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_HUB_CONTENT = "SET_HUB_CONTENT";
export const SET_CHECK_SUB = "SET_CHECK_SUB";

export const SET_CREDITS = "SET_CREDITS";
export const SET_DOWNLOAD_CREDITS = "SET_DOWNLOAD_CREDITS";
export const SET_ONDEMAND_CREDITS = "SET_ONDEMAND_CREDITS";
export const SET_LIBRARY_CREDITS = "SET_LIBRARY_CREDITS";

export const setReportData = (reportID, hubID, dashboardData) => {
  return {
    type: SET_REPORT_DATA,
    payload: {
      reportID,
      hubID,
      dashboardData,
    },
  };
};

export const setReportFileData = (datasheetReportId, dataSheetData) => {
  return {
    type: SET_REPORT_FILE_DATA,
    payload: {
      datasheetReportId,
      dataSheetData,
    },
  };
};

export const SetNavbarData = (heading, paragraph, backButtonData) => {
  return {
    type: SET_NAVBAR_DATA,
    payload: {
      heading,
      paragraph,
      backButtonData,
    },
  };
};

export const SetUserInfo = (configUsed, userData) => {
  return {
    type: SET_USER_INFO,
    payload: {
      configUsed,
      userData,
    },
  };
};

export const setHubContent = (
  hubContentData,
  checkSub,
  checkHubId,
  hubSlug
) => {
  return {
    type: SET_HUB_CONTENT,
    payload: {
      hubContentData,
      checkSub,
      checkHubId,
      hubSlug,
    },
  };
};

export const setCheckSub = (hubContentData, checkSub, checkHubId) => {
  return {
    type: SET_CHECK_SUB,
    payload: {
      checkSub,
    },
  };
};

export const setCredits = (credits) => {
  return {
    type: SET_CREDITS,
    payload: {
      credits,
    },
  };
};

export const setDownloadCredits = (total_credit, remaining_credit) => {
  return {
    type: SET_DOWNLOAD_CREDITS,
    payload: {
      total_credit,
      remaining_credit,
    },
  };
};

export const setOnDemandCredits = (total_credit, remaining_credit) => {
  return {
    type: SET_ONDEMAND_CREDITS,
    payload: {
      total_credit,
      remaining_credit,
    },
  };
};

export const setLibraryCredits = (total_credit, remaining_credit) => {
  return {
    type: SET_LIBRARY_CREDITS,
    payload: {
      total_credit,
      remaining_credit,
    },
  };
};
