import React from "react";

import Template1000 from "./Template1000";
import Template1001 from "./Template1001";
import Template1011 from "./Template1011";
import Template1100 from "./Template1100";
import Template1101C from "./Template1101C";
import Template1101R from "./Template1101R";
import Template1111 from "./Template1111";

import { TemplateContainer, SwitchContainer } from "./templates.styles";
import { Switch } from "antd";

const templatesConfig = {
  1000: Template1000,
  1001: Template1001,
  1011: Template1011,
  1100: Template1100,
  "1101c": Template1101C,
  "1101r": Template1101R,
  1111: Template1111,
};

export default class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templateName: "1000",
      templateData: [],
      slideName: "",
    };
    this.graphType = "initialGraphTypeValue";
  }

  componentDidMount() {
    if (this.props?.templateName) {
      this.setState({
        templateName: this.props.templateName,
      });
    }
    if (this.props?.templateData) {
      this.setState({
        templateData: this.props.templateData,
      });
    }
    if (this.props?.slideName) {
      this.setState({
        slideName: this.props.slideName,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      if (this.props?.templateName) {
        this.setState({
          templateName: this.props.templateName,
        });
      }
      if (this.props?.templateData) {
        this.setState({
          templateData: this.props.templateData,
        });
      }
      if (this.props?.slideName) {
        this.setState({
          slideName: this.props.slideName,
        });
      }
    }
  }

  handleSwitchChange = (checked) => {
    let revisedTemplateData = [...this.state.templateData]?.map((item) => {
      if (item.type === "boxes" || item.type === "graph") {
        this.graphType = item.type;
      }
      if (item.type === this.graphType || item.type === "table") {
        return {
          ...item,
          type: checked ? "table" : this.graphType,
        };
      } else {
        return {
          ...item,
        };
      }
    });

    this.setState({
      templateData: revisedTemplateData,
    });
  };

  render() {
    const Template = templatesConfig[this.state.templateName];

    return (
      <TemplateContainer>
        <SwitchContainer>
          <Switch
            onChange={this.handleSwitchChange}
            checkedChildren="TABLE"
            unCheckedChildren="GRAPH"
            className="graph=table-switch"
          />
        </SwitchContainer>
        <Template
          templateName={this.state.templateName}
          templateData={JSON.parse(JSON.stringify(this.state.templateData))}
          slideName={this.state.slideName}
          parentTitle={this.props.parentTitle}
        />
      </TemplateContainer>
    );
  }
}
