import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import exportFromJSON from "export-from-json";
import { Box , Button, Tooltip, Popover, Typography} from "@mui/material";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import { useAuth } from "../../context/authContext/Auth";
import Graphs2 from "../../Graphs/Graphs2";
import Loader from "../Common/Loader";
import Table from "../TemplateComponents/Table";
import { Switch } from "antd";
import _ from "lodash";
import downloadIcon from "../../assets/download_icon.svg";
import mordor_logo from "../../utils/img/Mi_logo.png"
import Graph from "../../Graphs";
import { checkAndUpdateDownloadCredit, displayNoDownloadCredit } from "../../utils/common";
import { setDownloadCredits } from "../Redux/action";
import DownloadAccessChecker from "../Common/DownloadOptionCheck/DownloadAccessChecker";
import LockBox from "../LockBox";
import LockBoxNew from "../LockBox/LockBoxNew";


function ViewVisualise({
  data,
  alternateHeading,
  heading,
  handleDrawerOpen,
  graphLength,
  expandAll,
  changeExpand,
  hubId,
  reportId,
  visualiseLevel,
  blurred = false,
  showModal,
}) {
  const [currentView, setCurrentView] = useState("graph");
  const [currentGraph, setCurrentGraph] = useState("volume");
  const [displayData, setDisplayData] = useState({});
  const [dataType, setDataType] = useState("VOLUME");
  const [subId, setSubId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [expand, setExpand] = useState(graphLength);
  const { total_credit, remaining_credit, remaining_ondemand_credit } =
    useSelector((state) => state);
  const dispatch = useDispatch();
  const { config, userInfo } = useAuth();
  const hasAccess =
    visualiseLevel === "report"
      ? DownloadAccessChecker("report", reportId)
      : DownloadAccessChecker("hub", hubId);

  useEffect(() => {
    if (!graphLength) {
      setExpand(expandAll);
    }
  }, [changeExpand]);

  const switchStyles = {
    marginLeft: "12px",
    color: "red",
    backgroundColor: "#446BA6",
    padding: "1px",
    height: "22px",
  };

  const downloadButtonStyles = {
    border: "1px solid #446BA6",
    borderRadius: "14px",
    marginLeft: "12px",
    height: "22px",
    width: "60px",
  };

  const colors = [
    "#E0EFFA",
    "#E0EFFA",
    "#D7F6EB",
    "#D7F6EB",
    "#FFEDC7",
    "#FFEDC7",
    "#E6E3FC",
    "#E6E3FC",
    "#FCECEC",
    "#FCECEC",
    "#F7E6FF",
    "#F7E6FF",
    "#A5CBE6",
    "#A5CBE6",
    "#A5E6C6",
    "#A5E6C6",
    "#FFE6A5",
    "#FFE6A5",
    "#C6B1FF",
    "#C6B1FF",
    "#FFC6C6",
    "#FFC6C6",
    "#FFB1FF",
    "#FFB1FF",
  ];

  const formatExportData = (data, fn, fileName) => {
    let formattedColumns = [];
    let formattedDataSource = [];
    switch (data.graphType) {
      case "bar":
      case "column":
      case "line": {
        for (let [index, value] of Object.keys(data.data[0]).entries()) {
          if (value === "value") {
            formattedColumns.push({
              title: `${data.dataType} (${data.unit})`?.toUpperCase(),
              key: value,
            });
          } else if (value === "label") {
            formattedColumns.push({
              title: `YEAR`,
              key: value,
            });
          } else {
            formattedColumns.push({
              title: value?.toUpperCase(),
              key: value,
            });
          }
        }

        for (let [index, value] of data.data.entries()) {
          let obj = {};
          for (let elem of formattedColumns) {
            let newKey = elem.key;
            let exportKey = elem.title;
            obj[exportKey] =
              typeof value[newKey] === "number" && !isNaN(value[newKey])
                ? value[newKey] % 1 === 0
                  ? fn(value[newKey], 0)
                  : fn(value[newKey], 2)
                : value[newKey];
          }
          formattedDataSource.push(obj);
        }
        break;
      }

      case "doughnut":
      case "pie": {
        for (let [index, value] of Object.keys(data.data[0]).entries()) {
          let newTitle = "";

          if (value === "label" && Number(data.data[0][value]) === NaN) {
            formattedColumns.push({
              title: "YEAR",
            });
          } else if (value === "value") {
            formattedColumns.push({
              title: `${data.dataType} (${data.unit})`?.toUpperCase(),
              key: value,
            });
          } else {
            formattedColumns.push({
              title: value?.toUpperCase(),
              key: value,
            });
          }
        }

        for (let [index, value] of data.data.entries()) {
          let obj = {};
          for (let elem of formattedColumns) {
            let newKey = elem.key;
            let exportKey = elem.title;
            obj[exportKey] =
              typeof value[newKey] === "number" && !isNaN(value[newKey])
                ? value[newKey] % 1 === 0
                  ? fn(value[newKey], 0)
                  : fn(value[newKey], 2)
                : value[newKey];
          }
          formattedDataSource.push(obj);
        }

        break;
      }

      case "Hstackedbar":
      case "stackedColumn": {
        for (let [index, value] of Object.keys(
          data.data.catagory[0]
        ).entries()) {
          let newTitle = "";
          if (
            value === "label" &&
            Number(data.data.catagory[0][value]) !== NaN
          ) {
            newTitle = "YEAR";
          } else {
            newTitle = value.toUpperCase();
          }
          formattedColumns.push({
            title: newTitle,
          });
        }
        for (let [index, value] of data.data.data.entries()) {
          let serName = value.seriesname
            .replaceAll("-", "")
            .replaceAll("<br/>", "");
          formattedColumns.push({
            title: serName.toUpperCase(),
          });
        }
        for (let [index, value] of data.data.catagory.entries()) {
          let genKey = Object.keys(value)[0];
          let obj = {
            ["YEAR"]: value[genKey],
          };
          formattedDataSource.push(obj);
        }
        for (let [i, v] of formattedDataSource.entries()) {
          let obj = v;
          for (let [ind, val] of data.data.data.entries()) {
            let serName = val.seriesname
              .replaceAll("-", "")
              .replaceAll("<br/>", "");
            obj[serName?.toUpperCase()] =
              typeof val.data[i]?.value === "number" &&
              !isNaN(val.data[i]?.value)
                ? val.data[i]?.value % 1 === 0
                  ? fn(val.data[i]?.value, 0)
                  : fn(val.data[i]?.value, 2)
                : val.data[i]?.value;
          }
        }
        break;
      }

      case "":
      case "none":
      case null:
      case undefined: {
        formattedColumns = null;
        formattedDataSource = null;
        break;
      }
      default: {
        formattedColumns = null;
        formattedDataSource = null;
      }
    }

    for (let i = 0; i <= 2; i++) {
      let sourceObj = {};
      formattedColumns?.map((column, index) => {
        let key = column?.title;
        let val = "";
        if (i === 1) {
          val = index === 0 ? `${fileName}` : "";
        } else if (i === 2) {
          val = index === 0 ? "Source: Mordor Intelligence" : "";
        } else {
          val = "";
        }

        sourceObj[`${key}`] = val;
      });
      formattedDataSource.push(sourceObj);
    }
    return formattedDataSource;
  };

  useEffect(() => {
    getCurrentData();
  }, [currentGraph]);

  let getCurrentData = () => {
    let newDisplayData = [];
    if (data?.length === 2) {
      newDisplayData = data?.filter((data) => {
        return data?.data?.dataType.toLowerCase().trim() === currentGraph;
      });
    } else {
      let objToPush = data?.length ? data[0] : null;
      newDisplayData = [objToPush];
      let newDataType = data?.length ? data[0]?.data?.dataType : null;
      setDataType(newDataType?.toUpperCase());
    }
    setSubId(heading?.trim()?.toLowerCase());
    setDisplayData(newDisplayData[0]);
  };

  let handleViewChange = (checked) => {
    setCurrentView(checked ? "table" : "graph");
  };

  let handleGraphChange = (checked) => {
    setCurrentGraph(checked ? "value" : "volume");
  };

  const computeHeader = () => {
    if (displayData?.data?.graphType === "pie") {
      let splitLength = heading?.split(",").length;
      let newData = _.cloneDeep(heading?.split(","));

      //Replace second last item alternateHeading
      // newData[splitLength - 2] = " " + alternateHeading;
      return {
        tableHeader: newData
          .join(",")
          .replaceAll("%", `${displayData?.data?.units}`),
        graphHeader: newData.join(","),
      };
    }
    // return displayData?.data?.heading;

    return {
      tableHeader: heading,
      graphHeader: heading,
    };
  };

  const handleDownloadModalOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadModalClose = (event) => {
    setAnchorEl(null);
  };

  const export_chart = async (format, id) => {
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    format = format.trim().toLowerCase();

    let selectedChart = FusionCharts(id);
    if (selectedChart) {
      selectedChart?.exportChart({
        exportFormat: format,
      });
    }
    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);

      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit)
      );
    }
  };

  const exportAsTable = async (exportData, type) => {
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    let fileName = heading?.trim() + "-(" + alternateHeading?.trim() + ")";
    let exportType = exportFromJSON.types[type];
    let data = formatExportData(exportData, formatToDecimals, fileName);

    exportFromJSON({ data, fileName, exportType });

    if (total_credit != null) {
      let credits = await checkAndUpdateDownloadCredit(config);

      dispatch(
        setDownloadCredits(credits.total_credit, credits.remaining_credit)
      );
    }
  };

  const formatToUnits = (number, precision) => {
    const abbr = ["", "K", "M", "B", "T"];
    const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3);
    const order = Math.max(0, Math.min(unrangifiedOrder, abbr.length - 1));
    const suffix = abbr[order];
    return (number / Math.pow(10, order * 3)).toFixed(precision) + suffix;
  };

  const formatToDecimals = (number, precision) => {
    return Number(number)?.toFixed(precision);
  };

  const Open = Boolean(anchorEl);
  const id = Open ? "popover" : undefined;
  const labels = heading;
  const checkingForData =
    data?.data?.length === 0
      ? data?.userAccess === false
        ? false
        : false
      : true;

  return expand ? (
    <Box
      sx={{
        flex: "0 1 49%",
        boxSizing: "border-box",
        boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.1)",
        borderRadius: "8px",
        marginBottom: "2rem",
        position: "relative",
        overflow: "hidden",
        border: "1px solid grey",
        "&:hover": {
          // cursor: "pointer",
          boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.25)",
        },
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px",
        }}
      >
        {data?.length === 2 ? (
          <Switch
            disabled={data?.length !== 2}
            onChange={handleGraphChange}
            checkedChildren={data?.length !== 2 ? dataType : "VALUE"}
            unCheckedChildren={data?.length !== 2 ? dataType : "VOLUME"}
            handleBg="#ff0000"
            style={switchStyles}
          />
        ) : null}
        {checkingForData && (
          <Switch
            onChange={handleViewChange}
            checkedChildren="TABLE"
            unCheckedChildren="GRAPH"
            style={switchStyles}
          />
        )}

        {checkingForData && hasAccess && !blurred && (
          <Tooltip title={"View Export Options"} placement="top">
            <Button
              style={downloadButtonStyles}
              onClick={handleDownloadModalOpen}
            >
              <img src={downloadIcon} alt="Download" />
            </Button>
          </Tooltip>
        )}

        <Popover
          id={id}
          open={Open}
          anchorEl={anchorEl}
          onClose={handleDownloadModalClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {currentView === "graph" && (
            <>
              <p>
                <Button
                  sx={{ width: "100%" }}
                  onClick={() => {
                    export_chart(
                      "jpg",
                      (
                        heading?.trim() +
                        "-" +
                        alternateHeading?.trim()
                      )?.toLowerCase()
                    );
                  }}
                >
                  Export As JPG
                </Button>
              </p>
              <p>
                <Button
                  sx={{ width: "100%" }}
                  onClick={() => {
                    export_chart(
                      "png",
                      (
                        heading?.trim() +
                        "-" +
                        alternateHeading?.trim()
                      )?.toLowerCase()
                    );
                  }}
                >
                  Export As PNG
                </Button>
              </p>
              <p>
                <Button
                  sx={{ width: "100%" }}
                  onClick={() => {
                    export_chart(
                      "svg",
                      (
                        heading?.trim() +
                        "-" +
                        alternateHeading?.trim()
                      )?.toLowerCase()
                    );
                  }}
                >
                  Export As SVG
                </Button>
              </p>
              <p>
                <Button
                  sx={{ width: "100%" }}
                  onClick={() => {
                    export_chart(
                      "pdf",
                      (
                        heading?.trim() +
                        "-" +
                        alternateHeading?.trim()
                      )?.toLowerCase()
                    );
                  }}
                >
                  Export As PDF
                </Button>
              </p>
            </>
          )}
          {currentView === "table" && (
            <>
              <p>
                <Button
                  sx={{ width: "100%" }}
                  onClick={() => exportAsTable(data, "csv")}
                >
                  Export As CSV
                </Button>
              </p>
              <p>
                <Button
                  sx={{ width: "100%" }}
                  onClick={() => exportAsTable(data, "xls")}
                >
                  Export As XLS
                </Button>
              </p>
            </>
          )}
        </Popover>

        {!graphLength ? (
          <Button onClick={() => setExpand(false)}>
            {`Hide ${currentView}`}
          </Button>
        ) : (
          ""
        )}
      </Box>
      {blurred && (
        <div style={{ margin: "0px 10px", textTransform: "uppercase" }}>
          <h2> {computeHeader()?.tableHeader}</h2>
          <div>{alternateHeading}</div>
        </div>
      )}
      {currentView === "graph" ? (
        <>
          {/* {data.map((item) => ( */}
          <div
            style={{
              filter: blurred ? "blur(5px)" : "",
              pointerEvents: blurred ? "none" : "",
            }}
          >
            <Graph
              key={data.graphType} // Assuming graphType is unique for each graph
              data={
                data?.data?.length === 0
                  ? data?.userAccess === false
                    ? "hidden"
                    : "notAvailable"
                  : data?.data
              }
              meta={{}}
              id={(
                heading?.trim() +
                "-" +
                alternateHeading?.trim()
              )?.toLowerCase()}
              header={heading}
              subheading={alternateHeading}
              width={"100%"}
              height={"100%"}
              yAxisName={data?.yAxisName}
              xAxisName={data?.xAxisName}
              dynamiclegends={false}
              graphType={data?.graphType}
              dataHidden={data?.data?.length === 0}
              allow_cagr={false}
              cagr_year={""}
              cagr_value={""}
              showValues={true}
              valueFontSize={12}
              valueFontBold={true}
              templateName={""}
              slideName={""}
              headingColor={"#ffffff"}
              handleDrawerOpen={handleDrawerOpen}
            />
          </div>
          {blurred && (
            <LockBoxNew
              sx={{
                top: "40%",
                left: "50%",
                zIndex: "5",
                width: "351px",
              }}
              componentName={"Visualize"}
              purchaseCustomText={"Purchase the hub to gain access."}
              toShowText={"You don't have access for "}
            ></LockBoxNew>
          )}
        </>
      ) : (
        <>
          <Box
            sx={{
              height: "595px",
              paddingBottom: "0",
              filter: blurred ? "blur(5px)" : "",
              pointerEvents: blurred ? "none" : "",
            }}
          >
            <Table
              tableData={data}
              // labels={labels}
              parentTitle={heading}
              heading={computeHeader()?.tableHeader}
              subheading={alternateHeading}
            />
          </Box>
          {blurred && (
            <LockBoxNew
              onclick={() =>
                remaining_ondemand_credit > 0
                  ? showModal(data.reportInfo)
                  : false
              }
              sx={{
                top: "40%",
                left: "50%",
                zIndex: "5",
                width: "351px",
              }}
              componentName={"Visualize"}
              purchaseCustomText={"Purchase the hub to gain access."}
              toShowText={"You don't have access for "}
            ></LockBoxNew>
          )}
        </>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "3px",
        alignItems: "center",
        padding: "3px",
        margin: "3px",
        boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.1)",
        border: "1px solid grey",
        borderRadius: "8px",
        marginBottom: "1rem",
        overflow: "hidden",
        "&:hover": {
          // cursor: "pointer",
          boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.25)",
        },
      }}
    >
      <Typography>{alternateHeading}</Typography>
      <Button onClick={() => setExpand(true)}>View Graph</Button>
    </Box>
  );
}

export default ViewVisualise;
