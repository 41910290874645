import { FormControl, MenuItem, Select } from "@mui/material";
import { usePurchaseHistoryCtx } from "../STATE";

const menuItemCss = {
  width: "246px",
  p: "16px 24px",
  height: "56px",
  color: "#2d2d2d",
  justifyContent: "start",
  gap: "12px",
  alignItems: "center",
  textTransform: "capitalize",
  "&.Mui-selected": {
    color: "#189CDE",
  },
  "&.MuiMenuItem-root:not(:last-child)": {
    borderBottom: "1px solid #E4E4E4",
  },
};

function Anytime() {
  const {
    state: { filterType },
    dispatch,
  } = usePurchaseHistoryCtx();

  const handleFilterTypeChange = (e) => {
    dispatch({ type: "SET_FILTER_TYPE", payload: e.target.value });
  }

  return (
    <FormControl
      sx={{
        mr: "24px",
        alignSelf: "flex-end",
      }}
    >
      <Select
        sx={{
          fontWeight: "bold",
          width: "139px",
          height: "50px",
          textTransform: "capitalize",
          color: "#002F75",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#002F75",
          },
          "&.MuiList-root": {
            paddingTop: 0,
            paddingBottom: 0,
            width: "240px",
          },
        }}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        value={filterType}
        onChange={handleFilterTypeChange}
      >
        <MenuItem sx={menuItemCss} value={null}>
          <p>Anytime</p>
        </MenuItem>
        <MenuItem sx={menuItemCss} value={30}>
          Last 30 Days
        </MenuItem>
        <MenuItem sx={menuItemCss} value={6 * 30}>
          Last 6 Months
        </MenuItem>
        <MenuItem sx={menuItemCss} value={2023}>
          2023
        </MenuItem>
        <MenuItem sx={menuItemCss} value={2022}>
          2022
        </MenuItem>
        <MenuItem sx={menuItemCss} value={Infinity}>
          Older
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default Anytime;
