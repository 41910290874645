import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Box,
  Tooltip,
  Hidden,
  Popover,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const AccordianFilter = ({ data, allSelectedValues, setAllSelectedValues, superIndex, setFilteredData, setFilterForGraph, selectedValues, setSelectedValues, toExpand, setToExpand, newFilterNames}) => {
  const [expanded, setExpanded] = useState(false);
  // const [selectAllValues, setSelectAllValues] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [checkingLength, setCheckingLength] = useState([])
  const [values, setValues] = useState(Object.values(data[0]));
  const [heading, setHeading] = useState(Object.keys(data[0])[0])
  const handleChange = (heading) => {
    if (heading === toExpand) {
      setToExpand(null);
    }
    else { 
      setToExpand(heading);
    }
    // setExpanded(!expanded);
  };

  useEffect(() => { 
    if (selectedValues.length === 0) { 
      setCheckingLength([]);
    }
  },[selectedValues])


  // const handleChangeCheckbox = (value, group, index) => {
  //   if (value === 'Select All') {
  //     // Handle the "Select All" checkbox separately
  //     const allValues = data.find((category) => Object.keys(category)[0] === group)[group];
  //     const isChecked = selectAllValues.includes(group);
  
  //     if (isChecked) {
  //       // If "Select All" is checked, unselect all values
  //       setSelectAllValues(selectAllValues.filter((g) => g !== group));
  //       setSelectedValues(selectedValues.filter((v) => v.group !== group));
  //     } else {
  //       // If "Select All" is unchecked, select all values
  //       setSelectAllValues([...selectAllValues, group]);
  //       setSelectedValues([...selectedValues, ...allValues.map((v, i) => ({ value: v, group, index: i, superIndex: superIndex }))]);
  //     }
  //   } else {
  //     // Handle other checkboxes
  //     const isChecked = selectedValues.some((v) => v.value === value && v.group === group);
  
  //     if (isChecked) {
  //       // If the checkbox is already selected, unselect it
  //       setSelectedValues(selectedValues.filter((v) => !(v.value === value && v.group === group && v.index === index)));
  //     } else {
  //       // If the checkbox is not selected, add it to the selected values
  //       setSelectedValues([...selectedValues, { value, group, index, superIndex: superIndex }]);
  //     }
  
  //     // Check the "Select All" checkbox only if all checkboxes in the group are selected
  //     const allValues = data.find((category) => Object.keys(category)[0] === group)[group];
  //     const allSelected = allValues.every((v) => selectedValues.some((s) => s.value === v && s.group === group));
  //     if (allSelected) {
  //       setSelectAllValues([...selectAllValues, group]);
  //     } else {
  //       setSelectAllValues(selectAllValues.filter((g) => g !== group));
  //     }
  //   }
  // };
  function handleChangeCheckbox(value, group, index) {
    if (value !== 'Select All') {
      const isChecked = selectedValues.some((v) => v.value === value && v.group === group);
      if (isChecked) {
        setSelectedValues(selectedValues.filter((v) => !(v.value === value && v.group === group && v.index === index)));
        setCheckingLength(checkingLength.filter((v) => !(v.value === value && v.group === group && v.index === index)));
      } else {
        setSelectedValues([...selectedValues, { value, group, index, superIndex: superIndex }]);
        setCheckingLength([...checkingLength, { value, group, index, superIndex: superIndex }]);
      }
    }
    else {
      const isChecked = checkingLength.length === values[0].length
      const addMissingValues = (values, selectedValues) =>{
        // Create a set of existing values from selectedValues
        const existingValuesSet = new Set(selectedValues.map(item => item.value && item.group));
      
        // Iterate over the values array
        values[0].forEach((value) => {
          // Check if the value is not present in selectedValues
          // if (!existingValuesSet.has(value)) {
            // Add the missing value to selectedValues
            selectedValues.push({
              value: value,
              group: group,
              index: index,
              superIndex: superIndex
            });
          // }
        });
      
        return selectedValues.filter(
          (item, index, array) =>
              array.findIndex(
                  obj => obj.value === item.value && obj.group === item.group
              ) === index
      );
      }

      if (!isChecked) { 
        const first = addMissingValues(values, selectedValues);
        const filteredArray = first.filter(item => item.group === group);
        setSelectedValues(first)
        setCheckingLength(filteredArray);
      } else {
        setSelectedValues(removeValues(group, selectedValues));
        setCheckingLength([]); // unselect all
       }
    }
  };

  const removeValues = (group, selectedValues) => {
    // Create a set of values to be removed
    // const valuesSet = new Set(values[0]);
  
    // Filter selectedValues to exclude values present in the values array
    const filteredValues = selectedValues.filter(item => item.group !== group);
  
    return filteredValues;
  };

//   const handleClearAll = () => {
//     setSelectedValues([]);
//     setSelectAllValues([]);
    //   };
    
  const handleClear = (group) => {
    setSelectedValues(removeValues(group, selectedValues));
    setCheckingLength([]); // unselect all
  };
  
  function isStringPresent(targetString, stringArray) {
    return stringArray.includes(targetString);
  }

  const handleApplyButton = (index, category) => {

    const keys = Object.keys(category);
    const key = keys[0];

    const uniqueGroupNames = [...new Set(selectedValues.map(item => item.group))];

    const isPresent = isStringPresent(key, uniqueGroupNames);
    let filteredValues;
    if (isPresent) {
      filteredValues = allSelectedValues.filter(
        (item) => !uniqueGroupNames.includes(item.group)
      );
    }
    else { 
      filteredValues = allSelectedValues.filter(
        (item) => !uniqueGroupNames.includes(item.group) && item.group !== key
      );      
    }
    
      
      // Update state with the filtered values
      const updatedValues = [...filteredValues, ...selectedValues];
      setAllSelectedValues(updatedValues);
      // data.forEach((category, categoryIndex) => {
        //     Object.entries(category).forEach(([key, options]) => {
          //         const selectedCategoryValues = selectedValues.filter((v) => v.group === key);
          //         allSelectedValues.push(...selectedCategoryValues);
          //     });
          // });
          //   console.log(index, selectedValues, allSelectedValues,"kjabsfjabf");
          
          // const uniqueSelectedValues = allSelectedValues.filter(
            //     (value, index, self) =>
            //       index ===
            //       self.findIndex((v) => v.value === value.value && v.group === value.group)
            // );
            //   setAllSelectedValues(uniqueSelectedValues);
    //   console.log('All Selected Values:', uniqueSelectedValues);
            
            const highestIndexMap = new Map();
            
            // Update the map with the highest index for each superIndex
            updatedValues.forEach(item => {
              const { superIndex, index } = item;
              if (!highestIndexMap.has(superIndex) || index > highestIndexMap.get(superIndex)) {
                highestIndexMap.set(superIndex, index);
              }
            });
            
            // console.log(index, "ajcnkjc", key,selectedValues,uniqueGroupNames,filteredValues,updatedValues);
            // Filter the input array based on the highest index for each superIndex
            const resultArray = updatedValues.filter(item => item.index === highestIndexMap.get(item.superIndex));
            
            setFilterForGraph(resultArray)
    const formattingData = convertToDataOneFormat(updatedValues);
            setFilteredData(formattingData)

          let groupToDisplay = null;

          // Check if there is any item in resultArray with a matching superIndex and lower index
          const isYellowButton = resultArray.some(item => {
            if (item.superIndex === superIndex && item.index > index) {
              groupToDisplay = item.group;
              return true;
            }
            return false;
          });

          if (isYellowButton) {
            // Show an alert with the desired message
            alert(`Graph will be based on ${groupToDisplay}.`);
          }
      };
    
  const convertToDataOneFormat = (array) => {
    const result = {};
  
    array.forEach(({ value, group }) => {
      if (!result[group]) {
        result[group] = [value];
      } else {
        result[group].push(value);
      }
    });
  
    return result;
  };

  return (
    <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    mb: 1,
    width: '100%',
  }}
>
  {data.map((category, index) =>
    Object.entries(category).map(([key, options]) => {
      const sortedOptions = [
        ...(options || [])
          .filter(option => option.toLowerCase() !== "all")
          .sort((option1, option2) =>
            option1.toLowerCase().localeCompare(option2.toLowerCase())
          )
      ];
      if (options?.some(option => option.toLowerCase() === "all")) {
        sortedOptions.unshift("All");
      }
      return (
      <React.Fragment key={key}>
        <Button aria-describedby={key} variant="contained" onClick={handleClick}
          sx={{display:"flex",justifyContent:"space-between",textTransform:"none"}}
          >
          <Tooltip title={newFilterNames[key]} arrow placement="top">
          <Typography sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            {newFilterNames[key]}
          </Typography>

          </Tooltip>
          <KeyboardArrowDownIcon/>
</Button>
<Popover
  id={key}
  open={open}
  anchorEl={anchorEl}
  onClose={handleClose}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
        >
          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between",
    alignItems:"center",
    position: "sticky",
    top: 0,
    background: '#e1f5fe', // Light blue background
    zIndex: 1, // Ensures it appears above other elements
    padding: '8px', // Adjust the padding as needed
  }}
>
            {newFilterNames[key]}
            <Button
               variant="contained"
    onClick={() => handleClose()}
    sx={{
      backgroundColor: '#1976d2', // Primary color
      color: 'white', // Text color
    }}
  >
    <CloseIcon/>
  </Button>
</Box>

<Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 1,
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        pl: 3,
                        display: 'flex',
                        alignItems: 'center',
                        '&:hover': {
                          borderRight: 'solid #1976d2 1px',
                          background: '#f1f1f1',
                        },
                      }}
            >
                      <FormControlLabel
                        sx={{
                          width: '30px',
                        }}
                        control={
                          <Checkbox
                            checked={checkingLength.length === options.length}
                            onChange={() => handleChangeCheckbox('Select All', key, index)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />                          
                        }
                      />
                      <Typography
                        sx={{
                          fontWeight: '400',
                          fontSize: '14px',
                          color: '#2D2D2D',
                        }}
                      >
                        Select All
                      </Typography>
                    </Box>
                    {sortedOptions?.map((option,i) => (
                      <Box
                        key={option}
                        sx={{
                          pl: 3,
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            borderRight: 'solid #1976d2 1px',
                            background: '#f1f1f1',
                          },
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            width: '30px',
                          }}
                          control={
                            <Checkbox
                              checked={selectedValues.some((v) => v.value === option && v.group === key)}
                              onChange={() => handleChangeCheckbox(option, key, index)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                        />
                        <Typography
                          sx={{
                            fontWeight: '400',
                            fontSize: '14px',
                            color: '#2D2D2D',
                          }}
                        >
                          {`${option}`}
                        </Typography>
                      </Box>
                    ))}
                    <Box sx={{ display: 'flex', padding: '0px 24px 24px 24px',position: "sticky",
    bottom: 0,
    background: '#e1f5fe', // Light blue background
    zIndex: 1, // Ensures it appears above other elements
    padding: '8px', }}>
                      <Button
                        sx={{
                          backgroundColor: 'white',
                          width: '127px',
                          height: '40px',
                          padding: '8px 0px 8px 0px',
                          textTransform: 'none',
                          }}
                          onClick={() => handleClear(key)}
                      >
                        Clear
                      </Button>
                      <Button
                      onClick={() => {
                        handleApplyButton(index, category);
                        handleClose();
                      }}
                        variant="contained"
                        sx={{
                          backgroundColor: '#189CDE',
                          width: '127px',
                          height: '40px',
                          padding: '8px 0px 8px 0px',
                          textTransform: 'none',
                        }}
                      >
                        Apply
                      </Button>
                    </Box>
                  </Box>
        </Popover>
        </React.Fragment>
    )})
  )}
</Box>
  );
};

export default AccordianFilter;
// AccordianFilterCss