import React from "react";

import { TemplateView, Row } from "../templates.styles";

import Analysis from "../../components/TemplateComponents/Analysis";
import Heading from "../../components/TemplateComponents/Heading";
import SubHeading from "../../components/TemplateComponents/SubHeading";
import Table from "../../components/TemplateComponents/Table";
import Boxes from "../../components/TemplateComponents/Boxes";
import Graph from "../../Graphs";
import _ from "lodash";

export default class Template1001 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templateData: [],
    };
  }

  componentDidMount() {
    if (this.props?.templateData) {
      this.setState({
        templateData: this.props.templateData,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      if (this.props?.templateData) {
        this.setState({
          templateData: this.props.templateData,
        });
      }
    }
  }

  render() {
    let templateViewData = [...this.state.templateData].filter(
      (item) => item.position === 1 || item.position === 4
    );

    return (
      <TemplateView
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        {templateViewData?.map((item) => {
          if (item.type === "analysis") {
            return (
              <Row
                key={_.uniqueId()}
                width={"100%"}
                height={"50%"}
                flexDirection={"column"}
              >
                {item?.data?.meta?.heading ? (
                  <SubHeading subheading={item.data.meta.heading} />
                ) : (
                  ""
                )}
                <Analysis analysisData={item?.data?.analysis} />
              </Row>
            );
          } else if (item.type === "graph") {
            return (
              <Row width={"100%"} height={"50%"}>
                <Graph
                  data={item?.data?.data}
                  graphType={item?.data?.graphType}
                  dataHidden={
                    item?.data?.dataHidden && item.data.dataHidden === true
                      ? true
                      : false
                  }
                  header={item?.data?.heading}
                  meta={item?.data?.meta}
                  allow_cagr={
                    item?.data?.hasOwnProperty("cagr_value") ? true : false
                  }
                  cagr_year={item?.data?.cagr_year}
                  cagr_value={item?.data?.cagr_value}
                  footer={""}
                  width={"100%"}
                  height={"50%"}
                  yAxisName={"yaxis"}
                  xAxisName={"xaxis"}
                  showValues={true}
                  valueFontSize={12}
                  valueFontBold={true}
                  templateName={this.props.templateName}
                  slideName={this.props.slideName}
                />
              </Row>
            );
          } else if (item.type === "table") {
            return (
              <Row width={"100%"} height={"50%"}>
                <Table
                  tableData={item?.data}
                  parentTitle={this.props.parentTitle}
                />
              </Row>
            );
          } else if (item.type === "boxes") {
            return (
              <Row width={"100%"} height={"50%"}>
                <div>Blocks</div>
              </Row>
            );
          }
        })}
      </TemplateView>
    );
  }
}
