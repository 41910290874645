const setBasedOnSearch = (reports, search) => {
  if (!search) {
    return reports;
  }
  return reports.filter((report) =>
    report.title.toLowerCase().startsWith(search.toLowerCase())
  );
};

export const setBasedOnPagination = (reports, { page, limit }) => {
  const start = (page - 1) * limit;
  const end = page * limit;
  const companiesList = [];

  for (let i = start; i < end; i++) {
    if (reports[i]) {
      companiesList.push(reports[i]);
    }
  }
  return companiesList;
};

export const getModifiedCompaniesReports = (state) => {
  if(state.currentTab === "MY_REPORTS") {
    return setBasedOnPagination(
      setBasedOnSearch(state.reports.filter((report) => report.subscribed === "yes"), state.search),
      state.pagination
    );
  }
  return setBasedOnPagination(
    setBasedOnSearch(state.reports, state.search),
    state.pagination
  );
};

export const getModifiedAllCompanyReportsCount = (state) => {
  if(state.currentTab === "ALL_REPORTS") {
    return setBasedOnSearch(state.reports, state.search).length;
  }
  return state.reports.length;
}

export const getModifiedMyCompanyReportsCount = (state) => {
  if(state.currentTab === "MY_REPORTS") {
    return setBasedOnSearch(state.reports.filter((report) => report.subscribed === "yes"), state.search).length;
  }
  return state.reports.filter((report) => report.subscribed === "yes").length;
}