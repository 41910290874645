import React, { useState } from "react";
import styles from "./styles.module.css";
import openVisibleEyeImg from "../../../assets/eye_open.svg";
const visibleEyeImg = "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/visible_eye.svg";

function PasswordVisibilityInput(props) {
  const [visibleType, setVisibleType] = useState(true);
  return (
    <div className={styles.inputContainer}>
      <input {...props} type={visibleType ? "password" : "text"} />
      <button type="button">
        <img
          src={visibleType ? openVisibleEyeImg : visibleEyeImg  }
          alt="eye"
          onClick={() => setVisibleType((p) => !p)}
        />
      </button>
    </div>
  );
}

export default PasswordVisibilityInput;
