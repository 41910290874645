import {
  Box,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Switch
} from "@mui/material";
import { useState } from "react";
import { useReportsContext } from "../../STATE";

function CatalogueReportStatus() {
  const [isChecked, setIsChecked] = useState(false);
  const { state, dispatch, setParams } = useReportsContext();

  const handleCatalogueReportStatusChange = (e) => {
    setIsChecked(prev=>{
      return !prev;
    });

     let value = e.target.checked ? "requested_catalogue_reports" : "all_catalogue_reports"

    // console.log(e.target.checked, e.target.value, value, 'cataloguefilter');

    setParams("catalogueReportStatusFilter", value);
    dispatch({ type: "SET_PAGINATION", payload: { page: 1, limit: 10 } });
    dispatch({
      type: "SET_CATALOGUE_REPORT_STATUS_FILTER",
      payload: value,
    });
  };

  return (
    <>
    {/* <Box sx={{ pl: 2 }}>
      <FormControl>
        <RadioGroup
          row
          value={state.catalogueReportStatusFilter}
          onChange={handleCatalogueReportStatusChange}
        >
          <FormControlLabel
            value="all_catalogue_reports"
            control={<Radio />}
            label="All Reports"
          />
          <FormControlLabel
            value="purchased_catalogue_reports"
            control={<Radio />}
            label="Purchased"
          />
          <FormControlLabel
            value="requested_catalogue_reports"
            control={<Radio />}
            label="Requested"
          />
        </RadioGroup>
      </FormControl>
    </Box> */}
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.8rem",
      }}
    >
      <FormControlLabel
        control={<Switch />}
        label={ "Requested Reports"}
        checked={isChecked}
        onClick={handleCatalogueReportStatusChange}
        // disabled={!haveLibraryReports}
      />
    </Box>
    </>
  );
}

export default CatalogueReportStatus;
