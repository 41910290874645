import { Stack } from "@mui/material";
import FilterBy from "./FilterBy";
import styles from "./styles.module.css";
import Sort from "./Sort";

function Filters() {
  return (
    <Stack direction={"row"} gap={2} className={styles.mainFilterContainer}>
      <FilterBy />
      <Sort />
    </Stack>
  );
}

export default Filters;
