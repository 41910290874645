import { useNavigate } from "react-router-dom";

import { useState } from "react";
import { Box, Tab, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ArrowLeftOutlined } from "@mui/icons-material";
import { useAuth } from "../../context/authContext/Auth";
import Loader from "../../components/Common/Loader";
import NoData from "../../components/Common/NoData";
import UserManagement from "../../components/UserProfile/UserManagement";
import Favorites from "../../components/UserProfile/Favorites";
import ChangePassword from "../../components/UserProfile/ChangePassword";

export default function UsersProfile() {
  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(false);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const navigate = useNavigate();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Button
            startIcon={<ArrowLeftOutlined />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: ["100%", "80%", "50%"],
              }}
            >
              <TabList variant="fullWidth" onChange={handleChange}>
                <Tab label="Subcription details" value="1" />
                <Tab label="Favorites" value="2" />
                <Tab label="User Management" value="3" />
                <Tab label="Change Password" value="4" />
                <Tab label="Credits" value="5" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <NoData />
            </TabPanel>
            <TabPanel value="2">
              <Favorites/>
            </TabPanel>
            <TabPanel value="3">
             <UserManagement/>
            </TabPanel>
            <TabPanel value="4">
              <ChangePassword />
            </TabPanel>
            <TabPanel value="5">
              <NoData />
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </>
  );
}

