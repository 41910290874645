import styled from "styled-components";

export const Container = styled.div`
 width: 100%;
 max-width: 1738px;
 margin: 0 auto;
 display: flex;
 justify-content: space-between;
 flex-direction: row;
`;

export const TabsContainer = styled.div`
 width: 100%;
`;