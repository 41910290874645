import axios from "axios";
import { requestInterceptor, responseInterceptor, errorInterceptor } from "./interceptor";

export const SYNAPSE_API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

SYNAPSE_API.interceptors.request.use(requestInterceptor);
SYNAPSE_API.interceptors.response.use(responseInterceptor, errorInterceptor);

const API = {
  Base_URL: process.env.REACT_APP_API_BASE_URL,
  debugMode: process.env.REACT_APP_DEBUG === "true",
  environment: process.env.REACT_APP_ENVIRONMENT,
  login: "/api/users/login",
  CRYPTO_SECRET_KEY: process.env.REACT_APP_REACT_CRYPTO_SECRET,
};

export default API;
