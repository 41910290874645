import {
  Autocomplete,
  Box,
  Button,
  Container,
  CssBaseline,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Pagination,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState, memo } from "react";
import { RiSearchLine } from "react-icons/ri";
import { useAuth } from "../../context/authContext/Auth";
import Notification from "../Common/Notification";
import HubsReportCard from "./HubsReportCard";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../Common/Loader";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ContactForm from "../Common/Form";
import Fuse from "fuse.js";
import { useMediaQuery } from "@mui/material";
import FilterSidebar from "../Common/FilterSidebar";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import API from "../../api/Api";
//Import lodash
import _ from "lodash";
import { ChevronLeftOutlined, Email } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import RenewDrawer from "../Common/RenewDrawer";

const localCashe = {};
const drawerWidth = "90%";

const ReportsSection = ({
  id,
  hubTitle,
  getCounts,
  content,
  callCheckSubscribed,
}) => {
  const [data, setData] = useState([]);
  const [initData, setInitData] = useState([]);
  const [mainData, setMainData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  const [fuse, setFuse] = useState(null);
  const [queryData, setQueryData] = useState([]);

  const [count, setCount] = useState(0);

  const [page, setPage] = useState(1);
  const [region, setRegion] = useState("");

  const [dimensions, setDimensions] = useState({});
  const [selectedDimensions, setSelectedDimensions] = useState({});

  const { config, notify, setNotify } = useAuth();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpen2, setDrawerOpen2] = useState(false);
  const isMobile = useMediaQuery("(max-width: 960px)");
  const [countsReports, setCountReports] = useState(
    // JSON.parse(sessionStorage.getItem("reportCount")) || {
    //   myReportsCount: 0,
    //   totolReportsCount: 0,
    // }
    {
      myReportsCount: 0,
      totolReportsCount: 0,
    }
  );

  const dispatch = useDispatch();

  const location = useLocation();

  const URL = location.pathname;

  const { Base_URL } = API;

  // const heading = URL !== "/bookmarks" ? hubTitle : "";
  // const hubContent = (val) => {
  //   console.log(val, 'val')
  //   if(val === undefined){
  //     return ""
  //   }
  //   if(val.length > 1 && val !== undefined){
  //     return val
  //   }else{
  //    return "We offer fully tailored intelligence solutions to meet the unique business requirements of any organization. Our deep industry expertise coupled with cross-functional analyst teams ensures we can support the intelligence requirements of even the most specific of business problems.";
  //   }
  // }

  // useEffect(() => {
  //   dispatch({
  //     type: "SET_NAVBAR_DATA",
  //     payload: {
  //       heading: heading,
  //       paragraph: URL !== "/bookmarks" ? hubContent(content) : "",
  //       backButtonData: URL !== "/bookmarks" ? "My Hubs" : null,
  //       talkToUs: URL === "/bookmarks" ? null : true,
  //       downloadBrochure: URL === "/bookmarks" ? null : id,
  //       bannerHeight: URL === "/bookmarks" ? "260px" : null,
  //     },
  //   });
  // }, []);

  useEffect(() => {
    // Update the fuse object when data or initData changes
    const options = {
      threshold: 0.2,
      keys: ["title", "description"],
      includeScore: true,
    };
    const newFuse = new Fuse(data, options);
    setFuse(newFuse);
    if (region) {
    } else {
    }
  }, [data]);

  useEffect(() => {
    // Update the search results when the query or fuse changes
    if (fuse && query.length > 0) {
      const results = fuse.search(query);
      const items = results?.map((obj) => obj.item);
      setQueryData(items);
    } else if (data && query.length === 0) {
      setQueryData(data);
    }
  }, [query, fuse]);
  const [myReport, setMyReport] = useState([]);
  const [allReport, setAllReport] = useState([]);

  const [currentReportType, setCurrentReportType] = useState("All");

  useEffect(() => {
    // if (URL === "/bookmarks") {
    //   getReportData();
    // } else if (!id && URL !== "/bookmarks") {
    //   setData([]);
    //   setQueryData([]);
    //   setInitData([]);
    // } else if (localCashe[id]) {
    //   setData(localCashe[id]);
    //   setAllReport(localCashe[id]);
    //   setMyReport(localCashe[id]?.filter((item) => item.subscribed === "yes"));
    //   setQueryData(localCashe[id]);
    //   setInitData(localCashe[id]);
    // } else {
    //   getReportData();
    // }
    if (
      sessionStorage.getItem("hubsTabId") == 1 ||
      sessionStorage.getItem("hubsTabId") === null
    ) {
      getReportData();
    }
  }, []);

  useEffect(() => {
    if (currentReportType === "All") {
      setData(allReport);
      setQueryData(data);
      setInitData(data);
    } else if (currentReportType === "My Reports") {
      setData(myReport);
      setQueryData(myReport);
      setInitData(myReport);
    }
  }, [currentReportType]);

  async function getReportData() {
    setLoading(true);
    setCurrentReportType("All");
    const HubReports = `/api/hubs/reports?hubId=${id}`;
    const favReports = "/api/hubs/favourite-reports";

    const endPoint = URL !== "/bookmarks" ? HubReports : favReports;

    axios
      .get(
        `${Base_URL}${endPoint}`,

        config
      )
      .then((res) => {
        if (URL === "/bookmarks") {
          // localCashe[id] = res.data.data;
          let mainData = res.data.data;
          setData(res.data.data);
          setQueryData(mainData);
          setAllReport(mainData);
          setMyReport(mainData?.filter((item) => item.subscribed === "yes"));
          setInitData(mainData);
        } else {
          let mainData = res.data.data.data;
          setData(mainData);
          setQueryData(mainData);
          setAllReport(mainData);
          setMyReport(mainData?.filter((item) => item.subscribed === "yes"));
          setInitData(mainData);
        }

        setCountReports({
          myReportsCount: res.data.data.myReportsCount,
          totolReportsCount: res.data.data.totolReportsCount,
        });

        //Storing the count in the local session
        sessionStorage.setItem(
          "reportCount",
          JSON.stringify({
            myReportsCount: res.data.data.myReportsCount,
            totolReportsCount: res.data.data.totolReportsCount,
          })
        );

        // setData(localCashe[id]);
        // setQueryData(localCashe[id]);
        // setAllReport(localCashe[id]);
        // setMyReport(
        //   localCashe[id]?.filter((item) => item.subscribed === "yes")
        // );
        // setInitData(localCashe[id]);
        // setFuse(new Fuse(res.data?.data, options));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        if (URL === "/bookmarks") {
          getCounts();
        }
      });
  }

  // from CompaniesTab ⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️

  useEffect(() => {
    // Retrieve data from session storage when component mounts
    // const storedData = sessionStorage.getItem("reportDimensions") || {};
    // const storedData =
    //   JSON.parse(sessionStorage.getItem("reportDimensions")) || {};
    // const storedRegion = JSON.parse(sessionStorage.getItem("region")) || "";
    // setSelectedDimensions(storedData);
    // setRegion(storedRegion);
  }, []);

  // We are filterData once we are reloding the data if its availble
  const filteredData = useMemo(
    () => data?.filter((item) => item.dimensions.region.includes(region)),
    [data, region]
  );

  // const handleRegion = (key) => {
  //   setRegion(key);
  //   sessionStorage.setItem("region", JSON.stringify(key));

  //   if (key === "") {
  //     setInitData(data);
  //     // setObj(initData);
  //   } else {
  //     setInitData(
  //       data?.filter((item) => item.dimensions.region.includes(key))
  //     );
  //     // setObj(
  //     //   initData?.filter((item) => item.dimensions.region.includes(key))
  //     // );
  //   }
  // };

  useEffect(() => {
    setInitData(filteredData);
  }, [filteredData]);

  const regionData = [
    ...new Map(data?.map((m) => [m.dimensions.region, m])).values(),
  ];

  const handleRegion = (key) => {
    setRegion(key);
    sessionStorage.setItem("region", JSON.stringify(key));

    if (key === "") {
      setInitData(data);
      // setObj(initData);
    } else {
      setInitData(data?.filter((item) => item.dimensions.region.includes(key)));
      // setObj(
      //   initData?.filter((item) => item.dimensions.region.includes(key))
      // );
    }
  };

  useEffect(() => {
    const newDimensions = {};
    initData?.forEach((report) => {
      Object.keys(report.dimensions)?.forEach((dimension) => {
        if (!newDimensions[dimension]) {
          newDimensions[dimension] = new Set();
        }
        newDimensions[dimension].add(report.dimensions[dimension]);
      });
    });

    // Convert sets to arrays
    const dimensionsArr = {};
    Object.keys(newDimensions)?.forEach((dimension) => {
      // dimensionsArr[dimension] = Array.from(newDimensions[dimension]);
      //Sort the array
      dimensionsArr[dimension] = Array.from(newDimensions[dimension]).sort();
    });

    setDimensions(dimensionsArr);
  }, [initData]);

  const handleDimensionChange = (dimension, value) => {
    setSelectedDimensions((prevState) => ({
      ...prevState,
      [dimension]: value,
    }));

    // Retrieve existing data from session storage
    // const existingData = sessionStorage.getItem("reportDimensions") || {};
    // const existingData =
    //   JSON.parse(sessionStorage.getItem("reportDimensions")) || {};

    // Merge existing data with new data
    // const newData = { ...existingData, [dimension]: value };

    // Set merged data to session storage
    // sessionStorage.setItem("reportDimensions", JSON.stringify(newData));

    // sessionStorage.setItem("reportDimensions", {..., [dimension] : value});
  };

  const filteredReports =
    query.length >= 1
      ? queryData
      : initData?.filter((report) => {
          let includeReport = true;
          Object.keys(selectedDimensions)?.forEach((dimension) => {
            if (selectedDimensions[dimension] === "") {
              includeReport = true;
            } else if (
              selectedDimensions[dimension] !== "All" &&
              report.dimensions[dimension] !== selectedDimensions[dimension]
            ) {
              includeReport = false;
            }
          });
          return includeReport;
        });

  // If Subscribed is selected, filter out reports that are not subscribed

  // ⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️⬆️

  // This code is for the pagination⬇️⬇️

  const [PER_PAGE, SET_PER_PAGE] = useState(10);

  const _DATA = filteredReports?.slice((page - 1) * PER_PAGE, page * PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p + 1);
  };

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setCount(() =>
      filteredReports ? Math.ceil(filteredReports?.length / PER_PAGE) : 0
    );
    //Set the total count
    setTotalCount(filteredReports?.length);
  }, [filteredReports]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleClick = () => {
    // Handle the click event for the drawer items
  };

  const omitDimensions = ["subscribed"];
  const resetAll = () => {
    const updatedData = {};
    for (const key in selectedDimensions) {
      updatedData[key] = "";
    }
    // setSelectedDimensions(updatedData);
    setSelectedDimensions({});
    // setInitData(data);
    handleRegion("");

    setQuery("");

    sessionStorage.removeItem("reportDimensions");
    sessionStorage.removeItem("region");
    sessionStorage.removeItem("reportCount");
  };

  const handleChangeRowsPerPage = (event) => {
    SET_PER_PAGE(parseInt(event.target.value, 10));
    setPage(0 + 1);
  };

  const handleRegionChange = (child) => {
    const updatedData = {};
    for (const key in selectedDimensions) {
      updatedData[key] = "All";
    }

    setSelectedDimensions(updatedData);
    handleRegion(child);
    // setRegState(event.target.value);
  };
  return (
    <Container maxWidth="xl">
      <Grid
        container
        sx={{
          marginTop: "50px",
        }}
      >
        {windowWidth >= 900 && (
          <Grid item md={3}>
            <FilterSidebar
              data={dimensions}
              handleDimensionChange={handleDimensionChange}
              resetAll={resetAll}
              handleRegionChange={handleRegionChange}
              region={region}
              regionData={regionData}
              // dimensions={dimensions}
            />
          </Grid>
        )}
        <Grid item xs={11} md={9}>
          <Box>
            {URL !== "/bookmarks" && (
              <Box
                component="div"
                id="scroll-to-hub-reports"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  mb: "20px",
                  ml: "20px",
                }}
              >
                {currentReportType === "All" ? (
                  <Button
                    style={{
                      backgroundColor: "#446BA6",
                      padding: "8px 24px",
                      textTransform: "capitalize",
                      borderRadius: "8px",
                    }}
                    variant="contained"
                  >
                    All Reports {`(${countsReports.totolReportsCount ?? 0})`}
                  </Button>
                ) : (
                  //Show only text type button
                  <Button
                    variant="text"
                    sx={{
                      textTransform: "capitalize",
                      padding: "8px 24px",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      resetAll();
                      // handleDimensionChange("subscribed", "All");
                      setCurrentReportType("All");
                    }}
                  >
                    All Reports {`(${countsReports.totolReportsCount ?? 0})`}
                  </Button>
                )}
                {currentReportType === "My Reports" ? (
                  <Button
                    style={{
                      backgroundColor: "#446BA6",
                      padding: "8px 24px",
                      textTransform: "capitalize",
                      borderRadius: "8px",
                    }}
                    variant="contained"
                  >
                    My Reports {`(${countsReports.myReportsCount ?? 0})`}
                  </Button>
                ) : (
                  //Show only text type button
                  <Button
                    variant="text"
                    sx={{
                      textTransform: "capitalize",
                      padding: "8px 24px",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      // handleDimensionChange("subscribed", "yes");
                      resetAll();
                      setCurrentReportType("My Reports");
                    }}
                  >
                    My Reports {`(${countsReports.myReportsCount ?? 0})`}
                  </Button>
                )}
              </Box>
            )}
            <Notification notify={notify} setNotify={setNotify} />
            <Box
              display={"flex"}
              gap={5}
              my={2}
              sx={{
                flexDirection: "column",
                alignItems: "end",
                mt: ["0", "0", "-60px"],
                ml: "20px",
              }}
            >
              <Box display={"flex"} width="100%" alignItems={"center"}>
                <Box flex={1} textAlign={"end"}>
                  {/* <Button
                    variant="contained"
                    endIcon={<RestartAltIcon />}
                    sx={{
                      mx: "20px",
                      display: ["none", "none", "inline-flex"],
                    }}
                    onClick={() => {
                      // setData(initData);
                      // setInitData(data);
                      // setSelectStates(getStatesData);

                      const updatedData = {};
                      for (const key in selectedDimensions) {
                        updatedData[key] = "All";
                      }

                      setSelectedDimensions(updatedData);
                      // setInitData(data);
                      handleRegion("");

                      setQuery("");

                      sessionStorage.removeItem("reportDimensions");
                      sessionStorage.removeItem("region");
                    }}
                  >
                    Reset All
                  </Button> */}
                </Box>

                {/* <Box
            // flex={1}
            sx={{
              width: "400px",
              border: "1px solid black",
              borderRadius: "30px ",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search "
              inputProps={{ "aria-label": "search " }}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
          </Box> */}

                <Box
                  item
                  sx={{
                    display: ["block", "block", "none"],
                    padding: ["10px", "10px", "0px"],
                  }}
                >
                  {/* <IconButton onClick={handleDrawerOpen} aria-label="open drawer"> */}
                  <CssBaseline />
                  <span
                    onClick={handleDrawerOpen}
                    class="material-symbols-outlined"
                  >
                    tune
                  </span>
                  {/* </IconButton> */}
                </Box>
              </Box>
              {/* Want to add close icon in inputbase */}
              <Box
                // flex={1}
                sx={{
                  width: "100%",
                  border: "1px solid #D5D5D5",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                  marginTop: "2rem",
                  mb: "1rem",
                }}
              >
                {/* <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search relevant reports"
                  inputProps={{ "aria-label": "search " }}
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  endAdornment={
                    query.length > 0 && (
                      <IconButton
                        onClick={() => {
                          setQuery("");
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )
                  }
                /> */}

                <FormControl fullWidth>
                  <TextField
                    sx={{ "& .MuiInputBase-input": { borderRadius: "8px" } }}
                    placeholder="Search Reports"
                    variant="outlined"
                    fullWidth
                    value={query}
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <RiSearchLine
                            style={{ fontSize: "24px", color: "#002F75" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>

              {isMobile ? (
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={handleDrawerClose}
                  sx={{
                    width: "90%",
                    padding: "30px",
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: "90%",
                      boxSizing: "border-box",
                      padding: "20px",
                    },
                  }}
                >
                  <Box
                    width="100%"
                    my={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography ml={2} fontWeight={600}>
                      Filters
                    </Typography>
                    <Button
                      // variant="contained"
                      // sx={{ mx: "20px", display: "block" }}
                      onClick={() => {
                        // setData(initData);
                        // setInitData(data);
                        // setSelectStates(getStatesData);

                        const updatedData = {};
                        for (const key in selectedDimensions) {
                          updatedData[key] = "All";
                        }

                        setSelectedDimensions(updatedData);
                        // setInitData(data);
                        handleRegion("");

                        setQuery("");

                        sessionStorage.removeItem("reportDimensions");
                        sessionStorage.removeItem("region");
                        sessionStorage.removeItem("reportCount");
                      }}
                    >
                      Reset
                    </Button>
                    <Button onClick={handleDrawerClose} sx={{ color: "red" }}>
                      {" "}
                      X
                    </Button>
                  </Box>
                  <div onClick={handleClick} onKeyDown={handleClick}>
                    <Box
                      display="block"
                      gap={2}
                      sx={{
                        // flexWrap: "wrap",
                        // overflow: "hidden",
                        // overflowX: "scroll",
                        width: "100%",
                        pt: "20px",
                      }}
                    >
                      {/* Region */}

                      {/* {regionData?.map((region, i) => ( */}
                      <div
                        style={{
                          flex: "1",
                          // display: dimensions[dimension].length < 2 ? "none" : "block",
                        }}
                      >
                        <label
                          style={{
                            textTransform: "capitalize",
                            padding: "20px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Regions
                        </label>
                        <br />
                        <select
                          value={region}
                          onChange={(event) => {
                            const updatedData = {};
                            for (const key in selectedDimensions) {
                              updatedData[key] = "All";
                            }

                            setSelectedDimensions(updatedData);
                            handleRegion(event.target.value);
                            // setRegState(event.target.value);
                          }}
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#f5f5f5",
                            border: "none",
                            padding: "8px",
                            fontSize: "16px",
                            color: "#333",
                            outline: "none",
                            boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)",
                            width: "100%",
                            marginTop: "5px",
                          }}
                        >
                          <option value="All">All</option>
                          {regionData?.map((region) => {
                            const value = region?.dimensions?.region;
                            return (
                              // {regionData?.dimensions?.region.map((value) => (
                              <option
                                style={{
                                  display: value === "All" && "none",
                                  backgroundColor: "#f5f5f5",
                                  color: "#333",
                                }}
                                key={value}
                                value={value}
                              >
                                {value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* ))} */}

                      {/* Others Dimensions */}

                      {Object.keys(dimensions)?.map((dimension) => (
                        <div
                          style={{
                            flex: "1",
                            display:
                              dimensions[dimension].length < 2 ||
                              dimension === "region"
                                ? "none"
                                : "block",
                          }}
                          key={dimension}
                        >
                          <label
                            style={{
                              textTransform: "capitalize",
                              padding: "20px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {dimension.replace(/_/g, " ")}
                          </label>
                          <br />
                          <select
                            value={selectedDimensions[dimension]}
                            onChange={(event) =>
                              handleDimensionChange(
                                dimension,
                                event.target.value
                              )
                            }
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#f5f5f5",
                              border: "none",
                              padding: "8px",
                              fontSize: "16px",
                              color: "#333",
                              outline: "none",
                              boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)",
                              width: "100%",
                              marginTop: "5px",
                            }}
                          >
                            <option value="All">All</option>
                            {dimensions[dimension]?.map((value) => (
                              <option
                                style={{
                                  display: value === "All" && "none",
                                  backgroundColor: "#f5f5f5",
                                  color: "#333",
                                }}
                                key={value}
                                value={value}
                              >
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                      ))}
                    </Box>
                  </div>
                </Drawer>
              ) : null}
            </Box>

            <Box display="flex" gap={2}>
              <Box
                flex={1}
                display="flex"
                sx={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* {loading ? (
            <Loader />
          ) : ( */}

                <Box width="96%">
                  {_DATA?.map((items, i) => {
                    return (
                      <Box
                        key={i}
                        width="100%"
                        style={{ marginBottom: "2rem" }}
                      >
                        <HubsReportCard
                          {...items}
                          hubId={id}
                          // makeThisFavorite={makeThisFavorite}
                          // makeThisUnFavorite={makeThisUnFavorite}
                          getReportData={getReportData}
                          callCheckSubscribed={callCheckSubscribed}
                        />
                      </Box>
                    );
                  })}
                </Box>
                {!_DATA?.length && !loading && (
                  <Box
                    sx={{
                      width: "50vw",
                      height: "80vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography>
                      You do not have access to any reports from this hub. Get
                      access now.
                    </Typography>
                    <Button
                      startIcon={<Email />}
                      style={{
                        backgroundColor: "#189CDE",
                        color: "white",
                        borderRadius: "8px",
                        textTransform: "none",
                        width: "120px",
                        padding: "0.5rem",
                        marginTop: "1rem",
                      }}
                      variant="contained"
                      onClick={() => {
                        setDrawerOpen2(true);
                      }}
                    >
                      Contact Us
                    </Button>
                    <RenewDrawer
                      title={"Talk To Us"}
                      drawerOpen={drawerOpen2}
                      setDrawerOpen={setDrawerOpen2}
                      typeModal={"contact"}
                    />
                  </Box>
                )}

                <TablePagination
                  component="div"
                  count={totalCount}
                  page={page - 1}
                  onPageChange={handleChange}
                  rowsPerPage={PER_PAGE}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="View reports per page"
                  sx={{
                    ".MuiInputBase-root": {
                      backgroundColor: "#E0EFFA",
                      borderRadius: "8px",
                    },
                    ".MuiTablePagination-toolbar": {
                      backgroundColor: "white",
                      color: "rgb(41, 39, 39)",
                      height: "35px",
                    },
                    ".MuiBox-root": {
                      backgroundColor: "yellow",
                      color: "black",

                      "& .MuiSvgIcon-root": {
                        backgroundColor: "purple",
                        color: "black",
                      },
                    },
                    "MuiTablePagination-actions": {
                      color: "purple",
                    },
                    "& .MuiTablePagination-next": {
                      color: "red" /* Change the color of the next arrow */,
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const HubsReportsSection = memo(ReportsSection);

export default HubsReportsSection;
