import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "../../../context/authContext/Auth";
import { CircularProgress, Box } from "@mui/material";
import ParsedReportPage from "./ReportParser";
import RenewDrawer from "../../Common/RenewDrawer";
import API, { SYNAPSE_API } from "../../../api/Api";

function Snippet({ slug, fetchReport }) {
  const [htmlReportPage, setHtmlReportPage] = useState("");
  const [loading, setLoading] = useState("PENDING");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { selectedReportDetail: reportDetails } = useSelector((state) => state);

  const {
    title,
    report_id
    
  } = reportDetails;

  const { Base_URL } = API

  const { config } = useAuth();
  const fetchReportHtml = useCallback(() => {
    setLoading("PENDING");
    SYNAPSE_API.get(`${Base_URL}/api/hubs/report-page/${slug}`)
      .then((resp) => {
        setHtmlReportPage(resp.data.data);
      })
      .catch((error) => {
        console.error(error);
        setHtmlReportPage(`<div style="width:100%; height:50vh; display:flex; justify-content:center; align-items: center">
          <h1>Snippet not found for this report</h1>
        </div>`);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  }, [config, slug]);

  useEffect(() => {
    if (!reportDetails) return;
    fetchReportHtml();
  }, [fetchReportHtml, reportDetails]);

  return (
    <>
      <div style={{ display: loading === "PENDING" ? "block" : "none" }}>
        <ReportPageLoadingSection />;
      </div>
      <div style={{ display: loading === "PENDING" ? "none" : "block" }}>
       {htmlReportPage && <ParsedReportPage HTML={htmlReportPage} setDrawerOpen={setDrawerOpen} reportData={reportDetails} fetchReport={fetchReport} />}
      </div>
      <RenewDrawer  
      title={title}
      report_id={report_id}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
      typeModal={'buy'}
      />
    </>
  );
}

export default Snippet;

const ReportPageLoadingSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "468px",
        width: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
