import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import ChatDialog from "../GlobalComponents/ContactUs";
import _ from "lodash";

const HubsHomeInfo = ({ data, heading, isLoading }) => {
  // to open the Dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Container key={_.uniqueId()} sx={{ display: "flex", gap: 3 }}>
      <Box flex="4" display="flex" flexDirection="column" gap={3}>
        <Typography
          sx={{
            fontSize: "20px",
            lineHeight: "30px",
            fontWeight: "800",
          }}
          variant="h5"
        >
          {" "}
          {heading}
        </Typography>
        {data?.map((value) => {
          return (
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "25.6px",
              }}
              color="#5A5A5A"
              variant="body2"
              key={_.uniqueId()}
            >
              {value}
            </Typography>
          );
        })}
        {/* {!data && !isLoading && (
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "25.6px",
            }}
            color="#5A5A5A"
            variant="body2"
          >
            No data available
          </Typography>
        )} */}
      </Box>
    </Container>
  );
};

export default HubsHomeInfo;
