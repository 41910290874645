import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState } from "react";
import _ from "lodash";

const Accordion = ({ title, data }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const boxStyle = {
    borderRadius: "8px",
    border: "1px solid #ECECEC",
    boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.08)",
    // padding: "10px",
    margin: "10px",
  };

  return (
    <div style={boxStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "#E0EFFA",
          padding: "10px",
          borderRadius: "8px 8px 0px 0px",
        }}
        onClick={toggleAccordion}
      >
        <div
          style={{
            paddingLeft: "1rem",
            paddingRight: "1rem",
            fontWeight: "800",
          }}
        >
          {title}
        </div>
      </div>
      {isExpanded && (
        <div
          style={{
            display: "flex",
            // justifyContent: "space-between",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            flexWrap: "wrap",
          }}
        >
          {data?.map((obj, index) => {
            return (
              <div
                style={{
                  padding: "10px",
                  textTransform: "capitalize",
                  minWidth: "32%",
                }}
                key={_.uniqueId()}
              >
                <p style={{ fontWeight: "500", fontSize: "14px" }}>
                  {obj?.title}
                </p>
                <ul
                  style={{
                    listStylePosition: "inside",
                    lineHeight: "1.7rem",
                  }}
                >
                  {obj?.bullets?.map((item, index) => {
                    return (
                      <li
                        key={_.uniqueId()}
                        style={{
                          color: "#5A5A5A",
                          fontSize: "14px",
                        }}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      )}
      {!isExpanded && (
        <div
          style={{
            display: "flex",
            // justifyContent: "space-between",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
        >
          {data?.slice(0, 3)?.map((obj, index) => {
            return (
              <div
                style={{
                  padding: "10px",
                  textTransform: "capitalize",
                  minWidth: "32%",
                }}
                key={_.uniqueId()}
              >
                <p style={{ fontWeight: "500", fontSize: "14px" }}>
                  {obj?.title}
                </p>
                <ul
                  style={{
                    listStylePosition: "inside",
                    lineHeight: "1.7rem",
                  }}
                >
                  {obj?.bullets?.map((item, index) => {
                    return (
                      <li
                        key={_.uniqueId()}
                        style={{
                          color: "#5A5A5A",
                          fontSize: "14px",
                        }}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      )}
      {data?.length > 3 && (
        <div
          style={{
            textAlign: "center",
            margin: "10px",
            borderRadius: "0px 0px 8px 8px",
            borderTop: "1px solid #E4E4E4",
          }}
        >
          <Button
            endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
            variant="text"
            onClick={toggleAccordion}
            sx={{
              textTransform: "capitalize",
              fontFamily: "Avenir Medium",
              fontSize: "medium",
              paddingTop: "0.5rem",
            }}
          >
            {!isExpanded ? "Show More" : "Show Less"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Accordion;
