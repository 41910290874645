import {
  Box,
  Button,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setDownloadCredits } from "../../components/Redux/action";
import { BiSolidFilePdf } from "react-icons/bi";
import { RiFilePpt2Fill } from "react-icons/ri";
import HubsReportShowPdf from "../../components/HubsComponents/HubsReportShowPdf";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import { useAuth } from "../../context/authContext/Auth";
import RenewDrawer from "../../components/Common/RenewDrawer";
import { saveAs } from "file-saver";
import LoadingButton from "@mui/lab/LoadingButton";
import LockBox from "../../components/LockBox";
import {
  checkAndUpdateDownloadCredit,
  displayNoDownloadCredit,
} from "../../utils/common";
import DownloadAccessChecker from "../../components/Common/DownloadOptionCheck/DownloadAccessChecker";
import { DownloadingOutlined } from "@mui/icons-material";

const buttonStyle = {
  height: "40px",
  padding: "8px 24px",
  fontWeight: "500",
  borderRadius: "8px",
};

const boxStyle = {
  width: "100%",
  height: "794px",
  backgroundColor: "#F4F8FA",
};

function ReportDetailsTab({
  subscribed,
  sampleUrl,
  reportUrl,
  id,
  title,
  reportPdf,
  isFlash = false,
  blurUrl= false
}) {
  const [selectedTab, setSelectedTab] = useState(1);
  const SUBSCRIBED =
    subscribed === "yes" ? true : subscribed === "no" ? false : subscribed;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const initialSelectedFile =
    Array.isArray(reportPdf) && reportPdf.length > 0
      ? reportPdf[reportPdf.length - 1]
      : reportPdf || null;
  const [selectedFile, setSelectedFile] = useState(initialSelectedFile);
  const [pdfUrl, setPdfUrl] = useState("");
  const [noUrl, setNoUrl] = useState(reportUrl || sampleUrl ? true : false);
  const dispatch = useDispatch();
  const { total_credit, remaining_credit } = useSelector((state) => state);
  const { userInfo, config } = useAuth();

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };
  const handleFileChange = (event) => {
    const selectedFile = reportPdf.find(
      (file) => file.file_name === event.target.value
    );
    setSelectedFile(selectedFile);
  };
  const [pdfLoading, setPdfLoading] = useState(false);
  const { setNotify } = useAuth();
  const hasAccess = DownloadAccessChecker("report", id);

  if (isFlash && SUBSCRIBED) {
    reportUrl = reportPdf[reportPdf.length - 1].url;
  }

  function cleanFileName(input) {
    // Step 1: Remove the date using regex
    let cleaned = input.replace(/\d{1,2}\/\d{1,2}\/\d{4}$/, "").trim();

    // Step 2: Replace multiple spaces with a single space
    cleaned = cleaned.replace(/\s+/g, " ");

    // Step 3: Replace spaces with underscores for a proper file name
    let finalFileName = cleaned.replace(/\s/g, "_");

    return finalFileName;
  }

  async function forceDownload({ report_url, report_sample_url }, pdf_name) {
    const fileName =
      selectedFile !== null ? cleanFileName(selectedFile.fileName) : pdf_name;
    if (total_credit != null) {
      if (remaining_credit <= 0) {
        displayNoDownloadCredit(total_credit);
        return;
      }
    }

    const urlToDownload = SUBSCRIBED ? report_url : report_sample_url;
    setPdfLoading(true);
    setNotify({
      isOpen: true,
      message: "Download in Progress",
      type: "success",
      position: "right",
    });
    return new Promise(function (resolve, reject) {
      var x = new XMLHttpRequest();
      x.open("GET", urlToDownload, true);
      x.responseType = "blob";
      x.onload = function (e) {
        resolve(x.response);
      };
      x.onerror = function () {
        reject(new Error("Error occurred while downloading PDF."));
      };
      x.send();
    })
      .then(async function (pdf_blob) {
        saveAs(pdf_blob, fileName, "application/pdf");
        setNotify({
          isOpen: true,
          message: "Download Completed",
          type: "success",
          position: "right",
        });
        if (total_credit != null) {
          let credits = await checkAndUpdateDownloadCredit(config);
          dispatch(
            setDownloadCredits(credits.total_credit, credits.remaining_credit)
          );
        }

        setPdfLoading(false);
      })
      .catch(function (error) {
        setNotify({
          isOpen: true,
          message: "Download Failed",
          type: "error",
          position: "right",
        });

        setPdfLoading(false);
        console.log(error);
      });
  }

  useEffect(() => {
    if (userInfo?.account_type === "view only") {
      setNotify({
        isOpen: true,
        message: "Please wait, the report might take a little time to render.",
        type: "info",
        position: "right",
      });
    }
  }, []);

  useEffect(() => {
    if (!selectedFile) return;
    const getPdfUrl = () => {
      // IF THERE IS CUSTOM PDF
      if (selectedFile?.url && SUBSCRIBED) {
        return selectedFile.url;
      }
      return "";
    };

    const newPdfUrl = getPdfUrl();
    setNoUrl(newPdfUrl ? true : false);
    setPdfUrl(newPdfUrl);
  }, [selectedFile, SUBSCRIBED]);

  function showDownloadPdfButton() {
    // hasAccess to download the pdf
    if (hasAccess) {
      // if total_credit is null user can download as many times
      if (total_credit === null) return true;
      // if total_credit is number and remaining_credit is more than 0, user can download
      else if (!isNaN(total_credit) && remaining_credit > 0) return true;
      return false;
    }
    return false;
  }

  return (
    <Container maxWidth={"xl"} sx={{ my: "1rem", position: "relative" }}>
      {SUBSCRIBED}
      {!noUrl && (
        <LockBox
          sx={{
            top: "50%",
            left: "50%",
            zIndex: "5",
          }}
          componentName={"Report"}
          purchaseCustomText={
            SUBSCRIBED
              ? "Your relationship manager will be assigning this report shortly"
              : "You do not have access to this report"
          }
          reportType={SUBSCRIBED ? "Talk to us" : "sample"}
          report_id={id}
          title={title}
        />
      )}
      {!noUrl && <div className="overlay-blur"></div>}
      {noUrl && (
        <Box
          display={"flex"}
          sx={{
            width: "100%",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"16px"}
            mb={1}
            sx={{
              paddingLeft: "35px",
              width: "100%",
            }}
          >
            <Button
              variant={selectedTab === 0 ? "contained" : "text"}
              onClick={() => handleTabChange(0)}
              sx={buttonStyle}
              style={{
                backgroundColor: selectedTab === 0 ? "#446ba6" : "transparent",
                color: selectedTab === 0 ? "#fff" : "#5a5a5a",
                textTransform: "capitalize",
                display: "none",
              }}
            >
              Default
            </Button>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {hasAccess && pdfLoading ? (
                <LoadingButton
                  variant="outlined"
                  loading={pdfLoading}
                  loadingPosition="start"
                  startIcon={<BiSolidFilePdf />}
                  onClick={() =>
                    forceDownload(
                      {
                        report_url: pdfUrl.length !== 0 ? pdfUrl : reportUrl,
                        report_sample_url:
                          pdfUrl.length !== 0 ? pdfUrl : ( blurUrl ? blurUrl : sampleUrl),
                      },
                      title
                    )
                  }
                  sx={{
                    ...buttonStyle,
                    textTransform: "capitalize",
                    borderColor: "#002F75",
                    border: "0.8px solid",
                    "&:hover": {
                      backgroundColor: "#446ba6",
                      color: "#fff",
                    },
                  }}
                >
                  Download PDF
                </LoadingButton>
              ) : (
                <IconButton
                  aria-label="download pdf"
                  variant="outlined"
                  loadingPosition="start"
                  startIcon={<BiSolidFilePdf />}
                  onClick={() =>
                    forceDownload(
                      {
                        report_url: pdfUrl.length !== 0 ? pdfUrl : reportUrl,
                        report_sample_url:
                          pdfUrl.length !== 0 ? pdfUrl : ( blurUrl ? blurUrl : sampleUrl),
                      },
                      title
                    )
                  }
                  sx={{
                    ...buttonStyle,
                    textTransform: "capitalize",
                    borderColor: "#189cde",
                    border: "0.8px solid",
                    color: "#189cde",
                    "&:hover": {
                      backgroundColor: "#446ba6",
                      color: "#fff",
                    },
                    display:
                      userInfo?.account_type === "view only"
                        ? "none"
                        : undefined,
                  }}
                >
                  {hasAccess && pdfLoading ? (
                    <CircularProgress />
                  ) : (
                    <DownloadingOutlined />
                  )}
                </IconButton>
              )}
              <Button
                startIcon={<RiFilePpt2Fill />}
                variant={selectedTab === 2 ? "contained" : "text"}
                onClick={() => handleTabChange(2)}
                sx={buttonStyle}
                style={{
                  backgroundColor:
                    selectedTab === 2 ? "#446ba6" : "transparent",
                  color: selectedTab === 2 ? "#fff" : "#5a5a5a",
                  display: "none",
                }}
              >
                PPT
              </Button>
              {!SUBSCRIBED && (
                <>
                  <p>
                    You are viewing a {blurUrl ? 'blur version' : 'sample'} of this report. Full report is
                    available upon purchase.
                  </p>
                </>
              )}

              {reportPdf && reportPdf.length > 1 && (
                <FormControl
                  variant="outlined"
                  className="file-dropdown"
                  sx={{
                    minWidth: 120,
                    marginRight: 2,
                  }}
                >
                  <InputLabel
                    id="file-select-label"
                  >
                    Select File
                  </InputLabel>
                  <Select
                    labelId="file-select-label"
                    value={selectedFile.file_name}
                    onChange={handleFileChange}
                    variant="outlined"
                    label="Select File"
                    sx={{
                      borderRadius: "8px", // Ensure the Select component also has curved edges
                      maxWidth: 400,
                      borderColor: "red",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#189cde",
                        // border: "0.8px solid",
                      },
                    }}
                  >
                    {reportPdf.map((file) => (
                      <MenuItem key={file.file_name} value={file.file_name}>
                        {file.fileName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </Box>
          {selectedTab === 2 && hasAccess && (
            <Box ml={"auto"}>
              <Button
                variant="outlined"
                sx={buttonStyle}
                style={{
                  borderColor: "#446ba6",
                  color: "#446ba6",
                  fontWeight: "800",
                }}
                startIcon={<VerticalAlignBottomOutlinedIcon />}
              >
                <span
                  style={{ textTransform: "capitalize", marginRight: "5px" }}
                >
                  {" "}
                  Download{" "}
                </span>{" "}
                PPT
              </Button>{" "}
            </Box>
          )}
        </Box>
      )}
      <Box>
        <Box
          style={boxStyle}
          component="div"
          role="tabpanel"
          hidden={selectedTab !== 0}
        >
        </Box>

        <Box
          style={{
            backgroundColor: "#F4F8FA",
            width: "105%",
            marginLeft: "-3.5%",
          }}
          component="div"
          role="tabpanel"
          hidden={selectedTab !== 1}
          sx={{
            marginBottom: "2rem",
            overflow: "hidden",
          }}
        >
          <HubsReportShowPdf
            showDownload={showDownloadPdfButton()}
            report_id={pdfUrl ? pdfUrl : SUBSCRIBED ? reportUrl : ( blurUrl ? blurUrl : sampleUrl)}
          />
        </Box>
        <Box
          style={boxStyle}
          component="div"
          role="tabpanel"
          hidden={selectedTab !== 2}
        >
        </Box>
      </Box>
      <RenewDrawer
        report_id={id}
        title={title}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={"buy"}
      />
    </Container>
  );
}

export default ReportDetailsTab;
