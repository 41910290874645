import React from "react";

import { TemplateView, Column } from "../templates.styles";

import Analysis from "../../components/TemplateComponents/Analysis";
import Heading from "../../components/TemplateComponents/Heading";
import SubHeading from "../../components/TemplateComponents/SubHeading";
import Table from "../../components/TemplateComponents/Table";
import Boxes from "../../components/TemplateComponents/Boxes";
import Graph from "../../Graphs";
import _ from "lodash";

import { COLORS } from "../templates.configs";

let COLORSARRAY = COLORS?.split(",")?.map((item) => {
  return item.trim();
});
export default class Template1100 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templateData: [],
    };
  }

  componentDidMount() {
    if (this.props?.templateData) {
      this.setState({
        templateData: this.props.templateData,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      if (this.props?.templateData) {
        this.setState({
          templateData: this.props.templateData,
        });
      }
    }
  }

  render() {
    let templateViewData = [...this.state.templateData].filter(
      (item) => item.position === 1 || item.position === 2
    );

    return (
      <TemplateView
        justifyContent={"space-betwen"}
        alignItems={"center"}
        flexDirection={"row"}
      >
        {templateViewData?.map((item) => {
          if (item.type === "analysis") {
            return (
              <Column
                key={_.uniqueId()}
                width={"50%"}
                height={"100%"}
                flexDirection={"column"}
              >
                {item?.data?.meta?.heading ? (
                  <SubHeading subheading={item.data.meta.heading} />
                ) : (
                  ""
                )}
                <Analysis analysisData={item?.data?.analysis} />
              </Column>
            );
          } else if (item.type === "graph") {
            return (
              <Column width={"50%"} height={"100%"}>
                <Graph
                  data={item?.data?.data}
                  meta={item?.data?.meta}
                  header={item?.data?.heading}
                  footer={""}
                  width={"50%"}
                  height={"100%"}
                  yAxisName={""}
                  xAxisName={""}
                  graphType={item?.data?.graphType}
                  dataHidden={
                    item?.data?.dataHidden && item.data.dataHidden === true
                      ? true
                      : false
                  }
                  allow_cagr={
                    item?.data?.hasOwnProperty("cagr_value") ? true : false
                  }
                  cagr_year={item?.data?.cagr_year}
                  cagr_value={item?.data?.cagr_value}
                  showValues={true}
                  valueFontSize={12}
                  valueFontBold={true}
                  templateName={this.props.templateName}
                  slideName={this.props.slideName}
                />
              </Column>
            );
          } else if (item.type === "table") {
            return (
              <Column width={"50%"} height={"100%"}>
                <Table
                  tableData={item?.data}
                  parentTitle={this.props.parentTitle}
                />
              </Column>
            );
          } else if (item.type === "boxes") {
            return (
              <Column
                width={"50%"}
                height={"100%"}
                flexDirection={"column"}
                style={{ marginLeft: "20px" }}
              >
                {item?.data?.heading ? (
                  <SubHeading subheading={item.data?.heading} />
                ) : (
                  ""
                )}
                <Boxes
                  boxesData={item?.data?.data}
                  backgroundColors={COLORSARRAY}
                  slideName={this.props.slideName}
                />
              </Column>
            );
          }
        })}
      </TemplateView>
    );
  }
}
