import React from 'react';
import styles from './rowCard.module.css';

const CompanyRowCard = ({ company, setSelectedCompany }) => {
  const { name, logo, founded, country, revenue, subscribedReports, totalReports, year } = company;
  return (
    <div className={styles.companyCard} onClick={() => setSelectedCompany(company)}>
      <div className={styles.companyInfo}>
        <img src={logo} alt={`${name} logo`} className={styles.companyLogo} />
        <div className={styles.companyDetails}>
          <h3 className={styles.companyName}>{name}</h3>
          <p className={styles.companyMeta}>
            Est. {founded} • {country} • $ {revenue} million ({year})
          </p>
        </div>
      </div>
      <div className={styles.reportInfo}>
        <div>
          <h4>Reports</h4>
          <p>{totalReports}</p>
        </div>
        <div>
          <h4>Purchased Reports</h4>
          <p>{subscribedReports}</p>
        </div>
      </div>
    </div>
  );
};

export default CompanyRowCard;
