import { useReducer, useEffect } from "react";
import { initialState, CompaniesReportContext, reducer } from "./STATE";
import Layout from "./Layout";
import { SYNAPSE_API } from "../../../api/Api";
import Pagination from "./Pagination";
import Loader from "../../../components/Common/Loader";
import SearchFilter from "./SearchFilter";
import HubsReportCard from "../../../components/HubsComponents/HubsReportCard";

function CompanyReportList({ selectedCompany }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getCompanyReports = async ({ company_id, hubIds, categoryId }) => {
    dispatch({ type: "SET_LOADING", payload: "PENDING" });
    await SYNAPSE_API.get("/api/company/company-reports", {
      params: {
        company_id: company_id,
        hub_ids: hubIds,
        category_id: categoryId,
      },
    })
      .then((response) => {
        dispatch({
          type: "SET_COMPANY_REPORTS",
          payload: response.data.reports,
        });
        dispatch({ type: "SET_COMPANY_REPORTS_SHOWING" });
        dispatch({ type: "SET_LOADING", payload: "IDLE" });
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: "SET_LOADING", payload: "IDLE" });
      });
  };

  useEffect(() => {
    getCompanyReports(selectedCompany);
  }, []);

  useEffect(() => {
    dispatch({ type: "SET_COMPANY_REPORTS_SHOWING" });
  }, [state.pagination]);

  return (
    <CompaniesReportContext.Provider value={{ state, dispatch }}>
      <Layout>
        <SearchFilter />
        {state.loading === "PENDING" ? (
          <Loader />
        ) : state.totalReports === 0 ? (
          <h2>No Reports Found</h2>
        ) : (
          state.reportsShowing.map((report) => (
            <HubsReportCard
              key={report.mi_reports_id}
              {...report}
              style={{ marginBottom: "20px" }}  
              report_id={report.mi_reports_id}
              currentTab={"Companies Covered"}
              getReportData={() =>
                getCompanyReports(selectedCompany)
              }
              company={selectedCompany}
              ids={{
                companyId: selectedCompany.company_id,
                hubId: report.hub_id,
                reportId: report.mi_reports_id,
                companyName: selectedCompany.name,
              }}
            />
          ))
        )}
        <Pagination />
      </Layout>
    </CompaniesReportContext.Provider>
  );
}

export default CompanyReportList;
