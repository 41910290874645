import axios from "axios";
import API from "../../../../api/Api";

let filterController = null;

const libReportsType = {
  "hub_and_library_reports": "all",
  "only_library_reports": "library",
  "only_hub_reports": "hubs",
}

const whatReportsFilterToShow = (state) => {
  if(state.currentTab === "CATALOGUE") return "only_catalogue_reports";
  if(state.currentTab === "MY_REPORTS") {
    if(state.onlyHubReportsForMyReports === true) return "only_hub_reports";
    if(state.isLibraryReports === true) return "only_library_reports";
    return "only_hub_reports";
  }
  return state.isLibraryHubReport ?? 'all';
}

export const getFilters = ({ setFilters, category, dispatch, state }) => {
  const { Base_URL } = API;
  if (filterController) {
    filterController.abort();
  }
  filterController = new AbortController();
  const signal = filterController.signal;
  axios
    .post(
      `${Base_URL}/api/reports/reportCatalogueFilter`,
      {
        catId: category,
        library_report_type: libReportsType[whatReportsFilterToShow(state)]
      },
      {
        signal,
      }
    )
    .then((res) => {
      const regions = Object.keys(res.data.geography);
      const countries = Object.values(res.data.geography).flat();
      const count = res.data.reports_count_by_geography;
      setFilters({
        regions,
        countries: Array.from(new Set(countries.sort())),
        count,
      });
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      if (signal.aborted) return;
      dispatch({
        type: "SET_FILTER_MODAL_LOADING",
        payload: "IDLE",
      });
    });
};

// remove this comment