import React from "react";
import Accordion from "../../Accordion";
import { Box, Container } from "@mui/material";
import _ from "lodash";

const Scope = ({ data }) => {
  let sampleData = {
    scope: {
      "By Category": [
        {
          title: "Meat",
          bullets: ["Beef", "Pork", "Mutton", "Poultry", "Other Meat Types"],
        },
        {
          title: "Seafood",
          bullets: ["Fish", "Shrimp", "Other Seafood Types"],
        },
        {
          title: "Meat Substitutes",
          bullets: [
            "Tofu",
            "Tempeh",
            "Textured Vegetable Protein",
            "Other Meat Substitutes",
          ],
        },
      ],
      "By Segmentation": [
        {
          title: "Form",
          bullets: ["Fresh & Chilled", "Frozen & Canned", "Processed"],
        },
        {
          title: "Distribution Channel",
          bullets: ["On-Trade", "Off-Trade"],
        },
        {
          title: "Off-Trade",
          bullets: [
            "Supermarket/hypermarket",
            "Convenience Stores",
            "Online Channels",
            "Other Channels",
          ],
        },
      ],
      "By Geography": [
        {
          title: "Geography",
          bullets: [
            "North America",
            "Europe",
            "Asia-Pacific",
            "South America",
            "Middle East",
            "Africa",
          ],
        },
        {
          title: "North America",
          bullets: [
            "United States",
            "Canada",
            "Mexico",
            "Rest of North America",
          ],
        },
        {
          title: "Europe",
          bullets: [
            "Germany",
            "United Kingdom",
            "France",
            "Spain",
            "Italy",
            "Russia",
            "Netherlands",
            "Rest of Europe",
          ],
        },
        {
          title: "Asia-Pacific",
          bullets: [
            "China",
            "India",
            "Japan",
            "South Korea",
            "Indonesia",
            "Malaysia",
            "Australia",
            "Rest of Asia-Pacific",
          ],
        },
        {
          title: "South America",
          bullets: ["Brazil", "Argentina", "Rest of South America"],
        },
        {
          title: "Middle East",
          bullets: [
            "Saudi Arabia",
            "United Arab Emirates",
            "Qatar",
            "Bahrain",
            "Oman",
            "Kuwait",
            "Rest of Middle East",
          ],
        },
        {
          title: "Africa",
          bullets: ["South Africa", "Nigeria", "Egypt", "Rest of Africa"],
        },
      ],
    },
  };
  return (
    <Box>
      <h3 style={{ marginLeft: "1rem", marginBottom: "2rem" }}>
        Our Segmental Coverage
      </h3>
      {Object?.entries(data ?? {})?.map(([key, value], index) => {
        return <Accordion key={_.uniqueId()} title={key} data={value} />;
      })}
    </Box>
  );
};

export default Scope;
