import { createContext, useContext } from "react";
import { getModifiedAllCompanyReportsCount, getModifiedCompaniesReports, getModifiedMyCompanyReportsCount } from "./helper";

const paginationInitialState = {
  limit: 10,
  page: 1,
};

export const initialState = {
  loading: "PENDING",
  currentTab: "ALL_REPORTS",
  search: "",
  reports: [],
  reportsShowing: [],
  totalReports: 0,
  totalMyReports: 0,
  pagination: paginationInitialState,
};

export const reducer = (state, action) => {
  let newState = {};
  switch (action.type) {
    case "SET_COMPANY_REPORTS":
      return {
        ...state,
        reports: action.payload,
        totalReports: action.payload.length,
        totalMyReports: action.payload.filter(
          (report) => report.subscribed === "yes"
        ).length,
      };

    case "SET_COMPANY_REPORTS_SHOWING":
      return {
        ...state,
        reportsShowing: getModifiedCompaniesReports(state),
      };

    case "CHANGE_TAB":
      newState = {
        ...state,
        search: "",
        currentTab: action.payload,
        loading: "IDLE",
        pagination: paginationInitialState,
      };
      
      newState.reportsShowing = getModifiedCompaniesReports(newState);
      newState.totalMyReports = getModifiedMyCompanyReportsCount(newState);
      newState.totalReports = getModifiedAllCompanyReportsCount(newState);

      return newState;

    case "SET_PAGINATION_PAGE":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
      };

    case "SET_PAGINATION_LIMIT":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          limit: action.payload.limit,
        },
      };

    case "SET_SEARCH":
      newState = {
        ...state,
        pagination: paginationInitialState,
        search: action.payload,
      };
      return {
        ...newState,
        reportsShowing: getModifiedCompaniesReports(newState),
        totalReports: getModifiedAllCompanyReportsCount(newState),
        totalMyReports: getModifiedMyCompanyReportsCount(newState),
      };

    case "RESET_PAGINATION":
      return {
        ...state,
        pagination: paginationInitialState,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export const CompaniesReportContext = createContext();
export const useCompaniesReportContext = () =>
  useContext(CompaniesReportContext);
