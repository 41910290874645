import CloseIcon from "@mui/icons-material/Close";
import useDebounce from "../../../../hooks/useDebounce";
import searchImg from "../../../../assets/search.svg";
import styles from "../style.module.css";
import { IconButton } from "@mui/material";
import { useCompaniesReportContext } from "../STATE";
import { useState, useEffect } from "react";

function Search() {
  const { state, dispatch } = useCompaniesReportContext();

  const [searchInput, setSearchInput] = useState("");

  const debounceSearchTerm = useDebounce(searchInput, 1000);

  const handleInputChange = (value) => {
    setSearchInput(value);
  };

  const handleChange = (search) => {
    dispatch({
      type: "SET_SEARCH",
      payload: search,
    });
  };

  const handleResetInputChange = () => {
    if (!searchInput) return;
    handleInputChange("");
  };

  useEffect(() => {
    setSearchInput(state.search);
  }, [state.search]);

  useEffect(() => {
    if (state.search === "" && debounceSearchTerm === "") return;
    handleChange(debounceSearchTerm);
  }, [debounceSearchTerm]);

  return (
    <section className={styles.searchMainContainer}>
      <img src={searchImg} alt="search" />
      <input
        type="text"
        placeholder="Search for companies"
        value={searchInput}
        onChange={(e) => handleInputChange(e.target.value)}
      />
      <IconButton
        onClick={() => handleResetInputChange()}
        sx={{
          position: "absolute",
          top: "7px",
          right: "10px",
        }}
      >
        <CloseIcon />
      </IconButton>
    </section>
  );
}

export default Search;
