import { Box, Button } from "@mui/material";
import { usePurchaseHistoryCtx } from "./STATE";

const buttonStyle = {
  height: "40px",
  padding: "8px 24px",
  fontWeight: "500",
  borderRadius: "8px",
  textTransform: "capitalize",
};

function Tabs() {
  const {
    state: { selectedTab, loading, count: { all, renewal, expired } },
    dispatch,
  } = usePurchaseHistoryCtx();

  const countToShow = {
    "ALL": all,
    "RENEWAL": renewal,
    "EXPIRED": expired,
  }

  const handleTabChange = (tab) => {
    dispatch({ type: "SET_SELECTED_TAB", payload: tab });
  }

  return (
    <Box display={"flex"} gap={"16px"} mb={10}>
      {tabsToRender.map((tab, index) => {
        return (
          <Button
            key={index}
            variant={selectedTab === tab.value ? "contained" : "text"}
            onClick={() => handleTabChange(tab.value)}
            sx={buttonStyle}
            style={{
              backgroundColor: selectedTab === tab.value ? "#446ba6" : "transparent",
              color: selectedTab === tab.value ? "#fff" : "#5a5a5a",
            }}
          >
            {tab.label} {`${loading === "PENDING" ? "" : "(" + countToShow[tab.value] + ")"}`}
          </Button>
        );
      })}
    </Box>
  );
}

export default Tabs;

const tabsToRender = [
  { label: "All", value: "ALL" },
  { label: "Renewal", value: "RENEWAL" },
  { label: "Expired", value: "EXPIRED" },
];
