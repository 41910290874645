import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import Notification from "../../components/Common/Notification";
import UserAccessHOC from "../../components/HOC/UserAccess";
import styles from "./styles.module.css";
import { LinearProgress } from "@mui/material";
import { Link, useLocation, useParams, useSearchParams,useNavigate  } from "react-router-dom";
import PasswordValidation from "../../components/Common/PasswordValidation";
import DialogModal from "../../components/Common/DialogModal";
import PasswordVisibilityInput from "../../components/Common/PasswordVisibiltyInput";
import API from "../../api/Api";
import LabelInputField from "../../components/Common/LabelInputField";
import infoToolTipIcon from "../../assets/info_tool_tip.svg";
const LinkSendImg =
  "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/link_sent.svg";

function InvitationRegister() {
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState(errorsState);
  const [isBtnDisabeled, setIsBtnDisabeled] = useState(true);
  const [loading, setLoading] = useState("IDEAL");
  const [open, setOpen] = useState(false);

  const { notify, setNotify } = useAuth();
  const { Base_URL } = API;
  const location = useLocation();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const emailParam = searchParams.get('email');
  let permissionsParam = searchParams.get('permissions');
  let invitedByParam = searchParams.get('invited-by');
  const navigate = useNavigate();

  if(permissionsParam === 'admin'){
    permissionsParam = 'Admin'
  }else if(permissionsParam === 'view-only'){
           permissionsParam = 'View Only'
  }else if(permissionsParam === 'view-and-download'){
        permissionsParam = 'View & Download'
  }

  const {  name, password, confirm_password } = formState;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("PENDING");
    await axios
      .post(Base_URL + "/api/users/register-invited-user", {
        invitedBy: invitedByParam,
        email: emailParam,
        permissions: permissionsParam === "View Only" ? "view only" : permissionsParam === "View & Download" ? "view & download" : permissionsParam === "Admin" ? 'admin' : '',
        name,
        password,
      })
      .then((response) => {
        setOpen(true);
        setFormState(initialState);
        setErrors(errorsState);
        navigate(`/`);
      })
      .catch((error) => {
        console.error(error, "err");
        setNotify({
          isOpen: true,
          message: error.response.data?.message || "Some Error Occurred",
          type: "error",
          position: "right",
        });
      })
      .finally(() => {
        setLoading("IDEAL");
      });
  };

  const handleFormChange = (e) => {
    let error = errors;
    let value = e.target.value;

    switch (e.target.name) {
     
      case "name":{
        if (value.length < 5)
          error = { ...errors, name: "Invalid Name, minimum 5 characters" };
        else error = { ...errors, name: false };
        break;
       }
      case "password":{
       value = value.trim();
        error = {
          ...errors,
          password: true,
          disabled: value.length === 0,
        };
        setErrors(error);
        break;
      }
      case "confirm_password":{
        value = value.trim();
        if (password !== value)
          error = { ...errors, confirm_password: "Password not matched" };
        else error = { ...errors, confirm_password: false };
        break;
}
      default:
        break;
    }

    setErrors(error);

    const { name, confirm_password, passwordValid } = error;
    if (
      typeof name === "boolean" &&
      !name &&
      // typeof email === "boolean" &&
      // !email &&
      typeof confirm_password === "boolean" &&
      !confirm_password &&
      passwordValid
    ) {
      setErrors((p) => ({ ...p, disabled: false }));
    } else {
      setErrors((p) => ({ ...p, disabled: true }));
    }

    setFormState((p) => ({
      ...p,
      [e.target.name]: value,
    }));
  };


  useEffect(()=>{
    if(errors.disabled === false && emailParam && permissionsParam ){
      setIsBtnDisabeled(false);
    }else{
      setIsBtnDisabeled(true)
    }
  }, [errors])


  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <div className={styles.detailsBox}>
        <h1>Registration For Invited Users</h1>
        <div className={styles.inputContainer}>
          <form onSubmit={handleSubmit}>
            <LabelInputField>
              <LabelInputField.Label>
                Name
                <LabelInputField.Tooltip
                  value={"Enter atleast 5 characters long name."}
                />
              </LabelInputField.Label>
              <LabelInputField.Input
                name="name"
                onChange={(e) => handleFormChange(e)}
                placeholder="Enter you name."
                value={name}
              />
              <LabelInputField.Error errors={errors.name} />
            </LabelInputField>

            <LabelInputField>
              <LabelInputField.Label>
                Email
                <LabelInputField.Tooltip
                  value={"You can not change email address."}
                />
              </LabelInputField.Label>
              <LabelInputField.Input
                name="email"
                disabled={true}
                // placeholder="Enter you email"
                value={emailParam}
              />
              <LabelInputField.Error errors={errors.email} />
            </LabelInputField>

            <LabelInputField>
            <LabelInputField.Label>
                Permissions
                <LabelInputField.Tooltip
                  value={"You can not change permissions."}
                />
              </LabelInputField.Label>
              <LabelInputField.Input
                name="permissions"
                disabled={true}
               
                value={permissionsParam}
              />
              <LabelInputField.Error errors={errors.email} />
            </LabelInputField>

     

            <LabelInputField>
              <LabelInputField.Label>
                Password
                <LabelInputField.Tooltip value={"Enter valid password"} />
              </LabelInputField.Label>
              <LabelInputField.CustomInput>
                <PasswordVisibilityInput
                  type="password"
                  name="password"
                  placeholder="Enter your password."
                  value={password}
                  onChange={(e) => handleFormChange(e)}
                />
              </LabelInputField.CustomInput>
              <LabelInputField.CustomError>
                {errors.password && (
                  <PasswordValidation
                    password={password}
                    setErrors={setErrors}
                  />
                )}
              </LabelInputField.CustomError>
            </LabelInputField>

            <LabelInputField>
              <LabelInputField.Label>
                Confirm Password
                <LabelInputField.Tooltip value={"Confirm your password."} />
              </LabelInputField.Label>
              <LabelInputField.CustomInput>
                <PasswordVisibilityInput
                  type="password"
                  name="confirm_password"
                  placeholder="Re-enter your password"
                  value={confirm_password}
                  onChange={(e) => handleFormChange(e)}
                />
              </LabelInputField.CustomInput>
              <LabelInputField.CustomError>
                {errors.confirm_password && (
                  <p className={styles.errorInput}>{errors.confirm_password}</p>
                )}
              </LabelInputField.CustomError>
            </LabelInputField>

            <div className={styles.loginContainer}>
              <button type="button" className={styles.backBtn}>
                <Link to="/">
                  <KeyboardBackspaceIcon />
                  Go to Login
                </Link>
              </button>'
              '
              <button
                type="submit"
                disabled={isBtnDisabeled}
                className={isBtnDisabeled ? `${styles.disabledBtn}` : ""}
                onClick={handleSubmit}
              >
                Register
              </button>
            </div>
            {loading === "PENDING" && <LinearProgress />}
          </form>
        </div>
      </div>
      <DialogModal open={open} handleClose={() => setOpen(false)}>
        <div className={styles.dialogModal}>
          <figure>
            <img src={LinkSendImg} alt="sent" />
          </figure>
          <h4>Confirmation link sent to your email</h4>
        </div>
      </DialogModal>
    </>
  );
}

export default InvitationRegister;

const initialState = {
  name: "",
  password: "",
  confirm_password: "",
};

const errorsState = {
  ...initialState,
  password: false,
  disabled: true,
  passwordValid: false,
};
