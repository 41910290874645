import { Stack, Box } from "@mui/material";
import { useRecentDevelopment } from "../STATE";
import { useRef } from "react";
import DevelopmentTable from "./DevelopmentTable";
import DevelopmentDownload from "./DevelopmentDownload";

function TableSection() {
  const tableRef = useRef(null);
  const { state } = useRecentDevelopment();
  return (
    <Box my={3}>
      <Stack spacing={2}>
        <DevelopmentDownload tableRef={tableRef} />
        <DevelopmentTable data={state.dataToDisplay} tableRef={tableRef}  />
      </Stack>
    </Box>
  );
}

export default TableSection;
