import { employeeOptionsConfig, revenueOptionsConfig } from "../../components/CompaniesComponents/CompanyHome/CompanyHome.config";

const setBasedOnSearch = (companies, search) => {
  if (!search) {
    return companies;
  }
  const searchCompanies = [];

  for(let i = 0; i < companies.length; i++) {
    if(companies[i].name.toLowerCase().includes(search.toLowerCase())) {
      searchCompanies.push(companies[i]);
    }
  }

  return searchCompanies;

};

export const setBasedOnPagination = (companies, { page, limit }) => {
  const start = (page - 1) * limit;
  const end = page * limit;
  const companiesList = [];

  for (let i = start; i < end; i++) {
    if (companies[i]) {
      companiesList.push(companies[i]);
    }
  }
  return companiesList;
};

const sortCompanies = (companies, filters) => {
  const { sortBy } = filters;
  const sortCompaniesComp = (a, b) => {
    if (sortBy === "Name (A-Z)") {
      return a.name.localeCompare(b.name);
    } else if (sortBy === "Name (Z-A)") {
      return b.name.localeCompare(a.name);
    } else if (sortBy === "Revenue") {
      return a.revenue - b.revenue;
    } else if (sortBy === "Employees") {
      return a.employees - b.employees;
    } else if (sortBy === "Headquarters") {
      return a.headquarters.localeCompare(b.headquarters);
    } else {
      return companies;
    }
  };

  const companiesCopy = [...companies];
  return companiesCopy.sort(sortCompaniesComp);
};

const filterCompanies = (companies, filters) => {
  const {
    geography: { regions, countries },
    revenue,
    employees,
  } = filters;

  let filteredCompanies = [...companies];
  let filterRan = false;

  if(regions.length > 0 || countries.length > 0) {
    filterRan = true;
    filteredCompanies = filteredCompanies.filter((company) => regions.includes(company.region) || countries.includes(company.country));
  }

  if(employees.length > 0) {
    filterRan = true;
    const limits = employees.map((employee) => employeeOptionsConfig[employee]);
    filteredCompanies = filteredCompanies.filter((company) => {
      for(let i = 0; i < limits.length; i++) {
        if(company.employees >= limits[i][0] && company.employees <= limits[i][1]) {
          return true;
        }
      }
      return false;
    });
  }

  if(revenue.length > 0) {
    filterRan = true;
    const limits = revenue.map((rev) => revenueOptionsConfig[rev]);
    filteredCompanies = filteredCompanies.filter((company) => {
      for(let i = 0; i < limits.length; i++) {
        if(company.revenue >= limits[i][0] && company.revenue <= limits[i][1]) {
          return true;
        }
      }
      return false;
    });
  }

  if(!filterRan) {
    return companies;
  }

  return filteredCompanies;

};

export const getModifiedCompanies = (state) => {
  return setBasedOnPagination(
    sortCompanies(
      filterCompanies(
        setBasedOnSearch(state.companies, state.search),
        state.filters
      ),
      state.filters
    ),
    state.pagination
  );
};

export const getModifiedCompanyCount = (state) => {
  return filterCompanies(setBasedOnSearch(state.companies, state.search), state.filters).length;
};
