import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import HubsHomeChatDialog from "../GlobalComponents/ContactUs";
import { Button, Grid } from "@mui/material";
import Notification from "../Common/Notification";
import { useAuth } from "../../context/authContext/Auth";
import HubsHomeInfo from "./HubsHomeInfo";
import { useEffect } from "react";
import axios from "axios";
import API from "../../api/Api";
import Cookies from "universal-cookie";
import { useState } from "react";
import Scope from "./About/Scope";
import ResearchMethodology from "./About/ResearchMethodology";
import WhyUs from "./About/WhyUs";
import PrimaryResearch from "./About/PrimaryResearch";
import Loader from "../Common/Loader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function HubsHomeNavbar(props) {
  const { notify, setNotify } = useAuth();
  const [value, setValue] = React.useState(1);
  const buttonStyle = {
    lineHeight: ["inherit", "inherit"],
    letterSpacing: ["inherit", "inherit"],
    padding: ["6px", "6px"],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //Get user token
  const cookies = new Cookies();
  const userToken = cookies.get("TOKEN");
  const hub_id = cookies.get("hub_id");

  // new data

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [data, setData] = useState({});
  const [lorienData, setLorienData] = useState({});

  useEffect(() => {
    axios
      .post(
        `${API.Base_URL}/api/hubs/home`,
        {
          hubId: props.hubId,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.data);
      });
  }, []);
  //Want host name from current url
  // let routeArray = window.location.href.split("/");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.hubId) {
      setIsLoading(true);
      axios
        .get(`${API.Base_URL}/api/hubs/about_us?hub_id=${props.hubId}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((res) => {
          setLorienData(res.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <Box
      sx={{
        display: {
          xs: "block",
          sm: "block",
          md: "flex",
        },
        padding: {
          xs: "0px",
          sm: "0px",
          md: "0px 40px",
        },
      }}
    >
      <Notification notify={notify} setNotify={setNotify} />
      <Box
        sx={{
          width: "100%",
        }}
        // sx={{
        //   flexGrow: 1,
        //   bgcolor: "background.paper",
        //   display: "flex",
        //   // height: 224,
        //   "&.MuiTab-root": {
        //     px: "12px",
        //     py: "30px",
        //     my: "40px",
        //     textTransform: "capitalize",
        //   },
        // }}
      >
        <Grid container>
          <Grid item md={2}>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                fontFamily: "Avenir Roman",
                borderRadius: "8px",
                boxShadow: "0px 2px 20px 0px rgba(0, 47, 117, 0.08)",
                borderColor: "divider",
                my: 4,
                color: "#191919",
                backgroundColor: "#FFF",
                overflow: ["visible", "visible", "hidden"],
                "& button": {
                  pr: {
                    xs: 0,
                    sm: 3,
                    md: 7,
                  },
                  py: {
                    xs: 0,
                    sm: 0,
                    md: 3,
                  },
                  textTransform: "capitalize",
                  alignItems: "baseline",
                  textAlign: "start",
                  // borderRadius: 2,
                  // border:"1px solid rgba(0, 0, 0, 0.12);"
                  boxShadow: [
                    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                    "none",
                    "none",
                  ],
                },
                "& button:hover": {
                  backgroundColor: "#f8f8f8",
                },
                "& button:active": {
                  backgroundColor: "#f8f8f8",
                },
                "& button.Mui-selected": {
                  backgroundColor: "#F0F7FC",
                  color: "#189CDE",
                  borderLeft: "4px solid var(--primary-teal-1, #189CDE)",
                },
              }}
              TabIndicatorProps={{
                hidden: true,
              }}
            >
              <Tab
                disabled={true}
                label="Index"
                {...a11yProps(0)}
                sx={{
                  color: "black",
                  borderBottom: "1px solid #E4E4E4",
                  boxShadow: " 0px 2px 20px 0px rgba(0, 47, 117, 0.08)",
                  fontWeight: "600",
                  //Modify disabled tab style
                  "&.Mui-disabled": {
                    color: "black",
                    fontWeight: "600",
                  },
                }}
              />
              <Tab className="tab" label="About the Hub" {...a11yProps(0)} />
              <Tab className="tab" label="Scope" {...a11yProps(1)} />
              <Tab
                className="tab"
                label="Research Methodology"
                {...a11yProps(2)}
              />
              <Tab className="tab" label="Primary Research" {...a11yProps(4)} />
              <Tab className="tab" label="Why Us?" {...a11yProps(3)} />
            </Tabs>
          </Grid>
          <Grid item xs={11} md={9}>
            <TabPanel value={value} index={1}>
              {isLoading ? (
                <Loader
                  sx={{
                    height: "50vh",
                  }}
                />
              ) : (
                <HubsHomeInfo
                  data={lorienData?.introduction?.title}
                  heading="Introduction"
                  isLoading={isLoading}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {isLoading ? (
                <Loader sx={{ height: "50vh" }} />
              ) : (
                <Scope data={lorienData?.scope} isLoading={isLoading} />
              )}
            </TabPanel>
            <TabPanel value={value} index={3}>
              {isLoading ? (
                <Loader sx={{ height: "50vh" }} />
              ) : (
                <ResearchMethodology
                  framework={lorienData?.research_methodology_framework ?? {}}
                  keyVariables={
                    lorienData?.research_methodology_keyVariables ?? {}
                  }
                  isSubscribed={props.isSubscribed}
                  isLoading={isLoading}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={4}>
              {isLoading ? (
                <Loader sx={{ height: "50vh" }} />
              ) : (
                <PrimaryResearch
                  researchData={lorienData?.primary_research ?? {}}
                  isSubscribed={props.isSubscribed}
                  isLoading={isLoading}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={5}>
              {isLoading ? (
                <Loader sx={{ height: "50vh" }} />
              ) : (
                <WhyUs
                  data={lorienData?.why_us?.data}
                  dataPoints={lorienData?.why_us?.dataPoints}
                  isLoading={isLoading}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={6}>
              {isLoading ? (
                <Loader sx={{ height: "50vh" }} />
              ) : (
                <HubsHomeInfo
                  data={props.case_studies}
                  heading="Case studies"
                  isLoading={isLoading}
                />
              )}
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
