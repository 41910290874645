import { useReportsContext, getReports } from "../STATE";
import { memo } from "react";
import { Box } from "@mui/material";
import styles from "../styles.module.css";
import Loader from "../../../components/Common/Loader";
import HubsReportCardForReports from "../../../components/HubsComponents/HubsReportCardForReports";
import SynapseReportsCard from "../../../components/HubsComponents/SynapseReportsCard";

function MyReports() {
  const { state, dispatch } = useReportsContext();

  const getReportsData = async () => {
    dispatch({ type: `SET_${state.currentTab}_LOADING`, payload: "PENDING" });
    await getReports(state, dispatch);
  };

  return (
    <div className={styles.myReportsMainContainer}>
      <section>
        {state.myReportsLoading === "PENDING" ? (
          <Loader />
        ) : state.myReports.length === 0 ? (
          <center>No Reports Found</center>
        ) : (
          state.myReports.map((report, i) => {
            if (report.hasOwnProperty("synapse_report_id")) {
              return (
                <Box position="relative">
                  <SynapseReportsCard
                    key={i}
                    {...report}
                    currentTab={state.currentTab}
                    report_id={report.synapse_report_id}
                    getReportData={getReportsData}
                  />
                </Box>
              );
            } else {
              return (
                <HubsReportCardForReports
                  key={i}
                  {...report}
                  currentTab={state.currentTab}
                  report_id={report.mi_reports_id}
                  getReportData={getReportsData}
                />
              );
            }
          })
        )}
      </section>
    </div>
  );
}

export default memo(MyReports);
