import { useEffect, useState } from "react";
import { useReportsContext, getReports } from "../STATE";
import { IconButton } from "@mui/material";
import styles from "../styles.module.css";
import searchImg from "../../../assets/search.svg";
import useDebounce from "../../../hooks/useDebounce";
import CloseIcon from "@mui/icons-material/Close";
import { getTabBasedOnLibrary } from "../reports.utils";

function Search() {
  const { state, dispatch } = useReportsContext();

  const [searchInput, setSearchInput] = useState("");
  const debounceSearchTerm = useDebounce(searchInput, 1000);

  const handleInputChange = (value) => {
    setSearchInput(value);
  };
  
  const handleChange = (search) => {
    dispatch({ type: `SET_${getTabBasedOnLibrary(state.currentTab, state.isLibraryReports)}_LOADING`, payload: "PENDING" });
    dispatch({
      type: "SEARCH_INPUT",
      payload: search,
      fn: async (state) => await getReports(state, dispatch),
    });
  };

  const handleResetInputChange = () => {
    if(!searchInput) return;
    handleInputChange("");
  }

  useEffect(() => {
    setSearchInput(state.search);
  }, [state.search])

  useEffect(() => {
    if(state.search === "" && debounceSearchTerm === "") return;
    handleChange(debounceSearchTerm);
  }, [debounceSearchTerm]);

  return (
    <section className={styles.searchMainContainer}>
      <img src={searchImg} alt="search" />
      <input
        type="text"
        placeholder="Search relevant reports"
        value={searchInput}
        onChange={(e) => handleInputChange(e.target.value)}
      />
      <IconButton
        onClick={() => handleResetInputChange()}
        sx={{
          position: "absolute",
          top: "7px",
          right: "10px",
        }}
      >
        <CloseIcon />
      </IconButton>
    </section>
  );
}

export default Search;
